import { Modal, Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  padding: 3,
  boxShadow: 24,
  maxHeight: "100%",
  overflow: "auto",
};

interface IAdditionalDocModal {
  handleClose: () => void;
  open: boolean;
}

const DownloadAppModal = ({ handleClose, open }: IAdditionalDocModal) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} width={{ xs: "90%", md: "fit-content" }}>
        <Box
          sx={{
            background: "#f5f9ff",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            px: {
              md: 6,
              xs: 0,
            },
            py: 2,
            width: {
              md: "450px",
            },
          }}
        >
          <img
            src="/images/phone-app-frame.svg"
            alt=""
            style={{ margin: "auto" }}
          />
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: 700, fontSize: "18px" }}
          >
            Autochek Mobile App
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Trusted, reliable and budget-friendly marketplace for a wide range
            of quality vehicles. Quick Car Loans With Multiple Financing
            Options.
          </Typography>
          <Grid
            item
            xs={6}
            md={6}
            display={"flex"}
            margin={2}
            gap={2}
            justifyContent={"space-between"}
          >
            <a
              href="https://apps.apple.com/ng/app/autochek/id1535055075"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/apple-store-white.svg"
                alt=""
                style={{ width: "100%" }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.autochek.buysalefixcars&utm_source=website&utm_medium=banner&utm_campaign=appdownload"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="/images/google-play-white.svg"
                alt=""
                style={{ width: "100%" }}
              />
            </a>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default DownloadAppModal;
