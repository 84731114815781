import React from "react";
import { Typography } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";
import { Noop, useController, UseControllerProps } from "react-hook-form";

type Props = {
  children: ({
    onChange,
    onBlur,
    value,
  }: {
    onChange: (...event: any[]) => void;
    onBlur: Noop;
    value: any;
  }) => any;
  label?: string;
  control: any;
  name: any;
  required?: boolean;
  rules?: Pick<UseControllerProps, "rules">;
  labelStyle?: SxProps<Theme>;
};

const InputControl = ({
  label,
  children,
  name,
  control,
  required = false,
  rules,
  labelStyle,
}: Props) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: {
        value: required,
        message: `${label} is required`,
      },
      ...rules?.rules,
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        alignItems: "stretch",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ ...labelStyle }}
          className="formLabel"
        >
          {label}
        </Typography>
      )}
      {children({ onChange, onBlur, value })}

      {error?.message && (
        <Typography
          variant="caption"
          gutterBottom
          color="orangered"
          className="formError"
        >
          * {error?.message?.toLowerCase()}
        </Typography>
      )}
    </Box>
  );
};

export default InputControl;
