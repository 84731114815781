import InputConfig from "@/components/formElements/mui-form/input-config";
import { FormSectionProps } from "@/components/formElements/mui-form/types";
import useCarFormStore from "@/states/create-car";
import { getSection } from "./helper";
import SectionWrapper from "./section-wrapper";

const CarSummarySection: React.FC<FormSectionProps> = ({ config, data, methods, dependencies }) => {
  const sectionStep = 5;
  const { currentStep, completedSteps } = useCarFormStore();
  if (currentStep !== sectionStep) return null;

  const section = getSection("carSummary", config);
  const isCompleted = completedSteps.includes(sectionStep);

  return (
    <SectionWrapper step={sectionStep} section={section} backable>
      <InputConfig
        step={sectionStep}
        config={config}
        section={section}
        data={data}
        methods={methods}
        dependencies={dependencies}
        isCompleted={isCompleted}
      />
    </SectionWrapper>
  );
};

export default CarSummarySection;
