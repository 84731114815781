import { Config, Item, Section } from "@crudmates/form-config";
import { UseFormReturn } from "react-hook-form";

type SectionName =
  | "carDetails"
  | "carSnapshots"
  | "carDocuments"
  | "carFeatures"
  | "carSummary";

export const getSection = (sectionName: SectionName, form?: Config) => {
  let section = { name: "", label: "" } as Section<Item>;

  if (!form) return section;

  section = form.sections.filter((section) => section.name === sectionName)[0];
  return section;
};

export const persistCarForm = (data: any) => {
  sessionStorage.setItem("car-form", JSON.stringify(data));
};

export const persistNewSnapshots = (data: any) => {
  sessionStorage.setItem("snapshotsNew", JSON.stringify(data));
};

export const hydrateCarForm = (methods: UseFormReturn<any, any>) => {
  const persistedJson = sessionStorage.getItem("car-form");
  if (persistedJson) {
    const persistedData = JSON.parse(persistedJson);
    methods.reset(persistedData);
  }
};

export const resetCarForm = () => {
  sessionStorage.removeItem("car-form-store");
  sessionStorage.removeItem("car-form");
  sessionStorage.removeItem("snapshotsNew");
};
