import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  createDocuments,
  fetchCountryId,
  getCities,
  getConfigData,
  getStates,
  getVehicleColors,
} from "@/hooks/use-loan";
import storage from "@/lib/storage";
import {
  Accordion,
  Alert,
  Box,
  Container,
  Snackbar,
  Stack,
} from "@mui/material";
import InputControl from "@/components/formElements/input-control";
import NewUploadButton from "@/components/formElements/new-upload-button";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { Modal } from "antd";
import { Input } from "@/components/formElements/input-group";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { device } from "@/utils/device";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { useQueryClient } from "@tanstack/react-query";

interface TField {
  options: [];
  fields: [];
  actions: [];
  when: [];
  label: string;
  name: string;
  type: string;
  required: boolean;
  visibility: boolean;
  initialLabel: string;
}

interface ICity {
  id: number;
  name: string;
}

interface IState {
  id: number;
  name: string;
}

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .top-text {
    font-size: 1.2rem;
    color: #30345e;
    font-weight: bolder;
    margin-left: 1rem;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-text {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1rem 0 0.5rem 0;
  }
  .bottom-text {
    text-align: center;
  }
  .button-ok {
    background: #ffb619;
    padding: 0.4rem 1.6rem;
    border-radius: 1rem;
    margin-top: 1rem;

    &:hover {
      background: #d89300;
    }
  }
`;

const SubmitButton = styled.div`
  .MuiButtonBase-root {
    background: #ffb619;
    color: #30345e;
    /* border-radius: 4rem; */
    font-weight: 600;

    &:disabled {
      background: #d6dbe2;
    }

    &:hover {
      background: #d89300;
    }
  }
`;

const CheckEligibilityFormContainer = styled.div`
  form {
    width: 45%;
    margin: 0 auto;
    @media ${device.tablet} {
      width: 100%;
    }
  }
`;

const UploadDocuments = () => {
  const { t } = useTranslation("inventory");
  const { t: t1 } = useTranslation("loan-form");
  const { t: t2 } = useTranslation("colors");
  const country = storage.getCountry();
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [modal2Open, setModal2Open] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const role = storage.getRole();
  const [countryId, setCountryId]: any = useState();
  const [state, setState] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [vehicleColors, setVehicleColors] = useState<string[]>([]);

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  const loanId = queryParameters.get("loan_id");
  const [formData, setFormData] = useState<any>({});
  const methods = useForm();
  const queryClient = useQueryClient();

  const [loanDocumentFields, setLoanDocumentFields]: any = useState();
  const [rerender, setRerender] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [completed, setCompleted]: any = useState({});
  const [loanDocumentSections, setLoanDocumentSections]: any = useState();

  const handleAccordionChange =
    (panel: any) => (event: any, newExpanded: any) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    const id = fetchCountryId(country);
    id.then((result) => setCountryId(JSON.parse(result?.data)));
  }, [country]);

  useEffect(() => {
    if (countryId) {
      const getState = getStates(countryId?.id);
      countryId?.id &&
        getState.then((result) => setState(JSON.parse(result?.data)));
    }
  }, [countryId]);

  const stateList = state?.states?.map((state: IState) => ({
    ...state,
    value: state.name,
  }));

  useEffect(() => {
    if (formData?.state?.value) {
      const selectedStateId = state?.states?.find(
        (item: IState) => item.name === formData?.state?.value
      )?.id;
      selectedStateId &&
        getCities(selectedStateId).then((result) =>
          setCity(JSON.parse(result?.data))
        );
    }
  }, [formData?.state?.value, state?.states]);

  const cityList = city?.cities?.map((city: ICity) => ({
    ...city,
    value: city.name,
  }));

  useEffect(() => {
    const vehicleColors = getVehicleColors();
    vehicleColors.then((result) => {
      const parsedResults = JSON.parse(result?.data);
      const vehicleColors = parsedResults?.colors?.map((color: string) => {
        return {
          label: t2(color),
          value: color,
        };
      });
      setVehicleColors(vehicleColors);
    });
  }, [t2]);

  const allFieldsWithWorkId = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("workId")
  );

  const allFieldsGovtId = loanDocumentFields?.filter((item: { name: string }) =>
    item.name.startsWith("identity")
  );
  const allFieldsWithPOF = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("proofOfEmployment")
  );

  const allFieldsWithBankStatement = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("bankStatement")
  );

  const allFieldsWithMMStatement = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("mobileMoneyStatement")
  );

  const { control, handleSubmit } = methods;

  const createNewDocObject = ({
    label,
    name,
    type,
    initialType,
    removeable,
    initialLabel,
    required,
    ...props
  }: // required,
  {
    label: string;
    name: string;
    type: string;
    initialType: string;
    removeable?: boolean;
    initialLabel: string;
    required?: boolean;
  }) => {
    return {
      label: label,
      name: name,
      type: type,
      initialType: initialType,
      initialLabel: initialLabel,
      removeable: removeable,
      required: required,
      ...props,
    };
  };

  const handleInputChange = async (
    e: any,
    item?: TField,
    when?: any,
    initialType?: string
  ) => {
    const { name, value, type } = e.target;
    const valueObject = {
      name,
      value: value,
      type: initialType ?? type,
      label: item?.label,
      valid: true,
    };

    const newDocField = createNewDocObject({
      ...item,
      label: `Upload ${value.includes("payslip") ? "Payslip 1" : value}`,
      name: name,
      type: "file",
      initialType: item?.type!,
      initialLabel: item?.label!,
      removeable: true,
      required: item?.required,
    });
    const fieldToBeReplaced = loanDocumentFields.find(
      (field: TField) => field.name === name
    );

    const fieldIndex = loanDocumentFields.indexOf(fieldToBeReplaced);

    const allFields = loanDocumentFields;

    if (name.startsWith("proofOfEmployment") && value.includes("payslip")) {
      allFields[fieldIndex] = newDocField;
      const subsetFields = when[0].fields.map((field: TField) => {
        return {
          ...field,
          required: !!newDocField.required,
          removeable:
            name === "proofOfEmployment" ? false : newDocField.removeable,
        };
      });

      allFields.splice(fieldIndex + 1, 0, ...subsetFields);
      setLoanDocumentFields(allFields);
    }
    if (!name.includes("Password")) {
      if (initialType?.startsWith("file")) {
        allFields[fieldIndex] = newDocField;
        setLoanDocumentFields(allFields);
      }
    }

    setFormData((state: {}) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  const onConfirmButton = () => {
    setModal2Open(false);
    navigate(`/applications/loans/${loanId}`);
    queryClient.invalidateQueries(["fetch_in_app_notifications"]);
  };

  useEffect(() => {
    const getProduct = async (loanId: any) => {
      try {
        const result = await getConfigData(loanId);
        const parsedData = JSON.parse(result.data);

        const allFieldsInUploadDocument = parsedData?.documents?.filter(
          (item: { label: string }) => item.label.startsWith("Upload Documents")
        );

        setLoanDocumentFields(allFieldsInUploadDocument[0]?.fields);
        setLoanDocumentSections(parsedData?.documents.reverse());
      } catch (error) {}
    };
    loanId && getProduct(loanId);
  }, [loanId]);

  const addedDocuments = loanDocumentSections?.filter(
    (item: any) => item?.label === "Additional Information"
  );

  const validateError = (values: any): boolean => {
    const showAlert = (label: string) => {
      setAlert(`${t1(label)} ${t1("is required")}`);
    };

    const isRequiredFieldMissing = (field: any): boolean => {
      if (!field.required) return false;

      const fieldName = field.name;
      const fieldValue = formData[fieldName]?.value;
      const isFileType = field.type === "file";

      if (
        (!isFileType && !fieldValue) ||
        (isFileType && !values[fieldName]?.data)
      ) {
        showAlert(field.label);
        return true;
      }

      return false;
    };

    for (const section of values) {
      for (const field of section.fields) {
        if (isRequiredFieldMissing(field)) {
          return true;
        }
      }
    }

    return false;
  };

  const validateDocuments = (documents: any[]): boolean => {
    return documents.some((doc) => doc.file_url);
  };

  const submit = async (values: any) => {
    let __values = values;

    const dataField = prepareDataFields();
    cleanUpValues(__values);

    const documents: any = Object.entries(values).map(([key, value]: any) => ({
      name: key,
      file_url: value?.data,
      description: formData[key]?.value || key,
      password:
        formData[
          `${key as string}${key.includes("File") ? "" : "File"}Password`
        ]?.value ?? "",
    }));

    if (validateError(addedDocuments)) {
      return;
    }

    if (!validateDocuments(documents)) {
      setAlert("Please fill atleast one document before submitting");
      return;
    }

    try {
      setLoading(true);
      await createDocuments({
        loan_id: loanId as string,
        documents,
        data: dataField,
      });
      setModal2Open(true);
    } catch (error: any) {
      setLoading(false);
      setAlert(JSON.parse(error?.response?.data)?.message);
    }
  };

  const shouldIncludeDataField = (item: string) => {
    return (
      !formData?.[item]?.name.includes("IsPasswordProtected") &&
      !formData?.[item]?.name.includes("FilePassword") &&
      !formData?.[item]?.type.includes("file") &&
      formData?.[item] !== undefined
    );
  };

  const prepareDataFields = () => {
    let dataField = [];

    for (let item of Object.keys(formData)) {
      if (shouldIncludeDataField(item)) {
        dataField.push(
          item === "state"
            ? {
                ...formData[item],
                value: formData[item]?.value,
              }
            : formData[item]
        );
      }
    }

    return dataField;
  };

  const cleanUpValues = (values: any) => {
    for (let item of Object.keys(values)) {
      if (item.includes("Password")) {
        delete values[item];
      }
    }

    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });
  };

  const showExtraIDButton = (item: { name: string }) => {
    const visibleIdField = allFieldsGovtId.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleIdFieldsCount = visibleIdField.length / 2;
    return (
      visibleIdFieldsCount < allFieldsGovtId.length / 2 &&
      item.name.endsWith(
        `identity${
          visibleIdFieldsCount < 2 ? "" : visibleIdFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showWorkIDButton = (item: { name: string }) => {
    const visibleWorkIdField = allFieldsWithWorkId.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleWorkIdFieldsCount = visibleWorkIdField.length / 2;
    return (
      visibleWorkIdFieldsCount < allFieldsWithWorkId.length / 2 &&
      item.name.endsWith(
        `workId${
          visibleWorkIdFieldsCount < 2 ? "" : visibleWorkIdFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraEmploymentBtn = (item: any) => {
    const visiblePOFField = allFieldsWithPOF.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visiblePOFFieldsCount = visiblePOFField.length / 2;
    return (
      visiblePOFFieldsCount < allFieldsWithPOF.length / 2 &&
      item.name.endsWith(
        `proofOfEmployment${
          visiblePOFFieldsCount < 2 ? "" : visiblePOFFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraBankStatmentBtn = (item: any) => {
    const visibleBankStField = allFieldsWithBankStatement.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleBankStFieldCount = visibleBankStField.length / 2;
    return (
      visibleBankStFieldCount < allFieldsWithBankStatement.length / 2 &&
      item.name.endsWith(
        `bankStatement${
          visibleBankStFieldCount < 2 ? "" : visibleBankStFieldCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraMMBtn = (item: any) => {
    const visibleMMField = allFieldsWithMMStatement.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleMMFieldCount = visibleMMField.length / 2;
    return (
      visibleMMFieldCount < allFieldsWithMMStatement.length / 2 &&
      item.name.endsWith(
        `mobileMoneyStatement${
          visibleMMFieldCount < 2 ? "" : visibleMMFieldCount
        }IsPasswordProtected`
      )
    );
  };

  const addExtraField = async (index: number) => {
    const allFields = loanDocumentFields;
    const field1 = {
      ...loanDocumentFields[index + 1],
      visibility: true,
      removeable: true,
    };
    const field2 = {
      ...loanDocumentFields[index + 2],
      visibility: true,
      removeable: true,
    };

    allFields[index + 1] = field1;
    allFields[index + 2] = field2;
    setLoanDocumentFields(allFields);
    setRerender(!rerender);
  };

  const removeField = (index: number, initialType: string, item: TField) => {
    const allFields = loanDocumentFields;
    const field1 = {
      ...loanDocumentFields[index],
      visibility: !!item.required,
      type: initialType ?? item.type,
      label: item.initialLabel ?? item.label,
    };
    const field2 = {
      ...loanDocumentFields[index + 1],
      visibility: !!item.required,
    };

    allFields[index] = field1;
    allFields[index + 1] = field2;
    const checkIfpayslipFieldIsPresent = allFields.find((field: TField) =>
      field.name.startsWith("proofOfEmploymentPayslip")
    );
    if (item.label.includes("Payslip 1")) {
      checkIfpayslipFieldIsPresent && allFields.splice(index + 1, 2);

      formData[item.name].value.includes("payslip") &&
        (formData[item.name].value = "");
    }
    if (item.name.startsWith("proofOfEmploymentPayslip")) {
      allFields.splice(index, 1);
    }
    setLoanDocumentFields(allFields);
    setRerender(!rerender);
  };

  const renderInputControlField = (item: any, index: number) => {
    return (
      <InputControl
        key={item.fields.indexOf(item)}
        control={control}
        name={item.name}
      >
        {({ value, onChange, onBlur }) =>
          item.visibility && item.type === "file" ? (
            <NewUploadButton
              fileType={item.fileType}
              label={item.label}
              value={value}
              onChange={onChange}
              fieldName={item.name}
              name={item.name}
              channel={item.channel}
              removeable={item.removeable ?? false}
              onRemoveField={() => removeField(index, item.initialType, item)}
            />
          ) : (
            item.visibility && (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    sx={{
                      fontWeight: 600,
                      backgroundColor: "#F8FAFC",
                      width: { md: "100%", xs: "100%" },
                      paddingY: "15px",
                      paddingX: "15px",
                      marginY: "9px",
                      cursor: "pointer",
                      display: "block",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    component="label"
                  >
                    <Input
                      formData={formData}
                      key={item.fields.indexOf(item)}
                      onBlur={onBlur}
                      required={item.required}
                      type={
                        item.type === "fileSelection" ? "selection" : item.type
                      }
                      label={item.label}
                      placeholder={item.label}
                      fileType={item.fileType}
                      name={item.name}
                      when={item.when}
                      options={inputOptionCheck(item)}
                      onChange={(e: any) => {
                        handleInputChange(e, item, item.when, item.type);
                      }}
                      // value={item.value}
                      value={formData[item.name]?.value}
                      selectOptions={item.options?.length ? item.options : []}
                      visibility={true}
                      innerVisibility={true}
                      fullWidth={item.fullWidth}
                      autoComplete={false}
                      subsection={null}
                      hideSubsection
                      addDirector={undefined}
                      directorCount={undefined}
                    />
                  </Box>
                </Stack>

                {showExtraIDButton(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
                {showWorkIDButton(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}

                {showExtraEmploymentBtn(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
                {showExtraBankStatmentBtn(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
                {showExtraMMBtn(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
              </>
            )
          )
        }
      </InputControl>
    );
  };

  const inputOptionCheck = (item: any) => {
    if (item?.name === "state") {
      return stateList;
    }
    if (item.name === "city") {
      return cityList;
    }
    if (item.name === "vehicleColour") {
      return vehicleColors;
    }
    if (item.options?.length) {
      return item.options;
    } else {
      return [];
    }
  };

  return (
    loanDocumentSections && (
      <div>
        <TextContainer
          onClick={() => navigate(-1)}
          style={{ marginBottom: "24px" }}
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
          <div className="top-text">{capitalFirstLetter(t("back"))}</div>
        </TextContainer>
        <Container>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={!!alert}
            autoHideDuration={5000}
            onClose={() => setAlert("")}
          >
            <Alert severity="error" variant="filled">
              {alert}
            </Alert>
          </Snackbar>

          <CheckEligibilityFormContainer>
            <form>
              {loanDocumentSections?.map((item: any, index: any) => (
                <Accordion
                  key={loanDocumentSections.indexOf(item)}
                  expanded={expanded === index}
                  onChange={handleAccordionChange(index)}
                  sx={{
                    width: { md: "100%", xs: "100%" },
                    marginBottom: "24px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      completed[index] && expanded !== index ? (
                        <CheckCircleIcon sx={{ color: "#22C55E" }} />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                  >
                    <Typography
                      variant="h6"
                      fontWeight="600"
                      textAlign="center"
                    >
                      {t1(item.label)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {item.fields.map((item: any, index: any) =>
                        item.channel && item.channel !== "dealerplus" ? (
                          <></>
                        ) : (
                          renderInputControlField(item, index)
                        )
                      )}

                      {index === loanDocumentSections.length - 1 ? (
                        <Grid container spacing={2} marginTop="16px">
                          <Grid item xs={12} sm={12} md={4}>
                            <SubmitButton>
                              <LoadingButton
                                variant="contained"
                                color="secondary"
                                type="submit"
                                sx={{ width: "151px", height: "43px" }}
                                loading={loading}
                                onClick={handleSubmit(submit)}
                              >
                                {t1("Submit")}
                              </LoadingButton>
                            </SubmitButton>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={2} marginTop="16px">
                          <Grid item xs={12} sm={12} md={4}>
                            <SubmitButton>
                              <LoadingButton
                                variant="contained"
                                type="button"
                                fullWidth
                                color="primary"
                                loading={loading}
                                onClick={(e) => {
                                  const error = validateError(addedDocuments);
                                  if (error) {
                                    setCompleted((current: any) => ({
                                      ...current,
                                      [index]: false,
                                    }));
                                    return;
                                  }
                                  setExpanded(index + 1);
                                  setCompleted((current: any) => ({
                                    ...current,
                                    [index]: true,
                                  }));
                                }}
                              >
                                {t1("Next")}
                              </LoadingButton>
                            </SubmitButton>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  </AccordionDetails>
                </Accordion>
              ))}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingY: "30px",
                }}
              >
                <Modal centered open={modal2Open} footer={null}>
                  <ModalContent>
                    <img
                      src="https://storage.googleapis.com/img.autochek.africa/d083d14a-5cd1-4017-baa0-97086e504a33-check-circle.svg"
                      alt="Check"
                    />
                    <h2 className="title-text">{t("documents-uploaded")}</h2>
                    <p className="bottom-text">
                      {t("the-documents-have-been-received-and-will")} <br />{" "}
                      {t("be-reviewed-promptly")}
                    </p>
                    <button onClick={onConfirmButton} className="button-ok">
                      {t("OK")}
                    </button>
                  </ModalContent>
                </Modal>
              </Box>
            </form>
          </CheckEligibilityFormContainer>
        </Container>
      </div>
    )
  );
};

export default UploadDocuments;
