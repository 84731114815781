import React from "react";

const GetHelpHeader = ({
  headerText,
  name,
  number,
  position,
}: {
  headerText: string;
  name: string;
  number: string;
  position: string;
}) => {
  return (
    <div className="w-full bg-white h-full md:h-[8.1875rem] rounded-tr-2xl rounded-tl-2xl p-2">
      <div className="flex flex-col divide-y space-y-[0.31rem]">
        <div className={`p-[0.81rem] flex ${position}`}>
          <h4 className="text-[#30345E] text-xl font-bold">{headerText}</h4>
        </div>
        <div className="p-2 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/avatar/image.png"
              alt="profile"
              className="w-10 h-10"
            />
            <div>
              <h5 className="text-[#30345E] font-bold leading-4">{name}</h5>
              <span className="text-[0.75rem] text-[#30345E] font-light leading-4">
                {number}
              </span>
            </div>
          </div>
          <div>
            <a
              href="https://web.whatsapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/avatar/whatsapp-symbol-logo_svgrepo.com.png"
                alt="whatsapp"
                className="w-8 h-8"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetHelpHeader;
