import React, { useEffect } from "react";
import storage from "@l/storage";
import {
  ApplicationLayout,
  FilterContainer,
  Header,
} from "@/layouts/application-layouts/styles";
import { Input, DatePicker, Table, TableProps } from "antd";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import french from "antd/es/date-picker/locale/fr_FR";
import arabic from "antd/es/date-picker/locale/ar_EG";
import english from "antd/es/date-picker/locale/en_US";

interface DataType {
  key: string;
  name: string;
  phone_number: string;
  interest: string;
  location: string;
  date: string;
}

type props = {
  variant: "inventory" | "change-car" | "select-car" | "repo" | "loan-transfer";
};

const MyLeads = ({ variant }: props) => {
  const role = storage.getRole();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const query: any = queryString.parse(search);
  const { RangePicker } = DatePicker;
  const { t } = useTranslation("inventory");
  const locale = storage.getLocale();

  useEffect(() => {
    document.title = `Dealer Plus | Inventory [${role}]`;
  }, [role]);

  const title = "My Leads";

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    navigate(
      `/${pathname}${paramsObjectToQueryString({
        ...query,
        query: value,
      })}`
    );
  };

  const handleDateFilter = (dateString: any) => {
    navigate(
      `/${pathname}${paramsObjectToQueryString({
        ...query,
        from_date: dateString
          ? moment(dateString[0].$d).format("YYYY-MM-DD")
          : "",
        to_date: dateString
          ? moment(dateString[1].$d).format("YYYY-MM-DD")
          : "",
      })}`
    );
  };

  const getLocalizedText = (locale: any) => {
    if (locale === "fr") {
      return french;
    } else if (locale === "ar") {
      return arabic;
    } else {
      return english;
    }
  };
  const localizedText = getLocalizedText(locale);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Interested in",
      dataIndex: "interest",
      key: "interest",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "John Brown",
      phone_number: "08068995689",
      location: "Ikeja, Lagos",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "2",
      name: "John Brown",
      phone_number: "08068995689",
      location: "Ikeja, Lagos",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "3",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "4",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "5",
      name: "John Brown",
      phone_number: "08068995689",
      location: "Ikeja, Lagos",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "6",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "7",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "8",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "9",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
  ];

  return (
    <React.Fragment>
      <Header style={{ marginBottom: 16, border: 0 }}>
        <div className="title">{title}</div>
      </Header>
      <ApplicationLayout style={{ paddingTop: "1rem" }}>
        <FilterContainer>
          <div className="filter-field">
            <div className="input-field">
              <div className="label mobile-input-field">
                {t("Search Leads")}
              </div>
              <Input
                placeholder={t("search-inventory-placeholder")}
                onChange={(val) => handleFilterSearch(val)}
                prefix={
                  <img
                    src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                    alt="search-icon"
                  />
                }
                allowClear
              />
            </div>

            <div className="input-field mobile-input-field">
              <div className="label">{t("Filter By Date")}</div>
              <RangePicker
                locale={localizedText}
                onChange={handleDateFilter}
                allowClear
              />
            </div>
          </div>
        </FilterContainer>
        <Table columns={columns} dataSource={data} />;
      </ApplicationLayout>
    </React.Fragment>
  );
};

export default MyLeads;
