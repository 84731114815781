import { Modal } from "antd";
import InventoryCustomFilter from "./InventoryFilter";
import { IVariant } from "@/types";
import { useTranslation } from "react-i18next";

interface IListModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  query: any;
  loading: boolean;
  variant: IVariant;
  setOpenFilter?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InventoryFilterModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  query,
  loading,
  variant,
  setOpenFilter,
}: IListModal) => {
  const { t: t2 } = useTranslation("component");

  return (
    <div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <div className="flex justify-end">
          <button onClick={handleCancel}>
            <img
              src="https://media.autochek.africa/file/publicAssets/x-close-u.svg"
              alt="close-icon"
            />
          </button>
        </div>
        <InventoryCustomFilter
          query={query}
          loading={loading}
          setOpenFilter={setOpenFilter}
          type="modal"
          variant={variant}
          t2={t2}
        />
      </Modal>
    </div>
  );
};

export default InventoryFilterModal;
