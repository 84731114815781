import { StorageKeys } from "../constants/storage-keys";
import adminRoles from "../constants/admin-roles.json";

const storage: IStorage | IStorageUtility = {};
const utilities: IStorage = {};

storage.setToken = (value: string) => {
  return localStorage.setItem(StorageKeys.TOKEN, value);
};

storage.setProxyUserToken = (value: string) => {
  return localStorage.setItem(StorageKeys.PROXY_USER_TOKEN, value);
};

storage.setAssumedUser = (value: string) => {
  return localStorage.setItem(StorageKeys.IS_ASSUMED_USER, value);
};

storage.setUser = (value: object) => {
  const user = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.USER, user);
};

storage.setRoles = (value: string[]) => {
  const roles = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.ROLES, roles);
};

storage.setRole = (value: string[]) => {
  const role = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.ROLE, role);
};

storage.setPermissions = (value: string[]) => {
  const permissions = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.PERMISSIONS, permissions);
};

storage.setFranchises = (value: string[]) => {
  const franchises = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.FRANCHISES, franchises);
};

storage.setCountry = (value: string) => {
  return localStorage.setItem(StorageKeys.COUNTRY, value);
};

storage.getToken = () => {
  const token = localStorage.getItem(StorageKeys.TOKEN);
  return token ?? null;
};

storage.getProxyUserToken = () => {
  const proxyToken = localStorage.getItem(StorageKeys.PROXY_USER_TOKEN);
  return proxyToken ?? null;
};

storage.getAssumedUser = () => {
  const assumedUser = localStorage.getItem(StorageKeys.IS_ASSUMED_USER);
  return assumedUser ?? null;
};

storage.getUser = () => {
  const user = localStorage.getItem(StorageKeys.USER);
  return user ? JSON.parse(user) : null;
};

storage.getRoles = () => {
  const roles = localStorage.getItem(StorageKeys.ROLES);
  return roles ? JSON.parse(roles) : null;
};

storage.getRole = () => {
  const role = localStorage.getItem(StorageKeys.ROLE);
  return role ? JSON.parse(role) : null;
};
storage.getFranchises = () => {
  const franchise = localStorage.getItem(StorageKeys.FRANCHISES);
  return franchise ? JSON.parse(franchise) : null;
};

storage.getLocale = () => {
  const locale = localStorage.getItem(StorageKeys.i18nextLng);
  return locale ?? null;
};

storage.getPermissions = () => {
  const permissions = localStorage.getItem(StorageKeys.PERMISSIONS);
  return permissions ? JSON.parse(permissions) : null;
};

storage.getCountry = () => {
  const token = localStorage.getItem(StorageKeys.COUNTRY);
  return token ?? null;
};

storage.clearToken = () => {
  return localStorage.removeItem(StorageKeys.TOKEN);
};

storage.clearProxyUserToken = () => {
  return localStorage.removeItem(StorageKeys.PROXY_USER_TOKEN);
};

storage.clearAssumedUser = () => {
  return localStorage.removeItem(StorageKeys.IS_ASSUMED_USER);
};

storage.clearAll = () => {
  return localStorage.clear();
};

utilities.isAdmin = () => {
  const roles = storage.getRoles() || [];
  return adminRoles.some((role: string) => roles.includes(role));
};

utilities.isProxy = () => {
  const role = storage.getRole();
  return role === "PROXY";
};

utilities.isAccountManager = () => {
  const role = storage.getRole();
  return role === "ACCOUNT_MANAGER";
};

utilities.isDealerFinanceManager = () => {
  const role = storage.getRole();
  return role === "DEALER_FINANCE_MANAGER";
};

utilities.isDsaAgent = () => {
  const role = storage.getRole();
  return role === "DSA_AGENT";
};

utilities.isFranchise = () => {
  const role = storage.getRole();
  return role === "FRANCHISE ADMIN";
};

utilities.isDealerDsa = () => {
  const role = storage.getRole();
  return role === "DEALER_DSA";
};

storage.utilities = utilities;

export default storage;
