import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { FormInputProps } from "./types";
import FormInputLabel from "./FormInputLabel";
import FormInputComment from "./FormInputComment";
import { useTranslation } from "react-i18next";

export const FormInputRadio: React.FC<FormInputProps> = ({
  item,
  field,
  disabled,
}) => {
  const { t } = useTranslation("car-form");

  return (
    <div>
      <FormInputLabel item={item} />
      <RadioGroup {...field}>
        {(item.options || []).map((opt: any) => (
          <FormControlLabel
            key={opt.value}
            value={opt.value}
            control={
              <Radio
                sx={{
                  color: "#30345E",
                  "&.Mui-checked": {
                    color: "#30345E",
                  },
                }}
              />
            }
            label={t(opt.label)}
            disabled={disabled || item.disabled}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                fontFamily: "OutfitRegular",
                fontWeight: 500,
                color: item.disabled ? "#A9A9A9" : "#30345E",
              },
            }}
          />
        ))}
      </RadioGroup>
      <FormInputComment item={item} />
    </div>
  );
};
