import { Modal, Image } from "antd";

interface IInspectionModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  damagesBySection: any;
}

const ViewInspectionDamages: React.FC<IInspectionModal> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  damagesBySection,
}) => {
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      zIndex={1007}
      footer={null}
      width={600}
    >
      <div>
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-semibold text-[#30345e]">
            Damages Section
          </h1>
          <button
            onClick={handleCancel}
            type="button"
            className="font-medium text-[#6B7280] text-base w-6 h-6 flex items-center justify-center rounded-sm"
          >
            &#x2715;
          </button>
        </div>

        <div className="flex flex-col divide-y">
          {damagesBySection?.map((damage: any) => (
            <div
              key={damagesBySection.indexOf(damage)}
              className="flex flex-col pt-4 pb-3"
            >
              <h2 className="text-sm text-[#30345e] font-medium mb-1">
                {damage.label}
              </h2>
              <div className="flex flex-wrap gap-2 md:gap-3 mb-3">
                {damage?.media?.map((media: any) => (
                  <Image
                    key={damage?.media.indexOf(media)}
                    width={100}
                    height={75}
                    src={media?.url}
                  />
                ))}
              </div>
              {damage?.comment && (
                <div>
                  <p className="text-sm font-medium text-[#30345e] leading-tight">
                    Inspector&apos;s comment
                  </p>
                  <ul className="list-disc ml-4">
                    <li className="text-sm font-medium text-[#6B7280]">
                      {damage?.comment}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ViewInspectionDamages;
