import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "antd";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ILimitById } from "@/interface/dealer";
import tw from "tailwind-styled-components";
import { useForm } from "react-hook-form";
import { Alert, Snackbar } from "@mui/material";
import InputControl from "@/components/formElements/input-control";
import NewUploadButton from "@/components/formElements/new-upload-button";
import { useTranslation } from "react-i18next";
import { uploadMasterAgreementDocuments } from "@/hooks/use-loan";
import { Loader } from "../auth/styles/loader";

const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

  .top-text {
    font-size: 16px;
    color: #30345e;
    font-weight: bolder;
    margin-left: 0.5rem;
  }
`;

export default function UploadMasterAgreement() {
  const methods = useForm();
  const { control, handleSubmit } = methods;
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const limitId = queryParameters.get("limit_id");
  const role = storage.getRole();
  const { t } = useTranslation("loan-form");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [limitDocument, setLimitDocument] = useState<any>({});
  const dealerId = searchParams.get("dealer_id");

  const { isLoading: isLoadingLimitById } = useQuery({
    queryKey: ["fetch_limitByIds"],
    queryFn: () => {
      return client.get(`/v1/dealer/loanlimit/${limitId}`);
    },
    onSuccess: (res: any) => {
      const parsedLimitDocumentData: ILimitById = JSON.parse(res?.data);
      const limitDocument = parsedLimitDocumentData?.loanLimits?.find(
        (item) => item?.limitDocuments?.length > 0
      );
      setLimitDocument(limitDocument);
    },
    enabled: !!limitId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    document.title = `Dealer Plus | Master Agreement [${role}]`;
  }, [role]);

  const onSubmitDocs = async (values: any) => {
    for (let item of Object.keys(values)) {
      if (!values?.[item]?.data) {
        const doc = limitDocument?.limitDocuments?.find(
          (field: any) => field.name === item
        );
        setAlert(`${doc?.name} is required`);
        return;
      }
    }

    const documents = Object.entries(values).map(([key, value]: any) => ({
      name: key,
      file_url: value?.data,
      description: key,
      code: "master_agreement",
    }));

    try {
      setLoading(true);
      await uploadMasterAgreementDocuments({
        loan_limit_request_id: limitId as string,
        loan_limit_id: limitDocument?.id as string,
        documents,
      });
      queryClient.invalidateQueries({
        queryKey: ["fetch_dealer_limit", "fetch_notifications"],
      });
      navigate(`/dealer/loan-limit/confirmation?path=master-agreement&dealer_id=${dealerId}`);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}>
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      {isLoadingLimitById ? (
        <CustomLoading>
          <Loader variant={"secondary"} />
        </CustomLoading>
      ) : (
        <main className="w-full lg:w-[40%] lg:bg-white my-0 mx-auto lg:rounded-lg">
          <TextContainer
            className="lg:px-6 lg:pt-6 lg:pb-4 lg:border-b lg:border-solid lg:border-[#E5E7EB]"
            onClick={() => navigate(-1)}>
            <img
              src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
              alt="left-arrow"
            />
            <div className="top-text">Upload Signed Master Agreement</div>
          </TextContainer>

          <div className="flex flex-col gap-8 lg:px-6 lg:pb-8">
            <div className="">
              {limitDocument?.limitDocuments?.map((item: any) => (
                <InputControl
                  key={limitDocument?.limitDocuments?.indexOf(item)}
                  control={control}
                  name={item.name}>
                  {({ value, onChange }) => (
                    <NewUploadButton
                      fileType={item.fileType}
                      label={t(item.name)}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </InputControl>
              ))}
            </div>

            <Button
              onClick={handleSubmit(onSubmitDocs)}
              className="bg-[#FFB619] w-full lg:w-[60%] my-0 mx-auto h-[48px] rounded-full border-none hover:border-none focus:border-none text-sm font-bold">
              <span className="text-[#30345E] hover:text-[#30345E] focus:text-[#30345E]">
                {loading ? (
                  <Loader variant="secondary" />
                ) : (
                  "Upload signed document"
                )}
              </span>
            </Button>
          </div>
        </main>
      )}
    </div>
  );
}
