import tw from "tailwind-styled-components";
import { TableContainer } from "./styles";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const PageLoader = () => {
  return (
    <div>
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    </div>
  );
};

export default PageLoader;
