import { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import styled from "styled-components";
import Cards from "@/components/cards";
import { device } from "@/utils/device";
import { Link } from "react-router-dom";
import { getLoanStatistics } from "@/hooks/use-loan";
import storage from "@/lib/storage";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "@u/commonFunctions";

const TextContainer = styled.div`
  display: flex;
  align-items: center;

  .top-text {
    font-size: 1.2rem;
    color: #30345e;
    font-weight: bolder;
    margin-left: 1rem;
  }
`;

const SingleCardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
  color: #30345e;

  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TotalSales = () => {
  const [loanStatistics, setLoanStatistics]: any = useState({});
  const country = storage.getCountry();
  const user = storage.getUser();
  const [statsLoading, setStatsLoading] = useState(false);
  const { t: t1 } = useTranslation(["component"]);

  useEffect(() => {
    document.title = "Dealer Plus | Total Sales";
  }, []);

  useEffect(() => {
    setStatsLoading(true);
    const getStatistics = getLoanStatistics(user?.id, country);
    getStatistics.then((result: any) => {
      setLoanStatistics(JSON.parse(result?.data));
      setStatsLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Link to="/dashboard">
        <TextContainer style={{ marginBottom: "16px" }}>
          <BsArrowLeft />
          <div className="top-text">{t1("total-sale")}</div>
        </TextContainer>
      </Link>
      <SingleCardContainer>
        <Cards
          cardHeader={t1("total-sales")}
          leftText={t1("no-of-sales")}
          leftValue={
            statsLoading
              ? "---"
              : numberWithCommas(+loanStatistics?.totalSales?.number || 0)
          }
          rightText={`${t1("sales-value")} (${getCountryCurrency(country)})`}
          rightValue={
            statsLoading
              ? "---"
              : numberWithCommas(+loanStatistics?.totalSales?.value || 0)
          }
          color="#60A5FA"
        />
      </SingleCardContainer>
    </div>
  );
};

export default TotalSales;
