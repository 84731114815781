import { Link } from "react-router-dom";

interface IOutlineButton {
  path: string;
  children: string | React.ReactNode;
}

export default function OutlineButton({ path, children }: IOutlineButton) {
  return (
    <Link to={path}>
      <button className="text-[#30345E] text-sm border border-solid border-[#30345E] font-semibold rounded-full h-[32px] flex items-center px-3">
        {children}
      </button>
    </Link>
  );
}
