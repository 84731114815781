import React from "react";
import { Form, Inputs } from "./auth/styles/sign-in";
import GethelpInput from "@/components/blocks/inputs/getheipinput";
import GethelpTextarea from "@/components/blocks/inputs/gethelptextarea";
import GetHelpHeader from "@/components/refix/GetHelpHeader";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";

const ButtonContainer = tw.button`
flex flex-row h-12 w-full gap-x-2 justify-center items-center px-6 py-3 text-sm font-bold rounded-[3.75rem] disabled:cursor-not-allowed tracking-wide
`;

const DealerHelp = () => {
  const { t } = useTranslation("dealer-gamification");

  const schema = yup
    .object({
      issueTitle: yup.string().required(),
      description: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IDealerHelp>({
    resolver: yupResolver(schema),
  });

  const handleSignIn = (data: IDealerHelp) => {
    return data;
  };
  return (
    <div className=" max-w-[45rem] mx-auto space-y-4 ">
      <GetHelpHeader
        headerText={t("my-account-manager")}
        name="Oreoluwa Adeniyi"
        number=" 080 5690 3731"
        position="justify-center"
      />
      {/* form */}
      <div className="w-full bg-white h-full">
        <div className="px-4 py-10">
          <div className="flex justify-center mb-5">
            <h4 className="text-[#30345E] text-center text-xl font-bold">
              Report an issue
            </h4>
          </div>

          <Form onSubmit={handleSubmit(handleSignIn)} autoComplete="off">
            <Inputs>
              <GethelpInput
                label={"Issue Title"}
                type="text"
                placeholder={"Enter issue title"}
                {...register("issueTitle")}
                error={errors.issueTitle}
              />

              <GethelpTextarea
                label={"Description"}
                type="text-area"
                placeholder={`Enter details`}
                {...register("description")}
                error={errors.description}
              />
            </Inputs>

            <div style={{ marginTop: "48px" }}>
              <ButtonContainer className={`bg-[#D1D5DB]`}>
                <span className="font-bold text-sm text-[#9CA3AF]">Submit</span>
              </ButtonContainer>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default DealerHelp;
