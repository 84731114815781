import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextContainer,
  ViewButton,
  TableContainer,
  EquityPaymentContainer,
} from "@/layouts/application-layouts/styles";
import tw from "tailwind-styled-components";
import {
  Form,
  Input,
  Upload,
  message,
  InputNumber,
  List,
  Radio,
  Tag,
} from "antd";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { EquityInputNumber } from "@/pages/auth/styles/sign-in";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import formatInt from "@/utils/format-int";
import moment from "moment";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import type { PaginationProps } from "antd";
import { Loader } from "../auth/styles/loader";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

const Text = tw.div`text-base text-[#6B7280] font-semibold`;

export default function UploadEquity() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const country = storage.getCountry();
  const queryClient = useQueryClient();
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const { t } = useTranslation("loan-form");
  const { t: t1 } = useTranslation("dashboard");
  const loan_id = (location.state as { loanId: string })?.loanId;
  const [loading, setLoading] = useState(false);
  const [finalOffer, setFinalOffer] = useState<any>([]);
  const [fetchEquity, setFetchEquity] = useState<any>([]);
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const isAssumedUser = storage.getAssumedUser();

  useQuery({
    queryKey: ["fetch_loans_by_the_id", country, loan_id],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${loan_id}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      const finalOfferI = parsedLoanDetails?.offers?.filter(function (
        item: any
      ) {
        return item?.offerType === "FINAL_OFFER";
      });
      setFinalOffer(finalOfferI[0]);
    },
    enabled: !!loan_id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: fetchEquityLoading } = useQuery({
    queryKey: ["fetch_equity_payment", loan_id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: loan_id,
      });
      return client.get(`/v2/origination/equity`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setFetchEquity(parsedResponse);
    },
    enabled: !!loan_id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <button>{t1("previous")}</button>;
    }
    if (type === "next") {
      return <button>{t1("next")}</button>;
    }
    return originalElement;
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .pdf",
    action: `${API_URL}document/upload`,
    headers: {
      authorization: `bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {},
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    const payload = {
      loan_id: loan_id,
      amount: values.amount.replaceAll(",", ""),
      file_url: values.document_upload[0].response.file.url,
      password: values.password,
      paid_to_dealer: values.paidToDealer,
    };

    try {
      const response = await client.post(`/v2/origination/equity`, payload);
      if (response) {
        message.success("Upfront payment added");
        queryClient.invalidateQueries(["fetch_equity_payment"]);
        queryClient.invalidateQueries(["loans_by_id"]);
        setLoading(false);
        form.resetFields();
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  if (fetchEquityLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <main className="flex flex-col gap-5">
      <div className="relative mb-5">
        <div className="text-2xl font-bold text-center text-[#30345e]">
          Upfront Payment
        </div>
        <button
          onClick={() => navigate(-1)}
          className="absolute left-0 top-[4px] cursor-pointer"
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
        </button>
      </div>

      <div
        className={`w-full xl:w-[45%] h-auto pb-0 bg-white my-0 mx-auto rounded-lg`}
      >
        <div
          className={`flex flex-col gap-2 px-4 lg:px-6 lg:pt-6 lg:pb-4 border-b border-solid border-[#E5E7EB] mb-0`}
        >
          <div className="flex flex-col items-center">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/cash-fill.png?updatedAt=1710196935945"
              alt="Approved Equity"
              width="30px"
              height="30px"
            />
            <div className="text-[#6B7280] font-semibold text-center leading-tight text-sm mb-1.5">
              Approved Upfront Payment <br />
              <span className="text-xs font-medium">(equity + charges)</span>
            </div>
            <div className="text-[#30345e] font-bold text-2xl">
              {formatInt(
                finalOffer?.additionalPayments?.find(
                  (item: any) => item?.name === "Total upfront payment"
                )?.value,
                true
              )}
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <Text>Paid</Text>
              <div className="font-semibold text-xl text-[#22C55E]">
                {formatInt(
                  fetchEquity?.equities?.reduce(
                    (acc: number, item: Record<string, any>) =>
                      acc + item.amountPaid,
                    0
                  ),
                  true
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <Text>Balance</Text>
              <div className="font-semibold text-xl text-[#DC2626]">
                {fetchEquity ? (
                  <>
                    {fetchEquity?.equities &&
                    fetchEquity?.equities[0]?.balance >= 0
                      ? formatInt(fetchEquity?.equities[0]?.balance, true)
                      : formatInt(
                          finalOffer?.additionalPayments?.find(
                            (item: any) =>
                              item?.name === "Total upfront payment"
                          )?.value,
                          true
                        )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {fetchEquity?.equities?.length === 0 ||
        (fetchEquity?.equities?.length > 0 &&
          fetchEquity?.equities[0]?.balance > 0) ? (
          <div className="px-4 lg:px-6 pt-6 pb-4">
            <EquityPaymentContainer>
              <Form
                name="document-upload-form"
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={handleSubmitForm}
                autoComplete="off"
              >
                <Form.Item>
                  <Form.Item
                    name="document_upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: t("Please upload proof of payment"),
                      },
                    ]}
                  >
                    <Upload.Dragger {...props} name="file" maxCount={1}>
                      <div className="flex gap-4 items-center px-4">
                        <img
                          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Vector%20(Stroke).svg?updatedAt=1711051618638"
                          alt="Upload"
                        />
                        <div className="text-base font-semibold">
                          {t("Upload upfront proof")}
                        </div>
                      </div>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>

                <Form.Item label="Password (optional)" name="password">
                  <Input.Password placeholder="Enter password" />
                </Form.Item>

                <Form.Item
                  label="Amount on proof of payment"
                  name="amount"
                  rules={[{ required: true, message: "Please enter amount" }]}
                >
                  <EquityInputNumber>
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `${value}`
                          .replace(/\D/g, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      keyboard={false}
                      placeholder={"Enter amount"}
                    />
                  </EquityInputNumber>
                </Form.Item>

                <Form.Item
                  name="paidToDealer"
                  label="Paid to dealer"
                  rules={[
                    { required: true, message: "Please choose an option" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>
                      <span className="text-[15px] font-medium">Yes</span>
                    </Radio>
                    <Radio value={false}>
                      <span className="text-[15px] font-medium">No</span>
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item>
                  <div className="button-container w-[50%] my-0 mx-auto">
                    <button
                      className="text-xs lg:text-sm mt-6 w-full h-[45px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                      disabled={!!isAssumedUser}
                    >
                      {loading ? <Loader variant={"secondary"} /> : t("Submit")}
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </EquityPaymentContainer>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="w-full pb-8 xl:w-[45%] min-h-[300px] bg-white my-0 mx-auto rounded-lg">
        <TextContainer
          className="px-2 lg:px-6 pt-6 pb-4 border-b border-solid border-[#E5E7EB]"
          style={{ marginBottom: "0px" }}
        >
          <div className="top-text">Payment History</div>
        </TextContainer>

        {fetchEquity?.equities?.length > 0 ? (
          <List
            dataSource={fetchEquity?.equities}
            loading={fetchEquityLoading}
            pagination={{
              current: activePage?.currentPage,
              pageSize: activePage?.pageSize,
              total: fetchEquity?.pagination?.total,
              onChange(page, pageSize) {
                setActivePage({
                  ...activePage,
                  currentPage: page,
                });
                paramsObjectToQueryString({
                  current_page: page,
                  page_size: pageSize,
                });
              },
              itemRender: itemRender,
              position: "bottom",
              align: "center",
              simple: true,
            }}
            renderItem={(item: any) => (
              <List.Item>
                <div className="w-full">
                  <div className="flex justify-between items-center w-full px-2 lg:px-8 py-2">
                    <div className="flex items-start gap-2">
                      <img
                        src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/check-circle.svg"
                        alt="check"
                        className="mt-1"
                      />
                      <div>
                        <Text>Upfront payment received</Text>
                        <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                          <Text>{formatInt(item?.amountPaid, true)}</Text>
                          <Text>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("LLLL")
                              : "---"}{" "}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <a href={item?.fileUrl} target="_blank" rel="noreferrer">
                      <ViewButton className="px-5">View</ViewButton>
                    </a>
                  </div>
                  <div className="flex items-center gap-2 pl-10 lg:pl-16">
                    <Text>Paid to dealer:</Text>
                    <Tag color="processing" className="uppercase w-fit">
                      {item?.paidToDealer ? "Yes" : "No"}
                    </Tag>
                  </div>
                </div>
              </List.Item>
            )}
          />
        ) : (
          <div className="w-full my-0 mx-auto pt-10 pb-3">
            <div className="flex flex-col items-center w-full">
              <div>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                  alt="caution"
                />
              </div>
              <div className="text-sm lg:text-lg text-[#6b7280]">
                No payment history available.
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
