import { Outlet } from "react-router-dom";
import Layout from "@c/layout";
import Aside from "@c/aside";
import Footer from "@/components/footer";
import Sider from "@/components/sider";

const AdaptiveAsideLayout = () => {
  return (
    <Layout.Container>
      <Aside />
      <Layout.Main>
        <Outlet />
      </Layout.Main>
      <Footer />
      <Sider />
    </Layout.Container>
  );
};

export default AdaptiveAsideLayout;
