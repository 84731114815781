import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function CollateralFieldCard({
  setCarFieldsArr,
  carFieldsArr,
}: any) {
  const [selectedCarField, setSelectedCarField]: any = useState([]);

  useEffect(() => {
    if (carFieldsArr && carFieldsArr?.length > 0) {
      const newDataArray = carFieldsArr.map((item: any, index: number) => ({
        id: index,
        carYear: item.vehicleYearModel?.value,
        carMake: item?.vehicleMake?.value,
        carModel: item?.vehicleModel?.value,
        price: item?.carWorth?.value,
        rating: "---",
        vin: item?.vehicleVin?.value,
        imageUrl:
          "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg",
      }));
      setSelectedCarField(newDataArray);
    } else {
      setSelectedCarField([]);
    }
  }, [carFieldsArr, setSelectedCarField]);

  const handleDeleteCar = (i: any) => {
    const items = selectedCarField?.filter(
      (item: any, index: number) => i !== index
    );
    setSelectedCarField(items);

    const updatedCarValue = carFieldsArr?.filter(
      (selectedCar: any, index: number) => i !== index
    );
    setCarFieldsArr(updatedCarValue);
  };

  return (
    <div className="flex flex-col gap-3">
      {selectedCarField?.map((item: any, index: number) => (
        <div
          key={item?.id}
          className="w-full border border-solid border-[#E5E7EB] rounded-lg flex justify-between gap-2 p-2"
        >
          <div className="w-[97px] h-[80px] rounded-md overflow-hidden">
            <img src={item?.imageUrl} alt="Car" className="object-cover" />
          </div>
          <div className="w-full">
            <div className="text-[#30345E] text-base font-bold mb-1">
              {`${item.carYear} ${item?.carMake} ${item?.carModel}`}
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between gap-2 pb-1">
              <div>
                <div className="text-xs text-[#6B7280] font-normal">VALUE</div>
                <div className="text-[#30345E] text-sm font-bold">
                  {item?.price}
                </div>
              </div>
              <div>
                <div className="text-xs text-[#6B7280] font-normal">VIN</div>
                <div className="text-[#30345E] text-sm font-bold">
                  {item?.vin || "---"}
                </div>
              </div>
              <div>
                <div className="text-xs text-[#6B7280] font-normal">RATING</div>
                <div className="text-[#30345E] text-sm font-bold">
                  {item?.rating}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-end text-right pt-1">
              <button
                onClick={() => handleDeleteCar(index)}
                className="text-[#F87171] text-sm font-bold flex items-center gap-1 cursor-pointer"
              >
                <RiDeleteBin6Line /> Delete
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
