import PrequalificationForm from "@/layouts/application-layouts/PrequalificationForm";
import PrequalificationResponse from "@/layouts/application-layouts/PrequalificationResponse";
import client from "@/lib/axios-client";
import { message } from "antd";
import { useState } from "react";

function Prequalification() {
  const [currentPage, setCurrentPage] = useState(0);
  const [prequalData, setPrequalData] = useState(
    {} as IPrequalificationResponse
  );
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});

  const handleNext = () => {
    setCurrentPage(1);
  };

  const handleBack = () => {
    setCurrentPage(0);
  };

  const handleSubmit = async (payload: any) => {
    setValues(payload);
    setLoading(true);
    try {
      const response = await client.post(
        "/v2/origination/loan/prequalification",
        payload
      );
      const parsedRes: IPrequalificationResponse = JSON.parse(response.data);
      setPrequalData(parsedRes);
      setLoading(false);
      handleNext();
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
      setLoading(false);
    }
  };

  if (currentPage === 0) {
    return (
      <PrequalificationForm
        initialData={values}
        loading={loading}
        submit={handleSubmit}
      />
    );
  } else {
    return <PrequalificationResponse back={handleBack} data={prequalData} />;
  }
}

export default Prequalification;
