import { useEffect } from "react";
import storage from "@l/storage";
import Inventory from "@/components/inventory";

const CarFound = () => {
  const role = storage.getRole();
  const queryParam = new URLSearchParams(window.location.search);
  const variant = queryParam.get("variant") as string;

  const handleCurrentVariant = () => {
    if (variant) {
      return "loan-transfer";
    } else {
      return "select-car";
    }
  };

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  return (
    <div>
      <Inventory variant={handleCurrentVariant()} />
    </div>
  );
};

export default CarFound;
