import { useEffect, useState } from "react";
import { DealerFinancingLockedProgress, UnlockedLock } from "@/assets/svgs";
import { Progress, Steps } from "antd";
import styled from "styled-components";
import {
  DEALER_FINANCE_COUNTRIES,
  LIMIT_REQUEST_STATUS,
} from "@/utils/finance-status";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";

const ProgressBar = styled.div`
  .ant-progress .ant-progress-inner {
    height: 5px !important;
  }
`;

const StepsContainer = styled.div`
  padding: 0px 0px;
  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.45);
    color: #6b7280;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.88);
    color: #6b7280;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.88);
    color: #6b7280;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    color: #fff;
    font-weight: 400;
    font-size: 12px;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #ffb619;
    border-color: #ffb619;
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #d1d5db;
    border-color: transparent;
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #d1d5db;
    border-color: transparent;
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    /* color: #fff; */
    color: #202020;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #202020;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background: #e5e7eb;
  }
  .ant-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
    vertical-align: super;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: rgb(255, 255, 255);
    vertical-align: super;
  }
  .anticon {
    vertical-align: -0.475em;
  }
`;

const RenderLoanProgress = ({
  dealerLimit,
  dealerSummaryErr,
  dealerSummary,
  isNonOriginatingDealer,
}: any) => {
  const country = storage.getCountry();
  const [progressPercent, setProgressPercent] = useState(0);
  const [current, setCurrent] = useState(0);
  const { t } = useTranslation("dealer-gamification");

  useEffect(() => {
    if (DEALER_FINANCE_COUNTRIES.includes(country)) {
      if (isNonOriginatingDealer) {
        setProgressPercent(0);
      } else if (
        dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED
      ) {
        setProgressPercent(34);
      } else if (dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED) {
        setProgressPercent(68);
      } else if (
        dealerLimit?.status ===
          LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED ||
        dealerLimit?.status ===
          LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW
      ) {
        setProgressPercent(68);
      } else if (dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED) {
        setProgressPercent(100);
      } else {
        setProgressPercent(34);
      }
    } else {
      setProgressPercent(0);
    }
  }, [
    dealerLimit?.status,
    dealerSummary?.level,
    dealerSummaryErr,
    country,
    isNonOriginatingDealer,
  ]);

  useEffect(() => {
    if (DEALER_FINANCE_COUNTRIES.includes(country)) {
      if (isNonOriginatingDealer) {
        setCurrent(0);
      } else if (
        dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED
      ) {
        setCurrent(1);
      } else if (dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED) {
        setCurrent(2);
      } else if (
        dealerLimit?.status ===
          LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED ||
        dealerLimit?.status ===
          LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW
      ) {
        setCurrent(2);
      } else if (dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED) {
        setCurrent(3);
      } else {
        setCurrent(1);
      }
    } else {
      setCurrent(0);
    }
  }, [
    country,
    dealerLimit?.status,
    dealerSummary?.level,
    dealerSummaryErr,
    isNonOriginatingDealer,
  ]);

  const getDealerLimitContent = () => {
    let content;

    const defaultContent = (
      <div className="flex justify-between">
        <div>
          <UnlockedLock />
        </div>
        <div>
          <UnlockedLock />
        </div>
        <div>
          <DealerFinancingLockedProgress />
        </div>
        <div>
          <DealerFinancingLockedProgress />
        </div>
      </div>
    );

    const defaultContent2 = (
      <div className="flex justify-between">
        <div>
          <UnlockedLock />
        </div>
        <div>
          <DealerFinancingLockedProgress />
        </div>
        <div>
          <DealerFinancingLockedProgress />
        </div>
        <div>
          <DealerFinancingLockedProgress />
        </div>
      </div>
    );

    switch (dealerLimit?.status) {
      case LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED:
        content = defaultContent2;
        break;
      case LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED:
        content = (
          <div className="flex justify-between">
            <div>
              <UnlockedLock />
            </div>
            <div>
              <UnlockedLock />
            </div>
            <div>
              <DealerFinancingLockedProgress />
            </div>
            <div>
              <DealerFinancingLockedProgress />
            </div>
          </div>
        );
        break;
      case LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED:
        content = defaultContent;
        break;
      case LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW:
        content = defaultContent;
        break;
      case LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED:
        content = (
          <div className="flex justify-between">
            <div>
              <UnlockedLock />
            </div>
            <div>
              <UnlockedLock />
            </div>
            <div>
              <UnlockedLock />
            </div>
            <div>
              <UnlockedLock />
            </div>
          </div>
        );
        break;
      default:
        content = (
          <div className="flex justify-between">
            <div>
              <UnlockedLock />
            </div>
            <div>
              <DealerFinancingLockedProgress />
            </div>
            <div>
              <DealerFinancingLockedProgress />
            </div>
            <div>
              <DealerFinancingLockedProgress />
            </div>
          </div>
        );
    }
    return content;
  };

  const content = getDealerLimitContent();

  return (
    <div>
      <div className="flex flex-col px-[0.81rem]">
        {/* progress icons on web */}
        <div className="hidden md:block">
          {DEALER_FINANCE_COUNTRIES.includes(country) ? (
            <>
              {isNonOriginatingDealer ? (
                <div className="flex justify-between">
                  <div>
                    <DealerFinancingLockedProgress />
                  </div>
                  <div>
                    <DealerFinancingLockedProgress />
                  </div>
                  <div>
                    <DealerFinancingLockedProgress />
                  </div>
                  <div>
                    <DealerFinancingLockedProgress />
                  </div>
                </div>
              ) : (
                <>{content}</>
              )}
            </>
          ) : (
            <div className="flex justify-between">
              <div>
                <DealerFinancingLockedProgress />
              </div>
              <div>
                <DealerFinancingLockedProgress />
              </div>
              <div>
                <DealerFinancingLockedProgress />
              </div>
              <div>
                <DealerFinancingLockedProgress />
              </div>
            </div>
          )}
        </div>

        {/* progress bar on mobile */}
        <div className="block sm:hidden">
          <StepsContainer>
            <Steps
              direction="vertical"
              current={current}
              items={[
                {
                  title: t("level-unlocked"),
                },
                {
                  title: t("financing-unlocked"),
                },
                {
                  title: (
                    <div>
                      {dealerLimit?.status ===
                        LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW ||
                      dealerLimit?.status ===
                        LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED
                        ? t("credit-limit-processing")
                        : t("credit-limit-approved")}
                    </div>
                  ),
                },
                {
                  title: t("loan-application"),
                },
              ]}
            />
          </StepsContainer>
        </div>

        <div className="hidden md:block">
          <ProgressBar>
            <Progress
              percent={progressPercent}
              trailColor="#EEEFFF"
              strokeColor="#FFB619"
              showInfo={false}
            />
          </ProgressBar>
        </div>

        <div className="hidden md:block">
          <div className="flex justify-between mt-0 md:-mt-[8px]">
            <div>
              <span className="block sm:inline text-center text-white text-sm md:text-base tracking-[-0.01563rem] w-[2.9rem] md:w-full ml-[-8px] md:ml-[0]">
                {t("level-unlocked")}
              </span>
            </div>
            <div>
              <span className="block sm:inline text-center text-white text-sm md:text-base tracking-[-0.01563rem] w-[3.9rem] md:full ml-0 md:ml-2">
                {t("financing-unlocked")}
              </span>
            </div>
            <div>
              <span className="block sm:inline text-center text-white text-sm md:text-base tracking-[-0.01563rem] w-[2.9rem] md:full ml-0 md:ml-2">
                {dealerLimit?.status ===
                  LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW ||
                dealerLimit?.status ===
                  LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED
                  ? t("credit-limit-processing")
                  : t("credit-limit-approved")}
              </span>
            </div>
            <div>
              <span className="block sm:inline text-center text-white text-sm md:text-base tracking-[-0.01563rem] w-[2.9rem] md:full">
                {t("loan-application")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderLoanProgress;
