const getSymbolByOffer = (code: string) => {
  let currency;
  switch (code) {
    case "USD":
      currency = "$ ";
      break;
    case "NGN":
    case "NG":
      currency = "₦ ";
      break;
    case "KES":
    case "KE":
      currency = "KSh ";
      break;
    case "GH":
    case "GH₵":
    case "GHS":
      currency = "GH₵ ";
      break;
    case "UGX":
    case "UG":
      currency = "USh ";
      break;
    case "XOF":
    case "CI":
    case "SN":
      currency = "XOF ";
      break;
    case "EGP":
    case "EG":
      currency = "EGP ";
      break;
    default:
      break;
  }

  return currency;
};

const formatLoanInt = (value: number, code: string): string => {
  if (value !== undefined && value !== 0) {
    const stringValue = value.toString();
    const amountChunk = Number(stringValue.split(".")[0]);

    return `${getSymbolByOffer(code)}${amountChunk.toLocaleString("en-US")}`;
  }

  return `${getSymbolByOffer(code)}0`;
};

export default formatLoanInt;
