import { IDealerBenefitState, IDealerSummaryState } from "@/types";
import create from "zustand";

export const useDealerBenefits = create<IDealerBenefitState>()((set) => ({
  benefits: [],
  setBenefits: (allDealerBenefits) =>
    set(() => ({ benefits: allDealerBenefits })),
  clearBenefits: () => set(() => ({ benefits: [] })),
}));

export const useDealerSummary = create<IDealerSummaryState>()((set) => ({
  summary: null,
  setSummary: (dealerSummaryInfo) =>
    set(() => ({ summary: dealerSummaryInfo })),
  clearSummary: () => set(() => ({ summary: null })),
}));
