export const getMetricsColor = (value: string = "") => {
  return value.startsWith("+") ? "text-[#15803D]" : "text-red-500";
};

export const dealerMedal = (level: string) => {
  switch (level) {
    case "Bronze":
      return "https://media.autochek.africa/file/publicAssets/Frame-37100.png";
    case "Silver":
      return "https://media.autochek.africa/file/publicAssets/Medals-6.png";
    case "Gold":
      return "https://media.autochek.africa/file/publicAssets/Medals-7.png";
    case "Platinum":
      return "https://media.autochek.africa/file/publicAssets/Medals-8.png";
    case "Non Originating":
      return "https://media.autochek.africa/file/publicAssets/Medals-1-l.png";
    default:
      return "https://media.autochek.africa/file/publicAssets/Medals-1-l.png";
  }
};
