export enum LIMIT_REQUEST_STATUS {
  LIMIT_REQUEST_COMPLETED = "limit_request_completed",
  LIMIT_REQUEST_NOT_COMPLETED = "limit_request_not_completed",
  LIMIT_ASSIGNED = "limit_assigned",
  LIMIT_SIGNED_DOC_UPLOADED = "limit_signed_doc_uploaded",
  LIMIT_AGREEMENT_PENDING_REVIEW = "limit_agreement_pending_review",
  LIMIT_ACTIVATED = "limit_activated",
  LIMIT_REQUEST_REJECTED = "limit_request_rejected",
}

export const DEALER_FINANCE_COUNTRIES = ["NG", "KE", "UG"];
