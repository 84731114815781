import React from "react";
import styled from "styled-components";
import { HistoryPointCardArrowIcon } from "@/assets/svgs";
import { useTranslation } from "react-i18next";
import { dealerLevel } from "./DealerLevelModal";
import { IDealerType } from "../DealerLevelCard";

const ModalOverlay = styled.div`
  z-index: 1007;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.31rem;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const DealerLevelRelapseModal = ({
  close,
  prevLevel,
  currentLevel,
}: {
  close: () => void;
  prevLevel: string;
  currentLevel: string;
}) => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <ModalOverlay>
      <div
        className={`z-[502] py-6 md:py-9 px-10 md:px-[5rem] rounded-[1.5rem] w-full md:w-[30rem] h-[34.75rem] md:h-fit bg-[#30345E] bg-cover bg-no-repeat`}
      >
        <div className="flex flex-col items-center justify-center w-full">
          <img
            src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/tears.gif"
            alt="tears"
            className=" w-16 h-16 "
          />
          <p className="text-sm text-[#EEEFFF] text-center font-bold max-w-[10rem] mx-auto leading-6 pt-2">
            {t("oh-no-relapse")}
          </p>

          <div className="max-w-[13.5rem] mx-auto pt-5 pb-3">
            <TransitionPointLevelCard
              prevLevel={prevLevel}
              currentLevel={currentLevel}
            />
          </div>

          <div>
            <p className="text-sm tracking-[0.01563rem] text-white text-center leading-6 max-w-[17.125rem] mx-auto">
              {t("you-will-access-relapse")}
            </p>
          </div>

          <button className="py-4" onClick={close}>
            <span className="text-white text-sm font-bold tracking-[0.01563rem]">
              {t("show-me-later")}
            </span>
          </button>
        </div>
      </div>
    </ModalOverlay>
  );
};

const TransitionPointLevelCard = ({
  prevLevel,
  currentLevel,
}: {
  prevLevel: string;
  currentLevel: string;
}) => {
  return (
    <div className="w-full bg-[#30345E] rounded-lg ">
      <div className="flex items-center justify-between h-full max-w-lg mx-auto">
        <PointLevelEarned
          text={currentLevel}
          url={dealerLevel[currentLevel as IDealerType]?.medal}
          levelType="current level"
        />
        <div className="flex flex-col justify-center items-center px-2">
          <div className=" rotate-180 ">
            <HistoryPointCardArrowIcon />
          </div>
        </div>
        {/*  */}
        <PointLevelEarned
          text={prevLevel}
          url={dealerLevel[prevLevel as IDealerType]?.medal}
          levelType="Previous level"
        />
      </div>
    </div>
  );
};

const PointLevelEarned = ({
  text,
  url,
  levelType,
}: {
  text: string;
  url: string;
  levelType: string;
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="pt-1">
        <p className="uppercase text-sm text-white text-center pt-1 font-bold tracking-[0.0625rem] leading-2 mb-2">
          {levelType}
        </p>
        <img src={url} alt="gold" className="w-16 h-16" />
        <p className="uppercase text-sm text-white text-center pt-1 font-bold tracking-[0.0625rem] leading-6">
          {text}
        </p>
      </div>
    </div>
  );
};

export default DealerLevelRelapseModal;
