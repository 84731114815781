import { Space } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import { device } from "@/utils/device";
import { isMobile } from "react-device-detect";
import formatInt from "@/utils/format-int";
import { useTranslation } from "react-i18next";
import formatLoanInt from "@/utils/format-loan-currency";

ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = ({ loanDetails, loansById }: any) => {
  const { t } = useTranslation("application");

  const activeColors = ["#BFDBFE", "#FFB619", "#30345E"];
  const disabledColor = [
    "rgba(0, 0, 0, 0.3)",
    "rgba(0, 0, 0, 0.3)",
    "rgba(0, 0, 0, 0.3)",
  ];

  const data = {
    datasets: [
      {
        data: [
          Number(loanDetails?.loanSummary?.additionalFees || 100),
          Number(loanDetails?.loanSummary?.equity || 0),
          Number(loanDetails?.loanSummary?.loan || 0),
        ],
        backgroundColor: loanDetails?.loanSummary
          ? activeColors
          : disabledColor,
        borderColor: ["#BFDBFE", "#FFB619", "#30345E"],
        borderWidth: 0,
        weight: 2,
        cutout: 70,
        responsive: false,
        clip: 40,
        elements: {
          arc: {
            radius: 10,
            angle: "circumference",
            borderAlign: "inner",
            borderRadius: 10,
            borderJoinStyle: "round",
            spacing: -12,
          },
        },
      },
    ],
  };

  const dataForMobile = {
    datasets: [
      {
        data: [
          Number(loanDetails?.loanSummary?.additionalFees || 100),
          Number(loanDetails?.loanSummary?.equity || 0),
          Number(loanDetails?.loanSummary?.loan || 0),
        ],
        backgroundColor: loanDetails?.loanSummary
          ? activeColors
          : disabledColor,
        borderColor: ["#BFDBFE", "#FFB619", "#30345E"],
        borderWidth: 0,
        weight: 2,
        cutout: 64,
        responsive: false,
        clip: 40,
        elements: {
          arc: {
            radius: 10,
            angle: "circumference",
            borderAlign: "inner",
            borderRadius: 10,
            borderJoinStyle: "round",
            spacing: -12,
          },
        },
      },
    ],
  };

  const ChartContainer = styled.div`
    position: relative;
    @media ${device.tablet} {
      display: flex;
      align-items: center;
    }
    @media ${device.mobileL} {
      display: flex;
      align-items: center;
    }
    .chart-value {
      position: absolute;
      left: 0;
      right: 0;
      transform: translateY(190%);
      text-align: center;
      @media ${device.mobileL} {
        position: absolute;
        left: 0%;
        right: 0;
        transform: translateY(170%);
      }
      .name {
        color: #6b7280;
        font-weight: 600;
        font-size: 10.9703px;
      }
      .value {
        color: #30345e;
        font-weight: 600;
        font-size: 14.6271px;
      }
    }
    .summary-container {
      display: flex;
      justify-content: center;
      .summary {
        color: #30345e;
        font-weight: 600;
        font-size: 14.6271px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }
      .loan {
        color: #6b7280;
        font-size: 12.7987px;
        &::before {
          content: "";
          width: 13.71px;
          height: 13.71px;
          background: #30345e;
          display: inline-block;
          vertical-align: sub;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .equity {
        color: #6b7280;
        font-size: 12.7987px;
        &::before {
          content: "";
          width: 13.71px;
          height: 13.71px;
          background: #ffb619;
          display: inline-block;
          vertical-align: sub;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .fees {
        color: #6b7280;
        font-size: 12.7987px;
        &::before {
          content: "";
          width: 13.71px;
          height: 13.71px;
          background: #bfdbfe;
          display: inline-block;
          vertical-align: sub;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
  `;

  const loanCurrency = loansById?.loanCurrency || loansById?.currency;

  return (
    <ChartContainer>
      {isMobile ? (
        <div style={{ width: "157px", height: "157px", position: "relative" }}>
          <div className="chart-value">
            <div className="name">{t("total-loan-value")}</div>
            <div className="value">{formatLoanInt(loansById?.loanValue, loanCurrency)}</div>
          </div>
          <Doughnut data={dataForMobile} />
        </div>
      ) : (
        <div>
          <div className="chart-value">
            <div className="name">{t("total-loan-value")}</div>
            <div className="value">{formatLoanInt(loansById?.loanValue, loanCurrency)}</div>
          </div>
          <Doughnut data={data} />
        </div>
      )}

      <div className="summary-container">
        <div>
          <div className="summary">{t("loan-summary")}</div>
          <Space direction="vertical">
            <div className="loan">
              {loanDetails?.loanSummary?.loan || 0}% {t("loan")}
            </div>
            <div className="equity">
              {loanDetails?.loanSummary?.equity || 0}% {t("equity")}
            </div>
            <div className="fees">
              {loanDetails?.loanSummary?.additionalFees || 0}%{" "}
              {t("additional-fees")?.toLowerCase()}
            </div>
          </Space>
        </div>
      </div>
    </ChartContainer>
  );
};

export default Chart;
