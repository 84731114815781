import { useEffect } from "react";
import storage from "@l/storage";
import Inventory from "@/components/inventory";

const CarList = () => {
  const role = storage.getRole();

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  return <Inventory variant="change-car" />;
};

export default CarList;
