import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs, Collapse, Tag, Button, TabsProps, Modal, Select } from "antd";
import {
  bodyType,
  carMake,
  carModel,
  getDealerCars,
  getDealerProduct,
  getDealerProductData,
} from "@/hooks/use-loan";
import formatInt from "@/utils/format-int";
import {
  DealerAppTabContainer,
  CustomerCarInfo,
  CollapseContainer,
  TableContainer,
  CustomerDocument,
  GeneralEmptyState,
  LoanDocsAccordion,
  PartnerSelect,
} from "@/layouts/application-layouts/styles";
import DealerLoanSteps from "@/layouts/application-layouts/DealerLoanSteps";
import tw from "tailwind-styled-components";
import { isMobile } from "react-device-detect";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import ResumeLoanApplication from "@/layouts/application-layouts/ResumeApplication";
import NoLoanOffers from "@/layouts/application-layouts/NoLoanOffer";
import InputControl from "@/components/formElements/input-control";
import AdditionalDealerDocModal from "@/layouts/application-layouts/AddedDealerDocsModal";
import DealerLoanOffers from "@/components/DealerLoansOffers";
import { Loader } from "../auth/styles/loader";
import { ILoansById, IPartnerList, LoanLimitItem } from "@/interface/dealer";
import {
  DEALER_FINANCE_COUNTRIES,
  LIMIT_REQUEST_STATUS,
} from "@/utils/finance-status";
import { Alert, Box, Snackbar, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { IDealerProductResult, IProductData } from "@/types";
import InputGroup, { Input } from "@/components/formElements/input-group";
import { BODY_TYPES, VEHICLE_MAKES, VEHICLE_MODELS } from "@/utils/helpers";
import { useForm } from "react-hook-form";
import {
  getFilteredCarResult,
  getUpdatedCarFields,
  handleCollateralInputOnChange,
  mapPrefilledCarInfo,
} from "@/utils/process-form-data";
import CollateralCarCardContainer from "@/components/CollateralCarCardContainer";
import UploadDocCard from "@/components/UploadDocCard";
import CustomsDocumentForm from "@/components/CustomsDocForm";

type IDocType = "CAR_DOCUMENTS" | "EXCLUDE_CAR_DOCUMENTS";
type LoanStatus =
  | "CONDITIONAL_OFFER_RECEIVED"
  | "DOCUMENT_SUBMITTED"
  | "INSPECTION_IN_PROGRESS"
  | "INSPECTION_COMPLETED"
  | "FINAL_OFFER_RECEIVED";

type LoansById = {
  status?: LoanStatus;
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DealerLoanInfo = styled.div`
  background: #ffffff;
  height: auto;
  padding: 16px;
  margin-bottom: 24px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-text {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1rem 0 0.5rem 0;
  }
  .bottom-text {
    text-align: center;
  }
  .button-ok {
    background: #ffb619;
    padding: 0.4rem 1.6rem;
    border-radius: 1rem;
    margin-top: 1rem;

    &:hover {
      background: #d89300;
    }
  }
`;

const Title = tw.div`text-[#30345E] text-base font-semibold`;

const DealDetails = () => {
  const { id }: any = useParams();
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("loan-form");
  const { t: t2 } = useTranslation("dealer-gamification");
  const { Panel } = Collapse;
  const [loansById, setLoansById] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [collateralOption, setCollateralOption] = useState("");
  const [selectedCollateral, setSelectedCollateral]: any = useState([]);
  const openAddCollateralModal = () => {
    setModalOpen(true);
    setCollateralOption("ADD COLLATERAL");
    setShowPrefilledCars([]);
  };
  const removeCollateral = (data: any) => {
    setModalOpen(true);
    setSelectedCollateral(data);
    setCollateralOption("REMOVE COLLATERAL");
  };
  const openCustomDocumentModal = (data: any) => {
    setModalOpen(true);
    setSelectedCollateral(data);
    setCollateralOption("CUSTOM DOCUMENT");
  };

  const openReplaceCollateralModal = (data: any) => {
    setModalOpen(true);
    setSelectedCollateral(data);
    setCollateralOption("REPLACE COLLATERAL");
    setShowPrefilledCars([]);
    setShowCarFields(true);
  };

  const queryClient: any = useQueryClient();
  const [showCarFields, setShowCarFields] = useState(false);
  const navigate = useNavigate();
  const role = storage.getRole();
  const country = storage.getCountry();
  const [uploadMoreDocs, setUploadMoreDocs] = useState(false);
  const [carDocType, setCarDocType] = useState<string>();
  const [dealerProduct, setDealerProduct] = useState<IDealerProductResult>();
  const [carVin, setCarVin] = useState<string>("");
  const [dealerLimitDocument, setDealerLimitDocument] =
    useState<LoanLimitItem>();

  const user = storage.getUser();
  const { hash } = useLocation();
  const [productData, setProductData] = useState<IProductData>();
  const [carFieldsArr, setCarFieldsArr] = useState<any>([]);
  const [selectedCarValue, setSelectedCarValue]: any = useState([]);
  const [franchiseCar, setFranchiseCar]: any = useState([]);
  const [allFranchiseCar, setAllFranchiseCar]: any = useState([]);
  const [loanCollateral, setLoanCollateral]: any = useState({});
  const [bodyTypes, setBodyTypes] = useState([]);
  const [showPrefilledCars, setShowPrefilledCars]: any = useState([]);
  const [getPartnerName, setGetPartnerName] = useState<string>("");
  const [partnersList, setPartnersList] = useState<IPartnerList[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState([]);

  const [carModels, setCarModels]: any[] = useState([]);
  const [carMakes, setCarMakes]: any[] = useState([]);
  const [loanCarField, setLoanCarField] = useState<any>({});
  const [productCarField, setProductCarField] = useState<any>({});
  const [loanCarsById, setLoanCarsById]: any = useState([]);
  const [loansTab, setLoansTab] = useState("1");
  const [tabKey, setTabKey] = useState("1");
  const [addCollateralLoading, setAddCollateralLoading] = useState(false);
  const [removeCollateralLoading, setRemoveCollateralLoading] = useState(false);
  const [dealerConditionalOfferLoans, setDealerConditionalOfferLoans] =
    useState([]);
  const [dealerFinalOfferLoans, setDealerFinalOfferLoans] = useState([]);
  const isAssumedUser = storage.getAssumedUser();
  const methods = useForm();
  const { control } = methods;
  let selectOptions: any = {};
  const [alert, setAlert] = useState("");
  const getLoanId = window.location.href.split("/").at(-1) as string;

  const resetFormFields = () => {
    const ownVehicle = {
      name: "ownVehicle",
      value: undefined,
      label: `Do you currently own the vehicle or are you planning to buy the vehicle?`,
      valid: true,
    };
    const isImportedVehicle = {
      name: "isImportedVehicle",
      value: undefined,
      label: `Is the vehicle you are pledging imported or not`,
      valid: true,
    };
    const vehicleMake = {
      name: "vehicleMake",
      value: undefined,
      type: "selection",
      label: "Vehicle Make",
      valid: true,
    };
    const vehicleModel = {
      name: "vehicleModel",
      value: undefined,
      type: "selection",
      label: "Vehicle model",
      valid: true,
    };
    const vehicleYearModel = {
      name: "vehicleYearModel",
      value: "",
      type: "number",
      label: "Vehicle year model",
      valid: true,
    };
    const sellingCondition = {
      name: "sellingCondition",
      value: undefined,
      type: "selection",
      label: "What is the selling condition ?",
      valid: true,
    };
    const bodyType = {
      name: "bodyType",
      value: undefined,
      type: "selection",
      label: "Body type",
      valid: true,
    };
    const vehicleUse = {
      name: "vehicleUse",
      value: undefined,
      type: "selection",
      label: "Vehicle use",
      valid: true,
    };
    const carWorth = {
      name: "carWorth",
      value: "",
      type: "amount",
      label: "How much do you believe your car is worth?",
      valid: true,
    };
    const spareKey = {
      name: "spareKey",
      value: "",
      type: "radio",
      label: "Do you have a spare key?",
      valid: true,
    };
    const engineCapacity = {
      name: "engineCapacity",
      value: undefined,
      type: "selection",
      label: "Engine capacity",
      valid: true,
    };
    const vehicleVin = {
      name: "vehicleVin",
      value: "",
      type: "text",
      label: "Vehicle Vin",
      valid: true,
    };

    let data: any = {
      ownVehicle,
      isImportedVehicle,
      vehicleMake,
      vehicleModel,
      vehicleYearModel,
      sellingCondition,
      bodyType,
      vehicleUse,
      carWorth,
      spareKey,
      engineCapacity,
      vehicleVin,
    };

    setLoanCarField((state: any) => ({
      ...state,
      ...data,
    }));
  };

  const handleLoanTabs = (key: string) => {
    navigate({ hash: `${key}` });
    setLoansTab(key);
  };

  useEffect(() => {
    if (hash === "#2") {
      setLoansTab("2");
    }
  }, [loansTab, hash]);

  useEffect(() => {
    const carMakes = carMake(country, 1000);
    carMakes.then((result) => setCarMakes(JSON.parse(result?.data).makeList));
  }, [country]);

  useEffect(() => {
    const bodyTypes = bodyType(1000);
    bodyTypes.then((result) =>
      setBodyTypes(JSON.parse(result?.data).bodyTypeList)
    );
  }, []);

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  useEffect(() => {
    const product = getDealerProduct(country);
    product.then((result) => setDealerProduct(JSON.parse(result.data)));
  }, [country]);

  useEffect(() => {
    setGetPartnerName(partnersList[0]?.value);
  }, [partnersList]);

  const handlePartnerChange = (value: string) => {
    setGetPartnerName(value);
  };

  useEffect(() => {
    if (dealerProduct?.result) {
      const productId = dealerProduct?.result?.find(
        (x: any) => x.name === loansById.productName
      )?.id;
      const product = getDealerProductData(productId, "");
      product.then((result) => {
        const parsedData = JSON.parse(result.data);
        setProductData(parsedData);
        setProductCarField(parsedData?.config?.carFields[0]?.fields);
      });
    }
  }, [dealerProduct, loansById.productName]);

  useEffect(() => {
    const vehicleModelId = {
      name: "vehicleModelId",
      value: `${
        carModels?.vehicleModel?.find(
          (model: any) => model?.name === loanCarField?.vehicleModel?.value
        )?.id || ""
      }`,
      type: "number",
      valid: true,
    };

    let data: any = {
      vehicleModelId,
    };

    setLoanCarField((state: any) => ({
      ...state,
      ...data,
    }));
  }, [carModels?.vehicleModel, loanCarField?.vehicleModel?.value]);

  useEffect(() => {
    handleGetAllPartnersWithFinalOffer(loansById);
  }, [loansById]);

  useEffect(() => {
    resetFormFields();
  }, []);

  useEffect(() => {
    const cars = getDealerCars(country, loansById?.customer?.dealerId);
    cars.then((result) => {
      const parsedResult = JSON.parse(result?.data);
      const filteredCarResult = getFilteredCarResult(parsedResult);
      setFranchiseCar(filteredCarResult);
      setAllFranchiseCar(parsedResult);
    });
  }, [country, loansById?.customer?.dealerId]);

  const { isFetching: loansByDealerLoading } = useQuery({
    queryKey: ["dealer_loans_by_id", country, id],
    queryFn: () => {
      return client.get(`/v1/dealer/loans/${id}`);
    },
    onSuccess: (res: any) => {
      const parsedDealerLoanDetails = JSON.parse(res?.data);
      const conditionalOfferI = parsedDealerLoanDetails?.offers?.filter(
        function (item: any) {
          return item?.offerType === "CONDITIONAL_OFFER";
        }
      );
      const finalOfferI = parsedDealerLoanDetails?.offers?.filter(function (
        item: any
      ) {
        return item?.offerType === "FINAL_OFFER";
      });
      setDealerConditionalOfferLoans(conditionalOfferI);
      setDealerFinalOfferLoans(finalOfferI);
      setLoansById(parsedDealerLoanDetails);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["document_list", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return client.get(`/v1/origination/documents`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedDocumentList = JSON.parse(res?.data);
      setDocumentList(parsedDocumentList?.documents);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["get_dealer_loans_by_id", country, getLoanId],
    queryFn: () => {
      return client.get(`/v1/dealer/loans/${getLoanId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);

      setLoanCarsById(parsedLoanDetails?.car);
    },
    enabled: !!getLoanId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  // render prefilled car inventory data
  useEffect(() => {
    if (loanCarsById && loanCarsById?.length > 0) {
      const mappedPrefilledCarInfo = mapPrefilledCarInfo(loanCarsById);

      setShowPrefilledCars(mappedPrefilledCarInfo);
    } else {
      setShowPrefilledCars([]);
    }
  }, [loanCarsById]);

  useQuery({
    queryKey: ["fetch_uploaded_documents", id],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v2/origination/loan/document-config/${id}`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedUploadedDocuments = JSON.parse(res?.data);
      const fieldWithDocuments = parsedUploadedDocuments?.documents?.find(
        (item: { label: string }) => item.label.startsWith("Upload Documents")
      );
      setUploadedDocuments(fieldWithDocuments?.fields);
    },
    enabled: !!id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["fetch_dashboard_dealer_limit"],
    queryFn: () => {
      return client.get(
        `/v1/dealer/${user?.company?.id || loansById?.customer?.dealerId}`
      );
    },
    onSuccess: (res: any) => {
      const parsedResponseData: any = JSON.parse(res?.data);
      const filteredParsedData = parsedResponseData?.results?.find(
        (item: any) => item?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED
      );
      const limitDocument = filteredParsedData?.loanLimits?.find(
        (item: any) => item?.limitDocuments?.length > 0
      );
      setDealerLimitDocument(limitDocument);
    },
    enabled: !!loansById?.customer?.dealerId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const showMoreDocs = (type: string, vin: string) => {
    setUploadMoreDocs(true);
    setCarDocType(type);
    setCarVin(vin);
  };

  const handleUploadMoreDocs = () => {
    setUploadMoreDocs(false);
  };

  const handleCancelUploadDoc = () => {
    setUploadMoreDocs(false);
  };

  let content;
  if (
    loansById?.productStatus?.name === "APPLICATION_COMPLETED" &&
    loansById?.offers?.length === 0
  ) {
    content = <NoLoanOffers />;
  } else if (loansById?.productStatus?.name === "APPLICATION_NOT_COMPLETED") {
    content = <ResumeLoanApplication loansById={loansById} />;
  } else {
    content = (
      <DealerLoanOffers
        tabKey={tabKey}
        setTabKey={setTabKey}
        id={id}
        loansById={loansById}
        initialOffer={dealerConditionalOfferLoans}
        finalOffer={dealerFinalOfferLoans}
      />
    );
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`loan-details`),
      children: content,
    },
    {
      key: "2",
      label: t(`application-stage`),
      children: <DealerLoanSteps id={id} />,
    },
  ];

  const getInspectionStatusColor = (car: Record<string, any>) => {
    let color = "orange";
    if (car?.inspectionStatus === "completed") {
      color = "green";
    } else if (car?.inspectionStatus === "cancelled") {
      color = "red";
    }
    return color;
  };

  const handleGetAllPartnersWithFinalOffer = (data: ILoansById) => {
    const finalOfferPartners: { label: string; value: string }[] = [];

    const filterFinalOffersWithLoanDocs =
      data?.offers?.filter(
        (document) =>
          document?.offerType === "FINAL_OFFER" &&
          document?.financeDocuments?.length > 0
      ) ?? [];

    const onlyPartnerList = filterFinalOffersWithLoanDocs?.map((item: any) => ({
      label: item?.partner?.name ?? "",
      value: item?.partner?.name ?? "",
    }));

    finalOfferPartners?.push(...onlyPartnerList);

    setPartnersList(finalOfferPartners);
  };

  const getDocumentStatus = (car: Record<string, any>) => {
    if (car?.isDocComplete === true) {
      return t2("document-completed");
    } else if (car?.isDocComplete === false) {
      return t2("incomplete-document");
    } else {
      return t2("document-pending");
    }
  };

  if (loansByDealerLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  const docsIsFalse = loansById?.car?.find(
    (item: Record<string, any>) =>
      !item?.isDocComplete && item?.inspectionStatus !== "cancelled"
  );

  const renderNextAction = (
    loansById?: LoansById,
    docsIsFalse?: any
  ): string | null => {
    const messages: { [key in LoanStatus]?: string } = {
      CONDITIONAL_OFFER_RECEIVED: t2("you-have-been-given-a-conditional-offer"),
      DOCUMENT_SUBMITTED: t2(
        "offer-accepted-your-document-is-currently-being-reviewed"
      ),
      INSPECTION_IN_PROGRESS: t2("document-has-been-reviewed"),
      INSPECTION_COMPLETED: docsIsFalse
        ? t2("inspection-completed-please-upload-car-document")
        : t2("inspection-is-completed-you-will-be-sent-a-final-offer-soon"),
      FINAL_OFFER_RECEIVED: t2("you-have-been-given-a-final-offer"),
    };

    const status = loansById?.status;
    return status ? messages[status] ?? null : null;
  };

  const ALLOW_STATUSES: string[] = [
    "CONDITIONAL_OFFER_RECEIVED",
    "DOCUMENT_SUBMITTED",
    "INSPECTION_IN_PROGRESS",
    "INSPECTION_COMPLETED",
    "FINAL_OFFER_RECEIVED",
  ];

  const getCarModels = (vehicleMake: string, fieldName: string) => {
    if (vehicleMake) {
      const carModels = carModel(country, vehicleMake, 1000);
      carModels.then((result) =>
        setCarModels((state: any) => ({
          ...state,
          [fieldName]: JSON.parse(result?.data).modelList,
        }))
      );
    } else {
      return [];
    }
  };

  const handleCarFieldsChange = async (
    e: any,
    item?: any,
    initialType?: string
  ) => {
    const { name, value, type, label } = e.target;
    const valueObject = {
      name,
      value:
        name === "vehicleMake"
          ? carMakes?.find((item: any) => item?.id === Number(value))?.name
          : value,
      type: initialType ?? type,
      label: item?.label ?? label,
      valid: true,
    };

    if (name.includes("vehicleMake")) {
      const vehicleMakeField = productCarField.find(
        (item: any) => item.name === name
      );
      const currentIndex = productCarField.indexOf(vehicleMakeField);
      const nextFieldName = productCarField[currentIndex + 1].name;
      getCarModels(value, nextFieldName);
    }

    setLoanCarField((state: {}) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  const handleRenderOptions = (item: Record<string, any>) => {
    if (VEHICLE_MAKES?.includes(item?.name)) {
      return carMakes?.map((x: any) => ({
        label: x?.name,
        value: `${x?.id}`,
      }));
    } else if (VEHICLE_MODELS?.includes(item?.name)) {
      return carModels[item.name]?.map((x: any) => ({
        label: x?.name,
        value: `${x.name}`,
      }));
    } else if (BODY_TYPES.includes(item.name)) {
      return bodyTypes;
    } else if (item.options?.length) {
      return item.options;
    }
    return [];
  };
  const totalCarFields = [...carFieldsArr];

  const selectedCarIds = selectedCarValue.map(
    (car: any) => car.dealerCar.value
  );

  const handlePayload = () => {
    const updatedCarFields = getUpdatedCarFields(totalCarFields);

    const payload: any = {
      previous_car_id: selectedCollateral?.id,
      new_car_id: selectedCarIds,
      loan_id: loansById?.id,
      car_fields: updatedCarFields,
    };

    return payload;
  };

  const handleSubmitCarFields = () => {
    setCarFieldsArr((prevDataArray: any) => [
      ...prevDataArray,
      { ...loanCarField },
    ]);

    resetFormFields();
    setShowCarFields(false);
  };

  const handleCloseCarFields = () => {
    resetFormFields();
    setShowCarFields(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setAddCollateralLoading(true);

    const payload = handlePayload();

    try {
      const response = await client.post(
        `v1/dealer/change-collateral`,
        payload
      );
      if (response) {
        setAddCollateralLoading(false);
        setModalOpen(false);
        queryClient.invalidateQueries("dealer_loans_by_id");
      }
    } catch (error: any) {
      setAddCollateralLoading(false);
      setAlert(JSON.parse(error.response.data).error);
    }
  };

  const handleRemoveCollateral = async (e: any) => {
    e.preventDefault();
    setRemoveCollateralLoading(true);
    const payload: any = {
      car_id: selectedCollateral?.id,
      loan_id: loansById?.id,
    };
    try {
      await client.delete(`v1/dealer/remove-dealer-collateral`, {
        data: payload,
      });
      setRemoveCollateralLoading(false);
      setModalOpen(false);
      queryClient.invalidateQueries("dealer_loans_by_id");
    } catch (error) {
      setRemoveCollateralLoading(false);
      setAlert("Error deleting collateral");
    }
  };

  const loanDocumentList = (data: ILoansById) => {
    const newLoanDocuments = [];

    const filterFinalOffers =
      data?.offers?.filter(
        (document) =>
          document?.offerType === "FINAL_OFFER" &&
          document?.partner?.name === getPartnerName
      ) ?? [];

    for (const item of filterFinalOffers) {
      const loanDocumentWithPartner = item?.financeDocuments?.map(
        (doc: any) => ({
          ...doc,
          partner: item?.partner?.name,
          offerId: item?.id,
        })
      );
      newLoanDocuments?.push(...loanDocumentWithPartner);
    }

    return newLoanDocuments;
  };

  const renderUploadedDocuments = () => {
    return (
      <LoanDocsAccordion>
        <Collapse
          accordion
          defaultActiveKey={["1"]}
          expandIconPosition="end"
          bordered={false}
          className="site-collapse-custom-collapse"
          style={{ background: "white" }}
        >
          <Panel
            header={
              <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                Uploaded documents ({loansById?.personalDocs?.length})
              </div>
            }
            key="1"
            className="site-collapse-custom-panel"
          >
            {/* loansById?.personalDocs */}
            <UploadDocCard
              docuFields={loansById?.personalDocs}
              id={id}
              documentList={documentList}
              loansById={loansById}
              loanDocs={[]}
              loanRequiredDocument={[]}
              loanCustomsDoc={[]}
              loanPpiDoc={[]}
              loanValuationDoc={[]}
              equityDoc={[]}
              type="preview"
            />
          </Panel>
          <Panel
            header={
              <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                Optional Documents ({loansById?.optionalDocs?.length || 0})
              </div>
            }
            key="2"
            className="site-collapse-custom-panel"
          >
            {/* loansById?.personalDocs */}
            <UploadDocCard
              docuFields={loansById?.optionalDocs}
              id={id}
              documentList={documentList}
              loansById={loansById}
              loanDocs={[]}
              loanRequiredDocument={[]}
              loanCustomsDoc={[]}
              loanPpiDoc={[]}
              loanValuationDoc={[]}
              equityDoc={[]}
              type="preview"
            />
          </Panel>
          <Panel
            header={
              <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                Master Agreement (
                {dealerLimitDocument?.limitDocuments?.length ?? 0})
              </div>
            }
            key="3"
            className="site-collapse-custom-panel"
          >
            {/* loansById?.personalDocs */}
            <UploadDocCard
              docuFields={dealerLimitDocument?.limitDocuments}
              id={id}
              documentList={documentList}
              loansById={loansById}
              loanDocs={[]}
              loanRequiredDocument={uploadedDocuments}
              loanCustomsDoc={[]}
              loanPpiDoc={[]}
              loanValuationDoc={[]}
              equityDoc={[]}
              type="preview"
            />
          </Panel>
          <Panel
            header={
              <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                Loan Documents ({loanDocumentList(loansById)?.length})
              </div>
            }
            key="4"
            className="site-collapse-custom-panel"
          >
            <div className="flex flex-col gap-5">
              <PartnerSelect className="flex justify-start md:justify-end">
                <Select
                  className="w-[200px]"
                  placeholder={t2("Select bank partner")}
                  onChange={handlePartnerChange}
                  value={getPartnerName}
                  disabled={!!loansById?.salesOppId?.trim()}
                  options={partnersList}
                />
              </PartnerSelect>

              {loanDocumentList(loansById)?.length > 0 ? (
                <UploadDocCard
                  docuFields={[]}
                  id={id}
                  documentList={[]}
                  loansById={loansById}
                  loanDocs={loanDocumentList(loansById)}
                  loanRequiredDocument={[]}
                  loanCustomsDoc={[]}
                  loanPpiDoc={[]}
                  loanValuationDoc={[]}
                  equityDoc={[]}
                  type="loan"
                />
              ) : (
                <div className="bg-white min-h-[200px] flex items-center justify-center">
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                    alt="empty-page"
                  />
                </div>
              )}
            </div>
          </Panel>
        </Collapse>
      </LoanDocsAccordion>
    );
  };

  let containsDealerFinanceCountry = false;

  for (const substring of DEALER_FINANCE_COUNTRIES) {
    if (country?.includes(substring)) {
      containsDealerFinanceCountry = true;
      break;
    }
  }

  return (
    <Main>
      <div className="flex items-center justify-between gap-6 bg-white px-[12px] py-[16px] md:p-[16px] rounded-lg border-b-[1px] border-[#e5e7eb]">
        <button
          className="flex items-center gap-2 font-semibold text-[#30345e] text-[16px] md:text-[20px] cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
            alt="arrow-back"
            className="w-8 h-8 cursor-pointer"
          />{" "}
          <div className="name">
            <span className="capitalize tracking-[0.00938rem] font-bold">
              {t1("back")}
            </span>
          </div>
        </button>
      </div>

      {ALLOW_STATUSES.includes(loansById?.status) && (
        <div className="bg-white border-b border-[#e5e7eb] rounded-lg min-h-[52px] h-auto p-3 md:p-4 flex flex-col md:flex-row items-start md:items-center gap-3 md:gap-0 justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/checkestepIcon.svg?updatedAt=1711358779069"
                alt="Status"
              />
              <div className="text-sm md:text-base font-semibold text-[#30345e]">
                {renderNextAction(loansById, docsIsFalse)}
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate(`/dealer/applications/loans/${id}/#2`)}
            className="bg-white text-sm border border-solid border-[#30345e] rounded-[60px] h-9 px-3 font-semibold ml-9 md:ml-0"
          >
            {t2("view-all-stages")}
          </button>
        </div>
      )}

      <div>
        <DealerAppTabContainer>
          <div className="flex flex-col xl:flex-row gap-7 xl:gap-6 w-full">
            {loansById?.product?.name === "Repo Finance" && (
              <>
                {isMobile ? (
                  <CollapseContainer>
                    <Collapse defaultActiveKey={["0"]} expandIconPosition="end">
                      <Panel header={t("car-info")} key="1">
                        <CustomerCarInfo>
                          <div className="car-info">
                            <div style={{ marginBottom: "10px" }}>
                              <img
                                src={
                                  loansById?.car[0]?.carDetails?.imageUrl ||
                                  `https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg`
                                }
                                alt="car"
                                width="404px"
                                height="246px"
                                style={{
                                  borderRadius: "7px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div>
                              <div className="detail">
                                <div>{t("car-name")}:</div>
                                <div>
                                  {loansById?.car[0]?.carDetails.model?.make
                                    ?.name
                                    ? `${loansById?.car[0]?.carDetails?.model?.make?.name} ${loansById?.car[0]?.carDetails?.model?.name} ${loansById?.car[0]?.carDetails?.year}`
                                    : "---"}
                                </div>
                              </div>
                              <div className="detail">
                                <div>{t("vin")}:</div>
                                <div>{loansById?.car[0]?.vin || "---"}</div>
                              </div>
                              <div className="detail">
                                <div>{t("rating")}:</div>
                                <div>
                                  {loansById?.car[0]?.gradeScore?.toFixed(1) ||
                                    "---"}
                                </div>
                              </div>
                              <div className="detail">
                                <div>{t("car-price")}:</div>
                                <div>
                                  {formatInt(loansById?.car[0]?.price, true) ||
                                    "---"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </CustomerCarInfo>
                      </Panel>
                    </Collapse>
                  </CollapseContainer>
                ) : (
                  <div className="flex-1">
                    <CustomerCarInfo
                      className="mb-6"
                      style={{ minHeight: "auto" }}
                    >
                      <div className="car-info">
                        <div style={{ marginBottom: "10px" }}>
                          <img
                            src={
                              loansById?.car[0]?.carDetails?.imageUrl ||
                              `https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg`
                            }
                            alt="car"
                            width="404px"
                            height="246px"
                            style={{
                              borderRadius: "7px",
                              width: "100%",
                              height: "246px",

                              ...(loansById?.car[0]?.carDetails?.imageUrl
                                ? {}
                                : { padding: "40px", objectFit: "contain" }),
                            }}
                          />
                        </div>
                        <div className="detail">
                          <div>{t("car-name")}</div>
                          <div>
                            {loansById?.car[0]?.carDetails.model?.make?.name
                              ? `${loansById?.car[0]?.carDetails?.model?.make?.name} ${loansById?.car[0]?.carDetails?.model?.name} ${loansById?.car[0]?.carDetails?.year}`
                              : "---"}
                          </div>
                        </div>
                        <div className="detail">
                          <div>{t("vin")}</div>
                          <div>{loansById?.car[0]?.vin || "---"}</div>
                        </div>
                        <div className="detail mb-2">
                          <div>{t("rating")}</div>
                          <div>
                            {loansById?.car[0]?.gradeScore?.toFixed(1) || "---"}
                          </div>
                        </div>

                        <div className="car-price">
                          <div>
                            {t("car-price")}:{" "}
                            {formatInt(loansById?.car[0]?.price, true)}
                          </div>
                        </div>
                      </div>
                    </CustomerCarInfo>
                  </div>
                )}
              </>
            )}

            <div className="xl:w-[70%] w-full h-full mt-4 md:mt-0">
              <DealerLoanInfo>
                <Tabs
                  activeKey={loansTab}
                  items={items}
                  onChange={handleLoanTabs}
                />
              </DealerLoanInfo>

              <CustomerDocument>
                <div className="border-[1px] border-[rgb(229, 231, 235)] px-[0px] md:px-[12px] py-[16px] rounded">
                  <header style={{ padding: 14 }}>
                    <div className="text-sm md:text-base text-[#30345e] font-semibold">
                      {t("customer-documents")}
                    </div>
                    <Button
                      onClick={() =>
                        showMoreDocs("EXCLUDE_CAR_DOCUMENTS" as IDocType, "")
                      }
                      className="upload"
                      disabled={!!isAssumedUser}
                    >
                      {t("upload-additional-document")}
                    </Button>
                  </header>
                  <div className="">{renderUploadedDocuments()}</div>
                </div>
              </CustomerDocument>
            </div>

            {loansById?.product?.name !== "Repo Finance" && (
              <div className="flex-1">
                {loansById?.car?.length > 0 ? (
                  <div className="w-full h-auto bg-white rounded-lg border-b-[1px] border-[#e5e7eb] py-4">
                    <div className="flex justify-between items-center">
                      <div className="text-xl text-[#30345E] font-bold px-3 mb-1">
                        {t2("collaterals")}
                      </div>
                      {(user.partner.authority.roles.includes(
                        "ACCOUNT_MANAGER"
                      ) ||
                        user.partner.authority.roles.includes(
                          "DEALER_FINANCE_MANAGER"
                        )) &&
                        containsDealerFinanceCountry && (
                          <div className="min-w-full flex flex-col items-center mb-6">
                            <button
                              onClick={openAddCollateralModal}
                              className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-6"
                              disabled={
                                loansById?.productStatus?.name ===
                                  "APPLICATION_COMPLETED" ||
                                loansById?.productStatus?.name ===
                                  "APPLICATION_NOT_COMPLETED" ||
                                loansById?.productStatus?.name ===
                                  "FINAL_OFFER_RECEIVED"
                              }
                            >
                              {t("add-more-collaterals")}
                            </button>
                          </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 divide-y">
                      {loansById?.car?.map((car: any, index: number) => (
                        <div
                          key={car?.id}
                          className="flex flex-col md:flex-row gap-2 py-3 px-3"
                        >
                          <div className="w-[97px] h-[64px] rounded-[7px] overflow-hidden">
                            <img
                              src={
                                car?.carDetails?.imageUrl ||
                                "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                              }
                              alt="Car"
                              width="97px"
                            />
                          </div>
                          <div className="flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <div className="flex flex-row items-start md:items-end gap-1 md:gap-0 justify-start md:justify-end">
                                {car?.inspectionStatus && (
                                  <Tag
                                    color={getInspectionStatusColor(car)}
                                    className="capitalizes text-[11px] font-bold"
                                  >
                                    {t2(
                                      `Inspection ${car?.inspectionStatus?.replaceAll(
                                        "-",
                                        ""
                                      )}`
                                    )}
                                  </Tag>
                                )}
                                {car?.inspectionStatus === "cancelled" ? (
                                  <></>
                                ) : (
                                  <Tag
                                    color={
                                      car?.isDocComplete === true
                                        ? "green"
                                        : "orange"
                                    }
                                    className="text-[11px] font-bold"
                                  >
                                    {getDocumentStatus(car)}
                                  </Tag>
                                )}
                              </div>
                              <div className="text-base text-[#30345E] font-bold">
                                {car?.carDetails?.model?.make?.name
                                  ? `${car?.carDetails?.year} ${car?.carDetails?.model?.make?.name} ${car?.carDetails?.model?.name}`
                                  : "---"}
                              </div>
                              <div className="flex justify-between items-center w-full">
                                <div>
                                  <div className="text-xs text-[#6B7280]">
                                    {t2("value")}
                                  </div>
                                  <div className="text-sm text-[#30345E] font-bold">
                                    {formatInt(car?.price, true)}
                                  </div>
                                </div>
                                <div>
                                  <div className="text-xs text-[#6B7280]">
                                    {t2("rating")}
                                  </div>
                                  <div className="text-sm text-[#30345E] font-bold">
                                    {car?.carDetails?.gradeScore
                                      ? car?.carDetails?.gradeScore?.toFixed(1)
                                      : "---"}
                                  </div>
                                </div>
                              </div>
                              {car?.inspectionStatus === "completed" &&
                                !car?.isDocComplete && (
                                  <div className="flex justify-end">
                                    <button
                                      onClick={() =>
                                        showMoreDocs(
                                          "CAR_DOCUMENTS" as IDocType,
                                          car?.vin
                                        )
                                      }
                                      disabled={!!isAssumedUser}
                                      className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                                    >
                                      {t2("upload-car-docs")}
                                    </button>
                                  </div>
                                )}
                            </div>
                            {(user.partner.authority.roles.includes(
                              "ACCOUNT_MANAGER"
                            ) ||
                              user.partner.authority.roles.includes(
                                "DEALER_FINANCE_MANAGER"
                              )) &&
                              containsDealerFinanceCountry && (
                                <div className="flex">
                                  <button
                                    onClick={() => removeCollateral(car)}
                                    className="bg-[#ff5a19] text-xs text-[#fff] font-semibold rounded-md px-3 w-fit h-6 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-2 mr-2"
                                    disabled={
                                      loansById?.productStatus?.name ===
                                        "APPLICATION_COMPLETED" ||
                                      loansById?.productStatus?.name ===
                                        "APPLICATION_NOT_COMPLETED" ||
                                      loansById?.productStatus?.name ===
                                        "FINAL_OFFER_RECEIVED" ||
                                      loansById?.productStatus?.name ===
                                        "PENDING_DISBURSEMENT" ||
                                      loansById?.productStatus?.name ===
                                        "SIGNED_LOAN_DOCUMENT_SUBMITTED" ||
                                      loansById?.productStatus?.name ===
                                        "SIGNED_LOAN_DOCUMENTS_SUBMITTED" ||
                                      loansById?.productStatus?.name ===
                                        "DISBURSED"
                                    }
                                  >
                                    {t("remove-collateral")}
                                  </button>
                                  <button
                                    onClick={() =>
                                      openReplaceCollateralModal(car)
                                    }
                                    className="bg-[#19a3ff] text-xs text-[#fff] font-semibold rounded-md px-3 w-fit h-6 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-2"
                                    disabled={
                                      loansById?.productStatus?.name ===
                                        "APPLICATION_NOT_COMPLETED" ||
                                      loansById?.productStatus?.name ===
                                        "FINAL_OFFER_RECEIVED" ||
                                      loansById?.productStatus?.name ===
                                        "PENDING_DISBURSEMENT" ||
                                      loansById?.productStatus?.name ===
                                        "SIGNED_LOAN_DOCUMENT_SUBMITTED" ||
                                      loansById?.productStatus?.name ===
                                        "SIGNED_LOAN_DOCUMENTS_SUBMITTED" ||
                                      loansById?.productStatus?.name ===
                                        "DISBURSED"
                                    }
                                  >
                                    {t("replace-collateral")}
                                  </button>
                                </div>
                              )}
                            {(user.partner.authority.roles.includes(
                              "ACCOUNT_MANAGER"
                            ) ||
                              user.partner.authority.roles.includes(
                                "DEALER_FINANCE_MANAGER"
                              )) &&
                              country === "NG" && (
                                <div>
                                  <button
                                    onClick={() => openCustomDocumentModal(car)}
                                    className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-6 border-1 border-black disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-2"
                                    disabled={
                                      loansById?.productStatus?.name ===
                                        "APPLICATION_NOT_COMPLETED" ||
                                      loansById?.productStatus?.name ===
                                        "FINAL_OFFER_RECEIVED" ||
                                      loansById?.productStatus?.name ===
                                        "PENDING_DISBURSEMENT" ||
                                      loansById?.productStatus?.name ===
                                        "SIGNED_LOAN_DOCUMENT_SUBMITTED" ||
                                      loansById?.productStatus?.name ===
                                        "SIGNED_LOAN_DOCUMENTS_SUBMITTED" ||
                                      loansById?.productStatus?.name ===
                                        "DISBURSED" ||
                                      !car?.carId
                                    }
                                  >
                                    {t("custom-document")}
                                  </button>
                                </div>
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <GeneralEmptyState
                    style={{
                      minHeight: 300,
                      background: "#fff",
                      border: "1px solid #e5e7eb",
                      flexDirection: "column",
                    }}
                  >
                    <div className="content">
                      <div>
                        <img
                          src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                          alt="caution"
                        />
                      </div>
                      <div>
                        <div className="sub-heading">
                          {t2("you-dont-have-a-collateral-yet")}
                        </div>
                      </div>
                    </div>
                    {(user.partner.authority.roles.includes(
                      "ACCOUNT_MANAGER"
                    ) ||
                      user.partner.authority.roles.includes(
                        "DEALER_FINANCE_MANAGER"
                      )) &&
                      containsDealerFinanceCountry && (
                        <button
                          onClick={openAddCollateralModal}
                          className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-6"
                          disabled={
                            loansById?.productStatus?.name ===
                              "APPLICATION_NOT_COMPLETED" ||
                            loansById?.productStatus?.name ===
                              "FINAL_OFFER_RECEIVED" ||
                            loansById?.productStatus?.name ===
                              "PENDING_DISBURSEMENT" ||
                            loansById?.productStatus?.name ===
                              "SIGNED_LOAN_DOCUMENT_SUBMITTED" ||
                            loansById?.productStatus?.name ===
                              "SIGNED_LOAN_DOCUMENTS_SUBMITTED" ||
                            loansById?.productStatus?.name === "DISBURSED"
                          }
                        >
                          {t("add-collateral")}
                        </button>
                      )}
                  </GeneralEmptyState>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingY: "30px",
                  }}
                >
                  <Modal
                    centered
                    open={modalOpen}
                    footer={null}
                    width={
                      collateralOption === "REMOVE COLLATERAL" ? "30%" : "60%"
                    }
                  >
                    {collateralOption === "REMOVE COLLATERAL" ? (
                      <ModalContent>
                        <Title>
                          {t("are-you-sure-you-want-to-delete")}{" "}
                          {selectedCollateral?.carDetails?.model?.make?.name}{" "}
                          {selectedCollateral?.carDetails?.model?.name}?
                        </Title>
                        {removeCollateralLoading ? (
                          <div className="mt-6">
                            <Loader variant={"secondary"} />
                          </div>
                        ) : (
                          <div className="flex">
                            <button
                              onClick={handleRemoveCollateral}
                              className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-6 mr-4"
                            >
                              {t("yes")}
                            </button>
                            <button
                              onClick={() => setModalOpen(false)}
                              className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-6"
                            >
                              {t("cancel")}
                            </button>
                          </div>
                        )}
                      </ModalContent>
                    ) : (
                      <ModalContent>
                        {collateralOption === "REPLACE COLLATERAL" ? (
                          <Title>
                            {t("proceed-to-replace")}{" "}
                            {selectedCollateral?.carDetails?.model?.make?.name}{" "}
                            {selectedCollateral?.carDetails?.model?.name}?
                          </Title>
                        ) : collateralOption === "CUSTOM DOCUMENT" ? (
                          <Title>{t("custom-document")}</Title>
                        ) : (
                          <div>
                            <button
                              onClick={() => {
                                setShowCarFields(!showCarFields);
                              }}
                              className="font-bold text-[#7F83A8] text-base cursor-pointer border border-solid border-[#7F83A8] px-3 py-1.5 rounded-md"
                            >
                              {t("add-new-car")}
                            </button>
                          </div>
                        )}

                        {collateralOption === "CUSTOM DOCUMENT" ? (
                          <div className="w-full max-w-full lg:max-w-[50%] bg-white px-4 h-auto my-0 mx-auto rounded-lg">
                            <CustomsDocumentForm
                              selectedCountry={country}
                              selectedCarId={selectedCollateral?.carId}
                            />
                            <div className="flex justify-center">
                              <button
                                onClick={() => setModalOpen(false)}
                                className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-6"
                              >
                                {t("cancel")}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full">
                            <div className="mb-4 w-full">
                              <InputGroup
                                label={
                                  productData?.config?.collateral[0]?.label
                                }
                                fields={
                                  productData?.config?.collateral[0]?.fields
                                }
                                formData={loanCollateral}
                                handleInputChange={(e: any) =>
                                  handleCollateralInputOnChange(
                                    e,
                                    setLoanCollateral,
                                    selectedCarValue,
                                    setSelectedCarValue
                                  )
                                }
                                selectOptions={{
                                  ...selectOptions,
                                  dealerCar: franchiseCar,
                                }}
                                addDirector={null}
                                directorCount={null}
                              />
                            </div>
                            <div className="mb-4 w-full">
                              <CollateralCarCardContainer
                                showPrefilledCars={showPrefilledCars}
                                setShowPrefilledCars={setShowPrefilledCars}
                                selectedCarValue={selectedCarValue}
                                setSelectedCarValue={setSelectedCarValue}
                                setPrefilledCarsNotInventory={() => {}}
                                setPrefilledCarFields={() => {}}
                                allFranchiseCar={allFranchiseCar}
                                carFieldsArr={carFieldsArr}
                                setCarFieldsArr={setCarFieldsArr}
                              />
                            </div>
                            <div className="grid grid-col-1 md:grid-cols-2 gap-4 mt-8">
                              {showCarFields && (
                                <Fragment>
                                  {productCarField?.map(
                                    (item: any, index: any) =>
                                      item.name &&
                                      item.visibility &&
                                      !item.name.startsWith(
                                        "vehicleModelId"
                                      ) && (
                                        <InputControl
                                          key={item.name}
                                          control={control}
                                          name={item.name}
                                        >
                                          {() =>
                                            item?.visibility && (
                                              <Input
                                                formData={loanCarField}
                                                key={item.name}
                                                required={item.required}
                                                type={
                                                  item.type === "fileSelection"
                                                    ? "selection"
                                                    : item.type
                                                }
                                                label={item.label}
                                                placeholder={item.label}
                                                fileType={item.fileType}
                                                name={item.name}
                                                when={item.when}
                                                options={handleRenderOptions(
                                                  item
                                                )}
                                                onChange={(e: any) =>
                                                  handleCarFieldsChange(
                                                    e,
                                                    item,
                                                    item.type
                                                  )
                                                }
                                                value={
                                                  loanCarField[item.name]?.value
                                                }
                                                selectOptions={
                                                  item.options?.length
                                                    ? item.options
                                                    : []
                                                }
                                                visibility={true}
                                                innerVisibility={true}
                                                fullWidth={item.fullWidth}
                                                autoComplete={true}
                                                subsection={null}
                                                hideSubsection
                                                addDirector={null}
                                                directorCount={null}
                                              />
                                            )
                                          }
                                        </InputControl>
                                      )
                                  )}
                                </Fragment>
                              )}
                            </div>

                            {showCarFields && (
                              <div className="flex gap-4">
                                <LoadingButton
                                  variant="contained"
                                  color="primary"
                                  style={{ marginTop: "36px" }}
                                  sx={{
                                    width: "151px",
                                    height: "43px",
                                  }}
                                  onClick={() => {
                                    handleSubmitCarFields();
                                  }}
                                >
                                  {t1("Add Car")}
                                </LoadingButton>
                                <LoadingButton
                                  variant="contained"
                                  color="primary"
                                  style={{ marginTop: "36px" }}
                                  sx={{
                                    width: "151px",
                                    height: "43px",
                                  }}
                                  onClick={handleCloseCarFields}
                                >
                                  {t1("Close")}
                                </LoadingButton>
                              </div>
                            )}

                            {addCollateralLoading ? (
                              <Loader variant={"secondary"} />
                            ) : (
                              <div className="flex">
                                <button
                                  onClick={submit}
                                  className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed mt-6 mr-4"
                                >
                                  {t("add-collateral")}
                                </button>
                                <button
                                  onClick={() => setModalOpen(false)}
                                  className="bg-[#fff] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid border-2 border-[#30345e] disabled:border-black/25 disabled:cursor-not-allowed mt-6"
                                >
                                  {t("cancel")}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </ModalContent>
                    )}
                  </Modal>
                </Box>
              </div>
            )}
          </div>
        </DealerAppTabContainer>
      </div>

      <Stack alignItems="center">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={!!alert}
          autoHideDuration={5000}
          onClose={() => setAlert("")}
        >
          <Alert severity="error" variant="filled">
            {alert}
          </Alert>
        </Snackbar>
      </Stack>

      <AdditionalDealerDocModal
        handleOk={handleUploadMoreDocs}
        handleCancel={handleCancelUploadDoc}
        isModalOpen={uploadMoreDocs}
        id={id}
        type={carDocType}
        vin={carVin}
        dealerId={loansById?.customer?.dealerId}
      />
    </Main>
  );
};

export default DealDetails;
