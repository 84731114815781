import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

const useDebouncedPersist = (
  methods: UseFormReturn<any>,
  persistFunction: (data: any) => void,
  delay: number = 10000
) => {
  const debouncedPersist = useDebouncedCallback((data) => {
    persistFunction(data);
  }, delay);

  useEffect(() => {
    const subscription = methods.watch((value) => {
      debouncedPersist(value);
    });
    return () => subscription.unsubscribe();
  }, [methods, debouncedPersist]);
};

export default useDebouncedPersist;
