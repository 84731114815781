import React, { useState, useEffect } from "react";
import {
  LoanDetailsHeader,
  TableContainer,
} from "@/layouts/application-layouts/styles";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import { device } from "@/utils/device";
import ListCarModal from "@/layouts/application-layouts/ListCarModal";
import DelistCarModal from "@/layouts/application-layouts/DelistCarModal";
import EditInventory from "@/layouts/application-layouts/EditInventory";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Loader } from "@/pages/auth/styles/loader";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .preview-container {
    display: none;
    @media ${device.mobileL} {
      display: none;
    }
  }
`;

const EditInventoryDetails = () => {
  const { t } = useTranslation("inventory");
  const { carId }: any = useParams();
  const navigate = useNavigate();
  const role = storage.getRole();
  const [carById, setCarById]: any = useState({});
  const [carMedia, setCarMedia]: any = useState([]);
  const [carFeature, setCarFeature]: any = useState([]);
  const [listCar, setListCar] = useState(false);
  const [delistCar, setDelistCar] = useState(false);
  const [carData] = useState("");

  useEffect(() => {
    document.title = `Dealer Plus | Edit Inventory [${role}]`;
  }, [role]);

  const handleOpenListCar = () => {
    setListCar(false);
  };

  const handleCancelListCar = () => {
    setListCar(false);
  };

  const handleOpenDelistCar = () => {
    setDelistCar(false);
  };

  const handleCancelDelistCar = () => {
    setDelistCar(false);
  };

  const { isLoading: carAdminLoading, refetch: refetchCar } = useQuery({
    queryKey: ["fetch_admin_cars_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inventory/admin/car/${carId}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarById(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carMediaLoading } = useQuery({
    queryKey: ["car_media_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        page_number: "1",
        page_size: "20",
      });
      return client.get(`/v1/inventory/car_media`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarMedia(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carFeaturesLoading } = useQuery({
    queryKey: ["car_feature_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        page_number: "1",
        page_size: "500",
      });
      return client.get(`/v1/inventory/car_feature`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const selectedFeatures = parsedResults?.carFeatureList?.map(
        (feature: Record<string, any>) => feature.feature.id
      );
      setCarFeature(selectedFeatures);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <>
      {carAdminLoading || carMediaLoading || carFeaturesLoading ? (
        <TableContainer>
          <div className="filter-mobile-loader-wrapper">
            <Loader variant={"secondary"} />
          </div>
        </TableContainer>
      ) : (
        <Main>
          <LoanDetailsHeader onClick={() => navigate(-1)}>
            <div className="loan-header">
              <img
                src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
                alt="arrow-back"
              />{" "}
              <div className="name">
                {" "}
                {carById?.year} {carById?.model?.make?.name}{" "}
                {carById?.model?.name}
              </div>
            </div>
          </LoanDetailsHeader>
          <LoanDetailsHeader>
            <div className="loan-header">
              <div style={{ fontSize: 16 }} className="name">
                {t("date-created")}:{" "}
                {moment(carById?.createdAt).format("DD/MM/YYYY, h:mm a")}
              </div>
            </div>
          </LoanDetailsHeader>
          <EditInventory
            refetchCar={refetchCar}
            car={carById}
            features={carFeature}
            media={carMedia}
          />
        </Main>
      )}

      <ListCarModal
        isModalOpen={listCar}
        handleOk={handleOpenListCar}
        handleCancel={handleCancelListCar}
        carId={carData}
      />
      <DelistCarModal
        isModalOpen={delistCar}
        handleOk={handleOpenDelistCar}
        handleCancel={handleCancelDelistCar}
        carId={carData}
      />
    </>
  );
};

export default EditInventoryDetails;
