export enum StorageKeys {
  TOKEN = "token",
  PROXY_USER_TOKEN = "proxyUserToken",
  USER = "user",
  ROLES = "roles",
  ROLE = "role",
  PERMISSIONS = "permissions",
  COUNTRY = "country",
  FRANCHISES = "franchises",
  i18nextLng = "i18nextLng",
  IS_ASSUMED_USER = "isAssumedUser",
}
