import { useState, useEffect } from "react";
import { Modal } from "antd";
import { createDocuments } from "@/hooks/use-loan";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Alert, Box, Snackbar, Stack } from "@mui/material";
import InputControl from "@/components/formElements/input-control";
import NewUploadButton from "@/components/formElements/new-upload-button";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { Input } from "@/components/formElements/input-group";
import Grid from "@mui/material/Grid";
import styled from "styled-components";

interface IUploadDocs {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  id: string;
  documentData: any;
  loanRequiredDocument: any;
}

interface TField {
  options: [];
  fields: [];
  actions: [];
  when: [];
  label: string;
  name: string;
  type: string;
  required: boolean;
  visibility: boolean;
  initialLabel: string;
}

const SubmitButton = styled.div`
  .MuiButtonBase-root {
    background: #ffb619;
    color: #30345e;
    /* border-radius: 4rem; */
    font-weight: 600;

    &:disabled {
      background: #d6dbe2;
    }

    &:hover {
      background: #d89300;
    }
  }
`;

export default function NewDocsUploadModal({
  isModalOpen,
  handleOk,
  handleCancel,
  id,
  documentData,
  loanRequiredDocument,
}: Readonly<IUploadDocs>) {
  const queryClient = useQueryClient();
  const { t: t1 } = useTranslation("loan-form");
  const [formData, setFormData] = useState<any>({});
  const methods = useForm();
  const [loanDocumentFields, setLoanDocumentFields]: any = useState();
  const [rerender, setRerender] = useState(false);
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = methods;

  useEffect(() => {
    setLoanDocumentFields(loanRequiredDocument);
  }, [loanRequiredDocument]);

  const filterDocumentByName = (group: string) => {
    const documents = loanRequiredDocument.filter(
      (item: any) => item?.group === group
    );
    return documents;
  };

  const filteredDocuments = filterDocumentByName(documentData?.group);

  const createNewDocObject = ({
    label,
    name,
    type,
    initialType,
    removeable,
    initialLabel,
    required,
    ...props
  }: {
    label: string;
    name: string;
    type: string;
    initialType: string;
    removeable?: boolean;
    initialLabel: string;
    required?: boolean;
  }) => {
    return {
      label: label,
      name: name,
      type: type,
      initialType: initialType,
      initialLabel: initialLabel,
      removeable: removeable,
      required: required,
      ...props,
    };
  };

  const removeField = (index: number, initialType: string, item: TField) => {
    const allFields = loanDocumentFields;
    const field1 = {
      ...loanDocumentFields[index],
      visibility: !!item.required,
      type: initialType ?? item.type,
      label: item.initialLabel ?? item.label,
    };
    const field2 = {
      ...loanDocumentFields[index + 1],
      visibility: !!item.required,
    };

    allFields[index] = field1;
    allFields[index + 1] = field2;
    const checkIfpayslipFieldIsPresent = allFields.find((field: TField) =>
      field.name.startsWith("proofOfEmploymentPayslip")
    );
    if (item.label.includes("Payslip 1")) {
      checkIfpayslipFieldIsPresent && allFields.splice(index + 1, 2);

      formData[item.name].value.includes("payslip") &&
        (formData[item.name].value = "");
    }
    if (item.name.startsWith("proofOfEmploymentPayslip")) {
      allFields.splice(index, 1);
    }
    setLoanDocumentFields(allFields);
    setRerender(!rerender);
  };

  const validateDocuments = (documents: any[]): boolean => {
    return documents.some((doc) => doc.file_url);
  };

  const handleInputChange = async (
    e: any,
    item?: TField,
    when?: any,
    initialType?: string
  ) => {
    const { name, value, type } = e.target;
    const valueObject = {
      name,
      value: value,
      type: initialType ?? type,
      label: item?.label,
      valid: true,
    };

    const newDocField = createNewDocObject({
      ...item,
      label: `Upload ${value.includes("payslip") ? "Payslip 1" : value}`,
      name: name,
      type: "file",
      initialType: item?.type!,
      initialLabel: item?.label!,
      removeable: true,
      required: item?.required,
    });
    const fieldToBeReplaced = loanDocumentFields.find(
      (field: TField) => field.name === name
    );

    const fieldIndex = loanDocumentFields.indexOf(fieldToBeReplaced);

    const allFields = loanDocumentFields;

    if (name.startsWith("proofOfEmployment") && value.includes("payslip")) {
      allFields[fieldIndex] = newDocField;
      const subsetFields = when[0].fields.map((field: TField) => {
        return {
          ...field,
          required: !!newDocField.required,
          removeable:
            name === "proofOfEmployment" ? false : newDocField.removeable,
        };
      });

      allFields.splice(fieldIndex + 1, 0, ...subsetFields);
      setLoanDocumentFields(allFields);
    }
    if (!name.includes("Password")) {
      if (initialType?.startsWith("file")) {
        allFields[fieldIndex] = newDocField;
        setLoanDocumentFields(allFields);
      }
    }

    setFormData((state: {}) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  const filterValues = (values: any): any => {
    const filteredValues = { ...values };
    for (const item of Object.keys(values)) {
      if (item.includes("Password")) {
        delete filteredValues[item];
      }
      if (values[item] === undefined) {
        delete filteredValues[item];
      }
    }
    return filteredValues;
  };

  const filterFormData = (formData: any): any[] => {
    const dataField = [];
    for (const item of Object.keys(formData)) {
      if (
        !formData[item]?.name.includes("Password") &&
        !formData[item]?.type.includes("file") &&
        formData[item] !== undefined
      ) {
        dataField.push(
          item === "state"
            ? {
                ...formData[item],
                value: formData[item]?.value,
              }
            : formData[item]
        );
      }
    }
    return dataField;
  };

  const mapDocuments = (values: any, formData: any): any[] => {
    return Object.entries(values).map(([key, value]: any) => ({
      name: key,
      file_url: value?.data,
      description: formData[key]?.value || key,
      password:
        formData[`${key}${key.includes("File") ? "" : "File"}Password`]
          ?.value ?? "",
    }));
  };

  const submit = async (values: any) => {
    let __values = values;
    const dataField = filterFormData(formData);
    __values = filterValues(__values);

    const documents = mapDocuments(__values, formData);

    if (!validateDocuments(documents)) {
      setAlert("Please upload document before submitting");
      return;
    }

    try {
      setLoading(true);
      await createDocuments({
        loan_id: id,
        documents,
        data: dataField,
      });
      queryClient.invalidateQueries(["fetch_uploaded_documents"]);
      queryClient.invalidateQueries(["fetch_in_app_notifications"]);
      setLoading(false);
      handleCancel();
    } catch (error: any) {
      setLoading(false);
      setAlert(JSON.parse(error?.response?.data)?.message);
    }
  };

  const inputOptionCheck = (item: any) => {
    if (item.options?.length) {
      return item.options;
    } else {
      return [];
    }
  };

  return (
    <div>
      <Modal
        title={documentData?.label}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        width={500}
        wrapClassName="upload-document-modal-container"
        zIndex={1007}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={!!alert}
          autoHideDuration={5000}
          onClose={() => setAlert("")}
        >
          <Alert severity="error" variant="filled">
            {alert}
          </Alert>
        </Snackbar>

        <form className="p-4">
          {filteredDocuments?.map((item: any, index: any) =>
            item.channel && item.channel !== "dealerplus" ? (
              <></>
            ) : (
              <InputControl
                key={item.fields.indexOf(item)}
                control={control}
                name={item.name}
              >
                {({ value, onChange, onBlur }) =>
                  item.visibility && item.type === "file" ? (
                    <NewUploadButton
                      fileType={item.fileType}
                      label={item.label}
                      value={value}
                      onChange={onChange}
                      fieldName={item.name}
                      name={item.name}
                      channel={item.channel}
                      removeable={item.removeable ?? false}
                      onRemoveField={() =>
                        removeField(index, item.initialType, item)
                      }
                    />
                  ) : (
                    item.visibility && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          sx={{
                            fontWeight: 600,
                            backgroundColor: "#F8FAFC",
                            width: { md: "100%", xs: "100%" },
                            paddingY: "15px",
                            paddingX: "15px",
                            marginY: "9px",
                            cursor: "pointer",
                            display: "block",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          component="label"
                        >
                          <Input
                            formData={formData}
                            key={item.fields.indexOf(item)}
                            onBlur={onBlur}
                            required={item.required}
                            type={
                              item.type === "fileSelection"
                                ? "selection"
                                : item.type
                            }
                            label={item.label}
                            placeholder={item.label}
                            fileType={item.fileType}
                            name={item.name}
                            when={item.when}
                            options={inputOptionCheck(item)}
                            onChange={(e: any) => {
                              handleInputChange(e, item, item.when, item.type);
                            }}
                            // value={item.value}
                            value={formData[item.name]?.value}
                            selectOptions={
                              item.options?.length ? item.options : []
                            }
                            visibility={true}
                            innerVisibility={true}
                            fullWidth={item.fullWidth}
                            autoComplete={false}
                            subsection={null}
                            hideSubsection
                            addDirector={undefined}
                            directorCount={undefined}
                          />
                        </Box>
                      </Stack>
                    )
                  )
                }
              </InputControl>
            )
          )}

          <Grid container spacing={2} marginTop="16px">
            <Grid item xs={12} sm={12} md={4}>
              <SubmitButton>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ width: "151px", height: "43px" }}
                  loading={loading}
                  onClick={handleSubmit(submit)}
                >
                  {t1("Submit")}
                </LoadingButton>
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </div>
  );
}
