import { Dependency } from "@/components/formElements/mui-form/types";
import { useCreateCarForm, useFetchers } from "@/hooks/use-fetchers";
import useDebouncedPersist from "@/hooks/use-persist";
import {
  createCarDefaultValues,
  createCarFormSchema,
  CreateCarFormValues,
} from "@/validations/create-car.schema";
import { compose, evaluate, prepare } from "@crudmates/form-config";
import { zodResolver } from "@hookform/resolvers/zod";
import storage from "@l/storage";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { Loader } from "../../auth/styles/loader";
import CarDetailsSection from "./car-details";
import CarDocumentsSection from "./car-documents";
import CarFeatureSection from "./car-features";
import CarSnapshotsSection from "./car-snapshots";
import CarSummarySection from "./car-summary";
import { hydrateCarForm, persistCarForm } from "./helper";
import ListingScoreProgress from "./listing-score-progress";

const CreateCarForm = () => {
  const country = storage.getCountry();
  const user = storage.getUser();
  const isFranchise = storage.utilities.isFranchise();

  const {
    data: { form_id, ...config } = {},
    isFetching,
    isLoading,
  } = useCreateCarForm();

  const methods = useForm({
    mode: "all",
    defaultValues: createCarDefaultValues,
    resolver: zodResolver(createCarFormSchema),
  });

  useEffect(() => {
    if (!methods.getValues("makeId")) {
      hydrateCarForm(methods);
    }
  }, [methods]);

  useMemo(() => {
    user?.company?.id && methods.setValue("franchiseId", user?.company?.id);
    methods.setValue("isDealer", String(isFranchise));
    methods.setValue("country", storage.getCountry());
    methods.setValue("formId", form_id);
    methods.setValue("createdBy", user?.id);
  }, [form_id, isFranchise, methods, user?.company?.id, user.id]);

  const evaluateForm = useCallback(() => {
    if (config) {
      const prepared = prepare(methods.watch(), config);
      const composed = compose(config, prepared.sections);
      const evaluated = evaluate(composed, 5);
      return (evaluated?.ratio ?? 0).toFixed(2);
    }
    return "0.00";
  }, [config, methods]);

  const watched = methods.watch([
    "isDealer",
    "makeId",
    "stateId",
    "registrationStatus",
    "isUpgraded",
    "franchiseId",
    "searchFeatures",
    "vin",
    "vinLookup",
    "modelId",
    "searchFranchise",
    "isModelDecoded",
  ]);

  const [debouncedFeatureLookup] = useDebounce(watched[6], 400);
  const [debouncedVinLookup] = useDebounce(watched[7], 400);
  const [debouncedFranchiseLookup] = useDebounce(watched[10], 2000);

  useMemo(() => {
    methods.setValue("vinLookup", debouncedVinLookup);
  }, [debouncedVinLookup, methods]);

  const dependencies: Dependency = {
    country,
    isDealer: watched[0],
    makeId: watched[1],
    stateId: watched[2],
    registrationStatus: watched[3],
    isUpgraded: watched[4],
    franchiseId: watched[5],
    featureLookup: debouncedFeatureLookup,
    vinLookup: watched[8],
    vin: watched[7],
    modelId: watched[9],
    franchiseLookup: debouncedFranchiseLookup,
    modelDecodeLookup: watched[11],
  };

  const fetchedData = useFetchers(dependencies);

  useMemo(() => {
    for (const _key in fetchedData.vinData) {
      const key = _key as keyof CreateCarFormValues;
      const value = fetchedData.vinData[key];
      if (value !== undefined && value !== null && value !== "") {
        methods.setValue(key, value);
      }
    }
  }, [fetchedData.vinData, methods]);

  useMemo(() => {
    if (fetchedData?.franchise?.ownerInfo?.id) {
      methods.setValue("ownerId", fetchedData.franchise.ownerInfo.id);
    }
  }, [fetchedData?.franchise?.ownerInfo?.id, methods]);

  useMemo(() => {
    if (fetchedData?.cityData) {
      methods.setValue("city", fetchedData.cityData.cityName);
      methods.setValue("cityId", fetchedData.cityData.cityId);
      methods.setValue("state", fetchedData.cityData.stateName);
      methods.setValue("stateId", fetchedData.cityData.stateId);
    }
  }, [fetchedData.cityData, methods]);

  const decodeModels = fetchedData?.models?.find(
    (item: Record<string, any>) =>
      item?.id === methods.getValues("modelId") && item?.decoded
  );

  useMemo(() => {
    const parsedData = JSON.parse(decodeModels?.decoded || "{}");
    for (const _key in parsedData) {
      const key = _key as keyof CreateCarFormValues;
      methods.setValue(key, parsedData[key]);
    }
  }, [decodeModels?.decoded, methods]);

  useDebouncedPersist(methods, persistCarForm, 5000);

  if (isFetching || isLoading) {
    return (
      <div className="w-full max-w-full min-h-screen h-auto bg-white flex items-center justify-center">
        <Loader variant="secondary" />
      </div>
    );
  }

  const props = {
    config,
    data: fetchedData,
    methods,
    dependencies,
  };

  return (
    <main>
      <CarDetailsSection {...props} />
      <CarSnapshotsSection {...props} />
      <CarDocumentsSection {...props} />
      <CarFeatureSection {...props} />
      <CarSummarySection {...props} />

      <ListingScoreProgress
        isFranchise={isFranchise}
        methods={methods}
        config={config}
        evaluateForm={evaluateForm}
      />
    </main>
  );
};

export default CreateCarForm;
