import { Form, Input } from "antd";
import type { FormProps } from "antd";
import { SettingFormContainer } from "@/layouts/application-layouts/styles";
import { TFranchiseDataType } from "@/pages/settings";
import { useEffect } from "react";
import { getCountryFlag } from "@/utils/getCountryFlag";
import { getCountryTelCode } from "@/utils/getCountryTelCode";
import storage from "@/lib/storage";
import {
  getCountryCustomerLine,
  getCountryTelCodeLength,
} from "@/utils/getCountryTelCodeLength";

type FieldType = {
  accountManagerName?: string;
  email?: string;
  phoneNumber?: string;
  customerCareNumber?: string;
};

export default function AccountManagerForm({
  franchiseData,
}: Readonly<{
  franchiseData: TFranchiseDataType;
}>) {
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {};

  const [form] = Form.useForm();
  const country = storage.getCountry();

  useEffect(() => {
    form.setFieldsValue({
      accountManagerName: franchiseData?.accountManager?.firstname
        ? `${franchiseData?.accountManager?.firstname} ${franchiseData?.accountManager?.lastname}`
        : "",
      email: franchiseData?.accountManager?.email,
      customerCareNumber: getCountryCustomerLine(country),
      phoneNumber: franchiseData?.accountManager?.primaryPhone?.slice(3),
    });
  }, [country, form, franchiseData]);

  return (
    <div className="flex flex-col gap-6">
      <div className="w-20 h-20 overflow-hidden rounded-full my-0 mx-auto md:mx-0 bg-blue-500 text-[30px] font-black items-center flex justify-center text-white">
        {franchiseData?.accountManager?.firstname
          ? `${franchiseData?.accountManager?.firstname.charAt(
              0
            )}${franchiseData?.accountManager?.lastname.charAt(0)}`
          : "--"}
      </div>
      <SettingFormContainer>
        <Form
          name="myAccountManager"
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Form.Item<FieldType>
            label="Account manager’s name"
            name="accountManagerName"
            rules={[
              { required: true, message: "Please enter account manager name" },
            ]}
          >
            <Input readOnly className="cursor-not-allowed custom-ant-input" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input readOnly className="cursor-not-allowed custom-ant-input" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input
              addonBefore={
                <img
                  src={getCountryFlag(country)}
                  alt="flag"
                  style={{ width: "32px", height: "32px" }}
                />
              }
              disabled
              prefix={getCountryTelCode(country)}
              maxLength={getCountryTelCodeLength(country)}
            />
          </Form.Item>
          <Form.Item
            name="customerCareNumber"
            label="Customer care line"
            rules={[
              {
                required: true,
                message: "Please input your customer care number",
              },
            ]}
          >
            <Input
              addonBefore={
                <img
                  src={getCountryFlag(country)}
                  alt="flag"
                  style={{ width: "32px", height: "32px" }}
                />
              }
              disabled
              prefix={getCountryTelCode(country)}
              maxLength={getCountryTelCodeLength(country)}
            />
          </Form.Item>
        </Form>
      </SettingFormContainer>
    </div>
  );
}
