import { useState } from "react";
import { Row, Col, Collapse, message } from "antd";
import { useNavigate } from "react-router-dom";
import { LoanInformation } from "@/layouts/application-layouts/EmailCustomer";
import {
  AdditionalFeeContent,
  BreakdownContainer,
  CollapseContainer,
  LoanBreakdownContent,
} from "@/layouts/application-layouts/styles";
import { isMobile } from "react-device-detect";
import formatInt from "@/utils/format-int";
import { useTranslation } from "react-i18next";
import client from "@/lib/axios-client";
import tw from "tailwind-styled-components";
import { Alert, Snackbar } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import storage from "@l/storage";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

interface ILoanInfo {
  id: string;
  allSimulations: Record<string, any>;
  loanDetails: Record<string, any>;
  loansById: Record<string, any>;
  currentLoanId: string;
  currentLoanDetails: Record<string, any>;
  currentLoansById: Record<string, any>;
  index: number;
  simulatedOffers: Record<string, any>;
}

const SimulatedOffer = ({
  id,
  loanDetails,
  loansById,
  currentLoanId,
  currentLoanDetails,
  currentLoansById,
  index,
  simulatedOffers,
  allSimulations,
}: ILoanInfo) => {
  const { t } = useTranslation("application");
  const { Panel } = Collapse;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const queryParam = new URLSearchParams(window.location.search);
  const loanId: string = queryParam.get("loan_id") ?? "";
  const isAccountManager = storage.utilities.isAccountManager();
  const isFranchise = storage.utilities.isFranchise();
  const isDsaAgent = storage.utilities.isDsaAgent();
  const isDealerDsa = storage.utilities.isDealerDsa();

  const [alert, setAlert] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);

  const getOfferValue = (finalDecision: string, offerValue: any) => {
    if (finalDecision === "REFER") {
      return t("Referred");
    } else if (finalDecision === "DECLINE") {
      return t("Declined");
    } else {
      return formatInt(offerValue, true);
    }
  };

  const getOfferTerm = (finalDecision: string, offerValue: any) => {
    if (finalDecision === "REFER") {
      return t("Referred");
    } else if (finalDecision === "DECLINE") {
      return t("Declined");
    } else {
      return offerValue + " " + t("months");
    }
  };

  const customAdditionalPayments = [
    {
      name: "Annual maintenance cost",
      value: loanDetails?.annualMaintenanceContract,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data?.[index + 1]?.simulationResponse?.offer
          ?.annualMaintenanceContract
      ),
    },
    {
      name: "Vehicle registration",
      value: loanDetails?.vehicleRegistration,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.vehicleRegistration
      ),
    },
    {
      name: "Insurance fees",
      value: loanDetails?.insurance,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer?.insurance
      ),
    },
    {
      name: "Service charge",
      value: loanDetails?.serviceCharge,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.serviceCharge
      ),
    },
    {
      name: "spare Key",
      value: loanDetails?.spareKey,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer?.spareKey
      ),
    },
    {
      name: "Tracker",
      value: loanDetails?.trackerTelematics,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.trackerTelematics
      ),
    },
    {
      name: "Credit life insurance",
      value: loanDetails?.creditLifeInsurance,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.creditLifeInsurance
      ),
    },
    {
      name: "Logbook Transfer",
      value: "0",
      value2: "0",
    },
    {
      name: "Total upfront payment",
      value: loanDetails?.totalUpfrontPayment,
      value2: getOfferValue(
        simulatedOffers?.data?.[index + 1]?.simulationResponse
          ?.finalCreditDecision,
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.totalUpfrontPayment
      ),
    },
  ];

  const handleAcceptOffer = async () => {
    setLoading(true);
    setAlert(null);

    const payload = {};

    try {
      const response: any = await client.put(
        `/v2/origination/loans/offer-simulations/${id}/accept`,
        payload
      );
      if (response) {
        queryClient.invalidateQueries(["loans_by_id", "fetch_dig_offers"]);
        message.success("Offer accepted successfully");
        navigate(`/applications/loans/${loanId}`);
        setLoading(false);
      }
    } catch (error: any) {
      setAlert(JSON.parse(error?.response?.data)?.error);
      setLoading(false);
    }
  };

  const getLoanDetailsCommissionPerAccountType = () => {
    if (isFranchise) {
      return formatInt(loanDetails?.dealerIncentiveCommission, true);
    } else if (isAccountManager) {
      return formatInt(loanDetails?.accountManagerOffer, true);
    } else if (isDsaAgent) {
      return formatInt(loanDetails?.independentDsaOffer, true);
    } else if (isDealerDsa) {
      return formatInt(loanDetails?.dealerDsaOffer, true);
    } else {
      return `---`;
    }
  };

  const getCurrentLoanDetailsCommissionPerAccountType = () => {
    if (isFranchise) {
      return formatInt(currentLoanDetails?.dealerCommission, true);
    } else if (isAccountManager) {
      return formatInt(currentLoanDetails?.accountManagerCommission, true);
    } else if (isDsaAgent) {
      return formatInt(currentLoanDetails?.dsaCommission, true);
    } else if (isDealerDsa) {
      return formatInt(currentLoanDetails?.dealerDsaCommission, true);
    } else {
      return `---`;
    }
  };

  const getSimulatedOfferCommissionPerAccountType = () => {
    if (isFranchise) {
      return formatInt(
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.dealerIncentiveCommission,
        true
      );
    } else if (isAccountManager) {
      return formatInt(
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.accountManagerOffer,
        true
      );
    } else if (isDsaAgent) {
      return formatInt(
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.independentDsaOffer,
        true
      );
    } else if (isDealerDsa) {
      return formatInt(
        simulatedOffers?.data[index + 1]?.simulationResponse?.offer
          ?.dealerDsaOffer,
        true
      );
    } else {
      return `---`;
    }
  };

  const getNextOfferMessage = () => {
    const nextOffer = simulatedOffers?.data[index + 1];

    if (!nextOffer) {
      return; // Handle the case where there's no next offer
    }

    const decision = nextOffer.simulationResponse?.finalCreditDecision;

    let message;
    if (decision === "REFER") {
      message = t("Referred");
    } else if (decision === "DECLINE") {
      message = t("Declined");
    } else {
      message = getSimulatedOfferCommissionPerAccountType();
    }

    return message;
  };

  const getSimOfferEquity = () => {
    const nextOfferIndex = Math.min(
      index + 1,
      simulatedOffers?.data?.length - 1 || 0
    );
    const nextOfferData = simulatedOffers?.data?.[nextOfferIndex];

    if (nextOfferData) {
      const finalCreditDecision =
        nextOfferData?.simulationResponse?.finalCreditDecision;
      const equityContribution =
        nextOfferData?.simulationResponse?.offer?.equityContribution;
      return getOfferValue(finalCreditDecision, equityContribution);
    } else {
      return formatInt(currentLoanDetails?.equity, true);
    }
  };

  const getSimOfferPrincipalDebt = () => {
    const nextOfferIndex = Math.min(
      index + 1,
      simulatedOffers?.data?.length - 1 || 0
    );
    const nextOfferData = simulatedOffers?.data?.[nextOfferIndex];

    if (nextOfferData) {
      const finalCreditDecision =
        nextOfferData?.simulationResponse?.finalCreditDecision;
      const principalDebt =
        nextOfferData?.simulationResponse?.offer?.principalDebt;
      return getOfferValue(finalCreditDecision, principalDebt);
    } else {
      return formatInt(currentLoanDetails?.approvedAmount, true);
    }
  };

  const getSimOfferTenure = () => {
    const nextOfferIndex = Math.min(
      index + 1,
      simulatedOffers?.data?.length - 1 || 0
    );
    const nextOfferData = simulatedOffers?.data?.[nextOfferIndex];

    if (nextOfferData) {
      const finalCreditDecision =
        nextOfferData?.simulationResponse?.finalCreditDecision;
      const financeTermOffer =
        nextOfferData?.simulationResponse?.financeTermOffer;
      return getOfferTerm(finalCreditDecision, financeTermOffer);
    } else {
      const tenure = currentLoanDetails?.tenure || "---";
      return `${tenure} ${t("months")}`;
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <div className="flex justify-end gap-1">
        <div>
          <div className="flex items-center gap-1 w-[120px]">
            <div className="w-[13.71px] h-[13.71px] bg-[#202020] rounded-full"></div>
            <div className="text-sm font-medium">{t("simulated-offer")}</div>
          </div>
          <div className="flex items-center gap-1 w-[120px]">
            <div className="w-[13.71px] h-[13.71px] bg-blue-700 rounded-full"></div>
            <div className="text-sm font-medium">{t("current-offer")}</div>
          </div>
        </div>
      </div>

      <Row gutter={[18, 18]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {isMobile ? (
            <CollapseContainer>
              <Collapse defaultActiveKey={["0"]} expandIconPosition="end">
                <Panel header={t("loan-breakdown")} key="1">
                  <LoanInformation>
                    <ul>
                      <li>
                        <div className="name">{t("loan-amount")}</div>
                        <div>
                          <div className="value">
                            {formatInt(loanDetails?.principalDebt, true)}
                          </div>
                          <div className="text-[12.9522px] text-blue-700 font-bold">
                            {getSimOfferPrincipalDebt()}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="name">{t("Equity")}</div>
                        <div>
                          <div className="value">
                            {formatInt(loanDetails?.equityContribution, true)}
                          </div>
                          <div className="text-[12.9522px] text-blue-700 font-bold">
                            {getSimOfferEquity()}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="name">{t("monthly-repayment")}</div>
                        <div>
                          <div className="value">
                            {formatInt(
                              loanDetails?.totalMonthlyInstalment,
                              true
                            )}
                          </div>
                          <div className="text-[12.9522px] text-blue-700 font-bold">
                            {index < simulatedOffers?.data?.length - 1 ? (
                              <>
                                {getOfferValue(
                                  simulatedOffers?.data[index + 1]
                                    ?.simulationResponse?.finalCreditDecision,
                                  simulatedOffers?.data[index + 1]
                                    ?.simulationResponse?.offer
                                    ?.totalMonthlyInstalment
                                )}
                              </>
                            ) : (
                              formatInt(
                                currentLoanDetails?.monthlyRepayment,
                                true
                              )
                            )}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="name">{t("tenure")}</div>
                        <div>
                          <div className="value">
                            {loansById?.financeTermOffer
                              ? `${loansById?.financeTermOffer} ${t("months")}`
                              : "---"}
                          </div>
                          <div className="text-[12.9522px] text-blue-700 font-bold">
                            {getSimOfferTenure()}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="name">Commission</div>
                        <div>
                          <div className="value">
                            {getLoanDetailsCommissionPerAccountType()}
                          </div>
                          <div className="text-[12.9522px] text-blue-700 font-bold">
                            {index < simulatedOffers?.data?.length - 1 ? (
                              <>{getNextOfferMessage()}</>
                            ) : (
                              <>
                                {getCurrentLoanDetailsCommissionPerAccountType()}
                              </>
                            )}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="name">{t("interest")}</div>
                        <div className="value">{t("available-on-request")}</div>
                      </li>
                    </ul>
                  </LoanInformation>
                </Panel>
                <Panel header={t("additional-fees")} key="2">
                  <LoanInformation>
                    <ul>
                      {customAdditionalPayments?.map((el: any, i: number) => {
                        const index = customAdditionalPayments.indexOf(el);
                        return (
                          <li key={index}>
                            <div className="name">{t(el?.name)}</div>
                            <div>
                              <div className="value">
                                {formatInt(el?.value, true)}
                              </div>
                              <div className="text-[12.9522px] text-blue-700 font-bold">
                                {index < simulatedOffers?.data?.length - 1
                                  ? el?.value2
                                  : formatInt(
                                      currentLoanDetails?.additionalPayments[i]
                                        ?.value,
                                      true
                                    )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </LoanInformation>
                </Panel>
              </Collapse>
            </CollapseContainer>
          ) : (
            <BreakdownContainer>
              <LoanBreakdownContent>
                <div className="header">{t("loan-breakdown")}</div>
                <div className="breakdown-container">
                  <div className="content">
                    <div className="name">{t("loan-amount")}</div>
                    <div className="value">
                      {formatInt(loanDetails?.principalDebt, true)}
                    </div>
                    <div className="text-[12.9522px] text-blue-700 font-bold">
                      {getSimOfferPrincipalDebt()}
                    </div>
                  </div>
                  <div className="content">
                    <div className="name">{t("Equity")}</div>
                    <div className="value">
                      {formatInt(loanDetails?.equityContribution, true)}
                    </div>
                    <div className="text-[12.9522px] text-blue-700 font-bold">
                      {getSimOfferEquity()}
                    </div>
                  </div>
                  <div className="content">
                    <div className="name">{t("tenure")}</div>
                    <div className="value">
                      {loansById?.financeTermOffer
                        ? `${loansById?.financeTermOffer} ${t("months")}`
                        : "---"}
                    </div>
                    <div className="text-[12.9522px] text-blue-700 font-bold">
                      {getSimOfferTenure()}
                    </div>
                  </div>
                  <div className="content">
                    <div className="name">{t("monthly-repayment")}</div>
                    <div className="value">
                      {formatInt(loanDetails?.totalMonthlyInstalment, true)}
                    </div>
                    <div className="text-[12.9522px] text-blue-700 font-bold">
                      {index < simulatedOffers?.data?.length - 1 ? (
                        <>
                          {getOfferValue(
                            simulatedOffers?.data[index + 1]?.simulationResponse
                              ?.finalCreditDecision,
                            simulatedOffers?.data[index + 1]?.simulationResponse
                              ?.offer?.totalMonthlyInstalment
                          )}
                        </>
                      ) : (
                        formatInt(currentLoanDetails?.monthlyRepayment, true)
                      )}
                    </div>
                  </div>
                  <div className="content">
                    <div className="name">Commission</div>
                    <div className="value">
                      {getLoanDetailsCommissionPerAccountType()}
                    </div>
                    <div className="text-[12.9522px] text-blue-700 font-bold">
                      {index < simulatedOffers?.data?.length - 1 ? (
                        <>{getNextOfferMessage()}</>
                      ) : (
                        <>{getCurrentLoanDetailsCommissionPerAccountType()}</>
                      )}
                    </div>
                  </div>
                  <div className="content">
                    <div className="name">{t("interest-rate")}</div>
                    <div className="value">{t("available-on-request")}</div>
                  </div>
                </div>
              </LoanBreakdownContent>
              <AdditionalFeeContent>
                <div className="header">{t("additional-payments")}</div>
                <div className="breakdown-container">
                  {customAdditionalPayments?.map((el: any, i: number) => {
                    const index = customAdditionalPayments.indexOf(el);
                    return (
                      <div className="content" key={index}>
                        <div className="name">{t(el?.name)}</div>
                        <div className="value">
                          {formatInt(el?.value, true)}
                        </div>
                        <div className="text-[12.9522px] text-blue-700 font-bold">
                          {index < simulatedOffers?.data?.length - 1
                            ? el?.value2
                            : formatInt(
                                currentLoanDetails?.additionalPayments[i]
                                  ?.value,
                                true
                              )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AdditionalFeeContent>
            </BreakdownContainer>
          )}

          <div className="flex justify-end gap-3 border-t-[1px] border-[rgb(229, 231, 235)] pt-4 pb-3">
            <button
              onClick={() => navigate(`/applications/loans/${loanId}`)}
              className="bg-white text-[14px] border border-[#30345e] rounded-full w-[full] h-[32px] px-4 font-bold"
            >
              {t("back-to-loan-details")}
            </button>
            {allSimulations?.status === "created" && (
              <button
                onClick={handleAcceptOffer}
                className="bg-[#ffb619] text-[#30345e] text-[14px] font-bold px-4 rounded-full w-[full] h-[32px]"
              >
                {loading ? <Loader variant="secondary" /> : t("accept-offer")}
              </button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SimulatedOffer;
