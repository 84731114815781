const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && d.toISOString() === str; // valid date
};

const LoanStatusColor = (text: string) => {
  let color;
  if (text === "OFFER_ACCEPTED") {
    color = "bg-green-700";
  } else if (text === "OFFER_RECEIVED") {
    color = "bg-emerald-300";
  } else if (text === "APPLICATION_COMPLETED") {
    color = "bg-yellow-500";
  } else if (text === "DECLINED") {
    color = "bg-rose-600";
  } else {
    color = "bg-neutral-400";
  }
  return color;
};

const numberWithCommas = (x: any) => {
  const numberValue = typeof x === "number" ? x : Number(x);

  return numberValue.toLocaleString("en-US", { minimumFractionDigits: 0 });
};

export { isIsoDate, LoanStatusColor, numberWithCommas };
