import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import tw from "tailwind-styled-components";
import moment from "moment";
import { convertToTitleCase } from "@/utils/capitalizeFirstLetter";
import { TableContainer } from "./styles";
import { Loader } from "@/pages/auth/styles/loader";
import { useTranslation } from "react-i18next";

interface IStatusTree {
  paymentIds: string[];
  statusName: string;
  dateCompleted: string;
  loanId: string;
  completed: boolean;
  action: boolean;
  platformId?: string;
  offerId?: string;
}

const Card = tw.div`flex justify-between items-center w-full py-4`;

const DealerLoanSteps = ({ id }: any) => {
  const { t } = useTranslation("loan-status");
  const [fetchDealerStatus, setFetchDealerStatus] = useState<any>({});

  const { isFetching: dealerStatusLoading } = useQuery({
    queryKey: ["fetch_dealer_status_trail", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return client.get(`/v1/dealer/${id}/status-trail`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setFetchDealerStatus(parsedResponse);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const filteredData: IStatusTree[] = fetchDealerStatus?.statusTree?.filter(
    (item: IStatusTree) => item?.statusName !== "DISBURSED"
  );
  const disbursedItem: IStatusTree | undefined =
    fetchDealerStatus?.statusTree?.find(
      (item: IStatusTree) => item?.statusName === "DISBURSED"
    );
  const dealerStatusTrail: IStatusTree[] = disbursedItem
    ? [...filteredData, disbursedItem]
    : filteredData;

  if (dealerStatusLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <div className="grid grid-cols-1 divide-y">
      {dealerStatusTrail?.map((item) => (
        <Card key={item?.statusName}>
          <div className="flex items-center gap-2">
            <img
              src={
                item?.completed
                  ? "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/checkestepIcon.svg?updatedAt=1711358779069"
                  : "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/stepIcon.svg?updatedAt=1711111528640"
              }
              alt="Status"
            />
            <div>
              <div className="text-sm font-semibold text-[#6b7280]">
                {convertToTitleCase(t(item?.statusName))}
              </div>
              <div className="text-xs text-[#6b7280] font-normal">
                {item?.dateCompleted
                  ? moment(item?.dateCompleted).format("LLL")
                  : "---"}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default DealerLoanSteps;
