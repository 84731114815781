import { Modal } from "antd";
import EditWhitelabelForm from "./EditWhitelabelDocForm";

interface IDocumentModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  documentData?: any;
  setOpenDocumentModal: any;
}

export default function WhitelabelDocModal({
  isModalOpen,
  handleOk,
  handleCancel,
  documentData,
  setOpenDocumentModal,
}: Readonly<IDocumentModal>) {
  return (
    <Modal
      title={"Edit Document"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={600}
      wrapClassName="upload-document-modal-container"
      zIndex={1007}
    >
      <EditWhitelabelForm
        documentData={documentData}
        setOpenDocumentModal={setOpenDocumentModal}
      />
    </Modal>
  );
}
