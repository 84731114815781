import { useTranslation } from "react-i18next";
import { GeneralEmptyState } from "./styles";

const NoLoanOffers = () => {
  const { t } = useTranslation("application");

  return (
    <GeneralEmptyState>
      <div className="content">
        <div>
          <img
            src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
            alt="caution"
          />
        </div>
        <div>
          <div className="sub-heading">{t("you-do-not-have-an-offer-yet")}</div>
        </div>
      </div>
    </GeneralEmptyState>
  );
};

export default NoLoanOffers;
