import { Button } from "antd";
import { useState } from "react";
import tw from "tailwind-styled-components";
import { dealerLevelsData } from "@/data/benefitListData";
import OutlineButton from "../OutlineButton";
import {
  IBenefitList,
  IBenefitListResponse,
  IUserDealerResponse,
} from "@/interface/dealer";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { useDealerBenefits } from "@/states/dealer";
import UnlockedBenefitModal from "../UnlockedBenefitModal";
import DealerLevelCard, { IDealerType } from "../DealerLevelCard";
import storage from "@/lib/storage";
import dayjs from "dayjs";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import formatCurrency from "@/utils/formatCurrency";
import PageLoader from "@/layouts/application-layouts/PageLoader";
import { useNavigate } from "react-router-dom";
import { getOrdinal } from "@/utils/helpers";
import { dealerMedal, getMetricsColor } from "@/utils/dealer-helpers";

const MedalText = tw.div`text-[#30345E] text-base font-bold uppercase`;
const Text = tw.div`text-[#6B7280] text-sm font-normal`;
const Container = tw.div`relative w-full flex items-center justify-between bg-white border px-3 md:px-4 py-8 border-solid border-[#e5e7eb] cursor-pointer rounded-lg`;

export const NewDealerHomepage = ({
  dealerType,
  loading,
  dealerSummary,
  toViewHistory,
}: {
  dealerType: string;
  loading: boolean;
  dealerSummary: any;
  toViewHistory: any;
}) => {
  const user = storage.getUser();
  const navigate = useNavigate();
  const country = storage.getCountry();
  const [benefitList, setBenefitList] = useState<IBenefitList[]>([]);
  const [openBenefitModal, setOpenBenefitModal] = useState<boolean>(false);
  const [dealerData, setDealerData] = useState<IUserDealerResponse>();
  const dealerBenefitStore = useDealerBenefits.getState();
  const isNonOriginating = () =>
    dealerData?.level === "Non Originating" || !dealerData;

  const { isFetching: dealerLoading } = useQuery({
    queryKey: ["get_franchise", user?.company?.id],
    queryFn: () => {
      return client.get(`/v1/user/dealers/${user?.company?.id}`);
    },
    onSuccess: (res: any) => {
      const parsedDealer = JSON.parse(res?.data);
      setDealerData(parsedDealer);
    },
    enabled: !!user?.company?.id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["fetch_benefit_list"],
    queryFn: () => {
      return client.get(`/v1/franchise/dealer-tiers/benefits`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IBenefitListResponse = JSON.parse(res?.data);
      setBenefitList(parsedResponseData.data);
      dealerBenefitStore.setBenefits(parsedResponseData.data);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const currentUserlevelBenefit = benefitList?.find((benefits) =>
    benefits.name.toLowerCase().includes(dealerType?.toLowerCase())
  );

  const onboardingDate = () => {
    const date = new Date(dealerData?.onboardingDate ?? "");
    return dealerData?.onboardingDate
      ? dayjs(date).format("MMMM YYYY")
      : "--:--";
  };

  const benefitClass = isNonOriginating()
    ? "text-gray-400"
    : "text-[#30345e] border-[#30345e]";

  const currencySign = getCountryCurrency(country);

  const dealerLookup = () => {
    return {
      level: isNonOriginating()
        ? "New Dealer"
        : `${dealerData?.level ?? "---"} tier dealer`,
      monthlyMetricVal: dealerData?.metrics.points.value ?? "0",
      monthlyMetricPoint: dealerData?.metrics.points.monthly
        ? Number(dealerData?.metrics.points.monthly)
        : "",
      loanLimit: `${currencySign}${
        dealerData?.utility.limit
          ? formatCurrency(Number(dealerData?.utility.limit))
          : "0"
      }`,
      totalRevenue: `${currencySign}${
        dealerData?.metrics.revenue.value
          ? formatCurrency(Number(dealerData?.metrics.revenue.value))
          : "0"
      }`,
      totalRevenuePerc: `${
        dealerData?.metrics.revenue.monthly
          ? Number(dealerData?.metrics.revenue.monthly)
          : "0"
      }% from last month`,
      totalMonthlyLeads: `${
        dealerData?.metrics.leads.monthly
          ? Number(dealerData?.metrics.leads.monthly)
          : "---"
      }% from last month`,
      liveMonthlyInv: ` ${
        dealerData?.metrics.inventory.monthly
          ? Number(dealerData?.metrics.inventory.monthly)
          : "---"
      }% from last month`,
      totalSales: `${
        dealerData?.metrics.sales.monthly
          ? Number(dealerData?.metrics.sales.monthly)
          : "---"
      }% from last month`,
      totalLoans: `${
        dealerData?.metrics.loans.monthly
          ? Number(dealerData?.metrics.loans.monthly)
          : "---"
      }% from last month`,
      carViews: `${
        dealerData?.metrics.carViews.monthly
          ? Number(dealerData?.metrics.carViews.monthly)
          : "---"
      }% from last month`,
    };
  };

  if (dealerLoading) return <PageLoader />;

  return (
    <main>
      <div className="flex flex-col gap-8">
        <div>
          <h1 className="font-semibold text-[#30345e] text-xl mb-4 font-outfit">
            About You
          </h1>
          <div className="grid grid-cols-1 xl:grid-cols-3 w-full gap-4 xl:gap-5">
            {!dealerData ? (
              <DealerLevelCard
                type={"new-dealer"}
                position={getOrdinal(dealerSummary?.position! ?? 0)}
                total={dealerSummary?.totalPosition}
                potentialPoints={dealerSummary?.potentialPoints! ?? 0}
                lostPoints={dealerSummary?.lostPoints! ?? 0}
                totalPoints={dealerSummary?.points! ?? 0}
                viewHistory={toViewHistory}
                level={dealerSummary?.level}
                loading={false}
                useDefault={false}
              />
            ) : (
              <div className="bg-white border border-solid border-[#e5e7eb] rounded-md p-4 xl:px-4 xl:py-4 w-full h-auto">
                <div className="flex justify-between">
                  <img
                    src={dealerMedal(dealerData?.level)}
                    alt="Medal"
                    className="w-9"
                  />
                  <button
                    className={`px-4 py-[3px] rounded-[44px] border my-auto font-semibold disabled:cursor-not-allowed ${benefitClass}`}
                    onClick={() => setOpenBenefitModal(true)}
                    disabled={!!isNonOriginating()}
                  >
                    My Benefits
                  </button>
                </div>
                <div className="flex justify-between my-3">
                  <div>
                    <h2 className="font-bold">
                      {dealerData?.dealership || "---"}
                    </h2>
                    <p className="font-thin text-gray-500">
                      {dealerLookup()?.level}
                    </p>
                  </div>
                  <div className="text-right">
                    <h2 className="font-bold">ID: {dealerData?.id || "---"}</h2>
                    <p className="font-thin text-gray-500">
                      Joined: {onboardingDate()}
                    </p>
                  </div>
                </div>
                <div
                  className={`flex justify-between mt-6 pb-3 ${
                    !isNonOriginating() && "border-b"
                  }`}
                >
                  <div>
                    <h2 className="font-bold">
                      {dealerLookup()?.monthlyMetricVal || "0"} POINTS
                    </h2>
                    {!isNonOriginating() && (
                      <p
                        className={`${getMetricsColor(
                          dealerData?.metrics.points.monthly
                        )} md:text-base text-xs`}
                      >
                        {`${
                          dealerLookup()?.monthlyMetricPoint
                        } % from last month`}
                      </p>
                    )}
                  </div>
                  <div className="text-right">
                    <p className="font-thin text-gray-500">
                      Available loan limit
                    </p>
                    <h2 className="font-bold">{dealerLookup()?.loanLimit}</h2>
                  </div>
                </div>
                {!isNonOriginating() && (
                  <div className="mt-3 flex justify-between relative">
                    <OutlineButton path={"/dealer/financing"}>
                      Increase Loan Limit
                    </OutlineButton>

                    <div className=" border-r "></div>
                    <a href="/applications/select-cars">
                      <Button
                        type="text"
                        style={{
                          color: "#000000",
                          background: "#FFB619",
                          display: "flex",
                          alignItems: "center",
                          padding: "12px 14px",
                          margin: "auto 0",
                          fontWeight: 600,
                          borderRadius: "44px",
                        }}
                      >
                        Apply for loan
                      </Button>
                    </a>
                  </div>
                )}
              </div>
            )}

            <div className="relative flex justify-end items-center bg-white border border-solid border-[#e5e7eb] rounded-md p-4 xl:px-4 xl:py-4 w-full h-[210px] xl:h-[260px]">
              <div className="w-full max-w-[180px] xl:max-w-[200px] h-auto absolute left-4 bottom-0">
                <img
                  src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/Group%20639794%20(1).png"
                  alt="Get Pre-Approved"
                  className="w-full h-auto"
                />
              </div>
              <button
                onClick={() => navigate(`/prequalification`)}
                className="flex justify-center items-center font-semibold w-fit h-9 px-4 md:px-5 text-xs md:text-sm text-white bg-[#303453] rounded-md"
              >
                Get Pre-approved
              </button>
            </div>

            <div className="flex justify-between gap-2 items-center bg-white border border-solid border-[#e5e7eb] rounded-md p-4 xl:px-4 xl:py-4 w-full h-[210px] xl:h-[260px]">
              <div className="flex-1 w-full max-w-[180px] xl:max-w-full">
                <img
                  src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/Frame%201171275276%20(1).png"
                  alt=""
                  className="w-full h-auto"
                />
              </div>
              <button
                onClick={() => navigate(`/loan-calculator`)}
                className="flex justify-center items-center font-semibold w-fit h-9 px-4 xl:px-5 text-xs md:text-sm text-white bg-[#303453] rounded-md"
              >
                Use loan calculator
              </button>
            </div>
          </div>
        </div>

        {isNonOriginating() ? (
          <div className="flex flex-col gap-7">
            <div className="">
              <h4 className="font-semibold text-base text-center">
                Unlock a dealership level to enjoy benefits
              </h4>
              <p className="text-gray-500 text-base text-center">
                To enjoy benefits, you need to be on any of these dealership
                levels
              </p>
            </div>
            <div className="grid md:grid-cols-4 grid-cols-2 gap-6">
              {dealerLevelsData?.map((level) => (
                <Container key={level.type} onClick={() => {}}>
                  <div className=" items-center gap-1">
                    <div>
                      <img
                        src={level.medalIcon}
                        width="46px"
                        height="46px"
                        alt="medal"
                      />
                    </div>
                    <div>
                      <MedalText>{level.title}</MedalText>
                      <Text>{level.description}</Text>
                    </div>
                  </div>
                </Container>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-4">
              <h2 className="font-bold text-xl text-pages-pending-approval-heading leading-tight">
                This month&apos;s performance
              </h2>
              <p className="text-gray-500 font-normal">Sales summary</p>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-2 gap-5">
              <div className="bg-white border border-solid border-[#e5e7eb] p-4 rounded-md flex flex-col gap-2">
                <div className="flex text-gray-500 gap-2">
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/total-sales.svg"
                    alt=""
                    className="w-[26px]"
                  />
                  {dealerData?.metrics.revenue.label ?? "Total Revenue"}
                </div>
                <h2 className="font-extrabold text-lg text-pages-loan-detail-heading">
                  {dealerLookup()?.totalRevenue}
                </h2>
                <p
                  className={`${getMetricsColor(
                    dealerData?.metrics.revenue.monthly
                  )} md:text-base text-xs`}
                >
                  {dealerLookup()?.totalRevenuePerc}
                </p>
              </div>
              <div className="bg-white p-4 border border-solid border-[#e5e7eb] rounded-md flex flex-col gap-2">
                <div className="flex text-gray-500 gap-2">
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/leads.svg"
                    alt=""
                    className="w-[26px]"
                  />
                  {dealerData?.metrics.leads.label ?? "Total Leads"}
                </div>
                <h2 className="font-extrabold text-lg text-pages-loan-detail-heading">
                  {dealerData?.metrics.leads.value ?? "---"}
                </h2>
                <p
                  className={`${getMetricsColor(
                    dealerData?.metrics.leads.monthly
                  )} md:text-base text-xs`}
                >
                  {dealerLookup()?.totalMonthlyLeads}
                </p>
              </div>
              <div className="bg-white p-4 border border-solid border-[#e5e7eb] rounded-md flex flex-col gap-2">
                <div className="flex text-gray-500 gap-2 text-[16px]">
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/live-inventory.svg"
                    alt=""
                    className="w-[26px]"
                  />
                  {""}
                  Live inventory
                </div>
                <h2 className="font-extrabold text-lg text-pages-loan-detail-heading">
                  {dealerData?.metrics.inventory.value ?? "---"}
                </h2>
                <p
                  className={`${getMetricsColor(
                    dealerData?.metrics.inventory.monthly
                  )} md:text-base text-xs`}
                >
                  {dealerLookup()?.liveMonthlyInv}
                </p>
              </div>
              <div className="bg-white p-4 border border-solid border-[#e5e7eb] rounded-md flex flex-col gap-2">
                <div className="flex text-gray-500 gap-2">
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/cars-sold.svg"
                    alt=""
                    className="w-[26px]"
                  />
                  {dealerData?.metrics.sales.label ?? "Total Sales"}
                </div>
                <h2 className="font-extrabold text-lg text-pages-loan-detail-heading">
                  {dealerData?.metrics.sales.value ?? "---"}
                </h2>
                <p
                  className={`${getMetricsColor(
                    dealerData?.metrics.inventory.monthly
                  )} md:text-base text-xs`}
                >
                  {dealerLookup()?.totalSales}
                </p>
              </div>
              <div className="bg-white p-4 border border-solid border-[#e5e7eb] rounded-md flex flex-col gap-2">
                <div className="flex text-gray-500 gap-2 text-[16px]">
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/loan-applications.svg"
                    alt=""
                    className="w-[26px]"
                  />
                  {""}
                  Loan applications
                </div>
                <h2 className="font-extrabold text-lg text-pages-loan-detail-heading">
                  {dealerData?.metrics.loans.value ?? "---"}
                </h2>
                <p
                  className={`${getMetricsColor(
                    dealerData?.metrics.inventory.monthly
                  )} md:text-base text-xs`}
                >
                  {dealerLookup()?.totalLoans}
                </p>
              </div>
              <div className="bg-white p-4 border border-solid border-[#e5e7eb] rounded-md flex flex-col gap-2">
                <div className="flex text-gray-500 gap-2">
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/cars-viewed.svg"
                    alt=""
                    className="w-[26px]"
                  />
                  {""}
                  Cars views
                </div>
                <h2 className="font-extrabold text-lg text-pages-loan-detail-heading">
                  {dealerData?.metrics.carViews.value ?? "---"}
                </h2>
                <p
                  className={`${getMetricsColor(
                    dealerData?.metrics.inventory.monthly
                  )} md:text-base text-xs`}
                >
                  {dealerLookup()?.carViews}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <UnlockedBenefitModal
        openBenefitModal={openBenefitModal}
        handleOpenBenefitModal={() => setOpenBenefitModal(false)}
        handleCloseBenefitModal={() => setOpenBenefitModal(false)}
        type={(dealerType?.toLowerCase() as IDealerType) ?? "bronze"}
        dealerBenefit={currentUserlevelBenefit}
        loading={loading}
      />
    </main>
  );
};
