import { Loader } from "@/pages/auth/styles/loader";
import tw from "tailwind-styled-components";

export default function FormsPageLoader() {
  const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;

  return (
    <CustomLoading>
      <Loader variant={"secondary"} />
    </CustomLoading>
  );
}
