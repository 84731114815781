import { useState } from "react";
import storage from "@/lib/storage";
import { fetchCarDocument } from "@/hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "@/pages/auth/styles/loader";
import { ICarDocumentList } from "@/types";
import { convertCamelCaseString } from "@/utils/convertCamelCase";
import WhitelabelDocForm from "./WhitelabelDocForm";
import WhitelabelDocModal from "./WhitelabelDocModal";

export default function UploadWhitelabelDoc() {
  const queryParam = new URLSearchParams(window.location.search);
  const carId = queryParam.get("carId");
  const country = storage.getCountry();
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [existingDocumentData, setExistingDocumentData] =
    useState<ICarDocumentList>();
  const [documentList, setDocumentList] = useState<ICarDocumentList[]>();

  const showDocumentModal = (
    data: ICarDocumentList = {} as ICarDocumentList
  ) => {
    setOpenDocumentModal(true);
    setExistingDocumentData(data);
  };

  const { isFetching: loadCarDocs } = useQuery({
    queryKey: ["fetch_car_documents", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId as string,
      });
      return fetchCarDocument(params);
    },
    onSuccess: (res: any) => {
      const parsedData = JSON.parse(res?.data);
      const filteredList: ICarDocumentList[] =
        parsedData?.cardocumentList?.filter((li: { name: string }) =>
          country === "KE"
            ? ["insurance", "logbookUrl"]?.includes(li?.name)
            : ["insurance", "vehicleLicence"]?.includes(li?.name)
        );

      setDocumentList(filteredList);
    },
    enabled: !!carId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const renderDocumentList = () => {
    if (loadCarDocs) {
      return (
        <div className="w-full max-w-full h-[200px] flex items-center justify-center">
          <Loader variant="secondary" />
        </div>
      );
    } else {
      return (
        <div className="py-2">
          <div className="flex flex-col gap-3.5">
            {documentList?.map((item) => (
              <div
                key={item?.id}
                className="flex flex-col divide-y border rounded-lg gap-4"
              >
                <div className="flex justify-between items-center p-3">
                  <div className="flex gap-x-2 items-center">
                    <img
                      src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Vector.svg"
                      alt="document"
                    />
                    <div>
                      <p className="text-base font-bold text-[#30345E] mb-1">
                        {convertCamelCaseString(item?.name)}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <a href={item?.url} target="_blank" rel="noreferrer">
                      <button className="px-5 font-semibold text-base rounded-[44px] border border-solid border-[#30345e] w-fit h-[32px]">
                        View
                      </button>
                    </a>
                    <button
                      onClick={() => showDocumentModal(item)}
                      className="px-5 font-semibold text-base rounded-[44px] border border-solid border-[#30345e] w-fit h-[32px]"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const checkDocumentsHaveUrl = (documents?: ICarDocumentList[]): boolean => {
    const requiredDocs =
      country === "KE"
        ? ["insurance", "logbookUrl"]
        : ["insurance", "vehicleLicence"];
    return requiredDocs.every((docName) =>
      documents?.some((doc) => doc.name === docName && doc.url)
    );
  };

  return (
    <main>
      {!checkDocumentsHaveUrl(documentList) && (
        <WhitelabelDocForm carDocuments={documentList} />
      )}

      {documentList && documentList?.length > 0 ? (
        renderDocumentList()
      ) : (
        <div className="flex flex-col w-full h-[250px] items-center justify-center border">
          <div>
            <img
              src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
              alt="caution"
            />
          </div>
          <div className="text-base lg:text-lg text-[#6b7280]">
            No car documents available.
          </div>
        </div>
      )}

      <WhitelabelDocModal
        documentData={existingDocumentData}
        isModalOpen={openDocumentModal}
        handleOk={() => setOpenDocumentModal(false)}
        handleCancel={() => setOpenDocumentModal(false)}
        setOpenDocumentModal={setOpenDocumentModal}
      />
    </main>
  );
}
