export const getCountryTelCode = (code: string) => {
  let telCode;
  switch (code) {
    case "NG":
      telCode = "234";
      break;
    case "GH":
      telCode = "233";
      break;
    case "KE":
      telCode = "254";
      break;
    case "CI":
      telCode = "225";
      break;
    case "UG":
      telCode = "256";
      break;
    case "SN":
      telCode = "221";
      break;
    case "EG":
      telCode = "20";
      break;
    default:
      break;
  }

  return telCode;
};
