import TextField from "@/components/formElements/text-field";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { carMake, carModel, dealerSearch } from "@/hooks/use-loan";
import FormButton from "@/components/formElements/button";
import storage from "@/lib/storage";
import {
  Autocomplete,
  Slider,
  TextField as FormTextField,
} from "@mui/material";
import RangeInput from "@/components/formElements/range-input";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import {
  SelectCarContainer,
  SelectYearContainer,
} from "@/layouts/application-layouts/styles";

function valuetext(value: any) {
  return `${value}`;
}

const minDistance = 0;

const SelectCars = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("component");
  const country = storage.getCountry();
  const user = storage.getUser();
  const isAccountManager = storage.utilities.isAccountManager();
  const queryParam = new URLSearchParams(window.location.search);
  const isFranchise = storage.utilities.isFranchise();
  const isDealerDsa = storage.utilities.isDealerDsa();
  const [formData, setFormData]: any = useState({});
  const [carMakes, setCarMakes]: any = useState([]);
  const [carModels, setCarModels]: any = useState({});
  const [carMakeId, setCarMakeId] = useState();
  const [getModelId, setGetModelId] = useState<string>();
  const [getMakeId, setGetMakeId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [dealers, setDealers]: any = useState([]);
  const handleOpen = () => setOpen(!open);
  const expanded = "panel1";
  const [value, setValue] = useState<string>();
  const [value1, setValue1]: any = useState([0, 0]);
  const [rangeInput, setRangeInput] = useState([0, 0]);
  const variant = queryParam.get("variant") as string;

  const handleChange1 = (event: any, newValue: any, activeThumb: any) => {
    setRangeInput([event[0], event[1]]);
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const handleBrandSearch = (e: any, newValue: any) => {
    setCarMakeId(newValue?.value);
    setGetMakeId(newValue?.value);
  };

  const handleModelSearch = (e: any, newValue: any) => {
    setGetModelId(newValue?.value);
  };

  const handleChange = (e: any, newValue: any) => {
    setValue(newValue?.value);
  };

  const role = storage.getRole();
  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  useEffect(() => {
    const getCarMake = carMake(country, 1000);
    getCarMake.then((result: any) => setCarMakes(JSON.parse(result?.data)));
  }, [country]);

  useEffect(() => {
    if (isAccountManager || isDealerDsa) {
      const getDealer = dealerSearch(country, 1000, user?.id, user?.id);
      getDealer.then((result: any) => setDealers(JSON.parse(result?.data)));
    }
  }, [country, isAccountManager, isDealerDsa, user?.id]);

  useEffect(() => {
    if (carMakeId) {
      const getCarModel = carModel(country, carMakeId, 1000);
      getCarModel.then((result: any) => setCarModels(JSON.parse(result?.data)));
    }
  }, [carMakeId, country]);

  const dealerList: Array<string> = [];

  dealers?.result?.forEach((dealer: any) => {
    dealerList?.push(dealer?.id);
  });

  const toCarFound = () => {
    const queryParams = new URLSearchParams();

    const addQueryParam = (key: string, value?: string) => {
      if (value) {
        queryParams.append(key, value);
      }
    };

    addQueryParam("car_id_vin", formData.selectCar);
    addQueryParam("from", value1[0] || rangeInput[0]);
    addQueryParam("to", value1[1] || rangeInput[1]);
    addQueryParam("make_id", getMakeId);
    addQueryParam("model_id", getModelId);
    addQueryParam("franchise_id", value);
    addQueryParam("franchise", isFranchise ? user.company.id : "");
    addQueryParam("variant", variant);

    navigate(`/applications/car-found?${queryParams.toString()}`);
  };

  const updateLoanForm = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const makeData = carMakes?.makeList?.map((data: any) => {
    return {
      value: data?.id,
      label: data?.name,
    };
  });

  return (
    <SelectCarContainer>
      <h2 className="top-header">{t("select-a-car")}</h2>

      <div className="accordion-container">
        <div
          style={{ marginBottom: "48px" }}
          className="accordion first-accordion"
        >
          <Accordion
            expanded={expanded === "panel1"}
            className="accordion-inner"
          >
            <AccordionSummary>
              <Typography className="accordion-title">
                {t("car-details")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {isFranchise && (
                <div className="dealer-container">
                  <img
                    className="dealer-avatar"
                    src="https://storage.googleapis.com/img.autochek.africa/66462fb6-0dc0-4d3a-84b3-f2d523d1d642-md.svg"
                    alt=""
                  />
                  <Typography className="dealer-title">
                    {user?.company?.name}
                  </Typography>
                </div>
              )}

              {isAccountManager || isDealerDsa ? (
                <div className="make-model">
                  <div className="year">
                    <div className="label">{t("select-dealer")}</div>
                    <FormControl fullWidth style={{ width: "100%" }}>
                      <Autocomplete
                        fullWidth
                        id="dealer"
                        disablePortal
                        options={dealers?.result?.map((d: any) => {
                          return {
                            value: d?.id,
                            label: d?.name,
                          };
                        })}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <FormTextField
                            {...params}
                            placeholder={t("search-for-a-dealer")}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="make-model">
                <div className="select-container">
                  <div className="label">{t("brand")}</div>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <Autocomplete
                      fullWidth
                      id="make"
                      disablePortal
                      options={makeData}
                      onChange={handleBrandSearch}
                      renderInput={(params) => (
                        <FormTextField
                          {...params}
                          placeholder={t("select-brand")}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="select-container">
                  <div className="label">{t("model")}</div>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <Autocomplete
                      fullWidth
                      id="model"
                      disablePortal
                      disabled={!getMakeId || !carModels?.modelList}
                      options={carModels?.modelList?.map(
                        (data: any, index: number) => {
                          return {
                            value: data?.id,
                            label: data?.name,
                          };
                        }
                      )}
                      onChange={handleModelSearch}
                      renderInput={(params) => (
                        <FormTextField
                          {...params}
                          placeholder={t("select-model")}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="make-model">
                <div className="year">
                  <div className="label">{t("search-by-year")}</div>
                  <SelectYearContainer onClick={handleOpen}>
                    <div
                      style={{
                        color: "#ccc",
                        paddingLeft: "12px",
                      }}
                    >
                      {t("select-year")}
                    </div>
                    <div>
                      {/* <ArrowDropDownCircleIcon /> */}
                      <img
                        src="https://media.autochek.africa/file/publicAssets/Icon-o.svg"
                        alt=""
                      />
                    </div>
                  </SelectYearContainer>
                  {open && (
                    <div className="range-slider-container">
                      <RangeInput value={value1} onChange={handleChange1} />
                      <Slider
                        style={{ marginTop: "0.8rem" }}
                        getAriaLabel={() => "Minimum distance"}
                        value={value1}
                        min={2000}
                        max={2023}
                        step={1}
                        onChange={handleChange1}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        disableSwap
                      />
                    </div>
                  )}
                </div>
                <div>{""}</div>
              </div>

              <div className="search-by-car">{t("search-by-car-vin")}</div>
              <div className="single-fields">
                <TextField
                  label={t("select-car-from-inventory")}
                  name="selectCar"
                  value={formData.selectCar}
                  onChange={updateLoanForm}
                  placeholder={t("search-by-car-or-vin")}
                  logo={<FiSearch />}
                  required
                />
              </div>
              <div className="find-car-button">
                <FormButton
                  type="primary"
                  shape="round"
                  text={t("find-car")}
                  background="#FFB619"
                  color="#30345E"
                  hoverBackground="#d89300"
                  hoverColor="#30345E"
                  onClick={toCarFound}
                  disabled={!!(isDealerDsa && dealers?.result?.length === 0)}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </SelectCarContainer>
  );
};

export default SelectCars;
