import create from "zustand";

const asideState = create<IAsideState>()((set) => ({
  isVisible: null,
  isCollapsible: null,
  setVisibility: (value) => set((state) => ({ ...state, isVisible: value })),
  setCollapsibility: (value) =>
    set((state) => ({ ...state, isCollapsible: value })),
  toggleVisibility: () =>
    set((state) => ({ ...state, isVisible: !state.isVisible })),
}));

export default asideState;
