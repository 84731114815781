import { CreateCarFormValues } from "@/validations/create-car.schema";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FormInputProps } from "./types";

const FormInputCheckbox: React.FC<FormInputProps> = ({
  item,
  group: _group,
  methods,
}) => {
  const group = _group as keyof CreateCarFormValues;
  const name = item.name as keyof CreateCarFormValues;

  let checked = false;

  if (group) {
    const list = methods.getValues(group);
    checked = list.includes(name);
  }

  if (!group) {
    checked = methods.getValues(name) === "true";
  }

  const handleChange = () => {
    if (group) {
      const list = methods.getValues(group) as string[];
      if (!list.includes(name)) {
        list.push(name);
        methods.setValue(group, list);
      } else {
        const filtered = list.filter((e) => e !== name);
        methods.setValue(group, filtered);
      }
    }

    if (!group) {
      methods.setValue(name, "true");
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox onChange={handleChange} checked={checked} />}
      label={item.label}
      sx={{
        "& .MuiFormControlLabel-label": {
          color: "#303452",
          fontWeight: "600",
          fontFamily: "OutfitRegular",
          fontSize: "14px",
        },
      }}
    />
  );
};

export default FormInputCheckbox;
