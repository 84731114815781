import { forwardRef } from "react";
import tw from "tailwind-styled-components";
import styled from "styled-components";

const Container = tw.div`flex flex-col gap-y-1`;

const Label = styled.div`
  color: #202020;
  font-weight: bold;
  font-size: 14px;
`;

const Input = tw.input`
${(props: IInput) =>
  props.error?.message ? "border-inputs-regular-error" : "border-gray-300"}

w-full h-12 border text-[16px] text-inputs-regular-input placeholder:text-slate-300 px-4 rounded-md outline-none`;

const ErrorText = tw.p`text-sm text-inputs-regular-error`;

const RegularInput = forwardRef(
  ({ label, type, placeholder, error, ...props }: IRegularInput, ref) => {
    return (
      <Container>
        <Label>{label}</Label>
        <Input
          type={type}
          placeholder={placeholder}
          ref={ref}
          error={error}
          required
          step={type === "number" ? "any" : null}
          {...props}
        />
        {error?.message ? <ErrorText>{error.message}</ErrorText> : null}
      </Container>
    );
  },
);

export default RegularInput;
