import { Fragment, useState } from "react";
import {
  Button,
  Modal,
  Select,
  Input,
  Form,
  message,
  Segmented,
  Empty,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { CustomSegmentButton } from "./styles";
import {
  disputeCarModeration,
  fetchFailedCarModerations,
} from "@/hooks/use-api";
import { ICarModeration, IModerationList } from "@/types";
import { Loader } from "@/pages/auth/styles/loader";
import storage from "@/lib/storage";
import { useNavigate } from "react-router-dom";

interface IListModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  carId: string;
}

const cancelStyle = {
  background: "#fff",
  borderRadius: 60,
  color: "#30345E",
  fontWeight: 600,
  border: "1px solid #30345E",
  height: 48,
  minWidth: 101,
  marginRight: 12,
};

const CarModerationModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  carId,
}: IListModal) => {
  const [form] = Form.useForm();
  const { t } = useTranslation("modal");
  const isAssumedUser = storage.getAssumedUser();
  const navigate = useNavigate();

  //states
  const [loading, setLoading] = useState(false);
  const [moderationTabValue, setModerationTabValue] = useState<string | number>(
    "failed_moderation"
  );
  const [getModerations, setGetModerations] = useState<ICarModeration[]>([]);
  const [moderationList, setModerationList] = useState<IModerationList[]>([]);
  const [modReason, setModReason] = useState("");
  const [modDetails, setModDetails] = useState("");

  const { isLoading: loadFailedModeration } = useQuery({
    queryKey: ["fetch_failed_moderation", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        status: "pending,under_review,disputed",
      });
      return fetchFailedCarModerations(params);
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const filteredResults = parsedResults?.moderations.map(
        (item: ICarModeration) => {
          return {
            label: item.moderationReason,
            value: item.id,
          };
        }
      );
      setGetModerations(parsedResults?.moderations);
      setModerationList(filteredResults);
    },
    enabled: !!carId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { moderationId, details } = values;
    const payload = {
      dispute_reason: details,
    };

    try {
      const response = await disputeCarModeration(payload, moderationId);
      if (response) {
        message.success(t("Dispute moderation successfully"));
        setLoading(false);
        handleOk();
        form.resetFields();
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err.response?.data)?.error || t("Error disputing moderation")
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        zIndex={1007}
        closeIcon={
          <img
            src="https://media.autochek.africa/file/publicAssets/x-close-u.svg"
            alt="close-icon"
            style={{ marginTop: 8 }}
          />
        }
      >
        <div className="my-3">
          <CustomSegmentButton>
            <Segmented
              value={moderationTabValue}
              onChange={setModerationTabValue}
              style={{
                width: "100%",
              }}
              options={[
                {
                  label: <div className="p-1">{t("Failed Moderation")}</div>,
                  value: "failed_moderation",
                },
                {
                  label: <div className="p-1">{t("Dispute Moderation")}</div>,
                  value: "dispute_moderation",
                },
              ]}
            />
          </CustomSegmentButton>
        </div>

        {moderationTabValue === "failed_moderation" && (
          <>
            {getModerations?.length > 0 ? (
              <Fragment>
                <div className="my-6">
                  <div className="font-semibold text-base">
                    Your car failed during the moderation because:
                  </div>
                  <ul className="text-base list-disc ml-6">
                    {getModerations?.map((item) => (
                      <li key={item?.id}>{item?.moderationReason}</li>
                    ))}
                  </ul>
                </div>
                <div className="flex justify-end mb-3 mt-6">
                  <Button onClick={handleCancel} style={cancelStyle}>
                    {t("cancel")}
                  </Button>
                  <button
                    onClick={() => navigate(`/inventory/${carId}/edit`)}
                    className="text-xs lg:text-base min-w-[101px] w-auto h-[45px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 md:px-8 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                    disabled={!!isAssumedUser || getModerations.length === 0}
                  >
                    {t("Update Car")}
                  </button>
                </div>
              </Fragment>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </>
        )}

        {moderationTabValue === "dispute_moderation" && (
          <Form
            name="basic"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmit}
          >
            <Form.Item
              label={
                <span className="text-sm text-[#202020] font-semibold">
                  Moderation reason
                </span>
              }
              name="moderationId"
              rules={[{ required: true, message: t("please-add-a-reason") }]}
            >
              <Select
                placeholder="Select a moderation reason"
                options={moderationList}
                loading={loadFailedModeration}
                size="large"
                onChange={(value) => setModReason(value)}
              />
            </Form.Item>
            <Form.Item
              name="details"
              rules={[{ required: true, message: t("Please enter details") }]}
              label={
                <span className="text-sm text-[#202020] font-semibold">
                  Enter details
                </span>
              }
            >
              <Input.TextArea
                rows={4}
                placeholder="Add more details"
                onChange={(e) => setModDetails(e.target.value)}
              />
            </Form.Item>
            <Form.Item className="flex justify-end">
              <Button onClick={handleCancel} style={cancelStyle}>
                {t("cancel")}
              </Button>
              <button
                className="text-xs lg:text-base min-w-[101px] w-auto h-[45px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 md:px-8 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                disabled={!!isAssumedUser || !modReason || !modDetails}
              >
                {loading ? <Loader variant={"secondary"} /> : t("confirm")}
              </button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default CarModerationModal;
