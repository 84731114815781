import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createDealerDocuments, getDealerConfigData } from "@/hooks/use-loan";
import storage from "@/lib/storage";
import {
  Alert,
  Box,
  Container,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import InputControl from "@/components/formElements/input-control";
import NewUploadButton from "@/components/formElements/new-upload-button";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { Modal } from "antd";
import { Input } from "@/components/formElements/input-group";
import { device } from "@/utils/device";
import { useTranslation } from "react-i18next";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import tw from "tailwind-styled-components";
import { Loader } from "../auth/styles/loader";

interface TField {
  options: [];
  fields: [];
  actions: [];
  when: [];
  label: string;
  name: string;
  type: string;
  required: boolean;
  visibility: boolean;
  initialLabel: string;
}

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .top-text {
    font-size: 1.2rem;
    color: #30345e;
    font-weight: bolder;
    margin-left: 1rem;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-text {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1rem 0 0.5rem 0;
  }
  .bottom-text {
    text-align: center;
  }
  .button-ok {
    background: #ffb619;
    padding: 0.4rem 1.6rem;
    border-radius: 1rem;
    margin-top: 1rem;

    &:hover {
      background: #d89300;
    }
  }
`;

const SubmitButton = styled.div`
  .MuiButtonBase-root {
    background: #ffb619;
    color: #30345e;
    /* border-radius: 4rem; */
    font-weight: 600;

    &:disabled {
      background: #d6dbe2;
    }

    &:hover {
      background: #d89300;
    }
  }
`;

const CheckEligibilityFormContainer = styled.div`
  form {
    width: 45%;
    margin: 0 auto;
    @media ${device.tablet} {
      width: 100%;
    }
  }
`;

const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;

const UploadDealerDocuments = () => {
  const { t } = useTranslation("inventory");
  const { t: t1 } = useTranslation("loan-form");
  const user = storage.getUser();
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const [modal2Open, setModal2Open] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const role = storage.getRole();
  const [loanDocumentSections, setLoanDocumentSections]: any = useState();

  useEffect(() => {
    document.title = `Dealer Plus | Upload Documents [${role}]`;
  }, [role]);

  const loanId = queryParameters.get("loan_id");
  const [formData, setFormData] = useState<any>({});
  const methods = useForm();

  const [loanDocumentFields, setLoanDocumentFields]: any = useState();
  const [rerender, setRerender] = useState(false);

  const allFieldsWithWorkId = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("workId")
  );

  const allFieldsGovtId = loanDocumentFields?.filter((item: { name: string }) =>
    item.name.startsWith("identity")
  );
  const allFieldsWithPOF = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("proofOfEmployment")
  );

  const allFieldsWithBankStatement = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("bankStatement")
  );

  const allFieldsWithMMStatement = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("mobileMoneyStatement")
  );

  const { control, handleSubmit } = methods;

  const createNewDocObject = ({
    label,
    name,
    type,
    initialType,
    removeable,
    initialLabel,
    required,
    ...props
  }: {
    label: string;
    name: string;
    type: string;
    initialType: string;
    removeable?: boolean;
    initialLabel: string;
    required?: boolean;
  }) => {
    return {
      label: label,
      name: name,
      type: type,
      initialType: initialType,
      initialLabel: initialLabel,
      removeable: removeable,
      required: required,
      ...props,
    };
  };

  const handleInputChange = async (
    e: any,
    item?: TField,
    when?: any,
    initialType?: string
  ) => {
    const { name, value, type } = e.target;
    const valueObject = {
      name,
      value: value,
      type: initialType ?? type,
      label: item?.label,
      valid: true,
    };

    const newDocField = createNewDocObject({
      ...item,
      label: `Upload ${value.includes("payslip") ? "Payslip 1" : value}`,
      name: name,
      type: "file",
      initialType: item?.type!,
      initialLabel: item?.label!,
      removeable: true,
      required: item?.required,
    });
    const fieldToBeReplaced = loanDocumentFields.find(
      (field: TField) => field.name === name
    );

    const fieldIndex = loanDocumentFields.indexOf(fieldToBeReplaced);

    const allFields = loanDocumentFields;

    if (name.startsWith("proofOfEmployment") && value.includes("payslip")) {
      allFields[fieldIndex] = newDocField;
      const subsetFields = when[0].fields.map((field: TField) => {
        return {
          ...field,
          required: !!newDocField.required,
          removeable:
            name === "proofOfEmployment" ? false : newDocField.removeable,
        };
      });

      allFields.splice(fieldIndex + 1, 0, ...subsetFields);
      setLoanDocumentFields(allFields);
    }
    if (!name.includes("Password")) {
      if (initialType?.startsWith("file")) {
        allFields[fieldIndex] = newDocField;
        setLoanDocumentFields(allFields);
      }
    }

    setFormData((state: {}) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  const onConfirmButton = () => {
    setModal2Open(false);
    navigate(`/dealer/applications/loans/${loanId}`);
  };

  useEffect(() => {
    setPageLoading(true);

    const getProduct = async (loanId: any) => {
      try {
        const result = await getDealerConfigData(loanId, false);
        const parsedData = JSON.parse(result.data);

        const allFieldsInUploadDocument = parsedData?.documents?.filter(
          (item: { label: string }) => item.label.startsWith("Upload Documents")
        );

        setLoanDocumentFields(allFieldsInUploadDocument[0]?.fields);
        setLoanDocumentSections(parsedData?.documents);
        setPageLoading(false);
      } catch (error) {}
    };
    loanId && getProduct(loanId);
  }, [loanId]);

  const submit = async (values: any) => {
    let __values = values;
    const requiredDocs = checkRequiredDocs(__values);

    if (requiredDocs) {
      setAlert(`${requiredDocs} is required`);
      return;
    }

    for (let item of Object.keys(__values)) {
      item.includes("Password") && delete __values?.[item];
    }

    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    const documents: any = Object.entries(values).map(([key, value]: any) => ({
      name: key,
      file_url: value?.data,
      description: key,
      code: key,
      password:
        formData[
          `${key as string}${key.includes("File") ? "" : "File"}Password`
        ]?.value ?? "",
    }));

    try {
      setLoading(true);
      await createDealerDocuments({
        loan_id: loanId as string,
        dealer_id: user?.company?.id as string,
        vin: "",
        documents,
      });
      setModal2Open(true);
    } catch (error: any) {
      setLoading(false);
      setAlert(JSON.parse(error?.response?.data)?.message);
    }
  };

  const checkRequiredDocs = (values: any) => {
    for (let item of Object.keys(values)) {
      if (!values?.[item]?.data) {
        const doc = loanDocumentFields?.find(
          (field: any) => field.name === item
        );
        if (
          doc.required &&
          (doc.type.includes("file") || !formData?.[item]?.value)
        ) {
          return doc?.label;
        }
      }
    }
  };

  const showExtraIDButton = (item: { name: string }) => {
    const visibleIdField = allFieldsGovtId.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleIdFieldsCount = visibleIdField.length / 2;
    return (
      visibleIdFieldsCount < allFieldsGovtId.length / 2 &&
      item.name.endsWith(
        `identity${
          visibleIdFieldsCount < 2 ? "" : visibleIdFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showWorkIDButton = (item: { name: string }) => {
    const visibleWorkIdField = allFieldsWithWorkId.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleWorkIdFieldsCount = visibleWorkIdField.length / 2;
    return (
      visibleWorkIdFieldsCount < allFieldsWithWorkId.length / 2 &&
      item.name.endsWith(
        `workId${
          visibleWorkIdFieldsCount < 2 ? "" : visibleWorkIdFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraEmploymentBtn = (item: any) => {
    const visiblePOFField = allFieldsWithPOF.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visiblePOFFieldsCount = visiblePOFField.length / 2;
    return (
      visiblePOFFieldsCount < allFieldsWithPOF.length / 2 &&
      item.name.endsWith(
        `proofOfEmployment${
          visiblePOFFieldsCount < 2 ? "" : visiblePOFFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraBankStatmentBtn = (item: any) => {
    const visibleBankStField = allFieldsWithBankStatement.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleBankStFieldCount = visibleBankStField.length / 2;
    return (
      visibleBankStFieldCount < allFieldsWithBankStatement.length / 2 &&
      item.name.endsWith(
        `bankStatement${
          visibleBankStFieldCount < 2 ? "" : visibleBankStFieldCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraMMBtn = (item: any) => {
    const visibleMMField = allFieldsWithMMStatement.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleMMFieldCount = visibleMMField.length / 2;
    return (
      visibleMMFieldCount < allFieldsWithMMStatement.length / 2 &&
      item.name.endsWith(
        `mobileMoneyStatement${
          visibleMMFieldCount < 2 ? "" : visibleMMFieldCount
        }IsPasswordProtected`
      )
    );
  };

  const addExtraField = async (index: number) => {
    const allFields = loanDocumentFields;
    const field1 = {
      ...loanDocumentFields[index + 1],
      visibility: true,
      removeable: true,
    };
    const field2 = {
      ...loanDocumentFields[index + 2],
      visibility: true,
      removeable: true,
    };

    allFields[index + 1] = field1;
    allFields[index + 2] = field2;
    setLoanDocumentFields(allFields);
    setRerender(!rerender);
  };

  const removeField = (index: number, initialType: string, item: TField) => {
    const allFields = loanDocumentFields;
    const field1 = {
      ...loanDocumentFields[index],
      visibility: !!item.required,
      type: initialType ?? item.type,
      label: item.initialLabel ?? item.label,
    };
    const field2 = {
      ...loanDocumentFields[index + 1],
      visibility: !!item.required,
    };

    allFields[index] = field1;
    allFields[index + 1] = field2;
    const checkIfpayslipFieldIsPresent = allFields.find((field: TField) =>
      field.name.startsWith("proofOfEmploymentPayslip")
    );
    if (item.label.includes("Payslip 1")) {
      checkIfpayslipFieldIsPresent && allFields.splice(index + 1, 2);

      formData[item.name].value.includes("payslip") &&
        (formData[item.name].value = "");
    }
    if (item.name.startsWith("proofOfEmploymentPayslip")) {
      allFields.splice(index, 1);
    }
    setLoanDocumentFields(allFields);
    setRerender(!rerender);
  };

  return (
    <div>
      {pageLoading ? (
        <CustomLoading>
          <Loader variant={"secondary"} />
        </CustomLoading>
      ) : (
        <div>
          <TextContainer
            onClick={() => navigate(-1)}
            style={{ marginBottom: "24px" }}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
              alt="left-arrow"
            />
            <div className="top-text">{capitalFirstLetter(t("back"))}</div>
          </TextContainer>

          <Container>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={!!alert}
              autoHideDuration={5000}
              onClose={() => setAlert("")}
            >
              <Alert severity="error" variant="filled">
                {alert}
              </Alert>
            </Snackbar>

            <CheckEligibilityFormContainer>
              <form>
                <Typography
                  variant="h6"
                  fontWeight="600"
                  textAlign="center"
                  style={{ marginBottom: "24px" }}
                >
                  {
                    loanDocumentSections?.find(
                      (item: any) => item?.name === "uploadDocuments"
                    )?.label
                  }
                </Typography>

                {loanDocumentFields?.map((item: any, index: any) => (
                  <InputControl
                    key={loanDocumentFields?.indexOf(item)}
                    control={control}
                    name={item.name}
                  >
                    {({ value, onChange, onBlur }) =>
                      item.visibility && item.type === "file" ? (
                        <NewUploadButton
                          fileType={item.fileType}
                          label={item.label}
                          value={value}
                          onChange={onChange}
                          fieldName={item.name}
                          name={item.name}
                          channel={item.channel}
                          removeable={item.removeable ?? false}
                          onRemoveField={() =>
                            removeField(index, item.initialType, item)
                          }
                        />
                      ) : (
                        item.visibility && (
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box
                                sx={{
                                  fontWeight: 600,
                                  backgroundColor: "#F8FAFC",
                                  width: { md: "100%", xs: "100%" },
                                  paddingY: "15px",
                                  paddingX: "15px",
                                  marginY: "9px",
                                  cursor: "pointer",
                                  display: "block",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                                component="label"
                              >
                                <Input
                                  formData={formData}
                                  key={loanDocumentFields.indexOf(item)}
                                  onBlur={onBlur}
                                  required={item.required}
                                  type={
                                    item.type === "fileSelection"
                                      ? "selection"
                                      : item.type
                                  }
                                  label={item.label}
                                  placeholder={item.label}
                                  fileType={item.fileType}
                                  name={item.name}
                                  when={item.when}
                                  options={
                                    item.options?.length ? item.options : []
                                  }
                                  onChange={(e: any) => {
                                    handleInputChange(
                                      e,
                                      item,
                                      item.when,
                                      item.type
                                    );
                                  }}
                                  value={formData[item.name]?.value}
                                  selectOptions={
                                    item.options?.length ? item.options : []
                                  }
                                  visibility={true}
                                  innerVisibility={true}
                                  fullWidth={item.fullWidth}
                                  autoComplete={false}
                                  subsection={null}
                                  hideSubsection
                                  addDirector={null}
                                  directorCount={null}
                                />
                              </Box>
                            </Stack>

                            {showExtraIDButton(item) && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingY: "30px",
                                }}
                              >
                                <SubmitButton>
                                  <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      width: "151px",
                                      height: "43px",
                                    }}
                                    onClick={() => addExtraField(index)}
                                  >
                                    {t1("Add more")}
                                  </LoadingButton>
                                </SubmitButton>
                              </Box>
                            )}
                            {showWorkIDButton(item) && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingY: "30px",
                                }}
                              >
                                <SubmitButton>
                                  <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      width: "151px",
                                      height: "43px",
                                    }}
                                    onClick={() => addExtraField(index)}
                                  >
                                    {t1("Add more")}
                                  </LoadingButton>
                                </SubmitButton>
                              </Box>
                            )}
                            {showExtraEmploymentBtn(item) && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingY: "30px",
                                }}
                              >
                                <SubmitButton>
                                  <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      width: "151px",
                                      height: "43px",
                                    }}
                                    onClick={() => addExtraField(index)}
                                  >
                                    {t1("Add more")}
                                  </LoadingButton>
                                </SubmitButton>
                              </Box>
                            )}
                            {showExtraBankStatmentBtn(item) && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingY: "30px",
                                }}
                              >
                                <SubmitButton>
                                  <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      width: "151px",
                                      height: "43px",
                                    }}
                                    onClick={() => addExtraField(index)}
                                  >
                                    {t1("Add more")}
                                  </LoadingButton>
                                </SubmitButton>
                              </Box>
                            )}
                            {showExtraMMBtn(item) && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingY: "30px",
                                }}
                              >
                                <SubmitButton>
                                  <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      width: "151px",
                                      height: "43px",
                                    }}
                                    onClick={() => addExtraField(index)}
                                  >
                                    {t1("Add more")}
                                  </LoadingButton>
                                </SubmitButton>
                              </Box>
                            )}
                          </>
                        )
                      )
                    }
                  </InputControl>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingY: "30px",
                  }}
                >
                  <SubmitButton>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      type="submit"
                      sx={{ width: "151px", height: "43px" }}
                      loading={loading}
                      onClick={handleSubmit(submit)}
                    >
                      {t1("Submit")}
                    </LoadingButton>
                  </SubmitButton>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingY: "30px",
                  }}
                >
                  <Modal centered open={modal2Open} footer={null}>
                    <ModalContent>
                      <img
                        src="https://storage.googleapis.com/img.autochek.africa/d083d14a-5cd1-4017-baa0-97086e504a33-check-circle.svg"
                        alt="Check"
                      />
                      <h2 className="title-text">{t("documents-uploaded")}</h2>
                      <p className="bottom-text">
                        {t("the-documents-have-been-received-and-will")} <br />{" "}
                        {t("be-reviewed-promptly")}
                      </p>
                      <button onClick={onConfirmButton} className="button-ok">
                        {t("OK")}
                      </button>
                    </ModalContent>
                  </Modal>
                </Box>
              </form>
            </CheckEligibilityFormContainer>
          </Container>
        </div>
      )}
    </div>
  );
};

export default UploadDealerDocuments;
