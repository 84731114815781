import { useState } from "react";
import { TrackerFormContainer } from "@/layouts/application-layouts/styles";
import { Loader } from "@/pages/auth/styles/loader";
import { fetchTrackerInfo } from "@/hooks/use-api";
import storage from "@/lib/storage";
import { useQuery } from "@tanstack/react-query";
import { ITrackerInfo } from "@/types";
import TrackerFormModal from "./TrackerFormModal";
import AddTrackerForm from "./AddTrackerForm";

export default function TrackersList() {
  const queryParam = new URLSearchParams(window.location.search);
  const carId = queryParam.get("carId");
  const isAssumedUser = storage.getAssumedUser();
  const [trackerInfo, setTrackerInfo] = useState<ITrackerInfo[]>([]);
  const [openTrackerModal, setOpenTrackerModal] = useState(false);
  const [existingTrackerInfo, setExistingTrackerInfo] =
    useState<ITrackerInfo>();

  const showTrackerModal = (data: ITrackerInfo = {} as ITrackerInfo) => {
    setOpenTrackerModal(true);
    setExistingTrackerInfo(data);
  };

  const { isFetching: loadTracker } = useQuery({
    queryKey: ["fetch_tracker", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId as string,
      });
      return fetchTrackerInfo(params);
    },
    onSuccess: (res: any) => {
      const parsedData = JSON.parse(res?.data);
      setTrackerInfo(parsedData?.trackers);
    },
    enabled: !!carId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const trackerLoaderComponent = () => {
    return (
      <div className="w-full max-w-full h-[200px] flex items-center justify-center">
        <Loader variant="secondary" />
      </div>
    );
  };

  return (
    <main>
      {loadTracker ? (
        trackerLoaderComponent()
      ) : (
        <div>
          {trackerInfo.length > 0 ? (
            <div className="flex flex-col gap-4 py-2">
              <div>
                <div className="flex flex-col gap-3">
                  {trackerInfo?.map((item) => (
                    <div key={item?.id} className="flex flex-col gap-4">
                      <div className="flex flex-row justify-between border rounded-md p-3">
                        <div className="flex flex-col gap-0.5">
                          <div className="flex gap-1 items-center">
                            <div className="text-base font-semibold text-[#30345e]">
                              Username:
                            </div>
                            <div className="text-base text-[#6B7280] font-medium">
                              {item.username}
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-base font-semibold text-[#30345e]">
                              Provider:
                            </div>
                            <div className="text-base text-[#6B7280] font-medium">
                              {item.provider}
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-base font-semibold text-[#30345e]">
                              Password:
                            </div>
                            <div className="text-base text-[#6B7280] font-medium">
                              {item.password}
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-base font-semibold text-[#30345e]">
                              URL:
                            </div>
                            <div className="text-base text-[#6B7280] font-medium">
                              {item.url}
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => showTrackerModal(item)}
                          disabled={!!isAssumedUser}
                          className="w-fit h-[32px] px-7 text-base font-semibold border border-solid border-[#30345e] rounded-[60px]"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button
                className="text-sm lg:text-base w-fit h-[36px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                disabled={!!isAssumedUser}
                onClick={() => showTrackerModal()}
              >
                Add tracker
              </button>
            </div>
          ) : (
            <TrackerFormContainer>
              <AddTrackerForm
                trackerData={existingTrackerInfo}
                setOpenTrackerModal={setOpenTrackerModal}
                type="default"
              />
            </TrackerFormContainer>
          )}
        </div>
      )}

      <TrackerFormModal
        isModalOpen={openTrackerModal}
        handleOk={() => setOpenTrackerModal(false)}
        handleCancel={() => setOpenTrackerModal(false)}
        trackerData={existingTrackerInfo}
        setOpenTrackerModal={setOpenTrackerModal}
      />
    </main>
  );
}
