import React, { useState, useEffect } from "react";
import moment from "moment";
import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { TableContainer } from "@/layouts/application-layouts/styles";
import tw from "tailwind-styled-components";
import SimulatedOffer from "@/components/SimulatedOffer";
import { convertCamelCaseString } from "@/utils/convertCamelCase";
import { useTranslation } from "react-i18next";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import storage from "@/lib/storage";
import ReferredOfferLayout from "@/layouts/application-layouts/ReferredOffer";
import DeclinationReasonLayout from "@/components/DeclinationReason";
import LoanInfo from "@/layouts/application-layouts/LoanInfo";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const CarContainer = styled.div`
  .css-15v22id-MuiAccordionDetails-root {
    padding: 16px 12px 16px;
    border-top: 1px solid rgb(229, 231, 235);
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 0px 12px;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    margin-bottom: 1.5rem;
  }
`;

const SimulationHistory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("inventory");
  const queryParam = new URLSearchParams(window.location.search);
  const loanId = queryParam.get("loan_id") || "";
  const partnerId: string = queryParam.get("partner_id") || "";
  const role = storage.getRole();
  const [expanded, setExpanded] = useState<number | false>();
  const [simulatedOffers, setSimulatedOffers] = useState<any>([]);
  const [offersById, setoffersById] = useState<any>({});
  const [loansById, setLoansById] = useState<any>({});

  const handleAccordionChange =
    (panel: any) => (event: React.SyntheticEvent, newExpanded: any) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    document.title = `Dealer Plus | Offer Simulation [${role}]`;
  }, [role]);

  useQuery({
    queryKey: ["offer_simulator_loan_id", loanId],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${loanId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      const filteredOffer = parsedLoanDetails?.offers?.filter(
        (item: any) => item?.partner?.id === partnerId,
      );
      setoffersById(filteredOffer[0]);
      setLoansById(parsedLoanDetails);
    },
    retry: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: isSimulatedLoading } = useQuery({
    queryKey: ["offer_simulation_history", loanId, partnerId],
    queryFn: () => {
      const params = new URLSearchParams({ partner_id: partnerId });
      return client.get(`/v2/origination/loans/${loanId}/offer-simulations`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setSimulatedOffers(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  if (isSimulatedLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  const renderOffer = (offer: any) => {
    const { simulationResponse } = offer;

    let componentToRender;

    if (simulationResponse?.finalCreditDecision === "REFER") {
      componentToRender = <ReferredOfferLayout loanDetails={null} />;
    } else if (simulationResponse?.finalCreditDecision === "DECLINE") {
      componentToRender = (
        <DeclinationReasonLayout offers={simulationResponse} loan={loansById} />
      );
    } else {
      componentToRender = (
        <SimulatedOffer
          id={offer?.id}
          allSimulations={offer}
          loanDetails={simulationResponse?.offer}
          loansById={simulationResponse}
          currentLoanId={loanId}
          currentLoanDetails={offersById}
          currentLoansById={loansById}
          index={offer?.id}
          simulatedOffers={simulatedOffers}
        />
      );
    }

    return componentToRender;
  };

  return (
    <div className="w-[100%] md:w-[51%] h-[auto] mx-[auto] my-[0] flex flex-col gap-4">
      <div className="relative flex items-center md:justify-center gap-2 bg-white md:bg-transparent px-[12px] py-[16px] md:p-[16px] rounded-lg border-b-[1px] md:border-b-transparent border-[#e5e7eb]">
        <div
          className="relative md:absolute left-0 flex items-center gap-1 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
            alt="arrow-back"
            className="w-[24px] h-[24px]"
          />{" "}
          <div className="hidden md:block font-bold text-[16px] text-[#30345e]">
            {capitalFirstLetter(t1("back"))}
          </div>
        </div>
        <div className="font-bold font-[OutfitBold] text-[#30345e] text-[20px] md:text-[30px]">
          {t("simulator-history")}
        </div>
      </div>

      <div className="mb-4">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="w-full sm:pr-2">
              <div className="flex items-center justify-between">
                <div>
                  <div className="text-[#30345e] text-[20px] font-bold">
                    {t("current-offer")}
                  </div>
                  <div className="text-[#7F83A8] text-[14px]">
                    {t("created")}:{" "}
                    {offersById?.createdAt
                      ? moment(offersById?.createdAt).format(
                          "DD-MM-YYYY, h:mm a",
                        )
                      : "---"}
                  </div>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <LoanInfo
              id={loanId}
              loanDetails={offersById}
              loansById={loansById}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <CarContainer>
        {simulatedOffers?.data?.length === 0 ? (
          <div className="min-h-[400px] bg-white rounded-lg flex items-center justify-center">
            <div className="w-full">
              <img
                src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                alt="empty-page"
                style={{ margin: "0 auto" }}
              />
              <div className="text-center text-[#30345e] text-[16px] font-semibold">
                {t("no-offer-simulations-recorded-yet")}
              </div>
            </div>
          </div>
        ) : (
          simulatedOffers?.data?.map(
            (offer: Record<string, any>, index: number) => (
              <Accordion
                key={offer?.id}
                expanded={expanded === index}
                onChange={handleAccordionChange(index)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="w-full sm:pr-2">
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="text-[#30345e] text-[20px] font-bold">
                          {index === 0
                            ? `${t("latest-simulation")}`
                            : `${t("simulation")} ${
                                simulatedOffers?.data?.length - index
                              }`}
                        </div>
                        <div className="text-[#7F83A8] text-[14px]">
                          {t("created")}:{" "}
                          {offer?.createdAt
                            ? moment(offer?.createdAt).format(
                                "DD-MM-YYYY, h:mm a",
                              )
                            : "---"}
                        </div>
                      </div>
                      <div
                        className={
                          offer?.status === "selected"
                            ? `text-[#fff] text-sm sm:text-base font-semibold bg-green-500 rounded-full px-3 py-1`
                            : `text-[#30345e] text-sm sm:text-base font-semibold bg-gray-200 rounded-full px-3 py-1`
                        }
                      >
                        {convertCamelCaseString(t(offer?.status))}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>{renderOffer(offer)}</div>
                </AccordionDetails>
              </Accordion>
            ),
          )
        )}
      </CarContainer>
    </div>
  );
};

export default SimulationHistory;
