import { useState } from "react";
import { AppTabContainer } from "@/layouts/application-layouts/styles";
import LoanOfferList from "@/components/LoanOfferList";
import { useTranslation } from "react-i18next";
import type { TabsProps } from "antd";
import { Tabs, notification } from "antd";
import client from "@/lib/axios-client";
import { useQueryClient } from "@tanstack/react-query";
import { Loader } from "@/pages/auth/styles/loader";
import storage from "@/lib/storage";
import { LOAN_STATUS } from "@/utils/loan-status";

type NotificationType = "success" | "info" | "warning" | "error";

const DealerLoanOffers = ({
  tabKey,
  setTabKey,
  id,
  initialOffer,
  finalOffer,
  loansById,
}: {
  tabKey: string;
  setTabKey: any;
  id: any;
  initialOffer: any;
  finalOffer: any;
  loansById: any;
}) => {
  const { t } = useTranslation("application");
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const isAssumedUser = storage.getAssumedUser();

  const TabArray = [
    {
      key: "1",
      label: t(`Conditional-Offer`),
      children: (
        <LoanOfferList
          id={id}
          conditionalOffer={initialOffer}
          loansById={loansById}
          tabKey={tabKey}
        />
      ),
    },
    {
      key: "2",
      label: t(`Final-Offer`),
      children: (
        <LoanOfferList
          id={id}
          conditionalOffer={finalOffer}
          loansById={loansById}
          tabKey={tabKey}
        />
      ),
      disabled: finalOffer.length === 0,
    },
  ];

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  const items: TabsProps["items"] = TabArray;

  const handleAcceptOffer = async () => {
    setLoading(true);
    const payload = {
      loan_id: id,
    };

    try {
      const response: any = await client.put(
        `/v1/dealer/accept-conditional-offer`,
        payload
      );
      if (response) {
        const openNotificationWithIcon = (type: NotificationType) => {
          api[type]({
            message: "You have accepted this offer",
          });
        };
        openNotificationWithIcon("success");
        queryClient.invalidateQueries();
        setLoading(false);
      }
    } catch (error: any) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: JSON.parse(error?.response?.data)?.error,
        });
      };
      openNotificationWithIcon("error");
      setLoading(false);
    }
  };

  const renderAcceptOfferButton = () => {
    const hasConditionalOffer =
      loansById?.status === LOAN_STATUS.CONDITIONAL_OFFER_RECEIVED;

    if (hasConditionalOffer) {
      return (
        <button
          onClick={() => handleAcceptOffer()}
          disabled={!!isAssumedUser}
          className="w-fit px-4 md:px-6 py-1.5 my-1.5 md:my-2.5 bg-[#ffb619] text-[#30345e] hover:text-[#30345e] text-sm font-bold rounded-[60px] border-none disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
        >
          {loading ? <Loader variant="secondary" /> : "Accept Offer"}
        </button>
      );
    } else {
      return null;
    }
  };

  return (
    <AppTabContainer
      className="dealer-loan-offer"
      style={{ paddingTop: "0px", paddingBottom: "0px" }}
    >
      <Tabs
        activeKey={tabKey}
        items={items}
        type="card"
        onChange={handleTabChange}
        tabBarExtraContent={renderAcceptOfferButton()}
      />

      {contextHolder}
    </AppTabContainer>
  );
};

export default DealerLoanOffers;
