import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { uploadDealerSignedDocuments } from "@/hooks/use-loan";
import storage from "@/lib/storage";
import { Alert, Box, Container, Snackbar } from "@mui/material";
import InputControl from "@/components/formElements/input-control";
import NewUploadButton from "@/components/formElements/new-upload-button";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import tw from "tailwind-styled-components";
import { TableContainer } from "@/layouts/application-layouts/styles";
import { useTranslation } from "react-i18next";

const getBorderStyle = (props: Partial<IButtonLoader>): string | undefined => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  }
  return undefined;
};

const Loader = tw.div`
${(props: Partial<IButtonLoader>) =>
  getBorderStyle(
    props,
  )} inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;

  .top-text {
    font-size: 1.2rem;
    color: #30345e;
    font-weight: bolder;
    margin-left: 1rem;
  }
`;

const SubmitButton = styled.div`
  .MuiButtonBase-root {
    background: #ffb619;
    color: #30345e;
    border-radius: 4rem;
    font-weight: 600;

    &:disabled {
      background: #d6dbe2;
    }

    &:hover {
      background: #d89300;
    }
  }
`;

const UploadDealerSignedDocuments = () => {
  const country = storage.getCountry();
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const loanId = queryParameters.get("loan_id");
  const loanDocument: any = queryParameters.get("loan_doc");
  const offerId: any = queryParameters.get("offer_id");
  const methods = useForm();
  const { control, handleSubmit } = methods;
  const role = storage.getRole();
  const filterLoanDocument = JSON.parse(loanDocument);
  const { t } = useTranslation("loan-form");

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  const { isLoading: loanByIdLoading } = useQuery({
    queryKey: ["loans_by_ids_s", country, loanId],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${loanId}`);
    },
    onSuccess: (res: any) => {},
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const onSubmitDocs = async (values: any) => {
    for (let item of Object.keys(values)) {
      if (!values?.[item]?.data) {
        const doc =
          filterLoanDocument &&
          filterLoanDocument?.find((field: any) => field.name === item);
        setAlert(`${doc?.name} is required`);
        return;
      }
    }

    const documents = Object.entries(values).map(([key, value]: any) => ({
      name: key,
      file_url: value?.data,
      description: key,
      code: key,
    }));

    try {
      setLoading(true);
      await uploadDealerSignedDocuments({
        loan_id: loanId as string,
        offer_id: offerId as string,
        documents,
      });
      navigate(`/dealer/applications/loans/${loanId}`);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <TextContainer
        onClick={() => navigate(-1)}
        style={{ marginBottom: "48px", cursor: "pointer" }}
      >
        <img
          src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
          alt="left-arrow"
        />
        <div className="top-text">{t("upload-signed-loan-documents")}</div>
      </TextContainer>

      <Container>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={!!alert}
          autoHideDuration={5000}
          onClose={() => setAlert("")}
        >
          <Alert severity="error" variant="filled">
            {alert}
          </Alert>
        </Snackbar>

        {loanByIdLoading ? (
          <TableContainer>
            <div className="filter-mobile-loader-wrapper">
              <Loader variant={"secondary"} />
            </div>
          </TableContainer>
        ) : (
          <div>
            {filterLoanDocument &&
              filterLoanDocument?.map((item: any, index: number) => (
                <InputControl
                  key={filterLoanDocument.indexOf(item)}
                  control={control}
                  name={item.name}
                >
                  {({ value, onChange }) => (
                    <NewUploadButton
                      fileType={item.fileType}
                      label={t(item.name)}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </InputControl>
              ))}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingY: "30px",
              }}
            >
              <SubmitButton>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  sx={{ width: "218px", height: "43px" }}
                  onClick={handleSubmit(onSubmitDocs)}
                  loading={loading}
                >
                  {t("Upload Documents")}
                </LoadingButton>
              </SubmitButton>
            </Box>
          </div>
        )}
      </Container>
    </div>
  );
};

export default UploadDealerSignedDocuments;
