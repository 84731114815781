import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import {
  EquityPaymentContainer,
  TableContainer,
  TextContainer,
  ViewButton,
} from "@/layouts/application-layouts/styles";
import { Loader } from "../auth/styles/loader";
import { useTranslation } from "react-i18next";
import DocFileUploader from "@/components/DocsUploader";
import storage from "@/lib/storage";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import moment from "moment";

interface IValues {
  password: string;
  ppis_document_upload: any[];
  vin: string;
}

interface IPPIResponse {
  loanId: string;
  inspectionReportUrl: string;
  vin: string;
  createdAt: string;
  updatedAt: string;
  country: string;
  type: string;
  password: string;
  id: string;
}

export default function UploadPpiDocument() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation("loan-form");
  const { t: t1 } = useTranslation("sales-funnel");
  const queryParameters = new URLSearchParams(window.location.search);
  const loan_id: any = queryParameters.get("loanId");
  const selling_condition: any = queryParameters.get("condition");
  const country = storage.getCountry();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [fetchPpiDocuments, setFetchPpiDocuments] = useState<IPPIResponse>();

  const { isLoading: fetchPPILoading } = useQuery({
    queryKey: ["fetch_ppi_documents", loan_id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: loan_id,
      });
      return client.get(`/v1/origination/ppi/${loan_id}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setFetchPpiDocuments(parsedResponse);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleSubmitPPIRequest = async (values: IValues) => {
    setLoading(true);

    const allPayload = {
      loan_id: loan_id,
      inspection_report_url: values?.ppis_document_upload
        ? values?.ppis_document_upload[0]?.response.file.url
        : "",
      vin: values?.vin,
      country: country,
      type: "warranty",
      password: values?.password,
    };

    const vinPayload = {
      loan_id: loan_id,
      vin: values?.vin,
      country: country,
      type: "warranty",
      inspection_report_url: "",
    };

    const payload = selling_condition === "new" ? vinPayload : allPayload;

    try {
      const response = await client.post(`/v1/origination/ppi/upload`, payload);
      if (response) {
        message.success("PPI Document submitted successfuly");
        queryClient.invalidateQueries(["fetch_ppi_documents"]);
        queryClient.invalidateQueries(["loans_by_id"]);
        setLoading(false);
        form.resetFields();
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  if (fetchPPILoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <main className="flex flex-col gap-5">
      <div className="relative mb-5">
        <div className="text-2xl font-bold text-center text-[#30345e]">
          {t1("PPI Request")}
        </div>
        <button
          onClick={() => navigate(-1)}
          className="absolute left-0 top-[4px] cursor-pointer"
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
        </button>
      </div>

      {Object.keys(fetchPpiDocuments || {})?.length === 0 && (
        <div className="w-full lg:w-[45%] h-auto pb-0 bg-white my-0 mx-auto rounded-lg">
          <div className="px-4 lg:px-6 pt-6 pb-4">
            <EquityPaymentContainer>
              <Form
                name="valuation-proof-upload-form"
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={handleSubmitPPIRequest}
                autoComplete="off"
              >
                {selling_condition === "new" ? (
                  <Form.Item
                    label={t1("car-vin")}
                    name="vin"
                    rules={[
                      {
                        required: true,
                        message: t1("please-enter-car-vin"),
                      },
                    ]}
                  >
                    <Input className="ant-input-custom" />
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item label="">
                      <DocFileUploader
                        title={t1("upload-ppi-document")}
                        messageLabel={t1("please-upload-ppi-document")}
                        name="ppis_document_upload"
                        required={true}
                      />
                    </Form.Item>

                    <Form.Item label={t1("password-optional")} name="password">
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label={t1("car-vin")}
                      name="vin"
                      rules={[
                        {
                          required: true,
                          message: t1("please-enter-car-vin"),
                        },
                      ]}
                    >
                      <Input className="ant-input-custom" />
                    </Form.Item>
                  </>
                )}

                <Form.Item>
                  <div className="button-container w-[50%] my-0 mx-auto">
                    <Button
                      className="submit-button mt-6"
                      type="primary"
                      htmlType="submit"
                    >
                      {loading ? <Loader variant={"secondary"} /> : t("Submit")}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </EquityPaymentContainer>
          </div>
        </div>
      )}

      <div className="w-full pb-8 lg:w-[45%] min-h-[300px] bg-white my-0 mx-auto rounded-lg">
        <TextContainer
          className="px-4 lg:px-6 pt-6 pb-4 border-b border-solid border-[#E5E7EB]"
          style={{ marginBottom: "0px" }}
        >
          <div className="top-text">{t1("request-history")}</div>
        </TextContainer>

        {Object.keys(fetchPpiDocuments || {})?.length > 0 ? (
          <div className="border border-solid border-[#E5E7EB] rounded-md flex flex-col gap-3 p-4 mx-4 lg:mx-8 my-6">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-start gap-2">
                <img
                  src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/check-circle.svg"
                  alt="check"
                  className="mt-1"
                />
                <div>
                  <div className="text-base text-[#6B7280] font-semibold">
                    {t1("ppi-documents-received")}
                  </div>
                  <div className="flex gap-4">
                    <div className="text-base text-[#6B7280] font-semibold">
                      {fetchPpiDocuments?.createdAt
                        ? moment(fetchPpiDocuments?.createdAt).format("LLLL")
                        : "---"}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <a
                href={fetchPpiDocuments?.inspectionReportUrl}
                target="_blank"
                rel="noreferrer"
              >
                <ViewButton
                  disabled={selling_condition === "new"}
                  className="px-5"
                >
                  {t1("View")}
                </ViewButton>
              </a>
            </div>
          </div>
        ) : (
          <div className="w-full my-0 mx-auto pt-10 pb-3">
            <div className="flex flex-col items-center w-full">
              <div>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                  alt="caution"
                />
              </div>
              <div className="text-sm lg:text-lg text-[#6b7280]">
                {t1("no-document-available")}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
