import React, { useState } from "react";
import { Tabs, List, Spin } from "antd";
import type { TabsProps } from "antd";
import { HistoryTabContainer } from "@/layouts/application-layouts/styles";
import styled from "styled-components";
import { HistoryPointCardArrowIcon } from "@/assets/svgs";
import Leaderboard from "@/components/refix/Leaderboard";
import HistoryPerformace from "@/components/refix/HistoryPerformace";
import { useQuery } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import {
  IDealerHistory,
  IDealerLeaderboard,
  IDealerLeaderboardResponse,
  IDealerperformance,
} from "@/interface/dealer";
import { useDealerBenefits } from "@/states/dealer";
import { useTranslation } from "react-i18next";

const levelMedals = {
  silver:
    "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals.png",
  bronze:
    "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png",
  gold: "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-1.png",
  platinum:
    "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-2.png",
};

type TMedal = "silver" | "bronze" | "gold" | "platinum";
const CustomList = styled.div`
  .ant-list-split .ant-list-item {
    border-block-end: none;
  }
  .ant-list-split.ant-list-something-after-last-item
    .ant-spin-container
    > .ant-list-items
    > .ant-list-item:last-child {
    border-block-end: none;
  }
  .ant-list .ant-list-item {
    padding: 10px 0;
  }
  .ant-card-small > .ant-card-body {
    padding: 12px 12px;
  }
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    background: #ffb619;
    border-radius: 44px;
    border: 0;
    padding: 0px 16px;
    font-weight: 600;
  }
`;

const DealerHistoryView = ({
  dealerType,
  dealerName,
  closeModal,
}: {
  dealerType: string;
  dealerName: string;
  closeModal?: () => void;
}) => {
  const { t } = useTranslation("dealer-gamification");

  const [historyTabValue, setHistoryTabValue] = useState("1");
  const [historyData, setHistoryData] = useState<IDealerHistory>();
  const [leaderBoard, setLeaderBoard] = useState<IDealerLeaderboard[]>();
  const [dealerPerformance, setDealerPerformance] =
    useState<IDealerperformance>();

  const handleHistoryTabChange = (key: string) => {
    setHistoryTabValue(key);
  };

  const user = storage.getUser();

  const { isLoading: historyLoading } = useQuery({
    queryKey: ["fetch_dealer_history"],
    queryFn: () => {
      const franchiseId = user.company.id;

      return client.get(`/v1/franchise/${franchiseId}/dealer-tiers/history`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerHistory = JSON.parse(res?.data);
      setHistoryData(parsedResponseData);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: leaderBoardLoading } = useQuery({
    queryKey: ["fetch_dealer_leaderboard"],
    queryFn: () => {
      return client.get(`/v1/franchise/dealer-tiers/leaderboard`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerLeaderboardResponse = JSON.parse(
        res?.data
      );
      setLeaderBoard(parsedResponseData.data);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: dealerPerfLoading } = useQuery({
    queryKey: ["fetch_dealer_performance"],
    queryFn: () => {
      const franchiseId = user.company.id;

      return client.get(
        `/v1/franchise/${franchiseId}/dealer-tiers/performance`
      );
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerperformance = JSON.parse(res?.data);
      setDealerPerformance(parsedResponseData);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const dealerbenefits = useDealerBenefits().benefits;

  const allBenefitNames = dealerbenefits.map((_bn) => _bn.name);
  const dealerLevel = allBenefitNames.filter((dealer) =>
    dealer?.toLowerCase().includes(dealerType?.toLowerCase())
  );

  const historyItems: TabsProps["items"] = [
    {
      key: "1",
      label: t("points-earned"),
      children: (
        <HistoryList
          history={historyData!}
          isLoading={historyLoading}
          dealerLevel={dealerLevel}
          dealerType={dealerType}
        />
      ),
    },
    {
      key: "2",
      label: t("leaderboard"),
      children: (
        <Leaderboard
          leaderBoardLoading={leaderBoardLoading}
          leaderBoard={leaderBoard!}
          dealerName={dealerName}
          closeModal={closeModal ? closeModal : () => {}}
        />
      ),
    },
    {
      key: "3",
      label: t("performance"),
      children: (
        <HistoryPerformace
          performanceData={dealerPerformance!}
          isLoading={dealerPerfLoading}
        />
      ),
    },
  ];
  return (
    <div className="h-[85vh]">
      <div className="pb-3">
        <p className="text-[#30345E] text-xl font-semibold ">
          {t("your-history")}
        </p>
      </div>

      <HistoryTabContainer>
        <div className="bg-white rounded-br-lg rounded-bl-lg">
          <Tabs
            defaultActiveKey={historyTabValue}
            items={historyItems}
            onChange={handleHistoryTabChange}
            className="h-[70vh]"
          />
        </div>
      </HistoryTabContainer>
    </div>
  );
};

const HistoryList = ({
  history,
  isLoading,
  dealerLevel,
  dealerType,
}: {
  history: IDealerHistory;
  isLoading: boolean;
  dealerLevel: string[];
  dealerType: string;
}) => {
  const totalPoints = history?.history.reduce(
    (ac, currentVal) => ac + currentVal.point,
    0
  );
  return (
    <CustomList className="overflow-scroll h-[70vh]">
      <List
        bordered={false}
        dataSource={history?.history ?? []}
        renderItem={(item, index) => (
          <HistoryListItem historyItem={item} isLoading={isLoading} />
        )}
      />
      {/* <TransitionPointLevelCard /> */}
      <SinglePointLevelCard
        totalPoints={totalPoints}
        dealerLevel={dealerLevel[0] ?? ""}
        dealerType={dealerType}
      />
    </CustomList>
  );
};

const HistoryListItem = ({
  historyItem,
  isLoading,
}: {
  historyItem: {
    sku: string;
    date: string;
    point: number;
    activityType: string;
  };
  isLoading: boolean;
}) => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <div className="flex justify-between items-center py-5 border-b border-[#E5E7EB]">
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {" "}
          <div className="flex flex-col">
            <div className="flex space-x-2 items-center ">
              <PointTitle
                text={historyItem.activityType}
                colorCode="bg-[#FFC852]"
              />
              <div>
                <span className="text-[#9CA3AF] font-medium text-[0.825rem]">
                  {new Date(historyItem.date).toLocaleDateString()}
                </span>
              </div>
            </div>
            <p className="text-sm pt-1 text-[#6B7280]">
              {historyItem.activityType === "Sale"
                ? `${t("you-sold")}: ${historyItem.sku}`
                : `${t("you-referred-a-customer")}: ${historyItem.sku}`}
            </p>
          </div>
          <div>
            <span
              className={`text-[#22C55E] text-sm font-bold tracking-[0.0625rem] uppercase`}
            >
              {historyItem.point} pts
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const PointTitle = ({
  text,
  colorCode,
}: {
  text: string;
  colorCode: string;
}) => {
  const formatText = () => {
    if (text.toLowerCase() == "application") {
      return {
        text: "loan application",
        color: "bg-[#FFC852]",
      };
    }
    if (text.toLowerCase() == "sale") {
      return {
        text: "car sold",
        color: "bg-[#BFDBFE]",
      };
    }
    return {
      text: text,
      color: "bg-[#FFC852]",
    };
  };
  const activityText = formatText().text;
  const activityColor = formatText().color;
  return (
    <div
      className={`w-fit px-2 h-7 flex items-center justify-center ${activityColor} rounded`}
    >
      <span className="uppercase font-semibold text-[#30345E] text-xs tracking-[-0.01563rem]">
        {activityText}
      </span>
    </div>
  );
};

const TransitionPointLevelCard = () => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <div className="w-full py-5 bg-[#30345E] rounded-lg px-4 ">
      <div className="flex items-center justify-between h-full max-w-lg mx-auto">
        <PointLevelEarned totalPoints={0} dealerLevel="" dealerType="" />
        <div className="flex flex-col justify-center items-center px-2">
          <span className=" text-[0.625rem] leading-3 uppercase text-white ">
            {t("up-next")}
          </span>
          <HistoryPointCardArrowIcon />
        </div>
        {/*  */}
        <PointLevelEarned totalPoints={0} dealerLevel="" dealerType="" />
      </div>
    </div>
  );
};

const SinglePointLevelCard = ({
  totalPoints,
  dealerLevel,
  dealerType,
}: {
  totalPoints: number;
  dealerLevel: string;
  dealerType: string;
}) => {
  return (
    <div className="w-full py-5 bg-[#30345E] rounded-lg px-4">
      <div className="flex items-center justify-center h-full">
        <PointLevelEarned
          totalPoints={totalPoints}
          dealerLevel={dealerLevel}
          dealerType={dealerType}
        />
      </div>
    </div>
  );
};

const PointLevelEarned = ({
  totalPoints,
  dealerLevel,
  dealerType,
}: {
  totalPoints: number;
  dealerLevel: string;
  dealerType: string;
}) => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <div className="flex flex-col items-center">
      <p className="text-[#EEEFFF] text-xs text-center uppercase">
        {totalPoints} {t("points-earned")}
      </p>
      <div className="pt-1">
        <img
          src={levelMedals[dealerType?.toLowerCase() as TMedal]}
          alt="level-medal"
          className="w-10 h-10"
        />
      </div>
      <p className="block md:flex text-[#EEEFFF] text-sm tracking-[0.0625rem] font-bold leading-6 text-center">
        {dealerLevel} {t("unlocked")}
      </p>
    </div>
  );
};

export default DealerHistoryView;
