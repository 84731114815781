import React, { Fragment, useState, useEffect } from "react";
import InputControl from "@/components/formElements/input-control";
import { Input } from "@/components/formElements/input-group";
import { BODY_TYPES, VEHICLE_MAKES, VEHICLE_MODELS } from "@/utils/helpers";
import { useForm } from "react-hook-form";
import { bodyType, carMake, carModel } from "@/hooks/use-loan";
import storage from "@/lib/storage";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

interface INewCarCollateral {
  submit: any;
  productCarField: any;
  setAlert: React.Dispatch<React.SetStateAction<string>>;
  setCarFieldsArr: React.Dispatch<React.SetStateAction<any>>;
}

export default function AddNewCollateral({
  submit,
  productCarField,
  setAlert,
  setCarFieldsArr,
}: Readonly<INewCarCollateral>) {
  const methods = useForm();
  const { control } = methods;
  const country = storage.getCountry();
  const { t: t1 } = useTranslation("inventory");

  //states
  const [loanCarField, setLoanCarField] = useState<any>({});
  const [carMakes, setCarMakes]: any[] = useState([]);
  const [carModels, setCarModels]: any[] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [showCarFields, setShowCarFields] = useState(false);

  useEffect(() => {
    const carMakes = carMake(country, 1000);
    carMakes.then((result) => setCarMakes(JSON.parse(result?.data).makeList));
  }, [country]);

  useEffect(() => {
    const bodyTypes = bodyType(1000);
    bodyTypes.then((result) =>
      setBodyTypes(JSON.parse(result?.data).bodyTypeList)
    );
  }, []);

  useEffect(() => {
    const vehicleModelId = {
      name: "vehicleModelId",
      value: `${
        carModels?.vehicleModel?.find(
          (model: any) => model?.name === loanCarField?.vehicleModel?.value
        )?.id || ""
      }`,
      type: "number",
      valid: true,
    };

    let data: any = {
      vehicleModelId,
    };

    setLoanCarField((state: any) => ({
      ...state,
      ...data,
    }));
  }, [carModels?.vehicleModel, loanCarField?.vehicleModel?.value]);

  const handleRenderOptions = (item: Record<string, any>) => {
    if (VEHICLE_MAKES?.includes(item?.name)) {
      return carMakes?.map((x: any) => ({
        label: x?.name,
        value: `${x?.id}`,
      }));
    } else if (VEHICLE_MODELS?.includes(item?.name)) {
      return carModels[item.name]?.map((x: any) => ({
        label: x?.name,
        value: `${x.name}`,
      }));
    } else if (BODY_TYPES.includes(item.name)) {
      return bodyTypes;
    } else if (item.options?.length) {
      return item.options;
    }
    return [];
  };

  const getCarModels = (vehicleMake: string, fieldName: string) => {
    if (vehicleMake) {
      const carModels = carModel(country, vehicleMake, 1000);
      carModels.then((result) =>
        setCarModels((state: any) => ({
          ...state,
          [fieldName]: JSON.parse(result?.data).modelList,
        }))
      );
    } else {
      return [];
    }
  };

  const handleCarFieldsChange = async (
    e: any,
    item?: any,
    initialType?: string
  ) => {
    const { name, value, type, label } = e.target;
    const valueObject = {
      name,
      value:
        name === "vehicleMake"
          ? carMakes?.find((item: any) => item?.id === Number(value))?.name
          : value,
      type: initialType ?? type,
      label: item?.label ?? label,
      valid: true,
    };

    if (name.includes("vehicleMake")) {
      const vehicleMakeField = productCarField.find(
        (item: any) => item.name === name
      );
      const currentIndex = productCarField.indexOf(vehicleMakeField);
      const nextFieldName = productCarField[currentIndex + 1].name;
      getCarModels(value, nextFieldName);
    }

    setLoanCarField((state: {}) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  const resetFormFields = () => {
    const ownVehicle = {
      name: "ownVehicle",
      value: undefined,
      label: `Do you currently own the vehicle or are you planning to buy the vehicle?`,
      valid: true,
    };
    const isImportedVehicle = {
      name: "isImportedVehicle",
      value: undefined,
      label: `Is the vehicle you are pledging imported or not`,
      valid: true,
    };
    const vehicleMake = {
      name: "vehicleMake",
      value: undefined,
      type: "selection",
      label: "Vehicle Make",
      valid: true,
    };
    const vehicleModel = {
      name: "vehicleModel",
      value: undefined,
      type: "selection",
      label: "Vehicle model",
      valid: true,
    };
    const vehicleYearModel = {
      name: "vehicleYearModel",
      value: "",
      type: "number",
      label: "Vehicle year model",
      valid: true,
    };
    const sellingCondition = {
      name: "sellingCondition",
      value: undefined,
      type: "selection",
      label: "What is the selling condition ?",
      valid: true,
    };
    const bodyType = {
      name: "bodyType",
      value: undefined,
      type: "selection",
      label: "Body type",
      valid: true,
    };
    const vehicleUse = {
      name: "vehicleUse",
      value: undefined,
      type: "selection",
      label: "Vehicle use",
      valid: true,
    };
    const carWorth = {
      name: "carWorth",
      value: "",
      type: "amount",
      label: "How much do you believe your car is worth?",
      valid: true,
    };
    const spareKey = {
      name: "spareKey",
      value: "",
      type: "radio",
      label: "Do you have a spare key?",
      valid: true,
    };
    const engineCapacity = {
      name: "engineCapacity",
      value: undefined,
      type: "selection",
      label: "Engine capacity",
      valid: true,
    };
    const vehicleVin = {
      name: "vehicleVin",
      value: "",
      type: "text",
      label: "Vehicle Vin",
      valid: true,
    };

    let data: any = {
      ownVehicle,
      isImportedVehicle,
      vehicleMake,
      vehicleModel,
      vehicleYearModel,
      sellingCondition,
      bodyType,
      vehicleUse,
      carWorth,
      spareKey,
      engineCapacity,
      vehicleVin,
    };

    setLoanCarField((state: any) => ({
      ...state,
      ...data,
    }));
  };

  useEffect(() => {
    resetFormFields();
  }, []);

  const handleSubmitCarFields = () => {
    const requiredFields = productCarField.filter(
      (item: { required: any }) => item.required
    );

    const isAnyRequiredFieldMissingValue: boolean = requiredFields.some(
      (item: { name: string | number }) => {
        return !loanCarField[item.name]?.value;
      }
    );

    if (isAnyRequiredFieldMissingValue) {
      setAlert("Please fill in all required fields.");
      return;
    }

    setCarFieldsArr((prevDataArray: any) => [
      ...prevDataArray,
      { ...loanCarField },
    ]);

    resetFormFields();
    setShowCarFields(false);
  };

  const handleCloseCarFields = () => {
    resetFormFields();
    setShowCarFields(false);
  };

  return (
    <main>
      <div className="grid grid-col-1 md:grid-cols-2 gap-4 mt-8">
        {showCarFields && (
          <Fragment>
            {productCarField?.map(
              (item: any, index: any) =>
                item.name &&
                item.visibility &&
                !item.name.startsWith("vehicleModelId") && (
                  <InputControl
                    key={item.name}
                    control={control}
                    name={item.name}
                  >
                    {() =>
                      item?.visibility && (
                        <Input
                          formData={loanCarField}
                          key={item.name}
                          onBlur={submit}
                          required={item.required}
                          type={
                            item.type === "fileSelection"
                              ? "selection"
                              : item.type
                          }
                          label={item.label}
                          placeholder={item.label}
                          fileType={item.fileType}
                          name={item.name}
                          when={item.when}
                          options={handleRenderOptions(item)}
                          onChange={(e: any) =>
                            handleCarFieldsChange(e, item, item.type)
                          }
                          value={loanCarField[item.name]?.value}
                          selectOptions={
                            item.options?.length ? item.options : []
                          }
                          visibility={true}
                          innerVisibility={true}
                          fullWidth={item.fullWidth}
                          autoComplete={true}
                          subsection={null}
                          hideSubsection
                          addDirector={null}
                          directorCount={null}
                        />
                      )
                    }
                  </InputControl>
                )
            )}
          </Fragment>
        )}

        <div className="absolute top-0 right-0 pr-6">
          <button
            onClick={() => {
              setShowCarFields(!showCarFields);
            }}
            className="font-bold text-[#7F83A8] text-base cursor-pointer border border-solid border-[#7F83A8] px-3 py-1.5 rounded-md"
          >
            Add New Car
          </button>
        </div>
      </div>

      {showCarFields && (
        <div className="flex gap-4">
          <LoadingButton
            variant="contained"
            color="primary"
            style={{ marginTop: "36px" }}
            sx={{
              width: "151px",
              height: "43px",
            }}
            onClick={() => {
              handleSubmitCarFields();
            }}
          >
            {t1("Add Car")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            style={{ marginTop: "36px" }}
            sx={{
              width: "151px",
              height: "43px",
            }}
            onClick={handleCloseCarFields}
          >
            {t1("Close")}
          </LoadingButton>
        </div>
      )}
    </main>
  );
}
