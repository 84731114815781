import styled from "styled-components";
import { device } from "@/utils/device";
import { useTranslation } from "react-i18next";

const UnqualifiedContainer = styled.div`
  width: 100%;
  /* min-height: 400px; */
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media ${device.mobileL} {
    margin-top: 24px;
  }
  .content {
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 14px;
    @media ${device.mobileL} {
      width: 100%;
    }
    img {
      margin: 0 auto;
    }
    .heading {
      color: #30345e;
      font-weight: 600;
      font-size: 20px;
      @media ${device.mobileL} {
        font-size: 18px;
        margin-bottom: 14px;
      }
    }
    .sub-heading {
      color: #6b7280;
      font-weight: 400;
      font-size: 14px;
    }
    .number-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .number {
        color: #485164;
        font-weight: 600;
        font-size: 24px;
        @media ${device.mobileL} {
          font-size: 20px;
        }
      }
    }
  }
`;

const ReferredOfferLayout = ({ loanDetails }: any) => {
  const { t } = useTranslation("application");

  return (
    <UnqualifiedContainer>
      <div className="content">
        <div>
          <img
            src="https://media.autochek.africa/file/publicAssets/Frame.svg"
            alt="caution"
          />
        </div>
        <div>
          <div className="heading">{t("offer-referred")}</div>
          <div className="sub-heading">
            {t(loanDetails?.reasonForDeclinationDescription) ||
              t("your-application-referred")}
          </div>
        </div>
      </div>
    </UnqualifiedContainer>
  );
};

export default ReferredOfferLayout;
