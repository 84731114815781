import { BsArrowLeft } from "react-icons/bs";
import styled from "styled-components";
import Cards from "@/components/cards";
import { device } from "@/utils/device";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getLoanStatistics } from "@/hooks/use-loan";
import storage from "@/lib/storage";
import {
  ApplicationLayout,
  AppTabContainer,
  Header,
} from "@/layouts/application-layouts/styles";
import { Tabs, TabsProps } from "antd";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import client from "@/lib/axios-client";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import TotalCommissionLayout from "@/layouts/application-layouts/TotalCommission";
import { useTranslation } from "react-i18next";
import formatCurrency from "@u/formatCurrency";

const TextContainer = styled.div`
  display: flex;
  align-items: center;

  .top-text {
    font-size: 1.2rem;
    color: #30345e;
    font-weight: bolder;
    margin-left: 1rem;
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
  color: #30345e;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TotalCommission = () => {
  const [loanStatistics, setLoanStatistics]: any = useState({});
  const [tabValue, setTabValue] = useState("1");
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [activePage, setActivePage] = useState<any>({
    currentPage: 1,
    pageSize: 10,
  });
  const [startDate, setStartDate] = useState<string | any>("");
  const [endDate, setEndDate] = useState<string | any>("");
  const [startMobileDate, setStartMobileDate] = useState<string | any>("");
  const [endMobileDate, setEndMobileDate] = useState<string | any>("");
  const [fetchFilteredLoans, setFetchFilteredAllLoans]: any = useState([]);
  const [statsLoading, setStatsLoading] = useState(false);
  const { search } = useLocation();
  const user = storage.getUser();
  const country = storage.getCountry();
  const role = storage.getRole();
  const query: any = queryString.parse(search);
  const { t } = useTranslation("dashboard");
  const { t: t1 } = useTranslation("component");

  useEffect(() => {
    document.title = `Dealer Plus | Total Commission [${role}]`;
  }, [role]);

  const { isLoading: filteredLoanLoading } = useQuery({
    queryKey: [
      "total_commision_loans",
      country,
      query,
      pageNumber,
      activePage,
      user,
      tabValue,
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        user_id: user?.id,
        current_page: activePage.currentPage || pageNumber,
        page_size: activePage.pageSize,
        from_date: startDate || startMobileDate,
        to_date: endDate || endMobileDate,
        source: query?.role ? "FRANCHISE_ADMIN" : "",
        source_data: query?.role ? query?.dealerName : "",
        has_commission: true,
        order: "DESC",
        sort_by: "createdAt",
        status: tabValue === "1" ? "DISBURSED" : "IN_PROGRESS",
        ...query,
      });
      return client.get(`/v1/origination/dig/loans`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setFetchFilteredAllLoans(parsedLoans);
    },
  });

  useEffect(() => {
    setStatsLoading(true);
    const getStatistics = getLoanStatistics(user?.id, country);
    getStatistics.then((result: any) => {
      setLoanStatistics(JSON.parse(result?.data));
      setStatsLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const handleTabChange = (key: string) => {
    setTabValue(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`earned`),
      children: (
        <TotalCommissionLayout
          activePage={activePage}
          setActivePage={setActivePage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startMobileDate={startMobileDate}
          setStartMobileDate={setStartMobileDate}
          endMobileDate={endMobileDate}
          setEndMobileDate={setEndMobileDate}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          tabValue={tabValue}
        />
      ),
    },
    {
      key: "2",
      label: t(`in-progress`),
      children: (
        <TotalCommissionLayout
          activePage={activePage}
          setActivePage={setActivePage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startMobileDate={startMobileDate}
          setStartMobileDate={setStartMobileDate}
          endMobileDate={endMobileDate}
          setEndMobileDate={setEndMobileDate}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          tabValue={tabValue}
        />
      ),
    },
  ];

  return (
    <div>
      <Link to="/dashboard">
        <TextContainer>
          <BsArrowLeft />
          <div className="top-text">{t1("total-commissions-dic")}</div>
        </TextContainer>
      </Link>
      <CardsContainer>
        <Cards
          cardHeader={t1("earned")}
          leftText={t1("commissions-cleared")}
          leftValue={
            statsLoading
              ? "---"
              : loanStatistics?.totalCommissionEarned?.number || 0
          }
          rightText={`${t1("commissions-earned")} (${getCountryCurrency(
            country,
          )})`}
          rightValue={
            statsLoading
              ? "---"
              : formatCurrency(
                  +loanStatistics?.totalCommissionEarned?.value || 0,
                )
          }
          color="#22C55E"
        />
        <Cards
          cardHeader={t1("total-commissions")}
          leftText={t1("commissions-in-progress")}
          leftValue={
            statsLoading
              ? "---"
              : loanStatistics?.totalCommissionBeingProcessed?.number || 0
          }
          rightText={`${t1("expected-value")} (${getCountryCurrency(country)})`}
          rightValue={
            statsLoading
              ? "---"
              : formatCurrency(
                  +loanStatistics?.totalCommissionBeingProcessed?.value || 0,
                )
          }
          color="#FFB619"
        />
      </CardsContainer>
      <ApplicationLayout>
        <Header style={{ fontWeight: "600" }}>{t("loans")}</Header>
        <AppTabContainer>
          <Tabs
            defaultActiveKey={tabValue}
            items={items}
            onChange={handleTabChange}
          />
        </AppTabContainer>
      </ApplicationLayout>
    </div>
  );
};

export default TotalCommission;
