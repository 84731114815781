import { Divider, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { NumericFormat } from 'react-number-format';

type Props = {
  value: number[],
  onChange: (value: number[]) => void,
  monthlyAdornment?: boolean,
  startAdornment?: string,
  endAdornment?: string,
  thousandSeparator?: string | undefined,
}

const RangeInput = ({ value, onChange, startAdornment, endAdornment, monthlyAdornment = false, thousandSeparator }: any) => {
  const [input1, setInput1] = React.useState(value[0])
  const [input2, setInput2] = React.useState(value[1])

  React.useEffect(() => {
    onChange([input1, input2])
  }, [input1, input2, onChange])

  return (
    <Stack
      direction='row' alignItems='center' sx={{
        '.startAdornment': {
          '.MuiOutlinedInput-input': {
            paddingLeft: '2px'
          }
        },
        '.endAdornment': {
          '.MuiOutlinedInput-input': {
            paddingRight: '2px'
          }
        }

      }}
    >
      <NumericFormat
        defaultValue={"0"}
        placeholder={`${startAdornment ? `${startAdornment}  ` : ''}0${endAdornment ? `  ${endAdornment}  ` : ''}`}
        value={value[0]}
        thousandSeparator={thousandSeparator}
        allowNegative={false}
        onValueChange={(values) => setInput1(parseInt(values.value))}
        customInput={TextField}
        prefix={startAdornment ? `${startAdornment}  ` : ''}
        suffix={endAdornment ? `  ${endAdornment}` : ''}
      />
      <Box><Divider orientation='horizontal' variant='middle' sx={{ borderWidth: '1.5px', backgroundColor: 'primary.dark', borderColor: 'primary.dark', width: '15px' }} /></Box>
      <NumericFormat
        defaultValue={"0"}
        placeholder={`${startAdornment ? `${startAdornment}  ` : ''}0${endAdornment ? `  ${endAdornment}  ` : ''}`}
        value={value[1]}
        thousandSeparator={thousandSeparator}
        allowNegative={false}
        onValueChange={(values) => setInput2(parseInt(values.value))}
        customInput={TextField}
        prefix={startAdornment ? `${startAdornment}  ` : ''}
        suffix={endAdornment ? `  ${endAdornment}` : ''}
      />
    </Stack>
  )
}

export default RangeInput
