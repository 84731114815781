import { device } from "@/utils/device";
import { Config } from "@crudmates/form-config";
import { Progress } from "antd";
import { UseFormReturn } from "react-hook-form";
import styled from "styled-components";

const ProgressWrapper = styled.div`
  .ant-progress-inner {
    height: 9px !important;
    @media ${device.mobileL} {
      height: 0.1875rem !important;
    }
  }
`;

type Props = {
  isFranchise: boolean;
  config: Config;
  methods: UseFormReturn<any, any>;
  evaluateForm: () => string;
};

const ListingScoreProgress: React.FC<Props> = ({
  evaluateForm,
  isFranchise,
}) => {
  if (!isFranchise) return null;

  const listingScore = evaluateForm();

  return (
    <div className="fixed bottom-0 left-0 right-0 w-full z-10 bg-white pb-20 md:pb-5 flex flex-col gap-1">
      <ProgressWrapper>
        <Progress
          percent={(parseFloat(listingScore) / 5.0) * 100}
          trailColor="#C8C9D3"
          strokeColor="#FFB619"
          strokeLinecap="butt"
          showInfo={false}
        />
      </ProgressWrapper>
      <div>
        <p className="text-xl text-[#30345E] font-semibold text-center">
          Listing score: {Number(listingScore).toFixed(1)}
        </p>
        <p className="text-sm text-[#474747] font-normal text-center">
          This score describes how visible you car would be once you make the
          car is listed on the marketplace
        </p>
      </div>
    </div>
  );
};

export default ListingScoreProgress;
