import styled from "styled-components";
import { device } from "@/utils/device";
import { useNavigate } from "react-router-dom";
import FormButton from "@/components/formElements/button";
import storage from "@/lib/storage";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ConfirmationContainer = styled.div`
  padding: 2rem 0 2rem 0;
  font-family: "Outfit";

  @media ${device.tablet} {
    padding: 1rem 0 1rem 0;
  }

  .container {
    width: 40%;
    background: #fff;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;

    @media ${device.tablet} {
      width: 90%;
    }

    .success-image {
      width: 60%;
    }

    .thanks {
      font-size: 1.2rem;
      font-weight: 900;
      color: #31355b;
    }

    .offer-text {
      text-align: center;
      color: #6b7280;
      line-height: 24px;
      margin-bottom: 1rem;
    }
  }
`;

const Confirmation = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const loanId: any = queryParameters.get("loan_id");
  const role = storage.getRole();
  const { t } = useTranslation("application");

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  const toLoanDetails = () => {
    navigate(`/applications/loans/${loanId}`);
  };
  return (
    <div>
      <ConfirmationContainer>
        <div className="container">
          <img
            className="success-image"
            src="https://storage.googleapis.com/img.autochek.africa/f9ac8f39-6cd6-41cc-ba2a-f338266aa8ac-success.png"
            alt=""
          />
          <h2 className="thanks">{t("thank-you")}</h2>
          <div className="offer-text">
            {t("we-have-received-this-application")} <br />{" "}
            {t("an-offer-will-be-provided-within-24-hours")}
          </div>
          <FormButton
            type="primary"
            shape="round"
            text={t("track-your-application")}
            background="#FFB619"
            color="#30345E"
            hoverBackground="#d89300"
            hoverColor="#30345E"
            onClick={() => toLoanDetails()}
          />
        </div>
      </ConfirmationContainer>
    </div>
  );
};

export default Confirmation;
