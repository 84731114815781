import NigeriaI from "../layouts/application-layouts/Assets/Nigeria.svg";
import KenyaI from "../layouts/application-layouts/Assets/Kenya.svg";
import GhanaI from "../layouts/application-layouts/Assets/Ghana.svg";
import IvoryCoastI from "../layouts/application-layouts/Assets/CIV.svg";
import UgandaI from "../layouts/application-layouts/Assets/Uganda.svg";
import SenegalI from "../layouts/application-layouts/Assets/Senegal.svg";
import EgyptI from "../layouts/application-layouts/Assets/Egypt.svg";

export const getCountryFlag = (code: string) => {
  if (code === "NG") {
    return NigeriaI;
  } else if (code === "KE") {
    return KenyaI;
  } else if (code === "GH") {
    return GhanaI;
  } else if (code === "UG") {
    return UgandaI;
  } else if (code === "CI") {
    return IvoryCoastI;
  } else if (code === "SN") {
    return SenegalI;
  } else if (code === "EG") {
    return EgyptI;
  }
};
