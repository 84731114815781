import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextContainer,
  ViewButton,
  TableContainer,
  EquityPaymentContainer,
} from "@/layouts/application-layouts/styles";
import tw from "tailwind-styled-components";
import { Form, Input, Button, Upload, message, InputNumber } from "antd";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { EquityInputNumber } from "@/pages/auth/styles/sign-in";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import formatInt from "@/utils/format-int";
import moment from "moment";
import { Loader } from "../auth/styles/loader";
import formatCurrency from "@u/formatCurrency";

const Text = tw.div`text-base text-[#6B7280] font-semibold`;

export default function ValuationReport() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const { t } = useTranslation("loan-form");
  const loan_id = (location.state as { loanId: string })?.loanId;
  const [loading, setLoading] = useState(false);
  const [fetchValuations, setFetchValuations] = useState<any>([]);

  const { isLoading: fetchValuationLoading } = useQuery({
    queryKey: ["fetch_valuation_proof", loan_id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: loan_id,
      });
      return client.get(`/v2/origination/${loan_id}/valuations`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setFetchValuations(parsedResponse);
    },
    enabled: !!loan_id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .pdf",
    action: `${API_URL}document/upload`,
    headers: {
      authorization: `bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {},
  };

  const handleSubmitValuation = async (values: any) => {
    setLoading(true);

    const payload = {
      loan_id: loan_id,
      amount: values.amount.replaceAll(",", ""),
      valuation_proof_url:
        values.valuation_document_upload[0].response.file.url,
      valuation_proof_password: values.password,
    };

    try {
      const response = await client.post(`/v2/origination/valuations`, payload);
      if (response) {
        message.success("Valuations added successfully");
        queryClient.invalidateQueries(["fetch_valuation_proof"]);
        queryClient.invalidateQueries(["loans_by_id"]);
        setLoading(false);
        form.resetFields();
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  if (fetchValuationLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <main className="flex flex-col gap-5">
      <div className="relative mb-5">
        <div className="text-2xl font-bold text-center text-[#30345e]">
          Valuation Payment
        </div>
        <button
          onClick={() => navigate(-1)}
          className="absolute left-0 top-[4px]"
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
        </button>
      </div>

      {Object.keys(fetchValuations)?.length === 0 && (
        <div
          className={`w-full lg:w-[45%] h-auto pb-0 bg-white my-0 mx-auto rounded-lg`}
        >
          <div className="px-4 lg:px-6 pt-6 pb-4">
            <EquityPaymentContainer>
              <Form
                name="valuation-proof-upload-form"
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={handleSubmitValuation}
                autoComplete="off"
              >
                <Form.Item label="">
                  <Form.Item
                    name="valuation_document_upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: t("Please upload valuation proof of payment"),
                      },
                    ]}
                  >
                    <Upload.Dragger {...props} name="file" maxCount={1}>
                      <div className="flex gap-4 items-center px-4">
                        <img
                          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Vector%20(Stroke).svg?updatedAt=1711051618638"
                          alt="Upload"
                        />
                        <div className="text-base font-semibold">
                          {t("Upload valuation proof")}
                        </div>
                      </div>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>

                <Form.Item label="Password (optional)" name="password">
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Amount on proof"
                  name="amount"
                  rules={[{ required: true, message: "Please enter amount" }]}
                >
                  <EquityInputNumber>
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value: any) => {
                        return formatCurrency(value);
                      }}
                      keyboard={false}
                      placeholder={"Enter amount"}
                    />
                  </EquityInputNumber>
                </Form.Item>

                <Form.Item>
                  <div className="button-container w-[50%] my-0 mx-auto">
                    <Button
                      className="submit-button mt-6"
                      type="primary"
                      htmlType="submit"
                    >
                      {loading ? <Loader variant={"secondary"} /> : t("Submit")}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </EquityPaymentContainer>
          </div>
        </div>
      )}

      <div className="w-full pb-8 lg:w-[45%] min-h-[300px] bg-white my-0 mx-auto rounded-lg">
        <TextContainer
          className="px-4 lg:px-6 pt-6 pb-4 border-b border-solid border-[#E5E7EB]"
          style={{ marginBottom: "0px" }}
        >
          <div className="top-text">Valuation History</div>
        </TextContainer>

        {Object.keys(fetchValuations)?.length > 0 ? (
          <div className="border border-solid border-[#E5E7EB] rounded-md flex flex-col gap-3 p-4 mx-4 lg:mx-8 my-6">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-start gap-2">
                <img
                  src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/check-circle.svg"
                  alt="check"
                  className="mt-1"
                />
                <div>
                  <Text>Valuation proof received</Text>
                  <div className="flex gap-4">
                    <Text>
                      {fetchValuations?.createdAt
                        ? moment(fetchValuations?.createdAt).format("LLLL")
                        : "---"}{" "}
                    </Text>
                  </div>
                </div>
              </div>
              <a
                href={fetchValuations?.valuationProofUrl}
                target="_blank"
                rel="noreferrer"
              >
                <ViewButton className="px-5">View</ViewButton>
              </a>
            </div>
            <Text>Amount: {formatInt(fetchValuations?.amount, true)}</Text>
          </div>
        ) : (
          <div className="w-full my-0 mx-auto pt-10 pb-3">
            <div className="flex flex-col items-center w-full">
              <div>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                  alt="caution"
                />
              </div>
              <div className="text-sm lg:text-lg text-[#6b7280]">
                No payment history available.
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
