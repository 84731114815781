import React, { useState, useEffect } from "react";
import { Form, message, Input } from "antd";
import { Loader } from "@/pages/auth/styles/loader";
import { postTrackerInfo, updateTrackerInfo } from "@/hooks/use-api";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { ITrackerInfo } from "@/types";

type FieldType = {
  username: string;
  password: string;
  provider: string;
  url?: string;
};

type IInitialForm = "default" | "modal";

interface ITrackerForm {
  trackerData?: ITrackerInfo;
  setOpenTrackerModal: React.Dispatch<React.SetStateAction<boolean>>;
  type: IInitialForm;
}

export default function AddTrackerForm({
  trackerData,
  setOpenTrackerModal,
  type,
}: Readonly<ITrackerForm>) {
  const [form] = Form.useForm();
  const { t } = useTranslation("loan-form");
  const queryParam = new URLSearchParams(window.location.search);
  const carId = queryParam.get("carId");
  const [loading, setLoading] = useState(false);
  const isAssumedUser = storage.getAssumedUser();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (trackerData) {
      form.setFieldsValue({
        username: trackerData?.username,
        provider: trackerData?.provider,
        password: trackerData?.password,
        url: trackerData?.url,
      });
    }
  }, [form, trackerData]);

  const handleSubmitTrackerInformation = async (values: FieldType) => {
    setLoading(true);

    const payload = {
      ...values,
      car_id: carId,
      ...(!trackerData?.id &&
        type === "modal" && {
          allowMultiple: true,
        }),
    };

    try {
      const response = trackerData?.id
        ? await updateTrackerInfo(payload, trackerData?.id)
        : await postTrackerInfo(payload);
      if (response) {
        message.success("Tracker information added successfully");
        form.resetFields();
        queryClient.invalidateQueries(["fetch_tracker"]);
        setLoading(false);

        if (trackerData) setOpenTrackerModal(false);
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Form
        name={`tracker-document-upload-form${trackerData?.id}`}
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmitTrackerInformation}
        autoComplete="off"
        className=""
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2.5">
          <Form.Item<FieldType>
            label="Tracker Username"
            name="username"
            rules={[{ required: true, message: "Please enter your username" }]}
          >
            <Input className="custom-ant-input" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Tracker Provider"
            name="provider"
            rules={[{ required: true, message: "Please enter your provider" }]}
          >
            <Input className="custom-ant-input" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Tracker Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter tracker password",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item<FieldType>
            label="Tracker URL"
            name="url"
            rules={[
              {
                required: true,
                message: "Please enter your tracker url",
              },
            ]}
          >
            <Input className="custom-ant-input" />
          </Form.Item>
        </div>
        <Form.Item>
          <div className="button-container w-[40%] my-0 mx-auto">
            <button
              className="text-xs lg:text-sm mt-4 w-full h-[45px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
              disabled={!!isAssumedUser}
            >
              {loading ? <Loader variant={"secondary"} /> : t("Submit")}
            </button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
