import { createTheme, PaletteColorOptions, Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomPalette {
    custom: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    custom: true;
  }
}

export const createCustomTheme = (mainColor: string): Theme => {
  const { palette } = createTheme();
  const { augmentColor } = palette;

  // Helper function to create a custom color
  const createColor = (mainColor: string) =>
    augmentColor({ color: { main: mainColor } });

  // Create a theme with the custom color
  return createTheme({
    palette: {
      custom: createColor(mainColor),
    },
  });
};
