import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface ISmartMessage {
  status: string;
}

const SmartNotificationLayout = ({ status }: ISmartMessage) => {
  const { t } = useTranslation("email");
  const { t: t1 } = useTranslation("component");

  const smartNotificationMessage = {
    conditionalOffer: {
      icon: "https://media.autochek.africa/file/publicAssets/Frame-36663-1.png",
      header: t1("you-have-received-a-conditional-offer"),
      body: t(
        "Congratulations! We are pleased to inform you that your application has been conditionally approved, subject to document validation. Please take a moment to review our finance offer here. This offer has been tailored to meet your requirements. Please accept our offer by submitting the required documents, if you have not done so already. Once your documents are validated we will convert the conditional offer into a final offer and send you a loan agreement. We appreciate your interest in our services and look forward to assisting you further."
      ),
    },
    finalOffer: {
      icon: "https://media.autochek.africa/file/publicAssets/Frame-36660.png",
      header: t("You have received an unconditional offer"),
      body: t(
        "Congratulations! We are pleased to inform you that your application has been unconditionally approved. Please take a moment to review our final offer here. This offer has been tailored to meet your requirements. We appreciate your interest in our services and look forward to assisting you further."
      ),
    },
    applicationCompleted: {
      icon: "https://media.autochek.africa/file/publicAssets/Frame-36673-P.png",
      header: t("Application Submitted"),
      body: t(
        "Thank you for submitting your application. We have received your application and your application is currently being reviewed. Our team is working diligently to complete the underwriting process. We will promptly notify you as soon as we have made our decision. We appreciate your patience and understanding."
      ),
    },
  };

  const currentMessage = useMemo(() => {
    if (status === "APPLICATION_COMPLETED") {
      return smartNotificationMessage.applicationCompleted;
    } else if (status === "FINAL_OFFER_RECEIVED") {
      return smartNotificationMessage.finalOffer;
    } else if (status === "CONDITIONAL_OFFER_RECEIVED") {
      return smartNotificationMessage.conditionalOffer;
    }
  }, [status, t, t1]);

  return (
    <div className="smart-notification-container">
      <div className="smart-image">
        <img src={currentMessage?.icon} alt="logo-img" />
      </div>
      <div>
        <div className="title">{currentMessage?.header}</div>
        <div className="content">{currentMessage?.body}</div>
      </div>
    </div>
  );
};

export default SmartNotificationLayout;
