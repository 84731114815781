import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Progress } from "antd";
import GetHelpHeader from "@/components/refix/GetHelpHeader";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProgressWrapper = styled.div`
  .ant-progress-inner {
    height: 0.3125rem !important;
    background: #e5e7eb !important;
  }
`;

const DealerLoanDetails = () => {
  const navigate = useNavigate();
  return (
    <Main>
      <div className="flex items-center justify-between bg-white px-[12px] py-[16px] md:p-[16px] rounded-lg border-b-[1px] border-[#e5e7eb]">
        <div
          className="flex items-center gap-2 font-semibold text-[#30345e] text-[16px] md:text-[20px] cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
            alt="arrow-back"
            className="w-8 h-8 cursor-pointer"
          />{" "}
          <div className="name">
            <span className="capitalize tracking-[0.00938rem] font-bold">
              back
            </span>
          </div>
        </div>
      </div>

      <div className="pt-1">
        <div className="bg-white w-full px-4 py-3">
          <div className="flex flex-col md:flex-row md:space-x-4 space-x-0 space-y-4 md:space-y-0">
            <div className="flex-1">
              {/* 1 */}
              <div className="flex justify-between w-full">
                <p className="text-[#6B7280] font-bold text-sm">
                  IMPORT FINANCING
                </p>
                <p className="text-[#6B7280] font-light text-sm">lD: 68876</p>
              </div>
              {/* 2 */}
              <div>
                <p className="text-2xl font-bold text-[#30345E]">$10,000</p>
                <p>
                  <span className="text-[#6B7280] text-sm font-light mr-[2px]">
                    Disbursed:
                  </span>
                  <span className="text-[#30345E] text-sm font-bold">
                    10-Oct-2023
                  </span>
                </p>
              </div>
              <div className="border-t mt-1">
                <div className="grid grid-cols-3 grid-rows-auto pt-1 divide-x">
                  <div className="px-2 py-2">
                    <p className="text-[#6B7280] text-xs">Loan status</p>
                    <p className="text-[#30345E] text-sm tracking-[0.0625rem] font-bold">
                      Due disbursement
                    </p>
                  </div>
                  <div className="px-2 py-2 pl-4">
                    <p className="text-[#6B7280] text-xs">Loan status</p>
                    <p className="text-[#30345E] text-sm tracking-[0.0625rem] font-bold">
                      Due disbursement
                    </p>
                  </div>
                  <div className="px-2 py-2 pl-4">
                    <p className="text-[#6B7280] text-xs">Loan status</p>
                    <p className="text-[#30345E] text-sm tracking-[0.0625rem] font-bold">
                      Due disbursement
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="bg-[#F8FAFC] w-full h-full rounded-lg p-4">
                {/* 1 */}
                <div className="flex justify-between w-full">
                  <p className="text-[#30345E] font-bold text-xl">$3,200</p>
                  <p className="">
                    <span className="bg-[#86EFAC] text-xs text-[#30345E] font-normal border-none rounded-sm p-[0.125rem]">
                      ON TRACK
                    </span>
                  </p>
                </div>
                <p className="text-[#6B7280] font-light text-sm">Due Nov. 29</p>
                <div className="flex flex-col w-full">
                  <ProgressWrapper>
                    <Progress
                      percent={30}
                      size="small"
                      trailColor="#EEEFFF"
                      strokeColor="#FFB619"
                      showInfo={false}
                    />
                  </ProgressWrapper>
                </div>
                <p className="text-[#30345E] tracking-[0.0625rem] text-sm font-bold pt-1">
                  1/3 payments made{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-8 flex flex-col md:flex-row md:space-x-3 space-x-0 space-y-3 md:space-y-0">
        {/* 1 */}
        <div className="flex-1">
          <div className="bg-white border border-[#E5E7EB] rounded-lg">
            <p className="text-xl font-bold text-[#30345E] p-2">Collaterals</p>

            <div className="divide-y">
              {[1, 2, 3].map((_) => {
                return <CollateralItem key={_} />;
              })}
            </div>
          </div>
          <div className="pt-5">
            <GetHelpHeader
              headerText="Dealer Helpdesk hotline"
              name="Oreoluwa Adeniyi"
              number=" 080 5690 3731"
              position="justify-start"
            />
          </div>
        </div>
        {/* 2 */}
        <div className="md:w-1/3 w-full h-full">
          <div className="bg-white border border-[#E5E7EB] rounded-lg w-full">
            <div className="flex justify-between items-center px-2 py-4 border-b">
              <p className="text-xl font-bold text-[#30345E]">
                Payment history
              </p>
              <button className="text-[#30345E] font-bold">View all</button>
            </div>

            <div className="divide-y">
              <PaymentHistoryRow />
              <PaymentHistoryRow />
              <PaymentHistoryRow />

              <div className="py-5 flex justify-center items-center">
                <button className="px-4 py-1 border border-[#30345E] rounded-full">
                  <span className="text-[#30345E] text-sm font-bold capitalize tracking-[0.01563rem]">
                    Download Loan schedule
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const CollateralItem = () => {
  return (
    <div className="flex justify-between w-full items-center p-2">
      <div className="flex">
        <img
          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/car-sample/zy4LS36m.webp?updatedAt=1701419151515"
          alt="car_image"
          className=" w-24 h-20 rounded object-cover "
        />
        <div className="pl-2 py-1">
          <p className="text-base font-bold text-[#30345E]">2017 Mazda 626</p>
          <p className="text-[0.75rem] text-[#6B7280] pt-3">VALUE</p>
          <p className="text-[#30345E] font-bold text-sm">₦23,000,000</p>
        </div>
      </div>
      <div>
        <p className="text-[0.75rem] text-[#6B7280]">RATING</p>
        <p className="text-[#30345E] font-bold text-sm text-right">4.5</p>
      </div>
    </div>
  );
};

const PaymentHistoryRow = () => {
  return (
    <div className="p-2 py-3 flex justify-between items-center last:rounded-bl-lg last:rounded-br-lg">
      <div className="flex pl-2">
        <div className="pr-2">
          <img
            src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/stepIcon.png"
            alt="checked"
            className="w-8 h-8"
          />
        </div>
        <div className="">
          <p className="text-[#6B7280] font-bold text-sm tracking-[0.0625rem]">
            Payment received
          </p>
          <p className="-mt-2">
            <span className="text-xs text-[#6B7280] tracking-[-0.01563rem] pr-4">
              $3,200
            </span>
            <span className="text-xs text-[#6B7280] tracking-[-0.01563rem]">
              23-05-2023
            </span>
          </p>
        </div>
      </div>
      <div>
        <button className="px-4 py-1 border border-[#30345E] rounded-full">
          <span className="text-[#30345E] text-sm font-bold capitalize tracking-[0.01563rem]">
            view
          </span>
        </button>
      </div>
    </div>
  );
};

export default DealerLoanDetails;
