import { ImageLoader } from "@/utils/ImageLoader";
import {
  carDetailsSectionKESchema,
  carDetailsSectionOthersSchema,
  carDetailsSectionSchema,
  carDocumentsSectionSchema,
  carFeaturesSectionSchema,
  carSnapshotsSectionSchema,
  carSummarySectionSchema,
} from "@/validations/create-car.schema";
import { Config, Item, Option, prepare } from "@crudmates/form-config";
import { Data } from "./types";
import storage from "@/lib/storage";

export const getOptions = (field: Item, data: Data): Option[] => {
  let options: Option[] = field?.options ?? [];

  switch (field.name) {
    case "make":
      options = data.makes;
      break;
    case "interiorColor":
    case "exteriorColor":
      options = data.colors;
      break;
    case "state":
      options = data.states;
      break;
    case "city":
      options = data.cities;
      break;
    case "franchise":
      options = data.franchises;
      break;
    case "bodyType":
      options = data.bodyTypes;
      break;
    case "model":
      if (field.dataSource === "arbitrary") {
        options = data.models;
      }
      break;
    case "trim":
      if (field.dataSource === "arbitrary") {
        options = data.trims;
      }
      break;
    default:
      if (field.dataSource === "arbitrary" && field.subType === "years") {
        const endYear = new Date().getFullYear();
        const startYear = endYear - 100;

        options = Array.from(
          { length: endYear - startYear + 1 },
          (_, i) => startYear + i
        )
          .reverse()
          .map((year) => ({
            label: year.toString(),
            value: year.toString(),
          }));
      }
      break;
  }

  return options;
};

export const validateFormSection = (
  step: number,
  values: Record<string, any>
) => {
  let schema;
  const country = storage.getCountry();

  const currentSchema = (code: string) => {
    switch (code) {
      case "NG":
        return carDetailsSectionSchema;
      case "KE":
        return carDetailsSectionKESchema;
      default:
        return carDetailsSectionOthersSchema;
    }
  };

  switch (step) {
    case 1:
      schema = currentSchema(country);
      break;
    case 2:
      schema = carSnapshotsSectionSchema;
      break;
    case 3:
      schema = carDocumentsSectionSchema;
      break;
    case 4:
      schema = carFeaturesSectionSchema;
      break;
    case 5:
      schema = carSummarySectionSchema;
      break;
    default:
      return true; // No validation for unknown steps
  }

  try {
    schema.parse(values);
    return true;
  } catch (e) {
    return false;
  }
};

const mimeTypes: Record<string, string> = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  webp: "image/webp",
  gif: "image/gif",
  bmp: "image/bmp",
  svg: "image/svg+xml",
  mp4: "video/mp4",
  avi: "video/x-msvideo",
  mov: "video/quicktime",
};

const getFileTypeFromUrl = (url: string): string => {
  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const extension = pathname.split(".").pop()?.toLowerCase();
    return extension ? mimeTypes[extension] || "" : "";
  } catch (error) {
    return "";
  }
};

export const getCreateCarPayload = (values: Record<string, any>) => {
  const payload = {
    owner_id: values.ownerId,
    year: values.year,
    mileage: values.mileage,
    mileage_unit: values.mileageUnit,
    vin: values.vin,
    license_plate: values.plateNumber,
    model_id: values.modelId,
    city: values.city,
    state: values.state,
    country: values.country,
    price: values.price.replace(/,/g, ""),
    address: values.location,
    car_manager_id: values.franchiseId,
    engine_type: values.engineType,
    fuel_type: values.fuelType,
    owner_type: values.ownershipStatus,
    transmission: values.transmissionType,
    trim_id: values.trimId,
    selling_condition: values.carCondition,
    body_type_id: values.bodyTypeId,
    interior_color: values.interiorColor,
    exterior_color: values.exteriorColor,
    franchise_id: values.franchiseId,
    image_url: values.imageUrl,
    is_upgraded: values.isUpgraded === "yes",
    engine_displacement: values.engineDisplacement || "",
    cc_measurement: values.engineCc || "",
    registration_series: values.registrationSeries,
    created_by: values.createdBy,
  };

  if (values.isUpgraded) {
    Object.assign(payload, {
      upgraded_from: values.upgradedFrom,
      upgraded_to: values.upgradedTo,
    });
  }

  return payload;
};

export const getCreateCarImagesPayload = (values: Record<string, any>) => {
  return {
    car_id: values.carId,
    payload: values.snapshots.map((snapshot: any) => {
      const fileType = getFileTypeFromUrl(snapshot.url);
      return {
        name: snapshot.name,
        url: snapshot.url,
        type: fileType,
      };
    }),
  };
};

export const getCreateCarDocsPayload = (values: Record<string, any>) => {
  return {
    car_id: values.carId,
    payload: values.documents.map((document: any) => {
      return {
        name: document.name,
        url: document.url,
      };
    }),
  };
};

export const getCreateCarFeaturesPayload = (values: Record<string, any>) => {
  return {
    car_id: values.carId,
    payload: values.features.map((feature: any) => Number(feature)),
  };
};

export const getUpdateCarPayload = (
  values: Record<string, any>,
  config?: Config
) => {
  const formData = config ? JSON.stringify(prepare(values, config)) : {};

  return {
    car_id: values.carId,
    summary: values.summary,
    form_id: values.formId,
    form_data: formData,
  };
};

export const getCompressedUrl = async (url: string) => {
  try {
    const img = new Image();
    img.src = url;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    const compressedUrl = ImageLoader({
      src: url,
      width: img.naturalWidth > 1200 ? 1200 : img.naturalWidth,
      quality: 100,
    });

    return compressedUrl;
  } catch (error) {
    return "";
  }
};

export const handleApiError = (error: any) => {
  const parsedError = JSON.parse(error?.response?.data ?? "{}");
  const message = parsedError?.error || parsedError?.message;
  return message;
};
