import { useState, useEffect } from "react";
import { Tabs, Segmented, Select } from "antd";
import type { TabsProps } from "antd";
import {
  ApplicationLayout,
  ParentTabHeader,
  MobileHeader,
  AppTabContainer,
  ShowFilterWeb,
} from "../layouts/application-layouts/styles";
import storage from "@l/storage";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import queryString from "query-string";
import DigDealersLayout from "@/layouts/application-layouts/DigDealers";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import { useTranslation } from "react-i18next";
import CustomerFinancingTable from "@/components/CustomerFinancingTable";
import { productNameByLoanType } from "@/utils/loan-product-util";

const Applications = () => {
  const role = storage.getRole();
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { hash } = useLocation();
  const country = storage.getCountry();
  const user = storage.getUser();
  const isAccountManager = storage.utilities.isAccountManager();
  const [value, setValue] = useState<string | number>("BY ME");
  const [parentTabValue, setParentTabValue] = useState("1");
  const [childTabValue, setChildTabValue] = useState("1");
  const [fetchFilteredLoans, setFetchFilteredLoans]: any = useState([]);
  const [fetchCarForCashLoans, setFetchCarForCashLoans]: any = useState([]);
  const [fetchLoanTransferLoans, setFetchLoanTransferLoans]: any = useState([]);
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const dealerQuery = queryString.parse(search);
  const [activePage, setActivePage] = useState<any>({
    currentPage: 1,
    pageSize: 10,
  });
  const [fetchDealers, setFetchDealers] = useState<any>([]);

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  const handleParentTabChange = (key: string) => {
    setParentTabValue(key);
  };

  const handleChildTabChange = (key: string) => {
    navigate({ hash: `${key}` });
    setChildTabValue(key);
  };

  useEffect(() => {
    if (hash === "#2") {
      setChildTabValue("2");
    }
  }, [childTabValue, hash]);

  let applicationLoanStatus = "";
  switch (childTabValue) {
    case "1":
      applicationLoanStatus = "";
      break;
    case "2":
      applicationLoanStatus = "PENDING_DOCS";
      break;
    case "3":
      applicationLoanStatus = "IN_PROGRESS";
      break;
    case "4":
      applicationLoanStatus = "DISBURSED";
      break;
    default:
      applicationLoanStatus =
        "REJECTED_FINAL_OFFER,REJECTED_CONDITIONAL_OFFER,DECLINED_FINAL_OFFER,DECLINED_CONDITIONAL_OFFER,FINAL_OFFER_REJECTED,CONDITIONAL_OFFER_REJECTED";
  }

  const { isLoading: fetchDealersLoading } = useQuery({
    queryKey: ["fetch_am_dealers", country, user, dealerQuery, activePage],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        account_manager_id: user?.id,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        ...dealerQuery,
      });
      return client.get(`/v1/franchise`, { params });
    },
    onSuccess: (res: any) => {
      const parsedDealers = JSON.parse(res?.data);
      setFetchDealers(parsedDealers);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: filteredLoanLoading } = useQuery({
    queryKey: [
      "pending_doc",
      country,
      query,
      activePage,
      user,
      parentTabValue,
      childTabValue,
      applicationLoanStatus,
      productNameByLoanType(parentTabValue),
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        user_id: user?.id,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        hasCommission: "all",
        order: "DESC",
        sort_by: "createdAt",
        productName: productNameByLoanType(parentTabValue),
        ...(parentTabValue === String(1)
          ? {
              status: applicationLoanStatus,
            }
          : ""),
        ...query,
      });
      return client.get(`/v1/origination/dig/loans`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      productResultByLoanType(parsedLoans);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleDealerLoansFilter = (value: any) => {
    navigate(
      `/applications${paramsObjectToQueryString({
        ...query,
        loan_type: value,
      })}`
    );
  };

  const renderCarLoanTable = () => {
    return (
      <CustomerFinancingTable
        activePage={activePage}
        setActivePage={setActivePage}
        query={query}
        fetchFilteredLoans={fetchFilteredLoans}
        filteredLoanLoading={filteredLoanLoading}
        parentTabValue={parentTabValue}
      />
    );
  };

  const cashLoansTable = () => {
    return (
      <CustomerFinancingTable
        activePage={activePage}
        setActivePage={setActivePage}
        query={query}
        fetchFilteredLoans={fetchCarForCashLoans}
        filteredLoanLoading={filteredLoanLoading}
        parentTabValue={parentTabValue}
      />
    );
  };

  const whiteLabelTable = () => {
    return (
      <CustomerFinancingTable
        activePage={activePage}
        setActivePage={setActivePage}
        query={query}
        fetchFilteredLoans={fetchLoanTransferLoans}
        filteredLoanLoading={filteredLoanLoading}
        parentTabValue={parentTabValue}
      />
    );
  };

  const productResultByLoanType = (data: any) => {
    if (parentTabValue === String(1)) {
      setFetchFilteredLoans(data);
    } else if (parentTabValue === String(2)) {
      setFetchCarForCashLoans(data);
    } else {
      setFetchLoanTransferLoans(data);
    }
  };

  const childTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: t(`all`),
      children: renderCarLoanTable(),
    },
    {
      key: "2",
      label: t(`pending-docs`),
      children: renderCarLoanTable(),
    },
    {
      key: "3",
      label: t(`in-progress`),
      children: renderCarLoanTable(),
    },
    {
      key: "4",
      label: t(`disbursed`),
      children: renderCarLoanTable(),
    },
    {
      key: "5",
      label: t(`rejected`),
      children: renderCarLoanTable(),
    },
  ];

  const isAccountManagerChildren = () => {
    let content;

    if (value === "BY ME") {
      content = (
        <Tabs
          defaultActiveKey={hash === "#2" ? "2" : childTabValue}
          items={childTabItems}
          onChange={handleChildTabChange}
        />
      );
    } else {
      content = (
        <DigDealersLayout
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          setValue={setValue}
          fetchDealers={fetchDealers}
          fetchDealersLoading={fetchDealersLoading}
        />
      );
    }

    return content;
  };

  const parentTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <ParentTabHeader>
          {isMobile ? (
            <div>
              {query.role ? (
                <MobileHeader>
                  <button
                    className="header"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/applications`);
                    }}
                  >
                    <div>
                      <img
                        src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
                        alt="chevron-back"
                        style={{ marginTop: "3px", marginRight: "10px" }}
                      />{" "}
                    </div>
                    {query.dealerName}
                  </button>
                </MobileHeader>
              ) : (
                <MobileHeader>
                  <div className="header">{t("car-loan")}</div>
                </MobileHeader>
              )}{" "}
            </div>
          ) : (
            <div>
              {query.role ? (
                <MobileHeader>
                  <button
                    className="header"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/applications`);
                    }}
                  >
                    <div>
                      <img
                        src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
                        alt="chevron-back"
                        style={{ marginTop: "3px", marginRight: "10px" }}
                      />{" "}
                    </div>
                    {query.dealerName}
                  </button>
                </MobileHeader>
              ) : (
                <div className="title">{t("car-loan")}</div>
              )}
            </div>
          )}
        </ParentTabHeader>
      ),
      children: isAccountManager ? (
        isAccountManagerChildren()
      ) : (
        <Tabs
          defaultActiveKey={hash === "#2" ? "2" : childTabValue}
          items={childTabItems}
          onChange={handleChildTabChange}
        />
      ),
    },
    {
      key: "2",
      label: <ParentTabHeader>{t(`cash-loan`)}</ParentTabHeader>,
      children: cashLoansTable(),
    },
    ...(["NG", "KE"].includes(country)
      ? [
          {
            key: "3",
            label: <ParentTabHeader>{t("Dealer Whitelabel")}</ParentTabHeader>,
            children: whiteLabelTable(),
          },
        ]
      : []),
  ];

  return (
    <div>
      <ApplicationLayout>
        <AppTabContainer style={{ padding: 0 }}>
          {["NG", "GH", "KE"].includes(country) ? (
            <Tabs
              tabBarExtraContent={
                parentTabValue === "1" &&
                isAccountManager && (
                  <div style={{ paddingRight: "16px" }}>
                    {query.role ? (
                      <ShowFilterWeb>
                        <div className="show-filter-container">
                          <div className="label">{t("show")}:</div>
                          <Select
                            defaultValue="DEALER_LOANS"
                            bordered={false}
                            onChange={handleDealerLoansFilter}
                            allowClear
                            options={[
                              {
                                value: "DEALER_LOANS",
                                label: t("dealer-loans"),
                              },
                              {
                                value: "MARKETPLACE_LOANS",
                                label: t("marketplace-loans"),
                              },
                              { value: "ALL_LOANS", label: t("all-loans") },
                            ]}
                          />
                        </div>
                      </ShowFilterWeb>
                    ) : (
                      <AppTabContainer style={{ padding: "0px" }}>
                        <Segmented
                          options={[
                            { label: t("by-me"), value: "BY ME" },
                            { label: t("by-dealers"), value: "BY DEALERS" },
                          ]}
                          value={value}
                          onChange={setValue}
                        />
                      </AppTabContainer>
                    )}
                  </div>
                )
              }
              defaultActiveKey={parentTabValue}
              items={parentTabItems}
              onChange={handleParentTabChange}
            ></Tabs>
          ) : (
            <Tabs
              defaultActiveKey={hash === "#2" ? "2" : childTabValue}
              items={childTabItems}
              onChange={handleChildTabChange}
            />
          )}
        </AppTabContainer>
      </ApplicationLayout>

      <NewLoansBtn />
    </div>
  );
};

export default Applications;
