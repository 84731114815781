import { useState, useEffect } from "react";
import LoanCalculator from "@/components/LoanCalculator";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import storage from "@l/storage";

const LoanCalculatorPage = () => {
  const queryParam = new URLSearchParams(window.location.search);
  const carId: string | null = queryParam.get("car_id");
  const role = storage.getRole();
  const [carById, setCarById]: any = useState({});

  useEffect(() => {
    document.title = `Dealer Plus | Loan calculator [${role}]`;
  }, [role]);

  const { isLoading: carAdminLoading } = useQuery({
    queryKey: ["fetch_admin_cars_by_id_loan", carId],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inventory/admin/car/${carId}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarById(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <div>
      <LoanCalculator
        car={carById ?? carById}
        loading={carAdminLoading}
        carId={carId}
      />
    </div>
  );
};

export default LoanCalculatorPage;
