import React, { useState, useEffect } from "react";
import {
  CustomFormContainer,
  CustomFormField,
} from "@/layouts/application-layouts/styles";
import { Button, message } from "antd";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader } from "@/pages/auth/styles/loader";
import { updateCarDetails } from "@/hooks/use-api";

type props = {
  car: any;
  refetch: () => void;
};

const TextAreaFieldStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "4px",
    backgroundColor: "#F8FAFC",
    fontFamily: "OutfitRegular",
    boxShadow: "none",
  },
  "& .MuiInputLabel-root": {
    color: "#30345e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&:hover fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E5E7EB",
      boxShadow: "none",
    },
  },
};

const EditCarSummaryForm = ({ car, refetch }: props) => {
  const { t } = useTranslation("inventory");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    carSummary: car.summary,
  });
  const [alert, setAlert]: any = useState("");
  const [loading, setLoading] = useState(false);

  // ALL PREFILLED DATA
  useEffect(() => {
    if (car) {
      setInputValue({
        carSummary: car.summary,
      });
    }
  }, [car]);

  const handleInputValue =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        [name]: e.target.value,
      });
    };

  const handleSubmitForm = async () => {
    setLoading(true);

    const payload: any = {
      summary: inputValue.carSummary,
    };

    try {
      const response: any = await updateCarDetails(car.id, payload);
      if (response) {
        refetch();
        message.success("Car Updated successfully");
        setLoading(false);
        navigate(`/inventory/${car.id}`, { replace: true });
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error || "Error updating car"
      );
      setLoading(false);
    }
  };

  return (
    <>
      {car && (
        <div>
          <CustomFormContainer>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmitForm}
            >
              <div className="">
                <CustomFormField>
                  <div className="text-base font-outfit mb-2 font-semibold text-[#30345e]">
                    Summary
                  </div>
                  <TextField
                    variant="outlined"
                    multiline
                    rows={7}
                    onChange={handleInputValue("carSummary")}
                    value={inputValue?.carSummary}
                    placeholder={"Enter a brief summary of the car"}
                    fullWidth
                    sx={TextAreaFieldStyle}
                  />
                </CustomFormField>
              </div>

              <CustomFormField>
                <div className="loading-btn-container">
                  <Button
                    className="loading-btn"
                    type="primary"
                    onClick={handleSubmitForm}
                  >
                    {loading ? <Loader variant={"secondary"} /> : t("save")}
                  </Button>
                </div>
              </CustomFormField>
            </Box>
          </CustomFormContainer>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={!!alert}
            autoHideDuration={5000}
            onClose={() => setAlert("")}
          >
            <Alert severity="error" variant="filled">
              {alert}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default EditCarSummaryForm;
