import formatInt from "@/utils/format-int";
import styled from "styled-components";
import { device } from "@/utils/device";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoanInfo from "@/layouts/application-layouts/LoanInfo";
import storage from "@l/storage";

const ChangeCarButton = styled.button`
  font-size: 14px;
  font-weight: bolder;
  color: rgb(48, 52, 94);
  background: rgb(255, 182, 25);
  border-radius: 44px;
  min-width: 110px;
  width: auto;
  height: 32px;
  border: 0;
  padding: 0 8px;
  @media ${device.mobileL} {
    min-width: 120px;
    height: 32px;
    font-size: 14px;
  }
`;

interface ICarInfo {
  carData: any;
  loanId: string;
  partnerId: string;
  loansById: Record<string, unknown>;
  offersById: any;
}

export default function SimulatedCarInfo({
  carData,
  loanId,
  partnerId,
  loansById,
  offersById,
}: ICarInfo) {
  const navigate = useNavigate();
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("inventory");
  const { t: t2 } = useTranslation("dashboard");
  const isAccountManager = storage.utilities.isAccountManager();
  const isFranchise = storage.utilities.isFranchise();
  const isDsaAgent = storage.utilities.isDsaAgent();
  const isDealerDsa = storage.utilities.isDealerDsa();

  const getOfferByIdPerAccountType = () => {
    if (isFranchise) {
      return formatInt(offersById?.dealerCommission, true);
    } else if (isAccountManager) {
      return formatInt(offersById?.accountManagerCommission, true);
    } else if (isDsaAgent) {
      return formatInt(offersById?.dsaCommission, true);
    } else if (isDealerDsa) {
      return formatInt(offersById?.dealerDsaCommission, true);
    } else {
      return `---`;
    }
  };

  const offerByIdPerAccountType = getOfferByIdPerAccountType();

  return (
    <main className="flex flex-col w-full">
      <div className="w-[100%] flex flex-col sm:flex-row gap-2 md:gap-3 pb-4">
        <div className="w-[100%] md:w-[auto]">
          <img
            src={carData?.imageUrl || "---"}
            alt="car"
            className="w-[100%] h-[150px] md:w-[274px] md:h-[180px] rounded-md object-cover"
          />
        </div>

        <div className="w-[100%] flex flex-col gap-2">
          <div className="text-[16px] font-bold text-[#30345e] mb-1">
            {carData?.year
              ? `${carData?.year} ${carData?.model?.make?.name} ${carData?.model?.name}`
              : "---"}
          </div>

          <div className="flex flex-wrap gap-3 md:gap-x-10">
            <div>
              <div className="text-[12px] font-light text-[#6b7280]">
                {t1("price")?.toUpperCase()}
              </div>
              <div className="text-[14px] font-bold text-[#30345e]">
                {formatInt(carData?.marketplacePrice, true)}
              </div>
            </div>
            <div>
              <div className="text-[12px] font-light text-[#6b7280]">
                {t2("loan-value")?.toUpperCase()}
              </div>
              <div className="text-[14px] font-bold text-[#30345e]">
                {formatInt(offersById?.approvedAmount, true)}
              </div>
            </div>
            <div>
              <div className="text-[12px] font-light text-[#6b7280]">
                {t2("commission")?.toUpperCase()}
              </div>
              <div className="text-[14px] font-bold text-[#30345e]">
                {offerByIdPerAccountType}
              </div>
            </div>
            <div>
              <div className="text-[12px] font-light text-[#6b7280]">
                {t("rating")?.toUpperCase()}
              </div>
              <div className="text-[14px] font-bold text-[#30345e]">
                {carData?.gradeScore?.toFixed(1) || "---"}
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between sm:flex-col sm:items-stretch pt-3.5 gap-3 border-t-[1px] border-[rgb(229, 231, 235)]">
            <div className="block sm:flex sm:justify-between">
              <div className="text-[12px] font-bold text-[#30345e] uppercase md:tracking-wide">
                {t("dealer")}: {carData?.carManagerName || "---"}
              </div>
              <div className="text-[12px] font-bold text-[#30345e] uppercase md:tracking-widest">
                {t("vin")}:{carData?.vin || "---"}
              </div>
            </div>
            <div className="flex justify-start md:border-t-[1px] border-[rgb(229, 231, 235)] sm:pt-3.5">
              <ChangeCarButton
                onClick={() =>
                  navigate(
                    `/applications/car-list?simulate=true&loan_id=${loanId}&partner_id=${partnerId}`
                  )
                }>
                {t("change-car")}
              </ChangeCarButton>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <LoanInfo id={loanId} loanDetails={offersById} loansById={loansById} />
      </div>
    </main>
  );
}
