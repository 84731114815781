import styled from "styled-components";
import { device } from "@/utils/device";
import { useTranslation } from "react-i18next";

const CardsContainer = styled.div`
  .inner-container {
    background: #fff;
    border-radius: 0.6rem;
    padding: 0.8rem 1.2rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media ${device.mobileL} {
      border-radius: 0.4rem;
      padding: 0.6rem 0.8rem;
    }

    .horizontal-line {
      height: 5px;
      background-color: ${(props) => props.color};
      margin-bottom: 0.5rem;
      border-radius: 99px;

      @media ${device.mobileL} {
        height: 3px;
      }
    }

    .inner-header {
      display: flex;
      align-items: center;
      gap: 14px;
      @media ${device.mobileL} {
        gap: 8px;
      }
      .header-logo {
        /* margin-right: 14px; */

        @media ${device.mobileL} {
          gap: 8px;
          /* margin-right: 8px; */
        }
      }
      .header-text {
        font-weight: bold;

        @media ${device.mobileL} {
          font-size: 0.6rem;
        }
      }
    }

    .value-container {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      font-size: 12px;

      @media ${device.mobileL} {
        font-size: 10px;
        flex-direction: column;
        margin-top: 1.4rem;
      }
      .left {
        h2 {
          font-size: 12px;
          font-weight: lighter;
          color: #6b7280;

          @media ${device.mobileL} {
            font-size: 0.6rem;
          }
        }
        div {
          font-size: 1.2rem;
          font-weight: bold;

          @media ${device.mobileL} {
            font-size: 1rem;
          }
        }
      }
      .right {
        text-align: right;

        h2 {
          font-size: 12px;
          font-weight: lighter;
          color: #6b7280;

          @media ${device.mobileL} {
            font-size: 0.6rem;
            text-align: left;
          }
        }
        div {
          font-size: 1.2rem;
          font-weight: bold;

          @media ${device.mobileL} {
            font-size: 1rem;
            text-align: left;
          }
        }
      }
      .upload {
        background: #ffb619;
        padding: 8px;
        border-radius: 20px;
        font-weight: bolder;
        font-size: 10px;
        height: fit-content;
        width: 40%;

        @media ${device.mobileL} {
          width: 100%;
        }
      }
    }
  }
`;

const Cards = ({
  cardHeader,
  leftText,
  leftValue,
  rightText,
  rightValue,
  isUpload,
  color,
  buttonLink,
}: ICard) => {
  const { t } = useTranslation("component");

  return (
    <CardsContainer color={color}>
      <div className="inner-container">
        <div className="horizontal-line"></div>
        <div className="inner-header">
          <img
            src="https://storage.googleapis.com/img.autochek.africa/e735ce03-68bb-437c-9704-b6ae4e1ac17e-cash-fill.svg"
            alt=""
            className="header-logo"
          />
          <h2 className="header-text uppercase">{cardHeader}</h2>
        </div>
        <div className="value-container">
          <div className="left">
            <h2>{leftText}</h2>
            <div>{leftValue}</div>
          </div>
          {isUpload ? (
            <button onClick={buttonLink} className="upload">
              {t("upload-now")}
            </button>
          ) : (
            <div className="right">
              <h2>{rightText}</h2>
              <div>{rightValue}</div>
            </div>
          )}
        </div>
      </div>
    </CardsContainer>
  );
};

export default Cards;
