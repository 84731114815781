import { useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { AppTabContainer } from "@/layouts/application-layouts/styles";
import { useTranslation } from "react-i18next";
import LoanOfferList from "@/components/LoanOfferList";

export default function AllLoanOffers({
  id,
  loansById,
  initialOffer,
  finalOffer,
}: any) {
  const { t } = useTranslation("application");
  const [tabKey, setTabKey] = useState("1");

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`Conditional-Offer`),
      children: (
        <LoanOfferList
          id={id}
          conditionalOffer={initialOffer}
          loansById={loansById}
          tabKey={tabKey}
        />
      ),
      // disabled: finalOffer.length > 0 ? true : false,
    },
    {
      key: "2",
      label: t(`Final-Offer`),
      children: (
        <LoanOfferList
          id={id}
          conditionalOffer={finalOffer}
          loansById={loansById}
          tabKey={tabKey}
        />
      ),
      disabled: finalOffer.length === 0,
    },
  ];

  return (
    <div>
      <AppTabContainer style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Tabs
          activeKey={tabKey}
          items={items}
          type="card"
          onChange={handleTabChange}
        />
      </AppTabContainer>
    </div>
  );
}
