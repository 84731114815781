import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkTermsConditionErrors } from "@/utils/process-form-data";

interface IFormButton {
  loading: boolean;
  data: any;
  fields: any;
  index: number;
  setAlert: (error: string) => void;
  setCompleted: React.Dispatch<React.SetStateAction<any>>;
  submit: (e: any, isDraft: boolean) => void;
  label: string;
}

export default function TermsConditionActionButton({
  loading,
  data,
  fields,
  index,
  setAlert,
  setCompleted,
  submit,
  label,
}: Readonly<IFormButton>) {
  const handleSectionNextAction = (
    e: any,
    data: any,
    fields: any,
    index: number,
    setAlert: (error: string) => void,
    setCompleted: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const error = checkTermsConditionErrors(data, fields);
    if (error) {
      setAlert(error);
      setCompleted((current: any) => ({
        ...current,
        [index]: false,
      }));
      return;
    }
    submit(e, false);
  };

  return (
    <LoadingButton
      variant="contained"
      type="button"
      fullWidth
      color="custom"
      style={{ fontWeight: 600 }}
      loading={loading}
      disabled={!!checkTermsConditionErrors(data, fields)}
      onClick={(e) =>
        handleSectionNextAction(e, data, fields, index, setAlert, setCompleted)
      }
    >
      {label}
    </LoadingButton>
  );
}
