import { useNavigate } from "react-router-dom";
import CustomsDocumentForm from "@/components/CustomsDocForm";

export default function UploadCustomDocument() {
  const navigate = useNavigate();

  return (
    <main>
      <div className="relative mb-5">
        <div className="text-2xl font-bold text-center text-[#30345e]">
          Customs Document
        </div>
        <button
          onClick={() => navigate(-1)}
          className="absolute left-0 top-[4px]"
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
        </button>
      </div>

      <div className="w-full max-w-full lg:max-w-[50%] bg-white px-4 h-auto my-0 mx-auto rounded-lg">
        <CustomsDocumentForm />
      </div>
    </main>
  );
}
