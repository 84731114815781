import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import Button from "@/components/button";
import { isMobile } from "react-device-detect";
import {
  FinancingRepoIcon,
  FinancingRepoInventoryIcon,
  FinancingRepoInspectedIcon,
} from "@/assets/svgs";
import CarCard from "@/components/refix/CarCard";
import styled from "styled-components";
import { device } from "@/utils/device";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@l/storage";

const BgWrapper = tw.div`
bg-[url("https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/gb-frame.png?updatedAt=1701351865458")] 
bg-cover bg-no-repeat px-8 md:px-[7.5rem] pt-24  h-fit md:h-[23.375rem] w-full
`;

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;

const RepoFinanceCarsWrapper = styled.div`
  padding: 24px 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const RepoFinancing = () => {
  const navigate = useNavigate();
  const role = storage.getRole();
  const country = storage.getCountry();
  const [repoCars, setRepoCars] = useState<any>([]);
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    document.title = `Dealer Plus | Best Deals [${role}]`;
  }, [role]);

  const { isLoading: categoryIdLoading } = useQuery({
    queryKey: ["fetch_category_id"],
    queryFn: () => {
      const params = new URLSearchParams({
        search: "repo",
      });
      return client.get(`/v1/inventory/category`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const filterCategoryId = parsedResults?.categoryList?.find(
        (item: any) => item?.name === "Repossessed Sale"
      )?.id;
      setCategoryId(filterCategoryId);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: repoCarsLoading } = useQuery({
    queryKey: ["repo_cars", country, categoryId],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        category_id: categoryId,
        page_size: "9",
      });
      return client.get(`/v1/inventory/car`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setRepoCars(parsedResults?.carList);
    },
    enabled: !!categoryId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <div className="absolute top-0 right-0 left-0">
      <BgWrapper>
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="max-w-full md:max-w-[37.6875rem]">
            <h1 className="text-2xl md:text-[2rem] leading-8 font-extrabold text-[#30345E] ">
              Great deals never seen before!!!
            </h1>
            <p className="text-sm md:text-base leading-6 pb-3 pt-1 ">
              Are you in the market for a reliable, yet affordable vehicle? Look
              no further! We have a lot of cars that are in great condition and
              ready for new owners.
            </p>
            <Button
              isLoading={false}
              width="10.1825rem"
              variant="secondary"
              onClick={() => navigate(`/dealer/all-deals`)}
              style={{ borderRadius: "3.75rem" }}
            >
              <span className="text-sm font-bold text-[#30345E]">
                View all best deals
              </span>
            </Button>
          </div>
          <div>
            {isMobile ? (
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/sitting-on-car.png"
                alt="sitting-on-car"
                className="w-full h-auto"
              />
            ) : (
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/sitting-on-card-web.png"
                alt="sitting-on-car"
                className="w-[31.5625rem] h-[12.75rem]"
              />
            )}
          </div>
        </div>
      </BgWrapper>
      <div className="relative grid grid-col-1 lg:grid-cols-3 grid-rows-auto gap-6 px-4 lg:px-24 mt-5 md:mt-[-2.5rem]">
        <GridCol
          icon={<FinancingRepoIcon />}
          heading="Autochek Financing"
          text="You can get a car and pay monthly over up to 36 months"
        />
        <GridCol
          icon={<FinancingRepoInventoryIcon />}
          heading="Large Inventory"
          text="Search from over 1,000+ cars available on our platform."
        />
        <GridCol
          icon={<FinancingRepoInspectedIcon />}
          heading="Thoroughly inspected"
          text="All cars have been inspected by our experts and inspection reports are available."
        />
      </div>

      <div className="pt-10 pb-5">
        <p className="text-2xl font-bold text-center tracking-[-0.01125rem] text-[#30345E]">
          Best Deals Available For Grabs
        </p>
      </div>

      <div className="max-w-[74.0625rem] mx-auto">
        {repoCarsLoading || categoryIdLoading ? (
          <CustomLoading>
            <Loader variant={"secondary"} />
          </CustomLoading>
        ) : (
          <RepoFinanceCarsWrapper>
            {repoCars?.map((elm: any, index: number) => {
              return (
                <CarCard key={repoCars.indexOf(elm)} variant="repo" elm={elm} />
              );
            })}
          </RepoFinanceCarsWrapper>
        )}

        <div className="flex justify-center pt-[2.5rem] pb-24 lg:py-[2.5rem]">
          <Button
            isLoading={false}
            width="9.5rem"
            variant="secondary"
            onClick={() => {
              navigate(`/dealer/all-deals`);
            }}
            style={{ borderRadius: "3.75rem" }}
          >
            <span className="text-sm font-bold text-[#30345E]">
              View more cars
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export const GridCol = ({
  icon,
  heading,
  text,
}: {
  icon: React.ReactNode;
  heading: string;
  text: string;
}) => {
  return (
    <div className="px-5 flex items-center bg-white rounded h-[5.6875rem] space-x-4">
      {icon}
      <div>
        <h5 className="font-bold text-sm text-black tracking-[0.0175rem] uppercase">
          {heading}
        </h5>
        <p className="text-[#6B7280] text-[0.9375rem]">{text}</p>
      </div>
    </div>
  );
};

export default RepoFinancing;
