import React from "react";
import CollateralCarDetails from "./CollateralCarDetails";
import CollateralFieldCard from "./CollateralFieldCard";

interface ICarCollateralContainer {
  allFranchiseCar: any;
  carFieldsArr: any;
  showPrefilledCars: any;
  selectedCarValue: any;
  setCarFieldsArr: React.Dispatch<React.SetStateAction<any>>;
  setSelectedCarValue: React.Dispatch<React.SetStateAction<any>>;
  setPrefilledCarFields: React.Dispatch<React.SetStateAction<any>>;
  setShowPrefilledCars: React.Dispatch<React.SetStateAction<any>>;
  setPrefilledCarsNotInventory: React.Dispatch<React.SetStateAction<any>>;
}

export default function CollateralCarCardContainer({
  selectedCarValue,
  setSelectedCarValue,
  carFieldsArr,
  setCarFieldsArr,
  showPrefilledCars,
  setShowPrefilledCars,
  setPrefilledCarFields,
  setPrefilledCarsNotInventory,
  allFranchiseCar,
}: Readonly<ICarCollateralContainer>) {
  return (
    <div className="flex flex-col gap-4">
      <CollateralCarDetails
        showPrefilledCars={showPrefilledCars}
        selectedCarValue={selectedCarValue}
        setSelectedCarValue={setSelectedCarValue}
        setPrefilledCarFields={setPrefilledCarFields}
        setShowPrefilledCars={setShowPrefilledCars}
        setPrefilledCarsNotInventory={setPrefilledCarsNotInventory}
        allFranchiseCar={allFranchiseCar}
      />

      <CollateralFieldCard
        carFieldsArr={carFieldsArr}
        setCarFieldsArr={setCarFieldsArr}
      />
    </div>
  );
}
