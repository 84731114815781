import { TelCustomField } from "@/layouts/application-layouts/styles";
import storage from "@/lib/storage";
import { getCountryFlag } from "@/utils/getCountryFlag";
import { getCountryTelCode } from "@/utils/getCountryTelCode";
import { getCountryTelCodeLength } from "@/utils/getCountryTelCodeLength";
import { Input } from "antd";

interface ITelInput {
  name: string;
  type: string;
  label: string;
  required: boolean;
  onChange: any;
  value: string;
  onBlur: any;
}

export default function TelInput({
  name,
  type,
  label,
  required,
  onChange,
  value,
  onBlur,
}: Readonly<ITelInput>) {
  const country = storage.getCountry();

  return (
    <TelCustomField>
      <Input
        addonBefore={
          <img src={getCountryFlag(country)} alt="flag" className="w-8 h-8" />
        }
        prefix={getCountryTelCode(country)}
        maxLength={getCountryTelCodeLength(country)}
        onChange={(event: any) => {
          const data = {
            target: {
              name,
              value: `${getCountryTelCode(country)}${event.target.value}`,
              type,
              label,
            },
          };
          onChange(data);
        }}
        required={required}
        value={
          value ? value?.substring(getCountryTelCode(country)?.length ?? 3) : ""
        }
        onBlur={onBlur}
        min={0}
        onInput={(event: any) => {
          const value = event.target.value;
          event.target.value = value.replace(/\D/g, "");
        }}
        onPaste={(event) => {
          const pastedData = event.clipboardData.getData("Text");
          if (!/^\d+$/.test(pastedData)) {
            event.preventDefault();
          }
        }}
      />
    </TelCustomField>
  );
}
