import { useState, useEffect, useCallback } from "react";
import storage from "@/lib/storage";
import { getDealerProduct, getDealerProductData } from "@/hooks/use-loan";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import DealerFinancingForm from "@/components/DealerFinancingForm";
import { FINANCE_TYPE } from "@/utils/finance-type";
import { documentPageTitle } from "@/utils/process-form-data";
import { IDealerProductResult, IFINANCEFORMTYPE, IProductData } from "@/types";

export default function FinanceApplicationForm() {
  const role = storage.getRole();
  const country = storage.getCountry();
  const queryParameters = new URLSearchParams(window.location.search);
  const getLoanId = queryParameters.get("loan_id") as string;
  const new_loan = queryParameters.get("form_type") as string;
  const productType = queryParameters.get("product_type") as IFINANCEFORMTYPE;
  const formPageTitle = documentPageTitle(productType);

  //states
  const [dealerProduct, setDealerProduct] = useState<IDealerProductResult>();
  const [productData, setProductData] = useState<IProductData>();
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [loanById, setLoanById]: any = useState([]);
  const [loanTermsById, setLoanTermsById]: any = useState([]);
  const [loanPreferenceById, setLoanPreferenceById]: any = useState([]);
  const [loanVehicleById, setLoanVehicleById]: any = useState([]);
  const [productCarField, setProductCarField] = useState<any>({});
  const [loanCarsById, setLoanCarsById]: any = useState([]);

  useEffect(() => {
    document.title = `Dealer Plus | ${formPageTitle} [${role}]`;
  }, [formPageTitle, role]);

  useQuery({
    queryKey: ["get_dealer_loans_by_id", country, getLoanId],
    queryFn: () => {
      return client.get(`/v1/dealer/loans/${getLoanId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      setLoanById(parsedLoanDetails?.customer?.profile);
      setLoanTermsById(parsedLoanDetails?.termsAndCondition);
      setLoanPreferenceById(parsedLoanDetails?.preferences);
      setLoanCarsById(parsedLoanDetails?.car);
      setLoanVehicleById(parsedLoanDetails?.vehicle);
    },
    enabled: !!getLoanId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const product = getDealerProduct(country);
    product.then((result) => setDealerProduct(JSON.parse(result.data)));
  }, [country]);

  const determineProductType = useCallback((productType: IFINANCEFORMTYPE) => {
    let currentProductType;
    switch (productType) {
      case "import":
        currentProductType = FINANCE_TYPE.IMPORT_FINANCE;
        break;
      case "local":
        currentProductType = FINANCE_TYPE.LOCAL_FINANCE;
        break;
      case "duty":
        currentProductType = FINANCE_TYPE.DUTY_FINANCE;
        break;
      case "repo":
        currentProductType = FINANCE_TYPE.REPO_FINANCE;
        break;
      default:
        currentProductType = FINANCE_TYPE.IMPORT_FINANCE;
        break;
    }
    return currentProductType;
  }, []);

  useEffect(() => {
    const financeType = determineProductType(productType);

    setPageLoading(true);
    if (dealerProduct?.result) {
      const productId = dealerProduct?.result?.find(
        (x: any) => x.name === financeType
      )?.id;
      const product = getDealerProductData(productId, "");
      product.then((result) => {
        const parsedData = JSON.parse(result.data);
        setProductData(parsedData);
        setProductCarField(parsedData?.config?.carFields[0]?.fields);
        setPageLoading(false);
      });
    }
  }, [dealerProduct, determineProductType, productType]);

  return (
    <main>
      <DealerFinancingForm
        productData={productData}
        productCarField={productCarField}
        loanById={loanById}
        loanTermsById={loanTermsById}
        loanPreferenceById={loanPreferenceById}
        loanCarsById={loanCarsById}
        loanVehicleById={loanVehicleById}
        pageLoading={pageLoading}
        getLoanId={getLoanId}
        new_loan={new_loan}
      />
    </main>
  );
}
