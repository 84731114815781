import React from "react";
import { Modal, Collapse } from "antd";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { dealerLevelsData } from "@/data/benefitListData";
import { IDealerType } from "./DealerLevelCard";
import { IBenefitList } from "@/interface/dealer";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IBenefitModal {
  openBenefitModal: boolean;
  handleOpenBenefitModal: () => void;
  handleCloseBenefitModal: () => void;
  type: IDealerType;
  loading: boolean;
  dealerBenefit?: IBenefitList;
}

interface UnlockedBenefitModalProps extends IBenefitModal {
  children?: React.ReactNode;
}

const DescText = tw.div`text-sm md:text-base text-[#E5E7EB]`;
const Header = tw.div`flex justify-center items-center gap-1 bg-[#30345E] py-3 rounded-t-[18px] w-full`;
const FinanceContainer = tw.div`py-6 mt-10 border-t border-solid border-[#e5e7eb] text-center`;
const FinanceButton = tw.button`text-[#30345E] font-bold text-base bg-[#FFB619] py-3 rounded-[60px] w-3/4 cursor-pointer`;

const CustomCollapse = styled.div`
  [data-theme="compact"]
    .site-collapse-custom-collapse
    .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    overflow: hidden;
    background: white;
    border-radius: 2px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-header-text {
    color: #30345e;
    font-size: 20px;
    font-weight: 600;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;

const ArrowDown = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9427 1.05727C19.4634 1.57797 19.4634 2.42219 18.9427 2.94289L10.9427 10.9429C10.422 11.4636 9.57781 11.4636 9.05711 10.9429L1.05711 2.94289C0.536409 2.42219 0.536409 1.57797 1.05711 1.05727C1.57781 0.536573 2.42203 0.536573 2.94273 1.05727L9.99992 8.11446L17.0571 1.05727C17.5778 0.536573 18.422 0.536573 18.9427 1.05727Z"
        fill="#30345E"
      />
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.05727 10.9428C0.536573 10.4222 0.536573 9.57793 1.05727 9.05723L9.05727 1.05723C9.57797 0.536532 10.4222 0.536532 10.9429 1.05723L18.9429 9.05723C19.4636 9.57793 19.4636 10.4222 18.9429 10.9428C18.4222 11.4635 17.578 11.4635 17.0573 10.9428L10.0001 3.88566L2.94289 10.9428C2.42219 11.4635 1.57797 11.4635 1.05727 10.9428Z"
        fill="#30345E"
      />
    </svg>
  );
};

export default function UnlockedBenefitModal({
  openBenefitModal,
  handleOpenBenefitModal,
  handleCloseBenefitModal,
  type,
  loading,
  dealerBenefit,
}: Readonly<UnlockedBenefitModalProps>) {
  const { Panel } = Collapse;
  const { t } = useTranslation("dealer-gamification");
  const navigate = useNavigate();

  let benefitList: IBenefitData;

  if (type === "bronze") {
    benefitList = dealerLevelsData[0];
  } else if (type === "silver") {
    benefitList = dealerLevelsData[1];
  } else if (type === "gold") {
    benefitList = dealerLevelsData[2];
  } else if (type === "platinum") {
    benefitList = dealerLevelsData[3];
  } else {
    benefitList = {};
  }

  const expandFunction = (isActive?: boolean) => {
    return isActive ? <ArrowUp /> : <ArrowDown />;
  };

  return (
    <main>
      <Modal
        open={openBenefitModal}
        onOk={handleOpenBenefitModal}
        onCancel={handleCloseBenefitModal}
        footer={null}
        width={700}
        wrapClassName="benefit-modal"
      >
        <div className="flex flex-col gap-4">
          <Header>
            <img
              src={benefitList.medalIcon}
              alt="medal"
              width="100px"
              height="100px"
            />

            {loading ? (
              <Box sx={{}}>
                {Array.from({ length: 4 }, (_, index) => (
                  <Skeleton
                    width={210}
                    height={10}
                    sx={{
                      background: "#E3E3E3",
                    }}
                    key={index}
                  />
                ))}
              </Box>
            ) : (
              <div>
                <div className="text-base md:text-lg text-white font-bold uppercase">
                  {dealerBenefit?.name}
                </div>
                {benefitList?.typeDescription?.map((desc: any) => (
                  <DescText key={benefitList?.typeDescription?.indexOf(desc)}>
                    {t(desc)}
                  </DescText>
                ))}
              </div>
            )}
          </Header>

          <div>
            <div className="mb-7 text-base font-bold px-4">
              {t("as-a")} {capitalFirstLetter(t(type))}{" "}
              {t("dealer-you-are-entitled-to")};
            </div>

            <CustomCollapse>
              <Collapse
                accordion
                bordered={false}
                defaultActiveKey={["0"]}
                expandIcon={({ isActive }) => expandFunction(isActive)}
                expandIconPosition="end"
                className="site-collapse-custom-collapse"
              >
                {dealerBenefit?.benefits.map((el, i) => (
                  <Panel
                    key={dealerBenefit?.benefits.indexOf(el)}
                    header={
                      <div className="flex items-center gap-3">
                        <img
                          src={el.icon}
                          alt="benefit logo"
                          width="44px"
                          height="44px"
                        />
                        <div>{t(el.title)}</div>
                      </div>
                    }
                    className="site-collapse-custom-panel"
                  >
                    <ul className="list-disc pl-10">
                      {el.options.map((li: string, index: number) => (
                        <li
                          className="text-sm md:text-base text-[#6B7280]"
                          key={el?.options?.indexOf(li)}
                        >
                          {t(li)}
                        </li>
                      ))}
                    </ul>
                  </Panel>
                ))}
              </Collapse>
            </CustomCollapse>
          </div>

          <FinanceContainer>
            <FinanceButton onClick={() => navigate("/dealer/financing")}>
              {t("apply-for-financing")}
            </FinanceButton>
          </FinanceContainer>
        </div>
      </Modal>
    </main>
  );
}
