export const convertStringToBoolean = (value: string | any) => {
  if (typeof value === "boolean") {
    return value; // Return the input value if it's already a boolean
  } else if (value === "false") {
    return false;
  } else if (value === "true") {
    return true;
  }

  // Return original value if not 'false', 'true', or boolean
  return value;
};
