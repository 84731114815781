import { getCountryCurrency } from "./getCountryCurrency";

export function getOrdinal(number: number) {
  const remainder10 = number % 10;
  const remainder100 = number % 100;

  if (remainder10 === 1 && remainder100 !== 11) {
    return number + "st";
  } else if (remainder10 === 2 && remainder100 !== 12) {
    return number + "nd";
  } else if (remainder10 === 3 && remainder100 !== 13) {
    return number + "rd";
  } else {
    return number + "th";
  }
}

export function isPrecededByPlusOrMinus(value: string) {
  const regex = /[+]\d+$/; // Regular expression to match "+" or "-" followed by digits at the end of the string

  return regex.test(value);
}

export function isPrecededByMinus(value: string) {
  const test = value?.startsWith("-");

  return test;
}

export const currencyFormat = (
  amount: string | number,
  countryCode: string = "NG"
) => {
  const currencySign = getCountryCurrency(countryCode);
  return `${currencySign} ${Number(amount).toLocaleString("en-NG")}`;
};

export const VEHICLE_MAKES = [
  "vehicleMake",
  "vehicleMake2",
  "vehicleMake3",
  "vehicleMake4",
  "vehicleMake5",
  "vehicleMake6",
  "vehicleMake7",
  "vehicleMake8",
  "vehicleMake9",
  "vehicleMake10",
];

export const VEHICLE_MODELS = [
  "vehicleModel",
  "vehicleModel2",
  "vehicleModel3",
  "vehicleModel4",
  "vehicleModel5",
  "vehicleModel6",
  "vehicleModel7",
  "vehicleModel8",
  "vehicleModel9",
  "vehicleModel10",
];

export const BODY_TYPES = [
  "bodyType",
  "bodyType2",
  "bodyType3",
  "bodyType4",
  "bodyType5",
  "bodyType6",
  "bodyType7",
  "bodyType8",
  "bodyType9",
  "bodyType10",
];
