import React from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { IDealerLeaderboard } from "@/interface/dealer";
import { getOrdinal } from "@/utils/helpers";

const LeaderTable = styled.div`
  .leader_board_wrapper {
    overflow: scroll;
    height: 70vh;
  }
`;

interface DataType {
  key: string;
  dealer: string;
  points: number;
  level: string;
}

const data: DataType[] = [
  {
    key: "1",
    dealer: "Udemba Autos",
    points: 4490,
    level: "gold",
  },
  {
    key: "2",
    dealer: "Kwam 1 cars",
    points: 3590,
    level: "silver",
  },
  {
    key: "3",
    dealer: "Deluxe Cars",
    points: 2500,
    level: "bronze",
  },
  {
    key: "4",
    dealer: "Deluxe Cars",
    points: 2900,
    level: "bronze",
  },
  {
    key: "5",
    dealer: "Deluxe Cars",
    points: 2400,
    level: "bronze",
  },
];

const Leaderboard = ({
  leaderBoardLoading,
  leaderBoard,
  dealerName,
  closeModal,
}: {
  leaderBoardLoading: boolean;
  leaderBoard: IDealerLeaderboard[];
  dealerName: string;
  closeModal: () => void;
}) => {
  const tableHeaders = ["Dealer", "Points", "Level"];
  return (
    <div className="overflow-scroll h-[70vh]">
      {/* <SwtichWrap>
        <div className="flex justify-between items-center">
          <div>
            <span className="text-[0.875rem] font-bold text-[#30345E] leading-6 tracking-[0.0625rem] ">
              Make my name visible to everyone
            </span>
          </div>
          <div>
            <Switch defaultChecked onChange={onChange} />
          </div>
        </div>
      </SwtichWrap> */}
      {leaderBoardLoading ? (
        <Spin />
      ) : (
        <div className="pt-3">
          <LeaderTable>
            <CustomTable
              headers={tableHeaders}
              data={leaderBoard.map((el, i) => ({
                ...el,
                key: i + 1,
                dealer: el.name,
                level: el.level.toLowerCase(),
              }))}
              // data={data}
              toBlur={true}
              dealerName={dealerName}
              closeModal={closeModal}
            />
          </LeaderTable>
        </div>
      )}
    </div>
  );
};

const TableContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  margin: 1em 0;
  font-size: 1rem;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 12px 16px !important;
  border-bottom: 1px solid #e2e8f0;
  background: #30345e !important;
  color: #fff;

  &:first-child {
    width: 100%;
  }
`;

const TableCell = styled.td`
  padding: 0.75em;
  border-bottom: 1px solid #e2e8f0;
`;

interface TableProps {
  headers: string[];
  data: {
    key: string;
    dealer: string;
    points: number;
    level: string;
  }[];
}

interface IDealerPointData {
  name: string;
  points: string;
  level: string;
  key: number;
  dealer: string;
}

const CustomTable = ({
  headers,
  data,
  toBlur,
  dealerName,
  closeModal,
}: {
  headers: any[];
  data: any[];
  toBlur: boolean;
  dealerName: string;
  closeModal: () => void;
}) => {
  const topDealer: IDealerPointData = data[0];

  const currentDealer = data.find(
    (dealer: { name: string }) => dealer.name === dealerName,
  );

  const dealerPosition: number = currentDealer.key;
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            {headers.map((header: any, index: any) => (
              <TableHeader key={header.indexOf(header)}>{header}</TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {dealerPosition > 5
            ? data
                .slice(0, 5)
                .concat(data.slice(dealerPosition - 1, dealerPosition + 4))
                .map((row: any, rowIndex: any) => (
                  <TableRow
                    key={row?.key}
                    data={row}
                    toBlur={toBlur}
                    dealerName={dealerName}
                    topDealerPoint={topDealer.points}
                    closeModal={closeModal}
                  />
                ))
            : data
                .slice(0, 10)
                .map((row: any, rowIndex: any) => (
                  <TableRow
                    key={row?.key}
                    data={row}
                    toBlur={toBlur}
                    dealerName={dealerName}
                    topDealerPoint={topDealer.points}
                    closeModal={closeModal}
                  />
                ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

const SpecialRow = ({ data, dealerLevel, topDealerPoint, closeModal }: any) => {
  let level = data.level;

  const pointNeededByNumberOfCars = Math.ceil(
    (topDealerPoint - data.points) / 200,
  );

  return (
    <tr className="special-row h-[11.5rem] text-white bg-[#30345e] relative font-bold align-top">
      <TableCell>
        <span className="text-sm ">
          {data.key + "." + " "} <span>{data.dealer}</span>
        </span>
      </TableCell>
      <TableCell>
        <div className="flex justify-center">
          <span className="font-bold text-sm">{data.points}</span>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex justify-center">
          <img src={dealerLevel} alt={level} className="w-6 h-6" />
        </div>
      </TableCell>

      <SpecialRowAttache
        text={
          Number(data.key) === 1
            ? `You are topping the chart. You're doing a great job`
            : `To top this table, sell ${pointNeededByNumberOfCars} cars (${
                pointNeededByNumberOfCars * 200
              }pts) Make up to 1.9% commission
        for each sale`
        }
        showBtn={Number(data.key) !== 1}
        position={data.key}
        closeModal={closeModal}
      />
    </tr>
  );
};

const SpecialRowAttache = ({
  text,
  showBtn,
  position,
  closeModal,
}: {
  text: string;
  showBtn: boolean;
  position: number;
  closeModal: () => void;
}) => {
  return (
    <div className="absolute top-[63%] md:top-[60%] left-2/4 transform -translate-x-1/2 -translate-y-1/2 ">
      <div className="flex flex-col items-center justify-center md:w-[25.875rem] w-[20rem] ">
        <p className="text-lg text-[#E5E7EB] leading-6 text-center pb-2">
          You are ranked <strong>{getOrdinal(position)}</strong> position
        </p>
        <p className="text-base text-[#E5E7EB] leading-6 text-center pb-2">
          {text}
        </p>
        {showBtn && (
          <MakeASaleButton
            text="make a sale"
            link="/dealer/applications"
            closeModal={() => closeModal()}
          />
        )}
      </div>
    </div>
  );
};

const MakeASaleButton = ({
  text,
  link,
  closeModal,
}: {
  text: string;
  link: string;
  closeModal: () => void;
}) => {
  return (
    <button
      className="bg-[#FFB619] rounded-[2.75rem] w-fit px-4 py-1 flex items-center justify-center"
      onClick={() => {
        closeModal();
      }}
    >
      <span className="text-[#30345E] font-bold tracking-[0.01563rem] capitalize">
        {text}
      </span>
    </button>
  );
};

const TableRow = ({
  data,
  toBlur,
  dealerName,
  topDealerPoint,
  closeModal,
}: any) => {
  let level = data.level;
  const $dealerLevel = () => {
    switch (level) {
      case "platinum":
        return `https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-2.png`;
      case "gold":
        return `https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-1.png`;
      case "silver":
        return `https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals.png`;
      case "bronze":
        return `https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png`;
      default:
        return "";
    }
  };

  const dealerLevel = $dealerLevel();

  let className = "";

  if (toBlur) {
    className = "blur-sm";
  }

  if (data.dealer === dealerName) {
    return (
      <SpecialRow
        data={data}
        dealerLevel={dealerLevel}
        toBlur={toBlur}
        topDealerPoint={topDealerPoint}
        closeModal={closeModal}
      />
    );
  } else {
    return (
      <tr>
        <TableCell>
          <span className="text-sm ">
            {data.key + "." + " "}{" "}
            <span className={className}>{data.dealer}</span>
          </span>
        </TableCell>
        <TableCell>
          <div className="flex justify-center">
            <span className="font-bold text-sm">{data.points}</span>
          </div>
        </TableCell>
        <TableCell>
          {" "}
          <div className="flex justify-center">
            <img src={dealerLevel} alt={level} className="w-6 h-6" />
          </div>
        </TableCell>
      </tr>
    );
  }
};

export default Leaderboard;
