import React, { useState, useEffect, ChangeEvent } from "react";
import { Input, message, Select, Table, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import type { TableProps } from "antd";
import { UsersTableContainer } from "@/layouts/application-layouts/styles";
import { useQuery } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { Loader } from "./auth/styles/loader";
import { determineRole } from "@/utils/determine-role";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

const ROLE_OPTIONS = [
  {
    label: "Account Manager",
    value: "ACCOUNT_MANAGER",
  },
  {
    label: "Franchise Admin",
    value: "FRANCHISE ADMIN",
  },
  {
    label: "DSA Agent",
    value: "DSA_AGENT",
  },
  {
    label: "Dealer DSA",
    value: "DEALER_DSA",
  },
];

const COUNTRY = [
  {
    label: "Nigeria",
    value: "NG",
  },
  {
    label: "Ghana",
    value: "GH",
  },
  {
    label: "Kenya",
    value: "KE",
  },
  {
    label: "Uganda",
    value: "UG",
  },
  {
    label: "Ivory Coast",
    value: "CI",
  },
  {
    label: "Senegal",
    value: "SN",
  },
  {
    label: "Egypt",
    value: "EG",
  },
  {
    label: "Morocco",
    value: "MA",
  },
];

export default function ManageUsersModule() {
  const country = storage.getCountry();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const navigate = useNavigate();
  const location = useLocation();
  const [fetchUsers, setFetchUsers] = useState<any>({});
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const [userSearch, setUserSearch] = useState("");
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [userCountry, setUserCountry] = useState<string>("");

  useEffect(() => {
    if (query.current_page && typeof query.current_page == "string") {
      setActivePage({
        ...activePage,
        currentPage: parseInt(query.current_page),
      });
    }
  }, [activePage, query.current_page, setActivePage]);

  const handleUserPageChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page.current,
    });
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        current_page: page.current,
        page_size: page.pageSize,
      })}`
    );
  };

  useEffect(() => {
    if (query.country) {
      setUserCountry(query.country as string);
    } else {
      setUserCountry(country);
    }
  }, [query.country, country]);

  const handleRoleChange = (value: string) => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        role_name: value,
      })}`
    );
  };

  const handleCountryChange = (value: string) => {
    setUserCountry(value);
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        country: value,
      })}`
    );
  };

  const handleUserSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setUserSearch(event.target.value);
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        search: event.target.value,
      })}`
    );
  };

  const { isLoading: loadUsers } = useQuery({
    queryKey: ["get_users", query, userCountry, country, activePage],
    queryFn: () => {
      const params = new URLSearchParams({
        ...(query as any),
        country: userCountry || country,
      });
      return client.get(`/v1/user`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedResponse: any = JSON.parse(res?.data);
      setFetchUsers(parsedResponse);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const toRolePath = (role: any) => {
    switch (role) {
      case "FRANCHISE ADMIN":
        navigate("/dealer/dashboard", { replace: true });
        break;
      case "ACCOUNT_MANAGER":
      case "DEALER_DSA":
      case "DSA_AGENT":
        navigate("/dashboard", { replace: true });
        break;
      default:
        navigate("/dashboard", { replace: true });
        break;
    }
  };

  const handleAssumeUser = async (id: string) => {
    setLoading((prev) => ({ ...prev, [id]: true }));
    const payload = {
      id,
    };

    try {
      const response: any = await client.post(`/v1/auth/assume-user`, payload);
      if (response) {
        const parsedResponse = JSON.parse(response?.data);
        const token = parsedResponse?.token;
        const proxyUserToken = parsedResponse?.proxyUserToken;
        const user = parsedResponse?.user;
        const roles = parsedResponse?.authority?.roles;
        const permissions = parsedResponse?.authority?.permissions;
        const country = parsedResponse?.user?.country;

        const role = determineRole(roles);

        if (role) storage.setRole(role);

        storage.setToken(token);
        storage.setProxyUserToken(proxyUserToken);
        storage.setUser(user);
        storage.setRoles(roles);
        storage.setPermissions(permissions);
        storage.setCountry(country);
        storage.setAssumedUser("true");

        toRolePath(role);
        setLoading((prev) => ({ ...prev, [id]: false }));
        message.success("User role assumed succesfully");
      }
    } catch (error: any) {
      const errorResp = JSON.parse(error?.response?.data)?.message;
      message.error(errorResp);
      setLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const columns: TableProps<any>["columns"] = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, elm) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {capitalFirstLetter(elm?.firstname)}{" "}
          {capitalFirstLetter(elm?.lastname)}
        </div>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "email",
      key: "email",
      render: (_, elm) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {elm?.email || "---"}
        </div>
      ),
    },
    {
      title: "Role",
      key: "status",
      dataIndex: "status",
      width: 300,
      render: (_, elm) => (
        <>
          {elm?.roles?.length > 0 ? (
            <div className="flex flex-row flex-wrap gap-1.5">
              {elm?.roles.map((role: any) => (
                <Tag
                  key={role?.id}
                  color="processing"
                  style={{ marginRight: 0 }}
                  className="uppercase w-fit"
                >
                  {role?.name?.replaceAll("_", " ")}
                </Tag>
              ))}
            </div>
          ) : (
            "---"
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, doc) => (
        <button
          onClick={() => handleAssumeUser(doc?.id)}
          className="text-sm min-w-[120px] w-auto h-[36px] bg-[#ffb619] font-semibold text-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
        >
          {loading[doc?.id] ? <Loader variant="secondary" /> : "Assume User"}
        </button>
      ),
    },
  ];

  return (
    <main>
      <div className="flex flex-col gap-4 bg-white p-3.5 md:p-4 rounded-lg w-full max-w-full xl:max-w-[75%] min-h-[400px] h-auto my-0 mx-auto">
        <div className="text-lg font-semibold">Manage Users</div>
        <div className="flex flex-row gap-3 mb-2">
          <div className="w-full max-w-full md:max-w-[12%]">
            <Select
              placeholder="Select country"
              value={userCountry}
              size="large"
              className="w-full"
              onChange={handleCountryChange}
              options={COUNTRY}
            />
          </div>
          <div className="w-full max-w-full md:max-w-[16%]">
            <Select
              placeholder="Select role"
              size="large"
              className="w-full"
              onChange={handleRoleChange}
              options={ROLE_OPTIONS}
              allowClear
            />
          </div>
          <div className="hidden md:block w-full max-w-full md:max-w-[28%]">
            <Input
              className="w-full"
              size="large"
              placeholder="Search user"
              prefix={<UserOutlined />}
              onChange={(e) => handleUserSearch(e)}
              value={userSearch}
              allowClear
            />
          </div>
        </div>

        <UsersTableContainer>
          <Table
            loading={loadUsers}
            columns={columns}
            dataSource={fetchUsers?.users}
            onChange={handleUserPageChange}
            pagination={{
              current: activePage?.currentPage,
              pageSize: activePage?.pageSize,
              total: fetchUsers?.pagination?.total,
              showSizeChanger: false,
              simple: true,
            }}
          />
        </UsersTableContainer>
      </div>
    </main>
  );
}
