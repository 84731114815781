import history from '@l/history';
import storage from '@l/storage';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const options = {
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-autochek-app': 'dealerplus',
  },
  transformResponse: [
    (data: AxiosResponse) => {
      return data;
    },
  ],
};

const client = axios.create(options);

client.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = storage.getToken();
  const proxyUserToken = storage.getProxyUserToken();

  config.headers!.Authorization = token ? `Bearer ${token}` : '';

  if (proxyUserToken) {
    config.headers!['x-proxy-user-token'] = proxyUserToken;
  }

  return config;
});

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      storage.clearAll();
      history.replace('/auth/sign-in');
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  },
);

export default client;
