import { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GiInfo } from "react-icons/gi";
import formatInt from "@/utils/format-int";

interface ICollateralDetails {
  showPrefilledCars: any;
  allFranchiseCar: any;
  selectedCarValue: any;
  setSelectedCarValue: React.Dispatch<React.SetStateAction<any>>;
  setPrefilledCarFields: React.Dispatch<React.SetStateAction<any>>;
  setShowPrefilledCars: React.Dispatch<React.SetStateAction<any>>;
  setPrefilledCarsNotInventory: React.Dispatch<React.SetStateAction<any>>;
}

export default function CollateralCarDetails({
  selectedCarValue,
  setSelectedCarValue,
  setPrefilledCarFields,
  showPrefilledCars,
  setShowPrefilledCars,
  setPrefilledCarsNotInventory,
  allFranchiseCar,
}: Readonly<ICollateralDetails>) {
  const [selectedCarInfo, setSelectedCarInfo]: any = useState([]);

  useEffect(() => {
    if (selectedCarValue && selectedCarValue.length > 0) {
      const filteredData = allFranchiseCar?.carList?.filter((item: any) =>
        selectedCarValue.some(
          (selectedCar: any) => selectedCar.dealerCar.value === item.id
        )
      );

      const newDataArray =
        filteredData?.map((item: any) => ({
          id: item.id,
          carName: `${item.year} ${item?.model?.make?.name} ${item?.model?.name}`,
          price: item?.marketplacePrice,
          rating: item?.gradeScore?.toFixed(1),
          imageUrl: item.imageUrl,
          vin: item.vin,
        })) ?? [];

      setSelectedCarInfo(newDataArray);
    } else {
      setSelectedCarInfo([]);
    }
  }, [selectedCarValue, allFranchiseCar?.carList, setSelectedCarInfo]);

  const handleDeleteCar = (id: any, modelId: any, carId: any) => {
    setSelectedCarInfo((prevSelectedCarInfo: any) =>
      prevSelectedCarInfo?.filter((item: any) => item.id !== id)
    );

    setShowPrefilledCars((prevSelectedCarInfo: any) =>
      prevSelectedCarInfo?.filter((item: any) => item.id !== id)
    );

    setSelectedCarValue((prevSelectedCarValue: any) =>
      prevSelectedCarValue?.filter(
        (selectedCar: any) => selectedCar.dealerCar.value !== id
      )
    );

    setPrefilledCarFields((prevSelectedCarField: any) =>
      prevSelectedCarField?.filter(
        (selectedCar: any) => selectedCar.dealerCar.value !== carId
      )
    );

    setPrefilledCarsNotInventory((prevSelectedCarField: any) =>
      prevSelectedCarField?.filter(
        (selectedCar: any) => selectedCar.vehicleModelId.value !== modelId
      )
    );
  };

  return (
    <main>
      {(selectedCarInfo?.length > 0 || showPrefilledCars.length > 0) && (
        <div>
          <div className="flex items-center gap-1 mb-2 text-[#60A5FA] text-xs font-bold">
            <GiInfo />
            You can add more than one car as collateral
          </div>
          <div className="flex flex-col gap-3">
            {[...selectedCarInfo, ...showPrefilledCars]?.map((item: any) => (
              <div
                key={item?.id}
                className="w-full border border-solid border-[#E5E7EB] rounded-lg flex justify-between gap-3 md:gap-4 p-2"
              >
                <div className="w-[97px] h-[80px] rounded-md overflow-hidden">
                  <img
                    src={item?.imageUrl}
                    alt="Car"
                    className="object-cover"
                  />
                </div>
                <div className="w-full">
                  <div className="text-[#30345E] text-base font-bold mb-1">
                    {item?.carName}
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between gap-2 pb-1">
                    <div>
                      <div className="text-xs text-[#6B7280] font-normal">
                        VALUE
                      </div>
                      <div className="text-[#30345E] text-sm font-bold">
                        {formatInt(item?.price, true)}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs text-[#6B7280] font-normal">
                        VIN
                      </div>
                      <div className="text-[#30345E] text-sm font-bold">
                        {item?.vin || "---"}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs text-[#6B7280] font-normal">
                        RATING
                      </div>
                      <div className="text-[#30345E] text-sm font-bold">
                        {item?.rating}
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full justify-end text-right pt-1">
                    <button
                      onClick={() =>
                        handleDeleteCar(item?.id, item?.modelId, item?.carId)
                      }
                      className="text-[#F87171] text-sm font-bold flex items-center gap-1 cursor-pointer"
                    >
                      <RiDeleteBin6Line /> Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </main>
  );
}
