import { Fragment, useState, useEffect } from "react";
import { InputNumber } from "antd";
import storage from "@l/storage";
import {
  ApplyLoanButton,
  CInputNumber,
  FilterLoanValue,
  OutlineButton,
} from "@/layouts/application-layouts/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import { useTranslation } from "react-i18next";
import formatCurrency from "@u/formatCurrency";
import FormControl from "@mui/material/FormControl";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  ICarMakeObj,
  ICarMakes,
  ICarModelObj,
  IFranchiseObj,
  IInspectionObj,
  IInventoryFilterType,
  IMinMaxObj,
  IVariant,
  IYearObj,
} from "@/types";
import { useQuery } from "@tanstack/react-query";
import {
  fetchCarMakes,
  fetchCarModels,
  fetchFranchise,
  fetchOriginationLoanStatuses,
} from "@/hooks/use-api";
import { ICarModels } from "@/interface/dealer";
import { generateArrayOfYears } from "@/utils/yearsArray";
import { Loader } from "@/pages/auth/styles/loader";

interface IInventoryFilter {
  query: any;
  loading: boolean;
  setOpenFilter?: React.Dispatch<React.SetStateAction<boolean>>;
  type: IInventoryFilterType;
  variant: IVariant;
  t2: any;
}

const InventoryCustomFilter = ({
  query,
  loading,
  setOpenFilter,
  type,
  variant,
  t2,
}: IInventoryFilter) => {
  const navigate = useNavigate();
  const isAccountManager = storage.utilities.isAccountManager();
  const country = storage.getCountry();
  const { t } = useTranslation("dashboard");
  const location = useLocation();

  //states
  const [minMaxValue, setMinMaxValue] = useState({
    min: "",
    max: "",
  } as IMinMaxObj);
  const [carMakes, setCarMakes] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [carMakeId, setCarMakeId] = useState({} as ICarMakeObj);
  const [carModelId, setCarModelId] = useState({} as ICarModelObj);
  const [year, setYear] = useState({} as IYearObj);
  const [inspectionStatusObj, setInspectionStatusObj] = useState(
    {} as IInspectionObj
  );
  const [franchiseData, setFranchiseData] = useState([]);
  const [franchiseId, setFranchiseId] = useState({} as IFranchiseObj);
  const [rawStatus, setRawStatus] = useState<any>([]);
  const [fetchLoanStatuses, setFetchLoanStatuses] = useState<any>([]);

  const inspectionStatusList = [
    { label: t("all"), value: "All" },
    {
      label: t("pending-inspection"),
      value: "Pending Inspection",
    },
    { label: t("listed"), value: "Listed" },
    { label: t("de-listed"), value: "De-listed" },
  ];

  const yearsList = generateArrayOfYears().map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });

  useQuery({
    queryKey: ["pending_loan_statuses_1", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
      });
      return fetchOriginationLoanStatuses(params);
    },
    onSuccess: (res: any) => {
      const parsedLoanStatus = JSON.parse(res?.data);
      setRawStatus(parsedLoanStatus?.statuses);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const filteredLoanStatus = rawStatus?.map((status: ILoanStatuses) => {
      return {
        label: t(status?.value, { ns: "loan-status" }),
        value: status?.value,
      };
    });
    setFetchLoanStatuses(filteredLoanStatus);
  }, [rawStatus, t]);

  const { isLoading: carMakesLoading } = useQuery({
    queryKey: ["get_cars_makes"],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "400",
      });
      return fetchCarMakes(params);
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carMakes = parsedResults?.makeList?.map((make: ICarMakes) => {
        return {
          label: make?.name,
          value: make?.id,
        };
      });
      setCarMakes(carMakes);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carModelLoading } = useQuery({
    queryKey: ["get_cars_models", country, carMakeId?.value],
    queryFn: () => {
      const params = new URLSearchParams({
        country_code: country,
        page_size: "500",
        make_id: carMakeId?.value,
      });
      return fetchCarModels(params);
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carModels = parsedResults?.modelList?.map((model: ICarModels) => {
        return {
          label: model?.name,
          value: model?.id,
        };
      });
      setCarModels(carModels);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!carMakeId?.value,
  });

  const { isLoading: loadingFranchise } = useQuery({
    queryKey: ["get_franchise", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        page_size: "1000",
      });
      return fetchFranchise(params);
    },
    onSuccess: (res: any) => {
      const parsedFranchise = JSON.parse(res?.data);
      const franchises = parsedFranchise?.result?.map((franchise: any) => {
        return {
          label: franchise?.name,
          value: franchise?.id,
        };
      });
      setFranchiseData(franchises);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleMinValue = (value: any) => {
    if (minMaxValue.max > value) {
      setMinMaxValue({ min: value, max: minMaxValue.max });
    }
    setMinMaxValue({ min: value, max: minMaxValue.max });
  };

  const handleMaxValue = (value: any) => {
    if (minMaxValue.min < value) {
      setMinMaxValue({ min: minMaxValue.min, max: value });
    }
  };

  const handleApplyFilter = () => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        ...(carMakeId?.value && { make_id: carMakeId?.value }),
        ...(carModelId?.value && { model_id: carModelId?.value }),
        ...(year?.value && { year_low: year?.value, year_high: year?.value }),
        ...(String(minMaxValue.min) && {
          min_loan_value: String(minMaxValue.min),
        }),
        ...(String(minMaxValue.max) && {
          max_loan_value: String(minMaxValue.max),
        }),
        ...(inspectionStatusObj?.value && {
          status: inspectionStatusObj?.value,
        }),
        ...(franchiseId?.value && { franchise_id: franchiseId?.value }),
      })}`
    );
    if (type === "modal" && setOpenFilter) setOpenFilter(false);
  };

  const handleResetFilter = () => {
    setMinMaxValue({
      min: 0,
      max: 0,
    });
    setCarMakeId({ label: "", value: "" } as ICarMakeObj);
    setCarModelId({ label: "", value: "" } as ICarModelObj);
    setYear({ label: "", value: "" } as IYearObj);
    setInspectionStatusObj({ label: "", value: "" } as IInspectionObj);
    setFranchiseId({ label: "", value: "" } as IFranchiseObj);
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        make_id: "",
        model_id: "",
        year_low: "",
        year_high: "",
        min_loan_value: "",
        max_loan_value: "",
        status: "",
        franchise_id: "",
      })}`
    );
    if (type === "modal" && setOpenFilter) setOpenFilter(false);
  };

  return (
    <div className="filter-field">
      <FilterLoanValue>
        <div className="filter-loan-value relative md:absolute w-full md:w-[480px]">
          <div className="filter-loan-container bg-white shadow-none md:shadow-[0_0px_15px_rgba(0,0,0,0.1)] p-0 pb-9 md:px-6 md:pt-6 md:pb-8">
            {isAccountManager &&
              ["inventory", "select-car"].includes(variant) && (
                <div className="mb-3">
                  <div className="text-base text-[#000000] font-medium mb-1">
                    Dealer
                  </div>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      id="filter_dealers"
                      disablePortal
                      loading={loadingFranchise}
                      options={franchiseData}
                      onChange={(e, newValue: any) => {
                        setFranchiseId(newValue);
                      }}
                      getOptionLabel={(option) => option.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="inventory_search_inputField"
                          placeholder={t("Select dealer")}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              )}

            {variant === "inventory" && (
              <Fragment>
                <div className="mb-3">
                  <div className="text-base text-[#000000] font-medium mb-1">
                    Make
                  </div>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      id="filter_makes"
                      disablePortal
                      options={carMakes}
                      value={carMakeId}
                      onChange={(e, newValue: any) => {
                        setCarMakeId(newValue);
                      }}
                      loading={carMakesLoading}
                      getOptionLabel={(option) => option.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="inventory_search_inputField"
                          placeholder={t("Select make")}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="mb-3">
                  <div className="text-base text-[#000000] font-medium mb-1">
                    Model
                  </div>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      id="filter_models"
                      disablePortal
                      options={carModels}
                      onChange={(e, newValue: any) => {
                        setCarModelId(newValue);
                      }}
                      loading={carModelLoading}
                      getOptionLabel={(option) => option.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="inventory_search_inputField"
                          placeholder={t("Select model")}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="mb-3">
                  <div className="text-base text-[#000000] font-medium mb-1">
                    Year
                  </div>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      id="filter_loans"
                      disablePortal
                      options={yearsList}
                      onChange={(e, newValue: any) => {
                        setYear(newValue);
                      }}
                      getOptionLabel={(option) => option.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="inventory_search_inputField"
                          placeholder={t("Select year")}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </Fragment>
            )}

            <div className="mb-3">
              <div className="text-base text-[#30345e] font-medium mb-1">
                {["dealer-financing", "others"].includes(variant)
                  ? "Loan status"
                  : "Inspection status"}
              </div>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  id="filter_loans"
                  disablePortal
                  options={
                    ["dealer-financing", "others"].includes(variant)
                      ? fetchLoanStatuses
                      : inspectionStatusList
                  }
                  onChange={(e, newValue: any) => {
                    setInspectionStatusObj(newValue);
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="inventory_search_inputField"
                      placeholder={
                        ["dealer-financing", "others"].includes(variant)
                          ? "Select loan status"
                          : t("Select inspection status")
                      }
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="mb-8">
              <div className="text-base text-[#30345e] font-semibold mb-1">
                Loan Value
              </div>
              <div className="flex flex-col md:flex-row md:items-center gap-2 w-full max-w-full">
                <div className="mb-3 md:mb-0 w-full">
                  <div className="text-base text-[#000000] font-medium mb-1">
                    {t2("min-loan-value")}
                  </div>
                  <CInputNumber>
                    <InputNumber
                      formatter={(value: any) => {
                        return (
                          getCountryCurrency(country) +
                          " " +
                          formatCurrency(value)
                        );
                      }}
                      onChange={handleMinValue}
                      value={minMaxValue.min}
                      className="w-full"
                    />
                  </CInputNumber>
                </div>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Line-1.svg"
                  alt="line-icon"
                  className="mt-5 hidden md:block"
                />
                <div className="w-full">
                  <div className="text-base text-[#000000] font-medium mb-1">
                    {t2("max-loan-value")}
                  </div>
                  <CInputNumber>
                    <InputNumber
                      formatter={(value: any) => {
                        return (
                          getCountryCurrency(country) +
                          " " +
                          formatCurrency(value)
                        );
                      }}
                      onChange={handleMaxValue}
                      value={minMaxValue.max}
                      className="w-full"
                    />
                  </CInputNumber>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <OutlineButton className="w-full" onClick={handleResetFilter}>
                {t2("Clear Filter")}
              </OutlineButton>
              <ApplyLoanButton className="w-full" onClick={handleApplyFilter}>
                {loading ? <Loader variant={"secondary"} /> : t2("Save")}
              </ApplyLoanButton>
            </div>
          </div>
        </div>
      </FilterLoanValue>
    </div>
  );
};

export default InventoryCustomFilter;
