export const toCamelCase = (str: string): string => {
  if (!str) return "";

  // A safer regex that splits by non-alphanumeric characters
  const words: string[] = str.split(/[^a-zA-Z0-9]+/).filter(Boolean);

  if (!words.length) return "";

  const formattedString: string = words
    .map((word, index) => {
      const lowerCaseWord = word.toLowerCase();
      // Capitalize the first letter of each word except the first one
      return index === 0
        ? lowerCaseWord
        : lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
    })
    .join("");

  return formattedString;
};
