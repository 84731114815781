import { Navigate, useLocation } from "react-router-dom";
import storage from "@/lib/storage";

const RequireAuthentication = ({ children }: IRequireAuthentication) => {
  const location = useLocation();
  const token = storage.getToken();

  return token ? (
    <>{children}</>
  ) : (
    <Navigate to="/auth/sign-in" state={{ path: location.pathname }} />
  );
};

export default RequireAuthentication;
