import SubmitConfirmation from "@/components/SubmitConfirmation";

export type ISubmitPath =
  | "loan-limit"
  | "master-agreement"
  | "dealer-financing";

export default function DealerLimitConfirmation() {
  const queryParameters = new URLSearchParams(window.location.search);
  const getPath = queryParameters.get("path");

  return (
    <main>
      <SubmitConfirmation path={getPath as ISubmitPath} />
    </main>
  );
}
