import { useMemo } from "react";
import { OldLoansContainer } from "./styles";
import formatInt from "@/utils/format-int";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { convertCamelCase } from "@/utils/convertCamelCase";
import NoLoanOffers from "./NoLoanOffer";
import { useLoanDocument, useDocumentByLoan } from "@/hooks/use-loan";

interface ILoansById {
  loansById: any;
  id: string;
}

const OldLoansLayout = ({ loansById, id }: ILoansById) => {
  const { isSuccess: isLDSuccess, data: ldData } = useLoanDocument(id, true);
  const { isSuccess: isDLSuccess, data: dlData } = useDocumentByLoan(id, true);

  const dlResponse = isDLSuccess && JSON.parse(dlData?.data);

  const documents =
    loansById &&
    loansById.customer?.profile?.filter(
      (profile: any) =>
        profile.type === "file" ||
        profile.value?.startsWith("https://storage.googleapis.com"),
    );

  const requestedDocuments = useMemo(() => {
    if (isLDSuccess && ldData) {
      return JSON.parse(ldData?.data)?.documents?.filter(
        (data: Documents) => data?.status === "REQUESTED",
      );
    } else {
      return [];
    }
  }, [isLDSuccess, ldData]);

  return (
    <div>
      {loansById?.offers?.length === 0 ? (
        <NoLoanOffers />
      ) : (
        <OldLoansContainer>
          <div className="document-container">
            <div className="header">Documents</div>
            <div className="kyc-document-container">
              {documents?.length !== 0 && (
                <div className="kyc-container">
                  <div>
                    <div className="offer-heading">KYC Documents</div>
                    <div className="document-list">
                      {documents?.map((doc: any, index: number) => (
                        <div key={documents.indexOf(doc)}>
                          <a href={doc?.value} target="_blank" rel="noreferrer">
                            <DescriptionIcon
                              sx={{ marginRight: "4px", color: "#7F83A8" }}
                            />
                            <span>{convertCamelCase(doc?.name) || "---"}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {requestedDocuments?.length !== 0 && (
                <div className="kyc-container">
                  <div>
                    <div className="offer-heading">Requested Documents</div>
                    <div className="document-list">
                      {requestedDocuments?.map((doc: any, index: number) => (
                        <div key={doc?.id}>
                          <div>
                            <span style={{ marginRight: "4px" }}>
                              {convertCamelCase(doc?.name) || "---"}
                            </span>
                            <span>
                              {convertCamelCase(doc?.status) || "---"}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {dlResponse?.loanDocuments?.length !== 0 && (
                <div className="kyc-container">
                  <div>
                    <div className="offer-heading">Loan Documents</div>
                    <div className="document-list">
                      {dlResponse?.loanDocuments?.map(
                        (doc: any, index: number) => (
                          <div key={dlResponse.indexOf(doc)}>
                            <a
                              href={doc?.fileUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <DescriptionIcon
                                sx={{ marginRight: "4px", color: "#7F83A8" }}
                              />
                              <span>
                                {convertCamelCase(doc?.name) || "---"}
                              </span>
                            </a>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="header">Loan Offers</div>
            <div className="offer-container">
              {loansById?.offers?.map((offer: any, index: number) => (
                <div key={offer?.id}>
                  <div className="offer-heading">{offer?.partner?.name}</div>
                  <div className="offer-list">
                    <div>
                      <div className="name">Loan Amount</div>
                      <div className="value">
                        {formatInt(loansById?.loanValue, true)}
                      </div>
                    </div>
                    <div>
                      <div className="name">Approved Amount</div>
                      <div className="value">
                        {formatInt(offer?.approvedAmount, true)}
                      </div>
                    </div>
                    <div>
                      <div className="name">Interest Rate</div>
                      <div className="value">
                        {offer?.interest ? `${offer?.interest}%` : "---"}
                      </div>
                    </div>
                    <div>
                      <div className="name">Loan Duration</div>
                      <div className="value">
                        {offer?.tenure ? `${offer?.tenure} months` : "---"}
                      </div>
                    </div>
                    <div>
                      <div className="name">Monthly Repayment</div>
                      <div className="value">
                        {formatInt(offer?.monthlyRepayment, true)}
                      </div>
                    </div>
                    <div>
                      <div className="name">Equity</div>
                      <div className="value">
                        {formatInt(offer?.equity, true)}
                      </div>
                    </div>
                    <div>
                      <div className="name">Status</div>
                      <div className="value">{offer?.status || "---"}</div>
                    </div>
                    {offer?.reasonForDeclination && (
                      <div>
                        <div className="name">
                          Reason for rejection {offer?.declinedBy}:
                        </div>
                        <div className="value">
                          {offer?.reasonForDeclination === "Other"
                            ? offer?.reasonForDeclinationDescription
                            : offer?.reasonForDeclination}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </OldLoansContainer>
      )}
    </div>
  );
};

export default OldLoansLayout;
