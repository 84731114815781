export const dealerLevelsData = [
  {
    type: "bronze",
    title: "BRONZE (LEVEL 1) DEALER",
    description: "Sell 1 car (200PTS) / month to unlock",
    medalIcon:
      "https://media.autochek.africa/file/publicAssets/Frame-37100.png",
    typeDescription: [
      "Sell 1 car (200 PTS) to unlock",
      "Up to 1.5% in commission",
      "Get 10pts for every new lead",
    ],
    typeList: [
      {
        benefitTitle: "An Account Manager",
        benefitList: [
          "Immersive - Hybrid approach",
          "DealerPlus Input Support",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/portfolio-bag-svgrepo.com.png",
      },
      {
        benefitTitle: "Level 1 Incentives",
        benefitList: [
          "1st Level Dealer Incentive Commission ",
          "Repossession Sales",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/gift-svgrepo.com.png",
      },
      {
        benefitTitle: "Financing",
        benefitList: ["Local Trading Financing"],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/coin-svgrepo.com.png",
      },
      {
        benefitTitle: "Marketing",
        benefitList: ["Marketing budget of $150", "Branded Merchandise"],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/marketing-svgrepo.com.png",
      },
    ],
  },
  {
    type: "silver",
    title: "SILVER (LEVEL 2) DEALER",
    description: "Sell 3 cars (600 PTS)/month to unlock",
    medalIcon: "https://media.autochek.africa/file/publicAssets/Medals-6.png",
    typeDescription: [
      "Sell 3 cars (600PTS) to unlock",
      "Up to 1.8% in commission",
      "Get 10pts for every new lead",
    ],
    typeList: [
      {
        benefitTitle: "An Account Manager",
        benefitList: [
          "Immersive - Hybrid approach",
          "DealerPlus Input Support",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/portfolio-bag-svgrepo.com.png",
      },
      {
        benefitTitle: "Silver Incentives",
        benefitList: [
          "2nd Level DIC",
          "Repossession Sales",
          "Custom Duty Checks",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/gift-svgrepo.com.png",
      },
      {
        benefitTitle: "Financing",
        benefitList: ["Duty Financing", "Local Trade Financing"],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/coin-svgrepo.com.png",
      },
      {
        benefitTitle: "Marketing",
        benefitList: [
          "Marketing budget of $250",
          "Branded Merchandise",
          "Online Boosts",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/marketing-svgrepo.com.png",
      },
    ],
  },
  {
    type: "gold",
    title: "GOLD (LEVEL 3) DEALER",
    description: "Sell 6 cars (1200)/month to unlock",
    medalIcon: "https://media.autochek.africa/file/publicAssets/Medals-7.png",
    typeDescription: [
      "Sell 6 cars (1200 PTS) to unlock",
      "Up to 1.9% in commission",
      "Get 10pts for every new lead",
    ],
    typeList: [
      {
        benefitTitle: "An Account Manager",
        benefitList: ["Hybrid approach"],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/portfolio-bag-svgrepo.com.png",
      },
      {
        benefitTitle: "Level 3 Incentives",
        benefitList: [
          "3rd Level DIC",
          "Import  Financing",
          "Dealer Landing Page",
          "Partnerships",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/gift-svgrepo.com.png",
      },
      {
        benefitTitle: "Financing",
        benefitList: [
          "Import Financing",
          "Duty Financing",
          "Local Trade Financing",
          "Dealer White Label",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/coin-svgrepo.com.png",
      },
      {
        benefitTitle: "Marketing",
        benefitList: [
          "$500 marketing budget",
          "Branded Merchandise",
          "Online Boosts",
          "Video/Testimonial",
          "Co-branded signage",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/marketing-svgrepo.com.png",
      },
    ],
  },
  {
    type: "platinum",
    title: "PLATINUM (LEVEL 4) DEALER",
    description: "Sell 12 cars (2400 PTS)/month to unlock",
    medalIcon: "https://media.autochek.africa/file/publicAssets/Medals-8.png",
    typeDescription: [
      "Sell 12 cars (2400 PTS) to unlock",
      "Up to 2.2% in commission",
      "Get 10pts for every new lead",
      "Get 200pts for every car sale",
    ],
    typeList: [
      {
        benefitTitle: "An Account Manager",
        benefitList: ["Long arm approach"],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/portfolio-bag-svgrepo.com.png",
      },
      {
        benefitTitle: "Level 4 Incentives",
        benefitList: [
          "Highest level of incentives",
          "Dealer Landing Page",
          "Partnerships",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/gift-svgrepo.com.png",
      },
      {
        benefitTitle: "Financing",
        benefitList: [
          "Import Financing",
          "Duty Financing",
          "Local Trade Financing",
          "Dealer White Label",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/coin-svgrepo.com.png",
      },
      {
        benefitTitle: "Marketing",
        benefitList: [
          "$1000 marketing budget",
          "Branded Merchandise",
          "Online Boosts",
          "Video/Testimonial",
          "Co-branded signage",
        ],
        benefitIcon:
          "https://media.autochek.africa/file/publicAssets/marketing-svgrepo.com.png",
      },
    ],
  },
];
