import { useState } from "react";
import styled from "styled-components";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import storage from "@/lib/storage";
import { ImageLoader } from "@/utils/ImageLoader";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;

const ImageContainer = styled.div`
  width: 302px;
  height: 195px;
  border-radius: 16px;
  display: grid;
  place-items: center;
`;

type props = {
  label: string;
  name: string;
  onUploaded: (data: any) => void;
  image?: any;
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

function ImageUploadInput({ label, name, onUploaded, image }: Readonly<props>) {
  const [imageResUrl, setImageResUrl] = useState(image.url);
  const [loadingImage, setLoadingImage] = useState(false);
  const dimensions = { width: 302, height: 195 };
  const { t } = useTranslation("car-upload");
  const token = storage.getToken();

  const handleOnUploadCompleted = async (url: string) => {
    const data = {
      name: name,
      type: "image",
      url: url,
    };
    onUploaded(data);
  };

  const uploadButton = (
    <div
      style={{
        width: dimensions.width,
        height: dimensions.height,
        display: "grid",
        placeContent: "center",
        zIndex: 2,
      }}
    >
      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
    </div>
  );

  const generateCompressedUrl = (url: string) => {
    let img = document.createElement("img");
    img.src = url;
    let compressedUrl = "";
    img.onload = function () {
      compressedUrl = ImageLoader({
        src: url,
        width: img.naturalWidth > 1200 ? 1200 : img.naturalWidth,
        quality: 100,
      });

      setImageResUrl(compressedUrl);
      handleOnUploadCompleted(compressedUrl);
    };

    return compressedUrl;
  };

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: `${API_URL}image/upload`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      if (info.file.status === "uploading") {
        setLoadingImage(true);
        return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj as RcFile, (url) => {
          setLoadingImage(false);
          message.success(`${info.file.name} file uploaded successfully.`);
          generateCompressedUrl(info.file.response.file.url);
        });
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div
      style={{
        backgroundImage: `url(${imageResUrl && "/images/spinner.gif"})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundPositionX: 115,
        backgroundPositionY: 60,
      }}
    >
      <ImageContainer
        style={{
          backgroundImage: `url(${imageResUrl})`,
          display: "grid",
          placeContent: "center",
          backgroundPosition: "center",
          backgroundSize: `${imageResUrl ? "cover" : "contain"}`,
          backgroundRepeat: "no-repeat",
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: 302,
            height: 195,
            background: "#EAEAEA",
            position: "absolute",
            opacity: 0.5,
            zIndex: 2,
          }}
        ></div>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          {...props}
          accept=".png, .jpg"
        >
          {uploadButton}
        </Upload>
      </ImageContainer>
      <div>{t(label)}</div>
    </div>
  );
}

export default ImageUploadInput;
