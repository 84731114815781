import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { uploadSignedDocuments } from "@/hooks/use-loan";
import storage from "@/lib/storage";
import { Alert, Box, Container, Snackbar } from "@mui/material";
import InputControl from "@/components/formElements/input-control";
import NewUploadButton from "@/components/formElements/new-upload-button";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { TableContainer } from "@/layouts/application-layouts/styles";
import { useTranslation } from "react-i18next";
import { Loader } from "../auth/styles/loader";

const SubmitButton = styled.div`
  .MuiButtonBase-root {
    background: #ffb619;
    color: #30345e;
    border-radius: 4rem;
    font-weight: 600;

    &:disabled {
      background: #d6dbe2;
    }

    &:hover {
      background: #d89300;
    }
  }
`;

const UploadSignedDocuments = () => {
  const country = storage.getCountry();
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const loanId = queryParameters.get("loan_id");
  const loanDocument: any = queryParameters.get("loan_doc");
  const offerId: any = queryParameters.get("offer_id");
  const partnerName: any = queryParameters.get("partner");
  const methods = useForm();
  const { control, handleSubmit } = methods;
  const role = storage.getRole();
  const filterLoanDocument = JSON.parse(loanDocument);
  const { t } = useTranslation("loan-form");
  const queryClient = useQueryClient();

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  const { isLoading: loanByIdLoading } = useQuery({
    queryKey: ["loans_by_ids", country, loanId],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${loanId}`);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const onSubmitDocs = async (values: any) => {
    for (let item of Object.keys(values)) {
      if (!values?.[item]?.data) {
        const doc = filterLoanDocument?.find(
          (field: any) => field.name === item
        );
        setAlert(`${doc?.name} is required`);
        return;
      }
    }

    const documents = Object.entries(values).map(([key, value]: any) => ({
      name: key,
      file_url: value?.data,
      description: key,
    }));

    try {
      setLoading(true);
      await uploadSignedDocuments(loanId as string, {
        loan_id: loanId as string,
        offer_id: offerId as string,
        documents,
      });
      navigate(`/applications/loans/${loanId}`);
      queryClient.invalidateQueries(["fetch_in_app_notifications"]);
    } catch (error: any) {
      setLoading(false);
      setAlert(JSON.parse(error?.response?.data)?.message);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <button
        className="flex items-center gap-1.5"
        onClick={() => navigate(-1)}
      >
        <img
          src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
          alt="left-arrow"
        />
        <span className="text-sm font-semibold">Go Back</span>
      </button>

      <div className="flex flex-col items-center">
        <div className="text-xl text-[#30345e] font-semibold mb-0.5">
          {t("upload-signed-loan-documents")}
        </div>
        <div className="text-base">
          By uploading, you are accepting an offer from {partnerName}
        </div>
      </div>

      <Container>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={!!alert}
          autoHideDuration={5000}
          onClose={() => setAlert("")}
        >
          <Alert severity="error" variant="filled">
            {alert}
          </Alert>
        </Snackbar>

        {loanByIdLoading ? (
          <TableContainer>
            <div className="filter-mobile-loader-wrapper">
              <Loader variant={"secondary"} />
            </div>
          </TableContainer>
        ) : (
          <div>
            {filterLoanDocument?.map((item: any) => (
              <InputControl
                key={filterLoanDocument.indexOf(item)}
                control={control}
                name={item.name}
              >
                {({ value, onChange }) => (
                  <NewUploadButton
                    fileType={item.fileType}
                    label={t(item.name)}
                    value={value}
                    onChange={onChange}
                  />
                )}
              </InputControl>
            ))}
          </div>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "center", paddingY: "30px" }}
        >
          <SubmitButton>
            <LoadingButton
              variant="contained"
              color="secondary"
              sx={{ width: "218px", height: "43px" }}
              onClick={handleSubmit(onSubmitDocs)}
              loading={loading}
            >
              {t("Upload Documents")}
            </LoadingButton>
          </SubmitButton>
        </Box>
      </Container>
    </div>
  );
};

export default UploadSignedDocuments;
