import { Typography } from "@mui/material";

interface ReasonProps {
  title: string;
  subtitle: string;
  items: string[];
  footer: string;
}

const CheckItem = ({ text }: { text: string }) => (
  <div style={{ display: "flex", gap: 12, margin: "16px auto" }}>
    <img
      src="https://media.autochek.africa/file/publicAssets/check-circle-1.svg"
      alt=""
    />
    <Typography>{text}</Typography>
  </div>
);

const ReasonSection = ({
  t,
  title,
  subtitle,
  items,
  footer,
}: ReasonProps & { t: (key: string) => string }) => (
  <>
    <Typography>{t(title)}</Typography>
    <Typography sx={{ mt: 2 }}>{t(subtitle)}</Typography>
    {items.map((itemKey) => (
      <CheckItem key={itemKey} text={t(itemKey)} />
    ))}
    <Typography sx={{ textAlign: "left" }}>{t(footer)}</Typography>
  </>
);

// ALL REASONS

export const vehicleReasonsCI = (t: (key: string) => string) => (
  <>
    <div style={{ display: "flex", gap: 12, margin: "16px auto" }}>
      <img
        src="https://media.autochek.africa/file/publicAssets/check-circle-1.svg"
        alt=""
      />
      <Typography>
        {t("all-brand-new-vehicles-with-maximum-finance-term-48 months")}
      </Typography>
    </div>
    <div style={{ display: "flex", gap: 12, margin: "16px auto" }}>
      <img
        src="https://media.autochek.africa/file/publicAssets/check-circle-1.svg"
        alt=""
      />
      <Typography>
        {t(
          "All-foreign-and-locally-used-vehicles-that-are-5-years-old-newer-with-maximum-finance-term-of-48-months"
        )}
      </Typography>
    </div>
    <div style={{ display: "flex", gap: 12, margin: "16px auto" }}>
      <img
        src="https://media.autochek.africa/file/publicAssets/check-circle-1.svg"
        alt=""
      />
      <Typography>{t("finance-trucks-buses-motorcycles")}</Typography>
    </div>
  </>
);

export const affordabilityReasonsNG = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title="monthly-instalment-for-the-vehicle"
    subtitle="increase-your-chances-of-approval"
    items={[
      "higher-upfront-equity-amount",
      "select-a-longer-finance-term",
      "vehicle-with-a-lower-selling-price",
      "declared-income-and-expenses",
    ]}
    footer="we-apologize-appreciate-your-understanding"
  />
);

export const scoreReasonsNG = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title="low-credit-score-insufficient-financial"
    subtitle="improve-your-likelihood-of-approval"
    items={[
      "higher-portion-of-the-deal",
      "anonymised-financial-information-from-your-mobile",
    ]}
    footer="we-apologize-appreciate-your-understanding"
  />
);

export const ageReasonsNG = (t: (key: string) => string) => (
  <>
    <Typography sx={{ textAlign: "left" }}>
      {t("finance-applicants-older-than-21-years")}
    </Typography>
    <Typography sx={{ textAlign: "left", mt: 2 }}>
      {t("apologize-for-any-inconvenience-caused")}
    </Typography>
  </>
);

export const validationReasonsNG = (t: (key: string) => string) => (
  <>
    <Typography sx={{ textAlign: "left" }}>
      {t("successfully-validate-the-documents")}
    </Typography>
    <Typography sx={{ mt: 2, textAlign: "left" }}>
      {t("thank-you-for-considering-we-apologize-for-any-inconvenience-caused")}
    </Typography>
  </>
);

export const otherReasonsNG = (t: (key: string) => string) => (
  <>
    <Typography sx={{ textAlign: "left" }}>
      {t("explore-alternative-financing-options")}
    </Typography>

    <Typography sx={{ textAlign: "left", mt: 2 }}>
      {t("apologize-for-any-inconvenience-caused")}
    </Typography>
  </>
);

// ALL VEHICLE REASONS BY PARTNERS

export const vehicleReasonsNGAccess = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("alternative-brand-or-a-newer-model")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "finance-term-of-60-months",
      "17-years-old-or-newer",
      "vehicles-that-are-20-years",
      "locally-used-vehicles-that-are-15-years",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsKEGetGari = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("selected-vehicle-is-not-eligible-for-financing")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "years-finance-term-of-48-months",
      "13-years-old-and-newer",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsNGGetGari = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("alternative-brand-or-a-newer-model")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "17-years-old-or-newer",
      "vehicles-that-are-20-years",
      "locally-used-vehicles-that-are-15-years",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsNGVFD = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("alternative-brand-or-a-newer-model")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "17-years-old-or-newer",
      "20-years-old-and-newer",
      "17-years-old-or-newer-24months",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsNGHallmark = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("alternative-brand-or-a-newer-model")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-maximum-finance-term-48 months",
      "vehicles-that-are-17-years-36months",
      "vehicles-that-are-20-years-36months",
      "locally-used-vehicles-that-are-15-years-risk-grade",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsNGStanbic = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("alternative-brand-or-a-newer-model")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "locally-foreign-used-vehicles-that-are-15-years",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsNGFCMB = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("alternative-brand-or-a-newer-model")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "locally-foreign-used-vehicles-that-are-14-years",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsKEIM = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("selected-vehicle-is-not-eligible-for-financing")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "popular-brand-new-vehicles-that-are-72-years",
      "popular-foreign-used-vehicles-that-are-8-years",
      "popular-locally-used-vehicles-that-are-8-years",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsGHGetGari = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("alternative-brand-or-a-newer-model")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "vehicles-that-are-12-years",
      "all-foreign-used-vehicles-that-are-15-years-old-newer-with-maximum-finance-term-of-48-months",
      "maximum-finance-term-of-36-months",
      "foreign-used-kia-hyundai-pickups",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsGHAccess = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title={t("selected-vehicle-is-not-eligible-for-financings")}
    subtitle={t("we-currently-finance-the-following-vehicles")}
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "all-used-vehicles-that-are-5-years",
      "finance-trucks-buses-motorcycles",
    ]}
    footer={t("we-apologize-appreciate-your-understanding")}
  />
);

export const vehicleReasonsUGGetGari = (t: (key: string) => string) => (
  <ReasonSection
    t={t}
    title="alternative-brand-or-a-newer-model"
    subtitle="we-currently-finance-the-following-vehicles"
    items={[
      "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months",
      "years-finance-term-of-48-months",
      "16-years-old-or-newer",
      "12-years-old-and-newer",
      "finance-trucks-buses-motorcycles",
    ]}
    footer="we-apologize-appreciate-your-understanding"
  />
);

export const vehicleReasonsSNGetGari = (t: (key: string) => string) => (
  <>
    <CheckItem
      text={t(
        "all-brand-new-vehicles-with-a-maximum-finance-term-of-60-months"
      )}
    />
    <CheckItem
      text={t("all-foreign-and-locally-used-vehicles-that-are-11-years")}
    />
    <CheckItem text={t("finance-trucks-buses-motorcycles")} />
  </>
);
