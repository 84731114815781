import { Segmented } from "antd";
import DealerFinancingList from "./DealerFinancingList";
import { CustomSegmentButton } from "@/layouts/application-layouts/styles";
import { useTranslation } from "react-i18next";

const DealerLoanApplications = ({
  activePage,
  setActivePage,
  query,
  fetchDealerLoans,
  dealerFinancingLoading,
  value,
  setValue,
}: any) => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <main>
      <CustomSegmentButton>
        <Segmented
          value={value}
          onChange={setValue}
          options={[
            {
              label: <div className="p-1">{t("loan-applications")}</div>,
              value: "loanApplications",
            },
            {
              label: <div className="p-1">{t("active-loans")}</div>,
              value: "activeLoans",
            },
          ]}
        />
      </CustomSegmentButton>
      <div className="pt-2">
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      </div>
    </main>
  );
};

export default DealerLoanApplications;
