import { InputFieldStyle } from "@/layouts/application-layouts/styles";
import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { FormInputProps } from "./types";
import FormInputLabel from "./FormInputLabel";
import FormInputComment from "./FormInputComment";
import { useTranslation } from "react-i18next";

export const FormInputNumber: React.FC<FormInputProps> = ({
  item,
  field,
  methods,
}) => {
  const errors = { ...methods.formState.errors } as Record<string, any>;
  const error = errors[item.name]?.message;
  const { t } = useTranslation("car-form");

  return (
    <div className="space-y-2">
      <FormInputLabel item={item} />
      <NumericFormat
        fullWidth
        placeholder={t(item.placeholder as string)}
        name={field.name}
        error={!!error}
        onBlur={field.onBlur}
        required={item.validation?.required}
        aria-label={item.label}
        thousandSeparator=","
        allowNegative={false}
        value={field.value}
        onChange={(e) => {
          field.onChange({
            ...e,
            target: {
              ...e.target,
              name: field.name,
            },
          });
        }}
        customInput={TextField}
        sx={InputFieldStyle}
      />
      <FormInputComment item={item} />
      {error && <span className="text-red-600 text-sm">{error}</span>}
    </div>
  );
};
