import { useState } from "react";
import { Button, message, Modal } from "antd";
import styled from "styled-components";
import { device } from "@/utils/device";
import formatInt from "@/utils/format-int";
import client from "@/lib/axios-client";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

interface IEmailModal {
  emailModal: boolean;
  handleOpenEmail: () => void;
  handleCloseEmail: () => void;
  loansById: any;
  loanDetails: any;
  id: string;
}

const AddressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .to-text {
    color: #6b7280;
    font-weight: 600;
    font-size: 16px;
  }
  .name,
  .address,
  .email {
    font-weight: 600;
    font-size: 16px;
    color: #202020;
    text-align: right;
  }
`;

const CongratsHeader = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 16px;
  .title {
    color: #30345e;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    @media ${device.mobileL} {
      font-size: 18px;
    }
  }
  .text {
    color: #30345e;
    font-size: 16px;
  }
`;

const CarDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background: #eef6ff;
  padding: 10px;
  margin-bottom: 20px;
  img {
    width: 226px;
    height: 141px;
    border-radius: 7px;
    @media ${device.mobileL} {
      width: 93px;
      height: 85px;
    }
  }
  ul {
    list-style-type: disc;
    margin-left: 16px;
    li {
      color: #30345e;
      font-weight: 600;
      font-size: 14px;
    }
  }
`;

export const LoanInformation = styled.div`
  .header {
    color: #30345e;
    font-weight: 600;
    font-size: 20px;
    padding: 5px 0px;
    @media ${device.mobileL} {
      font-size: 16px;
    }
  }
  ul {
    li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e7eb;
      padding: 5px 0px;
      .name {
        color: #6b7280;
        font-weight: 600;
        font-size: 16px;
        @media ${device.mobileL} {
          font-size: 14px;
        }
      }
      .value {
        color: #202020;
        font-weight: 600;
        font-size: 16px;
        @media ${device.mobileL} {
          font-size: 14px;
        }
      }
    }
  }
`;

const SendButton = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  .ant-btn {
    color: #30345e !important;
    font-weight: 600;
    font-size: 14px;
    background: #ffb619;
    border-radius: 60px;
    min-width: 257px;
    width: auto;
    height: 44px;
    border: 0;
    @media ${device.mobileL} {
      width: 100%;
    }
  }
`;

const EmailCustomer = ({
  emailModal,
  handleOpenEmail,
  handleCloseEmail,
  loanDetails,
  loansById,
  id,
}: IEmailModal) => {
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("email");
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    setLoading(true);
    const payload = {
      loan_id: id,
      offer_id: loanDetails?.id,
    };
    try {
      const response = await client.post(
        "/v1/origination/dig/loans/offer-email",
        payload,
      );
      if (response) {
        message.success(t1("offer-has-been-sent"));
        setLoading(false);
        handleCloseEmail();
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err.response?.data).error || t1("error-sending-offer"),
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="https://media.autochek.africa/file/publicAssets/logo-horizontal-a.svg"
              alt="brand"
            />
          </div>
        }
        open={emailModal}
        onOk={handleOpenEmail}
        onCancel={handleCloseEmail}
        width="631px"
        closeIcon={
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "14px" }}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/x-close-6.svg"
              alt="close"
            />
          </div>
        }
        footer={null}
        wrapClassName="email-customer-modal"
      >
        <AddressHeader>
          <div className="to-text">{t1("to")}</div>
          <div>
            <div className="name">{`${loansById?.firstName} ${loansById?.lastName}`}</div>
            <div className="email">{loansById?.email}</div>
          </div>
        </AddressHeader>

        <CongratsHeader>
          <div>
            <img
              src="https://media.autochek.africa/file/publicAssets/stepIcon.svg"
              alt="tick"
            />
          </div>
          <div>
            <div className="title">
              {t1("congratulations-you-have-an-offer")}.
            </div>
            <div className="text">
              {t1("here-are-the-details-of-your-offer")}
            </div>
          </div>
        </CongratsHeader>

        <CarDetails>
          <div>
            <img
              src={loansById?.car?.imageUrl}
              alt="car"
              style={{ width: "226px", height: "141px" }}
            />
          </div>
          <div>
            <ul>
              <li>
                {t("car-name")}:{" "}
                {loansById?.car?.model?.make?.name
                  ? `${loansById?.car?.model?.make?.name} ${loansById?.car?.model?.name} ${loansById?.car?.year}`
                  : "---"}
              </li>
              <li>
                {t("vin")}: {loansById?.car?.vin || "---"}
              </li>
              <li>
                {t1("interior-color")}: {loansById?.car?.interiorColor || "---"}
              </li>
              <li>
                {t1("exterior-color")}: {loansById?.car?.exteriorColor || "---"}
              </li>
            </ul>
          </div>
        </CarDetails>

        <LoanInformation style={{ marginBottom: "10px" }}>
          <div className="header">{t("loan-details")}</div>
          <ul>
            <li>
              <div className="name">{t("car-price")}</div>
              <div className="value">
                {formatInt(loansById?.car?.marketplacePrice, true)}
              </div>
            </li>
            <li>
              <div className="name">{t("loan-amount")}</div>
              <div className="value">
                {formatInt(loansById?.loanValue, true)}
              </div>
            </li>
            <li>
              <div className="name">{t("Equity")}</div>
              <div className="value">
                {formatInt(loanDetails?.equity, true)}
              </div>
            </li>
            <li>
              <div className="name">{t("monthly-repayment")}</div>
              <div className="value">
                {formatInt(loanDetails?.monthlyRepayment, true)}
              </div>
            </li>
            <li>
              <div className="name">{t("tenure")}</div>
              <div className="value">
                {loanDetails?.tenure || "---"} {t("months")}
              </div>
            </li>
            <li>
              <div className="name">{t("interest")}</div>
              <div className="value">{t("available-on-request")}</div>
            </li>
          </ul>
        </LoanInformation>

        <LoanInformation>
          <div className="header">{t("additional-fees")}</div>
          <ul>
            {loanDetails?.additionalPayments?.map((el: any, index: number) => (
              <li key={loanDetails?.additionalPayments.indexOf(el)}>
                <div className="name">{t(el?.name)}</div>
                <div className="value">{formatInt(el?.value, true)}</div>
              </li>
            ))}
          </ul>
        </LoanInformation>

        <SendButton>
          <Button onClick={onFinish}>
            {loading ? <Loader variant={"secondary"} /> : t1("send-email")}
          </Button>
        </SendButton>
      </Modal>
    </div>
  );
};

export default EmailCustomer;
