import React, { useState } from "react";
import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button, message } from "antd";
import LoanInfo from "@/layouts/application-layouts/LoanInfo";
import FinalOfferLayout from "@/layouts/application-layouts/FinalOfferTab";
import { device } from "@/utils/device";
import { useNavigate } from "react-router-dom";
import NoLoanOffers from "@/layouts/application-layouts/NoLoanOffer";
import DealerConditionalOffer from "@/layouts/application-layouts/DealerConditionalOffer";
import DealerFinalOffer from "@/layouts/application-layouts/DealerFinalOffer";
import storage from "@/lib/storage";
import client from "@/lib/axios-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader } from "@/pages/auth/styles/loader";
import formatLoanInt from "@/utils/format-loan-currency";

const CarContainer = styled.div`
  .MuiAccordion-root {
    background: #fff;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .MuiAccordionDetails-root {
    border-top: 1px solid rgb(229, 231, 235);
  }
`;

const CustomButton = styled.div`
  margin: 0 auto;
  .custom-button {
    color: rgb(48, 52, 94) !important;
    border-color: #ffb619 !important;
    height: 100%;
    padding: 6px 12px;
    align-items: center;
    justify-content: center;
    display: block;
    width: auto;
    @media ${device.mobileL} {
      display: none;
    }
    &:disabled {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
  .custom-buttom-new {
    color: rgb(48, 52, 94) !important;
    border: 0;
    height: 100%;
    padding: 6px 0;
    align-items: center;
    justify-content: center;
    display: none;
    @media ${device.mobileL} {
      display: block;
    }
  }
`;

interface ILoanOfferList {
  id: string;
  conditionalOffer: Record<string, any>;
  loansById: Record<string, any>;
  tabKey: string;
}

const LoanOfferList = ({
  id,
  conditionalOffer,
  loansById,
  tabKey,
}: ILoanOfferList) => {
  const { t } = useTranslation("application");
  const navigate = useNavigate();
  const isAccountManager = storage.utilities.isAccountManager();
  const isFranchise = storage.utilities.isFranchise();
  const isDsaAgent = storage.utilities.isDsaAgent();
  const isDealerDsa = storage.utilities.isDealerDsa();
  const [expanded, setExpanded] = useState();
  const user = storage.getUser();
  const isAssumedUser = storage.getAssumedUser();

  const REJECTION_STATUSES = ["REJECTED", "OFFER_DECLINED", "OFFER_REJECTED"];
  const REFERRED_STATUS = ["OFFER_REFERRED"];
  const PRODUCT_NAMES = [
    "Import Finance",
    "Repo Finance",
    "Duty Finance",
    "Local Finance",
  ];

  const regenerateOffer = async (loanId: string) => {
    try {
      const response = await client.get(
        `/v2/origination/${loanId}/regenerate-offer`
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const useRegenerateOffer = () => {
    const query = useQueryClient();
    return useMutation((loanId: string) => regenerateOffer(loanId), {
      onSuccess: (data) => {
        query.invalidateQueries(["loans_by_id"]);
        message.success("Offer regenerated successfully!");
      },
      onError: (error: any) => {
        message.error("Error regenerating offer!");
        console.error("Error regenerating offer:", error);
      },
    });
  };

  const { mutate, isLoading } = useRegenerateOffer();

  const handleRegenerateOffer = (loanId: string) => {
    mutate(loanId);
  };

  const handleAccordionChange =
    (panel: any) => (event: React.SyntheticEvent, newExpanded: any) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getCommission = (offer: Record<string, any>, code: string) => {
    if (!offer) return "---";

    if (isFranchise) {
      return formatLoanInt(offer.dealerCommission, code);
    } else if (isAccountManager) {
      return formatLoanInt(offer.accountManagerCommission, code);
    } else if (isDsaAgent) {
      return formatLoanInt(offer.dsaCommission, code);
    } else if (isDealerDsa) {
      return formatLoanInt(offer.dealerDsaCommission, code);
    }
  };

  const renderOfferDetails = (offer: Record<string, any>) => {
    const loanCurrency = offer?.loanCurrency || loansById?.currency;

    return (
      <div className="flex gap-3">
        <div className="flex-1">
          <div
            className={`grid grid-cols-2 ${renderLoanClassName()} gap-2 md:gap-3 items-center`}
          >
            <div>
              <div className="text-xs text-[#8F8F8F] font-bold">
                {t("loan-amount")}
              </div>
              <div className="text-[#31355B] text-[12px] font-bold font-[OutfitBold]">
                {formatLoanInt(offer?.approvedAmount, loanCurrency)}
              </div>
            </div>
            <div>
              <div className="text-xs text-[#8F8F8F] font-bold">
                {t("Downpayment")}
              </div>
              <div className="text-[#31355B] text-[12px] font-bold font-[OutfitBold]">
                {formatLoanInt(
                  offer?.additionalPayments?.find(
                    (item: any) => item?.name === "Total upfront payment"
                  )?.value,
                  loanCurrency
                )}
              </div>
            </div>
            <div>
              <div className="text-xs text-[#8F8F8F] font-bold">
                {t("tenure")}
              </div>
              <div className="text-[#31355B] text-[12px] font-bold font-[OutfitBold]">
                {offer?.tenure} {t("months")}
              </div>
            </div>
            <div>
              <div className="text-xs text-[#8F8F8F] font-bold">
                {t("interest-rate")}
              </div>
              <div className="text-[#31355B] text-[12px] font-bold font-[OutfitBold]">
                {t("available-on-request")}
              </div>
            </div>
            <div>
              <div className="text-[13px] text-[#8F8F8F] font-bold">
                {t("monthly-repayment")}
              </div>
              <div className="text-[#31355B] text-[13px] font-bold font-[OutfitBold]">
                {offer?.offerSummary?.installmentFrequency === "End of Term" ? (
                  "Not applicable"
                ) : (
                  <>
                    {formatLoanInt(offer?.monthlyRepayment, loanCurrency)}
                  </>
                )}
              </div>
            </div>
            {!PRODUCT_NAMES.includes(loansById?.productName) && (
              <div>
                <div className="text-[13px] text-[#8F8F8F] font-bold">
                  {t("commission")}
                </div>
                <div className="text-[#31355B] text-[13px] font-bold font-[OutfitBold]">
                  {getCommission(offer, loanCurrency)}
                </div>
              </div>
            )}
          </div>
        </div>

        {!PRODUCT_NAMES.includes(loansById?.productName) && (
          <div className="flex flex-row gap-3.5">
            {offer?.offerType === "FINAL_OFFER" && offer?.isExpired ? (
              <button
                onClick={() => handleRegenerateOffer(id)}
                className="hidden md:block px-4 py-1.5 bg-[#ffb619] text-[#30345e] text-[12px] font-bold rounded-[60px] w-full border-none disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                disabled={
                  (offer?.offerType === "FINAL_OFFER" &&
                    offer?.status === "ACCEPTED") ||
                  !!loansById?.salesOppId?.trim() ||
                  !!isAssumedUser ||
                  offer?.state === "REGENERATED"
                }
                style={{ height: "fit-content" }}
              >
                {isLoading ? <Loader /> : t("regenerate-offer")}
              </button>
            ) : (
              <button
                onClick={() =>
                  navigate(
                    `/applications/offer-simulator?loan_id=${id}&car_id=${loansById?.carId}&partner_id=${offer?.partner?.id}`
                  )
                }
                className="hidden md:block px-4 py-1.5 bg-[#ffb619] text-[#30345e] text-[12px] font-bold rounded-[60px] w-full border-none disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                disabled={
                  (offer?.offerType === "FINAL_OFFER" &&
                    offer?.status === "ACCEPTED") ||
                  !!loansById?.salesOppId?.trim() ||
                  !!isAssumedUser
                }
                style={{ height: "fit-content" }}
              >
                {t("simulate-offer")}
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderLoanOffers = (offer: Record<string, any>) => {
    if (REFERRED_STATUS.includes(offer?.status as string)) {
      return (
        <div className="block sm:flex justify-content md:mt-3">
          <div className="flex-1 text-[14px] text-[#31355B] font-bold">
            {t("we-are-still-reviewing-your-application-next-24-hours")}
          </div>
        </div>
      );
    } else if (REJECTION_STATUSES.includes(offer?.status as string)) {
      return (
        <div className="flex md:mt-3">
          <div className="flex-1 text-[14px] text-[#31355B] font-bold">
            {t("unfortunately-we-were-unable-to-extend-an-offer")}
          </div>
        </div>
      );
    } else {
      return renderOfferDetails(offer);
    }
  };

  const renderLoanClassName = () => {
    if (PRODUCT_NAMES.includes(loansById?.productName)) {
      return "md:grid-cols-4 lg:grid-cols-5";
    } else {
      return `md:grid-cols-3 lg:grid-cols-5`;
    }
  };

  const renderViewText = (index: number) => {
    const isExpanded = expanded === index;
    const viewText = isExpanded ? t("view-less") : t("view-more");
    const iconClass = isExpanded ? "rotate-180" : "";

    return (
      <div className="text-[12px] text-[#2563EB] font-bold text-center cursor-pointer">
        {viewText} <ExpandMoreIcon className={iconClass} />
      </div>
    );
  };

  const renderSimulateOffer = (offer: Record<string, any>) => {
    const isReferred = REFERRED_STATUS.includes(offer?.status as string);
    const isRejected = REJECTION_STATUSES.includes(offer?.status as string);
    const isButtonDisabled =
      (offer?.offerType === "FINAL_OFFER" && offer?.status === "ACCEPTED") ||
      !!loansById?.salesOppId?.trim() ||
      !!isAssumedUser;

    if (PRODUCT_NAMES.includes(loansById?.productName)) {
      return null;
    }

    if (!isReferred && !isRejected) {
      return (
        <CustomButton>
          <Button
            onClick={() =>
              navigate(
                `/applications/offer-simulator?loan_id=${id}&car_id=${loansById?.carId}&partner_id=${offer?.partner?.id}&offer_id=${offer?.id}`
              )
            }
            className="block md:hidden bg-[#ffb619] border-none text-[#30345e] text-[12px] font-bold rounded-[60px] w-[229px] h-[30.85px] md:w-full disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
            disabled={isButtonDisabled}
          >
            {t("simulate-offer")}
          </Button>
        </CustomButton>
      );
    }
  };

  return (
    <div>
      {conditionalOffer?.length === 0 ? (
        <NoLoanOffers />
      ) : (
        <CarContainer>
          {user?.email === "stanbicibtc@autochek.africa" ? (
            <>
              {conditionalOffer
                ?.filter(
                  (item: Record<string, any>) =>
                    item?.partner?.name === "Stanbic IBTC Bank Nigeria" ||
                    item?.partner?.name === "Get Gari Nigeria"
                )
                ?.map((offer: Record<string, any>, index: number) => (
                  <Accordion
                    key={offer?.id}
                    expanded={expanded === index}
                    onChange={handleAccordionChange(index)}
                    style={{ marginBottom: "1.5rem" }}
                  >
                    <AccordionSummary>
                      <div className="block md:flex md:gap-6 w-full">
                        <div className="w-auto h-auto mb-3 sm:mb-0 flex justify-center rounded">
                          <div>
                            <img
                              src={offer?.partner?.logoUrl}
                              alt="bank"
                              className="min-w-[45px] w-auto h-[45px] my-[0] mx-[auto] object-cover"
                            />
                          </div>
                        </div>

                        <div className="flex grow flex-col gap-3 justify-center">
                          {renderLoanOffers(offer)}
                          {renderSimulateOffer(offer)}
                          {renderViewText(index)}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {tabKey === "1" && (
                        <>
                          {PRODUCT_NAMES.includes(loansById?.productName) ? (
                            <DealerConditionalOffer
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          ) : (
                            <LoanInfo
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          )}
                        </>
                      )}
                      {tabKey === "2" && (
                        <>
                          {PRODUCT_NAMES.includes(loansById?.productName) ? (
                            <DealerFinalOffer
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          ) : (
                            <FinalOfferLayout
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </>
          ) : (
            <>
              {conditionalOffer.map(
                (offer: Record<string, any>, index: number) => (
                  <Accordion
                    key={offer?.id}
                    expanded={expanded === index}
                    onChange={handleAccordionChange(index)}
                    style={{ marginBottom: "1.5rem" }}
                  >
                    <AccordionSummary>
                      <div className="block md:flex md:gap-6 w-full">
                        <div className="w-auto h-auto mb-3 sm:mb-0 flex justify-center rounded">
                          <div>
                            <img
                              src={offer?.partner?.logoUrl}
                              alt="bank"
                              className="min-w-[45px] w-auto h-[45px] my-[0] mx-[auto] object-cover"
                            />
                          </div>
                        </div>

                        <div className="flex grow flex-col gap-3 justify-center">
                          {renderLoanOffers(offer)}

                          {/* Simulate offer button on mobile */}
                          {renderSimulateOffer(offer)}

                          {renderViewText(index)}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {tabKey === "1" && (
                        <>
                          {PRODUCT_NAMES.includes(loansById?.productName) ? (
                            <DealerConditionalOffer
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          ) : (
                            <LoanInfo
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          )}
                        </>
                      )}
                      {tabKey === "2" && (
                        <>
                          {PRODUCT_NAMES.includes(loansById?.productName) ? (
                            <DealerFinalOffer
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          ) : (
                            <FinalOfferLayout
                              id={id}
                              loanDetails={offer}
                              loansById={loansById}
                            />
                          )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </>
          )}
        </CarContainer>
      )}
    </div>
  );
};

export default LoanOfferList;
