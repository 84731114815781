import { useState, useEffect } from "react";
import { Modal, Form, Button, Upload, message } from "antd";
import { useTranslation } from "react-i18next";
import { uploadSignedDocuments, uploadSignedDocumentsDF } from "@/hooks/use-loan";
import { UploadSignedDocumentModal } from "./styles";
import storage from "@/lib/storage";
import UploadCloudIcon from "@/components/UploadCloudIcon";
import { useQueryClient } from "@tanstack/react-query";
import { Loader } from "@/pages/auth/styles/loader";
import { useLocation } from "react-router-dom";

interface IUploadDocs {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  id: string;
  documentData: any;
}

export default function UploadSignedDocModal({
  isModalOpen,
  handleOk,
  handleCancel,
  id,
  documentData,
}: Readonly<IUploadDocs>) {
  const { t } = useTranslation("loan-form");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const queryClient = useQueryClient();
  const location = useLocation();
  const isDealerFinance = location.pathname.includes("dealer");

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
    }
  }, [form, isModalOpen]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .pdf, .jpeg",
    action: `${API_URL}document/upload`,
    headers: {
      authorization: `bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {},
  };

  const handleSubmitLoanDocument = async (values: any) => {
    setLoading(true);
    const documents = [
      {
        name: documentData?.name,
        code: documentData?.name,
        file_url: values.document_upload[0].response.file.url,
        description: documentData?.name,
      },
    ];

    try {
      const response = !isDealerFinance ? await uploadSignedDocuments(id, {
        loan_id: id,
        offer_id: documentData?.offerId as string,
        documents,
      }) : await uploadSignedDocumentsDF({
        loan_id: id,
        offer_id: documentData?.offerId as string,
        documents,
      });
      if (response) {
        message.success("Document uploaded successfully");
        queryClient.invalidateQueries(["loans_by_id"]);
        queryClient.invalidateQueries(["fetch_in_app_notifications"]);
        setLoading(false);
        form.resetFields();
        handleCancel();
      }
    } catch (error: any) {
      message.error(
        JSON.parse(error.response?.data).message ||
          JSON.parse(error.response?.data).error
      );
      setLoading(false);
    }
  };

  const handleResetFormField = () => {
    setLoading(false);
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal
      title={`Upload ${documentData?.name}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={500}
      wrapClassName="upload-document-modal-container"
      zIndex={1007}
      closable={false}
    >
      <UploadSignedDocumentModal>
        <Form
          name="document-upload-form"
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmitLoanDocument}
          autoComplete="off"
        >
          <Form.Item
            label={`You are uploading ${documentData?.name} for ${documentData?.partner} offer`}
          >
            <Form.Item
              name="document_upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: t("please-upload-a-file"),
                },
              ]}
            >
              <Upload.Dragger {...props} name="file" maxCount={1}>
                <div className="flex items-center px-4">
                  <UploadCloudIcon sx={{ marginRight: "15px" }} />
                  <div className="text-base text-[#30345E] font-semibold">
                    {documentData?.name}
                  </div>
                </div>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <div className="button-container">
              <Button
                className="cancel-button"
                type="primary"
                onClick={handleResetFormField}
              >
                {t("Cancel")}
              </Button>
              <Button
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                {loading ? <Loader variant={"secondary"} /> : t("Submit")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </UploadSignedDocumentModal>
    </Modal>
  );
}
