import styled from "styled-components";
import { Input } from "antd";

const InputContainer = styled.div`
  margin-bottom: 0.6rem;
  .label {
    font-weight: bolder;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }
  .input {
    background: #f8fafc;
    border-radius: 4px;
    height: 42px;
    .ant-input {
      background: #f8fafc;
    }
  }
  .error {
    color: red;
    font-size: 0.8rem;
  }
`;

const TextField = ({
  label,
  logo,
  onChange,
  value,
  placeholder,
  name,
  error,
  type,
  required,
}: any) => {
  return (
    <InputContainer>
      {label && <div className="label">{label}</div>}
      <Input
        className="input"
        onChange={onChange}
        value={value}
        size="large"
        placeholder={placeholder}
        prefix={logo}
        name={name}
        type={type}
        required={required}
      />
      {error && <span className="error">{error}</span>}
    </InputContainer>
  );
};

export default TextField;
