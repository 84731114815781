import { device } from "@/utils/device";
import formatInt from "@/utils/format-int";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const PrequalificationContainer = styled.div`
  display: flex;
  width: 45%;
  padding: 16px 69px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  border: 1px solid var(--divider-divider, #e5e7eb);
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(96, 97, 112, 0.16);
  margin-inline: auto;
  @media ${device.mobileL} {
    width: 100%;
    padding: 1rem;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    justify-content: center;
    .image {
      width: 123px;
      height: 73px;
      margin-bottom: 1rem;
    }
    .title {
      font-family: Outfit;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15px;
      /* width: 100%; */
    }
    .subtitle {
      color: var(--typography-text-secondary, #6b7280);
      text-align: center;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      width: 80%;
      @media ${device.mobileL} {
        width: 100%;
      }
    }
  }
  .summary {
    display: flex;
    padding: 0px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-top: 1rem;
    @media ${device.mobileL} {
      flex-direction: column;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }
    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      flex: 1 0 0;
      @media ${device.mobileL} {
        align-items: center;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 6px;
      flex: 1 0 0;
      @media ${device.mobileL} {
        align-items: center;
      }
    }
    .label {
      color: var(--grey-grey-dark, #4b5563);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
    .value {
      color: var(--primary-primary-main, #30345e);
      font-family: Outfit;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 10px;
    margin-top: 1rem;
    .label {
      color: var(--primary-primary-light, #7f83a8);
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .value {
      color: var(--primary-primary-main, #30345e);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      /* line-height: 40px; */
      text-transform: capitalize;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    margin-block: 2rem;
    .outline-btn {
      cursor: pointer;
      display: flex;
      height: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      border-radius: 200px;
      border: 1px solid #000;
      color: var(--primary-main-color, #30345e);
      text-align: center;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      :hover {
        background: #fff;
      }
    }
    .solid-btn {
      display: flex;
      height: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      border-radius: 200px;
      background: var(--secondary-secondary-main, #ffb619);
      box-shadow:
        0px 2px 4px 0px rgba(96, 97, 112, 0.16),
        0px 0px 1px 0px rgba(40, 41, 61, 0.04);
      color: var(--primary-main-color, #30345e);
      text-align: center;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;
type props = {
  data: IPrequalificationResponse;
  back: () => void;
};

function PrequalificationResponse({ data, back }: props) {
  const { t } = useTranslation("inventory");

  const navigate = useNavigate();

  const handleContinue = () => {
    navigate(`/inventory?price_high=${data.maxPrincipalDebt}`);
  };
  return (
    <PrequalificationContainer>
      <div className="header">
        <div className="image">
          <img src="/images/success.webp" alt="checked" />
        </div>
        <h1 className="title">{t("your-prequalification-status")}</h1>
        <h2 className="subtitle">
          {t("your-prequalification-congratulations")}
        </h2>
      </div>
      <div className="summary">
        <div className="left">
          <div className="label">{t("maximum-financeable-amount")}</div>
          <div className="value">
            {formatInt(data?.maxPrincipalDebt || 0, true)}
          </div>
        </div>
        <div className="right">
          <div className="label">{t("minimum-financeable-amount")}</div>
          <div className="value">
            {formatInt(data?.minEquityContribution || 0, true)}
          </div>
        </div>
      </div>
      <div className="details">
        <div className="label">{t("maximum-monthly-installment")}</div>
        <div className="value">
          {formatInt(data?.totalMonthlyInstalment || 0, true)}
        </div>
      </div>
      <div className="details">
        <div className="label">{t("maximum-finance-tenure")}</div>
        <div className="value">
          {data?.maxFinanceTerm} {t("months")}
        </div>
      </div>
      <div className="button-container">
        <Button
          className="outline-btn"
          onClick={back}
          type="primary"
          size="large"
        >
          {t("edit-preferences")}
        </Button>
        <Button
          className="solid-btn"
          onClick={handleContinue}
          type="primary"
          size="large"
        >
          {t("view-eligible-cars")}
        </Button>
      </div>
    </PrequalificationContainer>
  );
}

export default PrequalificationResponse;
