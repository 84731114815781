interface IImageLoader {
  src: string;
  width: number;
  quality: number | undefined;
}

export const ImageLoader = ({ src, width, quality }: IImageLoader) => {
  if (src.includes("q_")) {
    return src;
  } else if (src.includes("https://media.autochek.africa/file")) {
    const imageWidth = width ? `w_${width}` : ``;
    const imageQuality = quality ? `q_${quality}` : `q_75`;

    const imageExt = `.webp`;

    const imageEndpoint = `https://media.autochek.africa/file`;

    const imageAttr = [imageWidth, imageQuality].filter((e) => e).join(",");

    const imageIdentifier = src.split("file")[1]?.split(".")[0];

    const imageSrc = `${imageIdentifier}${imageExt}`;

    return [imageEndpoint, imageAttr].join("/") + imageSrc || src;
  } else {
    return src;
  }
};
