import React from "react";
import DealerHistoryView from "@/components/refix/DealerHistoryView";
import { useDealerSummary } from "@/states/dealer";

const DealerHistory = () => {
  const dealerSummaryInfo = useDealerSummary.getState().summary;

  return (
    <DealerHistoryView
      dealerType={dealerSummaryInfo?.level as string}
      dealerName={dealerSummaryInfo?.dealership as string}
    />
  );
};

export default DealerHistory;
