export const getCountryCurrency = (code: string) => {
  let currency;
  switch (code) {
    case "NG":
      currency = "₦";
      break;
    case "GH":
      currency = "GH₵";
      break;
    case "KE":
      currency = "KSh";
      break;
    case "CI":
      currency = "CFA";
      break;
    case "UG":
      currency = "USh";
      break;
    case "SN":
      currency = "XOF";
      break;
    case "EG":
      currency = "EGP";
      break;
    default:
      break;
  }

  return currency;
};

export const getCountryName = (code: string) => {
  let currency;
  switch (code) {
    case "NG":
      currency = "Nigeria";
      break;
    case "GH":
      currency = "Ghana";
      break;
    case "KE":
      currency = "Kenya";
      break;
    case "CI":
      currency = "Cote D'Ivoire (Ivory Coast)";
      break;
    case "UG":
      currency = "Uganda";
      break;
    case "SN":
      currency = "Senegal";
      break;
    case "EG":
      currency = "Egypt";
      break;
    default:
      break;
  }

  return currency;
};
