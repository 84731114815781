import { useEffect } from "react";
import { Box, Typography, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ErrorOccurred = () => {
  const { t } = useTranslation("smart-notification");

  useEffect(() => {
    document.title = "Error Occurred";
  }, []);
  return (
    <>
      <Box height="550px" width="100%" position="relative">
        <Box
          width="100%"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          height="100%"
          zIndex="1"
          paddingTop="80px"
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              position="relative"
              width="100%"
              height="100%"
              marginRight="40px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src="/images/maintenance_image.webp" alt="Not Found" />
            </Box>
            <Box
              width="70%"
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
            >
              <Box textAlign="center" marginTop="20px">
                <Typography
                  variant="h3"
                  fontWeight="900"
                  style={{ marginBottom: 20 }}
                >
                  {t("something-went-wrong")} :(
                </Typography>
                <Typography variant="subtitle1" fontWeight="500" flexGrow="1">
                  {t("we-are-working-to-resolve-it")}
                </Typography>
                <Typography variant="subtitle1" fontWeight="500" flexGrow="1">
                  {t("let-take-you-home")}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: 20, backgroundColor: "#FFB619" }}
            >
              <a href="/applications" style={{ color: "black" }}>
                {t("back-to-home")}
              </a>
            </Button>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default ErrorOccurred;
