import create, { StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

interface CarFormState {
  currentStep: number;
  completedSteps: number[];
  alert: string;
  loading: boolean;
  setStep: (step: number) => void;
  setCompleted: (step: number) => void;
  setAlert: (alert: string) => void;
  setLoading: (loading: boolean) => void;
}

const useCarFormStore = create<CarFormState>(
  persist<CarFormState>(
    (set) => ({
      currentStep: 1,
      completedSteps: [],
      alert: "",
      loading: false,
      setStep: (step) => set({ currentStep: step }),
      setCompleted: (step) =>
        set((state) => ({
          completedSteps: state.completedSteps.includes(step)
            ? state.completedSteps
            : [...state.completedSteps, step],
        })),
      setAlert: (alert) => set({ alert }),
      setLoading: (loading) => set({ loading }),
    }),
    {
      name: "car-form-store",
      getStorage: () => sessionStorage,
    } as PersistOptions<CarFormState>
  ) as StateCreator<CarFormState, [], []>
);

export default useCarFormStore;
