import { useState } from "react";
import { Form, Input, Modal } from "antd";
import CustomFileUploader from "@/layouts/application-layouts/CustomFileUpload";
import {
  AddInputModal,
  CustomCarUploadContainer,
} from "@/layouts/application-layouts/styles";
import { toCamelCase } from "@/utils/convertToCamelCase";
import { generateCompressedUrl } from "@/utils/compressedUrl";
import { useTranslation } from "react-i18next";
import { ISnapshotTemplate } from "@/types";
import { UseFormReturn } from "react-hook-form";
import { CreateCarFormValues } from "@/validations/create-car.schema";

interface IGetSnapshots {
  name: string;
  type: string;
  url: string;
}

interface IAddImageModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  setNewSnapshots: React.Dispatch<React.SetStateAction<ISnapshotTemplate[]>>;
  methods: UseFormReturn<CreateCarFormValues, any>;
  getSnapshots: IGetSnapshots[];
}

export default function AddNewSnapshots({
  isModalOpen,
  handleOk,
  handleCancel,
  setNewSnapshots,
  methods,
  getSnapshots,
}: Readonly<IAddImageModal>) {
  const { t } = useTranslation("inventory");
  const { t: t1 } = useTranslation("car-upload");
  const { t: t2 } = useTranslation("car-form");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const handleAddImages = async (values: any) => {
    const compressedUrl = await generateCompressedUrl(
      values.uploadImage[0].response.file.url
    );

    const imageObj: ISnapshotTemplate = {
      label: values.imageName,
      name: toCamelCase(values.imageName),
      type: "file",
      template: compressedUrl,
    };

    setNewSnapshots((prevSubItems) => [...prevSubItems, imageObj]);
    methods.setValue("snapshots", [
      ...getSnapshots,
      {
        name: toCamelCase(values.imageName),
        url: compressedUrl,
        type: values.uploadImage[0]?.response.file.mimetype,
      },
    ]);
    handleOk();
    form.resetFields();
    setImageUrl("");
  };

  return (
    <div>
      <Modal
        title={t2("Add Snapshots")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={1007}
        footer={null}
        width={400}
        wrapClassName="upload-additional-car-image"
      >
        <Form
          name="addNewImage"
          form={form}
          onFinish={handleAddImages}
          layout="vertical"
          requiredMark={false}
          className="p-4"
        >
          <AddInputModal>
            <Form.Item
              label={t2("Snapshot Name")}
              name="imageName"
              rules={[{ required: true, message: t2("Please add image name") }]}
            >
              <Input />
            </Form.Item>
          </AddInputModal>
          <CustomCarUploadContainer>
            <CustomFileUploader
              imageUrl={imageUrl}
              loadingUpload={loading}
              setLoadingUpload={setLoading}
              setImageUrl={setImageUrl}
              label={t2("Upload new image")}
              name="uploadImage"
              t={t}
              t1={t1}
            />
          </CustomCarUploadContainer>
          <Form.Item>
            <button className="w-full max-w-full h-12 bg-[#ffb619] text-[#30345E] font-semibold text-sm rounded-md mt-8">
              {t2("Submit")}
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
