import { useEffect, useState } from "react";
import { Empty } from "antd";
import { useQuery } from "@tanstack/react-query";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { CautionIcon } from "@/assets/svgs";
import { IDamageItem, IInspectionData } from "@/types";
import { Link } from "react-router-dom";
import { fetchInspectionReport } from "@/hooks/use-api";
import styled from "styled-components";
import { device } from "@/utils/device";
import ViewInspectionDamages from "./refix/InspectionDamagesModal";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .preview-container {
    display: none;
    @media ${device.mobileL} {
      display: none;
    }
  }
`;

interface IProps {
  carId: string;
  damages: {
    section: string;
    comment: string;
    items: IDamageItem[];
  }[];
}

const InspectionResults: React.FC<IProps> = ({ carId, damages }) => {
  const [inspectionReport, setInspectionReport] = useState<IInspectionData>();
  const [damageToView, setDamageToView] = useState("");
  const [damagesBySection, setDamagesBySection] = useState<IDamageItem[]>([]);
  const [viewDamages, setViewDamages] = useState<boolean>(false);

  useEffect(() => {
    const aaa = damages?.find(
      (damage) => damage.section === damageToView
    )?.items;

    setDamagesBySection(aaa ?? []);
  }, [damageToView, damages]);

  useQuery({
    queryKey: ["get_marketplace_inspection", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        id: carId,
        full: "true",
        type: "dealer-regular",
      });
      return fetchInspectionReport(params, carId);
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const parsedFormData = JSON.parse(parsedResults.formData);
      setInspectionReport(parsedFormData);
    },
    enabled: !!carId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const renderRatingIcon = (rate: number) => {
    if (rate >= 4 && rate <= 5) {
      return <TaskAltIcon className="text-[#22C55E]" />;
    } else if (rate >= 3 && rate < 4) {
      return <CautionIcon color="#EAC608" />;
    } else {
      return <CautionIcon color="#F87171" />;
    }
  };

  return (
    <>
      <Main>
        {inspectionReport && inspectionReport?.sections.length > 0 ? (
          <div>
            <div className="flex flex-row justify-between">
              <div></div>
              <div className="flex flex-row gap-2 md:gap-4">
                <div className="w-[80px] md:w-[138px] px-3 rounded-t-xl md:px-2 bg-[#F8FAFC] text-sm md:text-lg text-[#30345E] text-center font-semibold font-outfit py-3">
                  Condition
                </div>
                <div className="w-[84px] md:w-[138px] px-3 rounded-t-xl md:px-2 bg-[#F8FAFC] text-sm md:text-lg text-[#30345E] text-center font-semibold font-outfit py-3">
                  Damages
                </div>
              </div>
            </div>

            {inspectionReport?.sections?.map((item, index) => (
              <div
                key={item?.name}
                className="flex flex-row items-center gap-1 justify-between"
              >
                <div className="text-xs md:text-base text-[#6B7280] font-outfit">
                  {item.label}
                </div>
                <div className="flex flex-row gap-2 md:gap-4">
                  <div
                    className={`${
                      index === inspectionReport.sections.length - 1
                        ? "border-none rounded-b-xl"
                        : "border-b"
                    } flex items-center justify-center border-solid w-[80px] md:w-[138px] bg-[#F8FAFC] text-sm md:text-xl text-[#30345E] text-center font-semibold font-outfit py-3`}
                  >
                    {renderRatingIcon(
                      item.label === "General Section" ? 5 : item.ratio
                    )}
                  </div>

                  <div
                    className={`${
                      index === inspectionReport?.sections?.length - 1
                        ? "border-none rounded-b-xl"
                        : "border-b"
                    } w-[84px] md:w-[138px] bg-[#F8FAFC] border-solid text-sm md:text-base text-center py-3.5`}
                  >
                    {damages?.filter((damage) => damage.section === item.label)
                      .length === 0 ? (
                      <span className="text-xs md:text-base text-[#6B7280] font-normal font-outfit">
                        None
                      </span>
                    ) : (
                      <Link
                        to="#"
                        className="text-xs md:text-base text-[#60A5FA] font-semibold font-outfit"
                        onClick={() => {
                          setViewDamages(true);
                          setDamageToView(item.label);
                        }}
                      >
                        See damage
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Main>

      <ViewInspectionDamages
        isModalOpen={viewDamages}
        handleOk={() => setViewDamages(false)}
        handleCancel={() => setViewDamages(false)}
        damagesBySection={damagesBySection}
      />
    </>
  );
};

export default InspectionResults;
