import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import TrackersList from "@/components/TrackerList";
import UploadWhitelabelDoc from "@/components/UploadWhitelabelDoc";
import CustomsDocumentForm from "@/components/CustomsDocForm";
import storage from "@/lib/storage";

interface ICarInfoList {
  id: string;
  title: string;
  content: React.ReactNode;
}

const AccordionContainer = styled.div`
  .MuiAccordion-root {
    margin-bottom: 1.5rem;
  }
  .MuiAccordionDetails-root {
    border-top: 1px solid rgb(229, 231, 235);
  }
  .formLabel {
    font-weight: 600;
    font-size: 14px;
    color: #30345e;
  }
`;

export default function CarTrackerDocument() {
  const navigate = useNavigate();
  const country = storage.getCountry();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const CarInfoList: ICarInfoList[] = [
    {
      id: "panel1",
      title: "Tracker Information",
      content: <TrackersList />,
    },
    {
      id: "panel2",
      title: "Additional Document",
      content: <UploadWhitelabelDoc />,
    },
    ...(country === "NG"
      ? [
          {
            id: "panel3",
            title: "Customs Document",
            content: <CustomsDocumentForm />,
          },
        ]
      : []),
  ];

  return (
    <main className="w-full max-w-full xl:max-w-[50%] my-0 mx-auto flex flex-col gap-5">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 font-semibold"
      >
        <img
          src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
          alt="left-arrow"
        />{" "}
        Go Back
      </button>

      <AccordionContainer>
        {CarInfoList.map((item) => (
          <Accordion
            expanded={expanded === item.id}
            onChange={handleAccordionChange(item.id)}
            key={item.id}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="font-semibold text-lg text-[#30345E]">
                {item.title}
              </div>
            </AccordionSummary>
            <AccordionDetails>{item.content}</AccordionDetails>
          </Accordion>
        ))}
      </AccordionContainer>
    </main>
  );
}
