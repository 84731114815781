import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { CaretUpIcon, CaretDownIcon } from "@/assets/svgs";
import { IDealerperformance } from "@/interface/dealer";
import { isPrecededByMinus } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import storage from "@/lib/storage";
import { getCountryCurrency } from "@/utils/getCountryCurrency";

const week = [
  {
    value: "1",
    label: "Week 1",
  },
  {
    value: "2",
    label: "Week 2",
  },
  {
    value: "3",
    label: "Week 3",
  },
  {
    value: "4",
    label: "Week 4",
  },
  {
    value: "5",
    label: "Week 5",
  },
];

const formatPercentageVal = (val: string) => {
  const isNegative = isPrecededByMinus(val);
  if (isNegative) {
    return { value: `${Math.ceil(Number(val?.slice(1)))}`, isNegative };
  } else {
    return { value: `${Math.ceil(Number(val))}`, isNegative };
  }
};
function formatNumberInMillions(number: number) {
  if (number >= 1000000) {
    const millionValue = (number / 1000000).toFixed(1);
    return `${millionValue}M`;
  }

  const num = number.toFixed(1);
  return Number(num).toLocaleString();
}

const HistoryPerformace = ({
  performanceData,
  isLoading,
}: {
  performanceData: IDealerperformance;
  isLoading: boolean;
}) => {
  const { t } = useTranslation("dealer-gamification");

  const SelectWrap = styled.div`
    .ant-select {
      width: 108px !important;
    }
    .ant-select:hover {
      border: none !important;
    }

    .ant-select-selector {
      border-color: 1px solid #e5e7eb;
      border-radius: 0px;
    }
    .ant-select-selection-item {
      color: #30345e;
      font-weight: 600;
      letter-spacing: 0.0625rem;
    }
  `;

  return (
    <div className="overflow-scroll h-[70vh]">
      <SelectWrap>
        <div className="flex justify-between items-center">
          {/* 1 */}
          <div>
            <span className="text-base font-bold text-[#30345E] leading-6 tracking-[0.01rem] ">
              {t("your-performance")}
            </span>
          </div>
          {/* 2 */}
          <div className="flex items-center space-x-2">
            {/* <div>
              <Select
                defaultValue="Week"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "-", label: "Week", disabled: true },
                  ...week,
                ]}
                suffixIcon={<PerformaceDropdownIcon />}
              />
            </div>
            <div>
              <button>
                <HistoryFilterIcon />
              </button>
            </div> */}
          </div>
        </div>
      </SelectWrap>

      {isLoading ? (
        <Spin />
      ) : (
        <div className="pt-7 max-w-3xl mx-auto">
          <WidePerformaceCard
            text={t("commissions-earned")}
            value={performanceData.commissions}
            percent={performanceData.commissionsPerc}
            isCurrency
          />
          <div className="pt-4 grid grid-cols-2 md:grid-cols-3 grid-rows-auto gap-x-3 gap-y-4 max-w-2xl mx-auto ">
            <NarrowPerformaceCard
              text={t("points-earned-upc")}
              value={performanceData.points}
              percent={performanceData.pointsPerc}
            />
            <NarrowPerformaceCard
              text={t("cars-sold")}
              value={performanceData.carSold}
              percent={performanceData.carSoldPerc}
            />
            <NarrowPerformaceCard
              text={t("avg-com-per-sale")}
              value={performanceData.avgCommissionssSale}
              isCurrency
            />
            <NarrowPerformaceCard
              text={t("lost-commisions")}
              value={performanceData.lostCommissions ?? "-"}
              isCurrency
            />
            <NarrowPerformaceCard
              text={t("lead-conv-rate")}
              value={performanceData.leadsConversion}
              percent={performanceData.leadsConversionRate}
            />
            {/* <NarrowPerformaceCard
              text="CUSTOMER RATING"
              value="4.0"
              percent="10"
            /> */}
            <NarrowPerformaceCard
              text={t("total-gmv")}
              value={performanceData.revenue}
              percent={performanceData.revenuePerc}
              isCurrency
            />
            <NarrowPerformaceCard
              text={t("avg-sel-price")}
              value={performanceData.averageSellingPrice}
              isCurrency
            />
            <NarrowPerformaceCard
              text={t("created-inventory")}
              value={performanceData.createdInventory}
              percent={performanceData.createdInventoryPerc}
            />
            <NarrowPerformaceCard
              text={t("inventory-on-mrktplace")}
              value={performanceData.marketplaceInventory}
            />
          </div>
          <div className="pt-4 md:pt-[4.71rem] flex flex-col md:flex-row space-x-0 md:space-x-3 max-w-full md:max-w-full mx-auto">
            <div className="w-full md:w-1/2">
              <WidePerformaceCard
                text={t("app-submitted")}
                value={performanceData.applicationSubmitted ?? 0}
                percent={performanceData.applicationSubmittedPerc ?? "0"}
              />
            </div>
            <div className="grid grid-cols-2 grid-rows-auto gap-x-3 gap-y-4 w-full pt-4 md:pt-0">
              <NarrowPerformaceCard
                text={t("disbursed")}
                value={performanceData.disbursed ?? 0}
                percent={performanceData.disbursedPerc ?? "0"}
              />
              <NarrowPerformaceCard
                text={t("ineligible-leads")}
                value={performanceData.ineligibleLeads ?? 0}
                percent={performanceData.ineligibleLeadsPerc ?? "0"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const WidePerformaceCard = ({
  text,
  value,
  percent,
  isCurrency,
}: {
  text: string;
  value: string;
  percent?: string;
  isCurrency?: boolean;
}) => {
  const country = storage.getCountry();
  const currencySign = getCountryCurrency(country);
  return (
    <div className="w-full px-1 py-6 border border-[#E5E7EB] rounded-tr-[0.25rem] rounded-tl-[0.25rem] h-[5.75rem]">
      <div className="flex flex-col justify-center items-center h-full ">
        <p className="text-[0.625rem]">{text}</p>
        <p className="flex items-center space-x-2">
          <span className="text-2xl font-bold text-[#30345E] leading-8">
            {isCurrency
              ? `${currencySign} ${formatNumberInMillions(Number(value))}`
              : value}
          </span>
          {percent && (
            <span className="flex items-center text-[#22C55E] space-x-1">
              {formatPercentageVal(percent).isNegative ? (
                <CaretDownIcon />
              ) : (
                <CaretUpIcon />
              )}
              <span>{formatPercentageVal(percent).value} %</span>
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
const NarrowPerformaceCard = ({
  text,
  value,
  percent,
  isCurrency,
}: {
  text: string;
  value: string;
  percent?: string;
  isCurrency?: boolean;
}) => {
  const country = storage.getCountry();
  const currencySign = getCountryCurrency(country);
  return (
    <div className="w-full px-1 py-2 border border-[#E5E7EB] rounded-[0.25rem] h-[5.75rem]">
      <div className="flex flex-col justify-center items-center h-full ">
        <p className="text-[0.625rem]">{text}</p>
        <p className="flex items-center space-x-2">
          <span className={`text-2xl font-bold "text-[#30345E] leading-8`}>
            {isCurrency
              ? `${currencySign} ${formatNumberInMillions(Number(value))}`
              : value}
          </span>
          {percent && (
            <span
              className={`flex items-center text-[#22C55E] space-x-1 ${
                formatPercentageVal(percent).isNegative
                  ? "text-[#F87171]"
                  : "text-[#22C55E]"
              }`}
            >
              {formatPercentageVal(percent).isNegative ? (
                <CaretDownIcon />
              ) : (
                <CaretUpIcon />
              )}

              <span>{formatPercentageVal(percent).value} %</span>
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default HistoryPerformace;
