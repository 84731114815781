import { useState, useEffect } from "react";
import { Steps, Button } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { device } from "@/utils/device";
import tw from "tailwind-styled-components";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { convertToTitleCase } from "@/utils/capitalizeFirstLetter";
import { TableContainer } from "./styles";
import moment from "moment";

interface IStatusTree {
  paymentIds: string[];
  statusName: string;
  dateCompleted: string;
  loanId: string;
  completed: boolean;
  action: boolean;
  platformId?: string;
  offerId?: string;
}

const getBorderStyle = (props: Partial<IButtonLoader>): string | undefined => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  }
  return undefined;
};

const Loader = tw.div`
${(props: Partial<IButtonLoader>) =>
  getBorderStyle(
    props
  )} inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const StepsContainer = styled.div`
  padding: 16px 16px;
  @media ${device.mobileL} {
    padding: 16px 0px;
  }
  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.45);
    color: #6b7280;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.88);
    color: #6b7280;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.88);
    color: #6b7280;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    color: #6b7280;
    font-weight: 400;
    font-size: 12px;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #22c55e;
    border-color: #22c55e;
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #d1d5db;
    border-color: transparent;
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #d1d5db;
    border-color: transparent;
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    /* color: #fff; */
    color: #202020;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #202020;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background: #e5e7eb;
  }
  .ant-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
    vertical-align: super;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: rgb(255, 255, 255);
    vertical-align: super;
  }
  .upload-document {
    display: flex;
    gap: 36px;
    .default-upload {
      color: #6b7280;
      font-weight: 600;
      font-size: 14px;
      background: #d1d5db;
      border-radius: 44px;
      border: 0;
    }
    .active-upload {
      color: #30345e;
      font-weight: 600;
      font-size: 14px;
      background: #ffb619;
      border-radius: 44px;
      border: 0;
    }
    button {
      &:disabled {
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
        border: 1px solid #d9d9d9;
        span {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
`;

const Card = tw.div`flex justify-between items-center w-full py-4`;

const LoanSteps = ({ loansById, id }: any) => {
  const [carForCashCurrent, setCarForCashCurrent] = useState(0);
  const [carForCashCurrentStatus, setCarForCashCurrentStatus] =
    useState<any>("process");
  const navigate = useNavigate();
  const { t } = useTranslation("application");
  const [fetchStatus, setFetchStatus]: any = useState({});

  const { isFetching: statusLoading } = useQuery({
    queryKey: ["fetch_status_trail", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return client.get(`/v2/origination/${id}/status-trail`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResult = JSON.parse(res?.data);
      setFetchStatus(parsedResult);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const filteredData: IStatusTree[] = fetchStatus?.statusTree?.filter(
    (item: IStatusTree) => item?.statusName !== "DISBURSED"
  );
  const disbursedItem: IStatusTree | undefined = fetchStatus?.statusTree?.find(
    (item: IStatusTree) => item?.statusName === "DISBURSED"
  );
  const statusTree: IStatusTree[] = disbursedItem
    ? [...filteredData, disbursedItem]
    : filteredData;

  const filterLoanDocument = loansById?.offers?.filter(function (item: any) {
    return item?.offerType === "FINAL_OFFER";
  });

  const toUploadDocuments = () => {
    navigate(`/applications/upload-documents?loan_id=${id}`);
  };

  const toUploadSignedDocuments = () => {
    navigate(
      `/applications/upload-signed-documents?loan_id=${id}&offer_id=${filterLoanDocument[0]?.id}`
    );
  };

  const toUploadCustomsDocuments = () => {
    navigate(
      `/applications/custom-document?carId=${loansById?.carId}&loanId=${id}`
    );
  };

  const toUploadEquity = () => {
    navigate("/applications/equity-payment", { state: { loanId: id } });
  };

  const toRequestPPI = () => {
    navigate("/applications/request-inspection", {
      state: {
        carId: loansById?.carId,
        loanId: id,
      },
    });
  };

  useEffect(() => {
    switch (loansById.status) {
      case "APPLICATION_NOT_COMPLETED":
        setCarForCashCurrent(0);
        setCarForCashCurrentStatus("error");
        break;
      case "APPLICATION_COMPLETED":
        setCarForCashCurrent(1);
        break;
      case "REJECTED_CONDITIONAL_OFFER":
      case "DECLINED_CONDITIONAL_OFFER":
      case "REFERRED_CONDITIONAL_OFFER":
        setCarForCashCurrent(1);
        setCarForCashCurrentStatus("error");
        break;
      case "CONDITIONAL_OFFER_RECEIVED":
      case "OFFER_RECEIVED":
        setCarForCashCurrent(2);
        break;
      case "DOCUMENT_SUBMITTED":
        setCarForCashCurrent(3);
        break;
      case "INSPECTION_PENDING":
        setCarForCashCurrent(3);
        setCarForCashCurrentStatus("error");
        break;
      case "INSPECTION_COMPLETED":
        setCarForCashCurrent(4);
        break;
      case "REJECTED_FINAL_OFFER":
      case "FINAL_OFFER_REJECTED":
      case "DECLINED_FINAL_OFFER":
      case "REFERRED_FINAL_OFFER":
        setCarForCashCurrent(4);
        setCarForCashCurrentStatus("error");
        break;
      case "FINAL_OFFER_RECEIVED":
      case "OFFER_ACCEPTED":
        setCarForCashCurrent(5);
        break;
      case "SIGNED_LOAN_DOCUMENTS_SUBMITTED":
        setCarForCashCurrent(6);
        break;
      case "DISBURSED":
        setCarForCashCurrent(7);
        break;
      default:
        // Handle any other cases
        break;
    }
  }, [loansById]);

  const getStatusText = (item: Record<string, any>) => {
    switch (item?.statusName) {
      case "REQUIRED_DOCUMENTS_UPLOAD_COMPLETED":
        return "Customer Documents Upload";
      case "SIGNED_LOAN_DOCUMENTS_SUBMITTED":
        return "Upload Signed Loan Documents";
      case "CUSTOMS_DOCUMENT_APPROVED":
        return "Customs Document";
      case "PPI_COMPLETED":
        return "PPI Request";
      case "EQUITY_PAID":
        return "Upfront Payment";
      default:
        return convertToTitleCase(item?.statusName?.replaceAll("_", " "));
    }
  };

  if (statusLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <StepsContainer>
      {["Decision Engine Loan", "Dealer WhiteLabel"].includes(
        loansById?.productType
      ) && (
        <div className="grid grid-cols-1 divide-y">
          {statusTree?.map((item: Record<string, any>) => (
            <Card key={item?.statusName}>
              <div className="flex items-center gap-2">
                <img
                  src={
                    item?.completed
                      ? "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/checkestepIcon.svg?updatedAt=1711358779069"
                      : "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/stepIcon.svg?updatedAt=1711111528640"
                  }
                  alt="Status"
                />
                <div>
                  <div className="text-sm font-semibold text-[#6b7280]">
                    {getStatusText(item)}
                  </div>
                  <div className="text-xs text-[#6b7280] font-normal">
                    {item?.dateCompleted
                      ? moment(item?.dateCompleted).format("LL")
                      : "---"}
                  </div>
                </div>
              </div>
              <div className="hidden upload-documents">
                {item?.action && (
                  <Button
                    className="default-upload"
                    onClick={() => {
                      switch (item?.statusName) {
                        case "REQUIRED_DOCUMENTS_UPLOAD_COMPLETED":
                          toUploadDocuments();
                          break;
                        case "PPI_COMPLETED":
                          toRequestPPI();
                          break;
                        case "CUSTOMS_DOCUMENT_APPROVED":
                          toUploadCustomsDocuments();
                          break;
                        case "SIGNED_LOAN_DOCUMENTS_SUBMITTED":
                          toUploadSignedDocuments();
                          break;
                        case "EQUITY_PAID":
                          toUploadEquity();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={item?.completed}
                  >
                    {t("upload")}
                  </Button>
                )}
              </div>
            </Card>
          ))}
        </div>
      )}

      {loansById?.productType === "Logbook Finance" && (
        <Steps
          direction="vertical"
          current={carForCashCurrent}
          status={carForCashCurrentStatus}
          items={[
            {
              title:
                loansById.status === "APPLICATION_NOT_COMPLETED"
                  ? t("application-incompleted")
                  : t("application-completed"),
              icon: loansById.status === "APPLICATION_NOT_COMPLETED" && (
                <img
                  src="https://media.autochek.africa/file/publicAssets/stepIcon-1.svg"
                  alt="Error Signal"
                />
              ),
            },
            {
              title: t("conditional-offer"),
              icon: loansById.status === "REJECTED_CONDITIONAL_OFFER" && (
                <img
                  src="https://media.autochek.africa/file/publicAssets/stepIcon-1.svg"
                  alt="Error Signal"
                />
              ),
            },
            {
              title: (
                <div className="upload-document">
                  <div>{t("documents-upload")}</div>
                  <Button
                    className={
                      carForCashCurrent === 2 &&
                      carForCashCurrentStatus === "process"
                        ? "active-upload"
                        : "default-upload"
                    }
                    onClick={toUploadDocuments}
                    disabled={
                      !(
                        carForCashCurrent === 2 &&
                        carForCashCurrentStatus === "process"
                      )
                    }
                  >
                    {t("upload")}
                  </Button>
                </div>
              ),
            },
            {
              title: "Customer inspection",
              icon: loansById.status === "REJECTED_FINAL_OFFER" && (
                <img
                  src="https://media.autochek.africa/file/publicAssets/stepIcon-1.svg"
                  alt="Error Signal"
                />
              ),
            },
            {
              title: t("final-offer"),
              icon: loansById.status === "REJECTED_FINAL_OFFER" && (
                <img
                  src="https://media.autochek.africa/file/publicAssets/stepIcon-1.svg"
                  alt="Error Signal"
                />
              ),
            },
            {
              title: (
                <div className="upload-document">
                  <div>{t("Signed Contract")}</div>
                  {/* <Button
                    className={
                      carForCashCurrent === 5 &&
                      carForCashCurrentStatus === "process"
                        ? "active-upload"
                        : "default-upload"
                    }
                    onClick={toUploadSignedDocuments}
                    disabled={
                      !(
                        carForCashCurrent === 5 &&
                        carForCashCurrentStatus === "process"
                      )
                    }
                  >
                    {t("upload")}
                  </Button> */}
                </div>
              ),
            },
            {
              title: t("Disbursed"),
              icon: loansById.status === "REJECTED_FINAL_OFFER" && (
                <img
                  src="https://media.autochek.africa/file/publicAssets/stepIcon-1.svg"
                  alt="Error Signal"
                />
              ),
            },
          ]}
        />
      )}
    </StepsContainer>
  );
};

export default LoanSteps;
