import { Modal } from "antd";
import { ITrackerInfo } from "@/types";
import AddTrackerForm from "./AddTrackerForm";
import { TrackerFormContainer } from "@/layouts/application-layouts/styles";

interface ITrackerModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  trackerData?: ITrackerInfo;
  setOpenTrackerModal: any;
}

export default function TrackerFormModal({
  isModalOpen,
  handleOk,
  handleCancel,
  trackerData,
  setOpenTrackerModal,
}: Readonly<ITrackerModal>) {
  return (
    <Modal
      title={
        trackerData?.id
          ? `Edit ${trackerData?.provider} Tracker`
          : "Add Tracker"
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={600}
      wrapClassName="upload-document-modal-container"
      zIndex={1007}
    >
      <TrackerFormContainer className="p-4">
        <AddTrackerForm
          trackerData={trackerData}
          setOpenTrackerModal={setOpenTrackerModal}
          type="modal"
        />
      </TrackerFormContainer>
    </Modal>
  );
}
