import tw from "tailwind-styled-components";
import { Collapse } from "antd";
import styled from "styled-components";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";

interface IBenefitList {
  benefitList: IBenefitData | any;
  setCurrentView: (a: boolean) => void;
}

const BackButton = tw.div`flex items-center gap-2 text-[#30345E] text-base md:text-lg font-bold mb-5 cursor-pointer`;
const DescText = tw.div`text-sm md:text-base text-[#E5E7EB]`;
const Header = tw.div`flex justify-center items-center gap-1 bg-[#30345E] py-3 rounded-t-[18px] w-full`;
const FinanceContainer = tw.div`py-6 md:pb-12 mt-3 md:mt-0 md:mt-16 text-center`;
const FinanceButton = tw.button`text-[#30345E] text-base font-bold bg-[#FFB619] py-3 rounded-[60px] w-4/6 cursor-pointer`;

const CustomCollapse = styled.div`
  [data-theme="compact"]
    .site-collapse-custom-collapse
    .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    overflow: hidden;
    background: white;
    border-radius: 2px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-header-text {
    color: #30345e;
    font-size: 20px;
    font-weight: 600;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;

const ArrowDown = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9427 1.05727C19.4634 1.57797 19.4634 2.42219 18.9427 2.94289L10.9427 10.9429C10.422 11.4636 9.57781 11.4636 9.05711 10.9429L1.05711 2.94289C0.536409 2.42219 0.536409 1.57797 1.05711 1.05727C1.57781 0.536573 2.42203 0.536573 2.94273 1.05727L9.99992 8.11446L17.0571 1.05727C17.5778 0.536573 18.422 0.536573 18.9427 1.05727Z"
        fill="#30345E"
      />
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.05727 10.9428C0.536573 10.4222 0.536573 9.57793 1.05727 9.05723L9.05727 1.05723C9.57797 0.536532 10.4222 0.536532 10.9429 1.05723L18.9429 9.05723C19.4636 9.57793 19.4636 10.4222 18.9429 10.9428C18.4222 11.4635 17.578 11.4635 17.0573 10.9428L10.0001 3.88566L2.94289 10.9428C2.42219 11.4635 1.57797 11.4635 1.05727 10.9428Z"
        fill="#30345E"
      />
    </svg>
  );
};

export default function DealerBenefitDetail({
  benefitList,
  setCurrentView,
}: Readonly<IBenefitList>) {
  const { Panel } = Collapse;

  return (
    <div>
      <BackButton onClick={() => setCurrentView(true)}>
        <img
          src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-l.svg"
          alt="back arrow"
        />{" "}
        Back
      </BackButton>

      <div className="flex flex-col gap-4 bg-white">
        <Header className="">
          <img
            src="https://media.autochek.africa/file/publicAssets/Frame-37100.png"
            alt="medal"
            width="100px"
            height="100px"
          />
          <div>
            <div className="text-base md:text-lg text-white font-bold uppercase">
              {benefitList.title}
            </div>
            {benefitList.typeDescription.map((desc: any, index: number) => (
              <DescText key={benefitList.typeDescription.indexOf(desc)}>
                {desc}
              </DescText>
            ))}
          </div>
        </Header>

        <div>
          <div className="mb-7 text-base font-bold">
            As a {capitalFirstLetter(benefitList.type)} dealer, you are entitled
            to;
          </div>

          <CustomCollapse>
            <Collapse
              accordion
              bordered={false}
              defaultActiveKey={["0"]}
              expandIcon={({ isActive }) =>
                isActive ? <ArrowUp /> : <ArrowDown />
              }
              expandIconPosition="end"
              className="site-collapse-custom-collapse"
            >
              {benefitList.typeList.map(
                (list: Record<string, any>, index: number) => (
                  <Panel
                    key={benefitList.typeList.indexOf(list)}
                    header={
                      <div className="flex items-center gap-3">
                        <img
                          src={list.benefitIcon}
                          alt="benefit logo"
                          width="44px"
                          height="44px"
                        />
                        <div>{list.benefitTitle}</div>
                      </div>
                    }
                    className="site-collapse-custom-panel"
                  >
                    <ul className="list-disc pl-10">
                      {list.benefitList.map((li: string, index: number) => (
                        <li
                          className="text-sm md:text-base text-[#6B7280]"
                          key={list.benefitList.indexOf(li)}
                        >
                          {li}
                        </li>
                      ))}
                    </ul>
                  </Panel>
                )
              )}
            </Collapse>
          </CustomCollapse>
        </div>

        <FinanceContainer>
          <FinanceButton>Apply for Financing</FinanceButton>
        </FinanceContainer>
      </div>
    </div>
  );
}
