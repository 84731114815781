import {
  FieldItem,
  ICarItem,
  IFieldDefinition,
  IFilteredCarResult,
  IFINANCEFORMTYPE,
  IInputField,
  ILoanItem,
  IMappedCarInventory,
  ISetFormData,
  LoanCollateralItem,
  LoanFieldItem,
  TermsConditionItem,
  VehicleFieldItem,
} from "@/types";
import { convertStringToBoolean } from "./stringToBoolean";

// Helper function to get valid form data
export const getValidFormData = (formData: any) => {
  const validFormData: any = {};

  Object.keys(formData).forEach((item) => {
    if (formData[item]?.value !== undefined && formData[item]?.value !== null) {
      validFormData[item] = { ...formData[item] };
    }
  });

  return validFormData;
};

// Helper function to clean loan fields
export const getCleanedLoanFields = (loanFields: any) => {
  const cleanedLoanFields = { ...loanFields };

  Object.keys(cleanedLoanFields).forEach((item) => {
    if (cleanedLoanFields[item]?.type === "amount") {
      cleanedLoanFields[item].value = cleanedLoanFields[
        item
      ]?.value?.replaceAll(",", "");
    }
  });

  return Object.values(cleanedLoanFields).map((field: any) => ({
    ...field,
    value:
      field.type === "checkbox"
        ? convertStringToBoolean(field.value)
        : field.value,
  }));
};

// Helper function to update car fields
export const getUpdatedCarFields = (totalCarFields: any) => {
  return totalCarFields?.map(
    (car: { carWorth: { type: string; value: string } }) => {
      if (
        car?.carWorth &&
        car?.carWorth?.type === "amount" &&
        typeof car?.carWorth?.value === "string"
      ) {
        car.carWorth.value = car.carWorth.value.replace(/,/g, "");
      }
      return car;
    }
  );
};

// Helper function to clean an object
export const cleanFieldObject = (obj: any) => {
  const cleanedObject: any = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (
      value !== undefined &&
      value !== null &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      cleanedObject[key] = value;
    }
  });

  return cleanedObject;
};

// check error for section error
const validateFormDataItem = (
  item: FieldItem,
  formData: any
): string | null => {
  if (item.type && item.required && !formData[item.name]?.value) {
    return `${item.label} is required`;
  }
  if (item.when?.length && (item.required || formData[item.name]?.value)) {
    const matchingWhen = item.when.find(
      (whenItem) => whenItem.value === formData[item.name]?.value
    );
    if (matchingWhen) {
      const error = validateFormDataSubfields(matchingWhen.fields, formData);
      if (error) return error;
    }
  }
  return null;
};

const validateFormDataSubfields = (
  fields: FieldItem[],
  formData: any
): string | null => {
  for (const item of fields) {
    const error = validateFormDataItem(item, formData);
    if (error) return error;
  }
  return null;
};

export const checkErrors = (
  formData: any,
  data: FieldItem[] = []
): string | null => {
  for (const item of data) {
    const error = validateFormDataItem(item, formData);
    if (error) return error;
    if (item.fields?.length) {
      const subfieldError = validateFormDataSubfields(item.fields, formData);
      if (subfieldError) return subfieldError;
    }
  }
  return null;
};

// check error for loan fields
const validateLoanFieldItem = (
  item: LoanFieldItem,
  loanFields: any
): string | null => {
  if (item.type && item.required && !loanFields[item.name]?.value) {
    return `${item.label} is required`;
  }
  if (item.shouldBe && item.shouldBe !== loanFields[item.name]?.value) {
    return `${item.label} should be ${item.shouldBe}`;
  }
  return null;
};

const validateLoanFieldSubfields = (
  fields: LoanFieldItem[],
  loanFields: any
): string | null => {
  for (const item of fields) {
    const error = validateLoanFieldItem(item, loanFields);
    if (error) return error;
  }
  return null;
};

export const checkLoanFieldErrors = (
  loanFields: any,
  data: LoanFieldItem[] = []
): string | null => {
  for (const item of data) {
    const error = validateLoanFieldItem(item, loanFields);
    if (error) return error;
    if (item.fields?.length) {
      const subfieldError = validateLoanFieldSubfields(item.fields, loanFields);
      if (subfieldError) return subfieldError;
    }
  }
  return null;
};

// check error for loan collaterals
const validateCollateralItem = (
  item: LoanCollateralItem,
  loanCollateral: any
): string | null => {
  if (item.type && item.required && !loanCollateral[item.name]?.value) {
    return `${item.label} is required`;
  }
  if (item.shouldBe && item.shouldBe !== loanCollateral[item.name]?.value) {
    return `${item.label} should be ${item.shouldBe}`;
  }
  return null;
};

const validateCollateralSubfields = (
  fields: LoanCollateralItem[],
  loanCollateral: any
): string | null => {
  for (const item of fields) {
    const error = validateCollateralItem(item, loanCollateral);
    if (error) return error;
  }
  return null;
};

export const checkLoanCollateralErrors = (
  loanCollateral: any,
  data: LoanCollateralItem[] = []
): string | null => {
  for (const item of data) {
    const error = validateCollateralItem(item, loanCollateral);
    if (error) return error;
    if (item.fields?.length) {
      const subfieldError = validateCollateralSubfields(
        item.fields,
        loanCollateral
      );
      if (subfieldError) return subfieldError;
    }
  }
  return null;
};

//check errors for terms
const validateTermsItem = (
  item: TermsConditionItem,
  termsCondition: any
): string | null => {
  if (item.type && item.required && !termsCondition[item.name]?.value) {
    return `${item.label} is required`;
  }
  if (item.shouldBe && item.shouldBe !== termsCondition[item.name]?.value) {
    return `${item.label} should be ${item.shouldBe}`;
  }
  return null;
};

const validateTermsSubfields = (
  fields: TermsConditionItem[],
  termsCondition: any
): string | null => {
  for (const item of fields) {
    const error = validateTermsItem(item, termsCondition);
    if (error) return error;
  }
  return null;
};

export const checkTermsConditionErrors = (
  termsCondition: any,
  data: TermsConditionItem[] = []
): string | null => {
  for (const item of data) {
    const error = validateTermsItem(item, termsCondition);
    if (error) return error;
    if (item.fields?.length) {
      const subfieldError = validateTermsSubfields(item.fields, termsCondition);
      if (subfieldError) return subfieldError;
    }
  }
  return null;
};

//check error for vehicles
const validateItem = (
  item: VehicleFieldItem,
  loanVehicleFields: any
): string | null => {
  if (item.type && item.required && !loanVehicleFields[item.name]?.value) {
    return `${item.label} is required`;
  }
  if (item.shouldBe && item.shouldBe !== loanVehicleFields[item.name]?.value) {
    return `${item.label} should be ${item.shouldBe}`;
  }
  return null;
};

const validateSubfields = (
  fields: VehicleFieldItem[],
  loanVehicleFields: any
): string | null => {
  for (const item of fields) {
    const error = validateItem(item, loanVehicleFields);
    if (error) return error;
  }
  return null;
};

export const checkVehicleErrors = (
  loanVehicleFields: any,
  data: VehicleFieldItem[] = []
): string | null => {
  for (const item of data) {
    const error = validateItem(item, loanVehicleFields);
    if (error) return error;
    if (item.fields?.length) {
      const subfieldError = validateSubfields(item.fields, loanVehicleFields);
      if (subfieldError) return subfieldError;
    }
  }
  return null;
};

// set all default values in the loanFields
export const extractAndSetLoanFields = (
  loanPreferenceById: any[],
  productData: any,
  setLoanFields: React.Dispatch<React.SetStateAction<any>>
) => {
  const loanPreferencesFields: any[] = [];

  productData?.config?.preference.forEach((item: any) => {
    if (item.name === "yourLoanPreference") {
      loanPreferencesFields.push(item.fields);
    }
  });

  const fieldWithDefaultVal = loanPreferencesFields
    .flat()
    .filter((field: any) => field.defaultValue);

  const initialDefaultValField: IInputField[] = fieldWithDefaultVal.map(
    (field: any) => ({
      name: field.name,
      value:
        loanPreferenceById?.find((item: any) => item?.name === field?.name)
          ?.value || field.defaultValue,
      type: field.type,
      valid: true,
    })
  );

  initialDefaultValField.forEach((field: IInputField) => {
    setLoanFields((state: any) => ({
      ...state,
      [field.name]: field,
    }));
  });
};

// util function for getting initial data fields
export const getInitialData = (
  loanById: any,
  loanTermsById: any,
  loanPreferenceById: any,
  new_loan: string,
  loanVehicleById: any
) => {
  let initialFormData: Record<string, any> = {};
  let initialTermsCondition: Record<string, any> = {};
  let initialLoanFields: Record<string, any> = {};
  let initialVehicleFields: any = {};

  if (new_loan === "new") {
    return {
      initialFormData,
      initialTermsCondition,
      initialLoanFields,
      initialVehicleFields,
      formRender: true,
    };
  } else {
    loanById?.forEach((item: any) => {
      initialFormData[item?.name] = item;
    });

    loanTermsById?.forEach((item: any) => {
      initialTermsCondition[item?.name] = item;
    });

    loanPreferenceById?.forEach((item: any) => {
      initialLoanFields[item?.name] = item;
    });

    loanVehicleById?.forEach((item: any) => {
      initialVehicleFields[item?.name] = item;
    });

    return {
      initialFormData,
      initialTermsCondition,
      initialLoanFields,
      initialVehicleFields,
      formRender: true,
    };
  }
};

// display the aboutDealership section
export const formFieldForDealership = (
  loanLimitById: ILoanItem[],
  fieldDefinitions: IFieldDefinition[]
): Record<string, IInputField> => {
  return fieldDefinitions.reduce((acc, { name, type }) => {
    const field = loanLimitById.find((item) => item?.name === name);
    acc[name] = {
      name,
      value: Number(field?.value.replaceAll(",", "")),
      type,
      valid: true,
    };
    return acc;
  }, {} as Record<string, IInputField>);
};

// field definitions for aboutDealership that is prefilled
export const fieldDefinitions: IFieldDefinition[] = [
  { name: "averageVehicleSale", type: "number" },
  { name: "averageVehicleProfitMargin", type: "amount" },
  { name: "averageSellingPrice", type: "amount" },
  { name: "averageMonthlyOverhead", type: "amount" },
];

// handle input change on the form (formData, loanField, termsCondition)
export const handleInputOnChange = (e: any, setData: ISetFormData) => {
  const { name, value, type, label } = e.target;
  const valueObject = {
    name,
    value,
    type,
    label: label || e.label,
    valid: true,
  };

  setData((state: any) => {
    return {
      ...state,
      [name]: valueObject,
    };
  });
};

// handle input change on the form (loanCollateral)
export const handleCollateralInputOnChange = async (
  e: any,
  setData: ISetFormData,
  selectedCar: any,
  setSelectedCar: any
) => {
  const { name, value, type, label } = e.target;
  const valueObject = {
    name,
    value,
    type,
    label: label || e.label,
    valid: true,
  };

  // Check if the value is empty, meaning the clear button was clicked and if car already exist in the list before updating the list
  if (
    !value ||
    selectedCar.some(
      (selectedCar: any) => selectedCar.dealerCar.value === value
    )
  ) {
    setSelectedCar((prevSelectedCarValue: any) =>
      prevSelectedCarValue.filter(
        (selectedCar: any) => selectedCar.dealerCar.value !== value
      )
    );
  } else {
    setSelectedCar((prevSelectedCarValue: any) => [
      ...prevSelectedCarValue,
      { [name]: valueObject },
    ]);
  }

  setData((state: any) => {
    return {
      ...state,
      [name]: valueObject,
    };
  });
};

// map all cars that are in the car inventory that have been selected
export const mapCarsInInventory = (
  loanCarsById: any[]
): IMappedCarInventory[] => {
  return (
    loanCarsById
      ?.filter((item: Record<string, any>) => item?.carId)
      ?.map((item: any) => ({
        dealerCar: {
          name: "dealerCar",
          value: item?.carId,
          label: `${item?.carDetails?.year} ${item?.carDetails?.model?.make?.name} ${item?.carDetails?.model?.name}`,
          valid: true,
        },
      })) ?? []
  );
};

// map all cars that are not in the car inventory that have been selected
export const mapCarsNotInInventory = (loanCarsById: any[]) => {
  return (
    loanCarsById
      ?.filter((item: Record<string, any>) => !item?.carId)
      ?.map((item: any) => ({
        ownVehicle: {
          name: "ownVehicle",
          value:
            item?.ownVehicle === 1
              ? "I already own the vehicle"
              : "I do not own the vehicle, I plan to buy the vehicle and pledge it as collateral",
          label: `Do you currently own the vehicle or are you planning to buy the vehicle?`,
          valid: true,
        },
        isImportedVehicle: {
          name: "isImportedVehicle",
          value: item?.isVehicleImported,
          label: `Is the vehicle you are pledging imported or not`,
          valid: true,
        },
        vehicleMake: {
          name: "vehicleMake",
          value: item?.carDetails?.model?.make?.name,
          type: "selection",
          label: "Vehicle Make",
          valid: true,
        },
        vehicleModel: {
          name: "vehicleModel",
          value: item?.carDetails?.model?.name,
          type: "selection",
          label: "Vehicle model",
          valid: true,
        },
        vehicleModelId: {
          name: "vehicleModelId",
          value: item?.carDetails?.model?.id,
          type: "selection",
          label: "Vehicle model Id",
          valid: true,
        },
        vehicleYearModel: {
          name: "vehicleYearModel",
          value: item?.carDetails?.year,
          type: "number",
          label: "Vehicle year model",
          valid: true,
        },
        sellingCondition: {
          name: "sellingCondition",
          value: item?.carDetails?.sellingCondition,
          type: "selection",
          label: "What is the selling condition ?",
          valid: true,
        },
        bodyType: {
          name: "bodyType",
          value: item?.carDetails?.bodyType,
          type: "selection",
          label: "Body type",
          valid: true,
        },
        vehicleUse: {
          name: "vehicleUse",
          value: item?.carDetails?.vehicleUse,
          type: "selection",
          label: "Vehicle use",
          valid: true,
        },
        carWorth: {
          name: "carWorth",
          value: item?.price,
          type: "amount",
          label: "How much do you believe your car is worth?",
          valid: true,
        },
        spareKey: {
          name: "spareKey",
          value: item?.carDetails?.spareKey,
          type: "radio",
          label: "Do you have a spare key?",
          valid: true,
        },
        engineCapacity: {
          name: "engineCapacity",
          value: item?.carDetails?.engineCapacity,
          type: "selection",
          label: "Engine capacity",
          valid: true,
        },
        vehicleVin: {
          name: "vehicleVin",
          value: item?.vin,
          type: "text",
          label: "Vehicle Vin",
          valid: true,
        },
      })) ?? []
  );
};

// map all cars selected to be displayed on the car cards
export const mapPrefilledCarInfo = (loanCarsById: any[]): any[] => {
  return (
    loanCarsById?.map((item: any) => ({
      id: item.id,
      modelId: item?.carDetails?.model?.id,
      carId: item?.carId,
      carName: `${item?.carDetails?.year} ${item?.carDetails?.model?.make?.name} ${item?.carDetails?.model?.name}`,
      price: item?.price,
      rating: item?.carDetails?.gradeScore?.toFixed(1) || "---",
      vin: item?.vin,
      imageUrl:
        item?.carDetails?.imageUrl ||
        "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg",
    })) ?? []
  );
};

/**
 * Utility function to format and filter car data.
 * @param parsedResult The result object containing car data to be filtered.
 * @param formatInt Function to format the integer values (prices).
 * @returns Array of formatted car results.
 */
export const getFilteredCarResult = (
  parsedResult: { carList: ICarItem[] } | undefined
): IFilteredCarResult[] => {
  return (
    parsedResult?.carList.map((item: ICarItem) => ({
      name: `${item.year} ${item.model.make.name} ${item.model.name} \u2013 (${item?.vin})`,
      value: item.id,
    })) || []
  );
};

export const documentPageTitle = (productType: IFINANCEFORMTYPE) => {
  let documentTitle;
  switch (productType) {
    case "import":
      documentTitle = "Import Financing";
      break;
    case "local":
      documentTitle = "Local Financing";
      break;
    case "duty":
      documentTitle = "Duty Financing";
      break;
    default:
      documentTitle = "Repo Financing";
      break;
  }
  return documentTitle;
};
