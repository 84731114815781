import { Form, Upload, message } from "antd";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";

interface IDocUploads {
  title: string;
  messageLabel: string;
  name: string;
  required: boolean;
}

export default function DocFileUploader({
  title,
  messageLabel,
  name,
  required,
}: Readonly<IDocUploads>) {
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const { t } = useTranslation("loan-form");

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .pdf",
    action: `${API_URL}document/upload`,
    headers: {
      authorization: `bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {},
  };

  return (
    <div>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
        rules={[
          {
            required: required,
            message: t(messageLabel),
          },
        ]}
      >
        <Upload.Dragger {...props} name="file" maxCount={1}>
          <div className="flex gap-4 items-center px-4">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Vector%20(Stroke).svg?updatedAt=1711051618638"
              alt="Upload"
            />
            <div className="text-base font-semibold">{t(title)}</div>
          </div>
        </Upload.Dragger>
      </Form.Item>
    </div>
  );
}
