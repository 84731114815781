import { Button, Row, Col, Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import { LoanInformation } from "./EmailCustomer";
import {
  ActionButtons,
  AdditionalFeeContent,
  BreakdownContainer,
  CollapseContainer,
  DownloadLoanButton,
  DealerFootNoteInfo,
  LoanBreakdownContent,
  PreviewUploadedDoc,
} from "./styles";
import { isMobile } from "react-device-detect";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { convertCamelCase } from "@/utils/convertCamelCase";
import ReferredOfferLayout from "./ReferredOffer";
import NoLoanOffers from "./NoLoanOffer";
import { useTranslation } from "react-i18next";
import DeclinationReasonLayout from "@/components/DeclinationReason";
import DealerChart from "@/components/DealerChart";
import storage from "@/lib/storage";
import formatLoanInt from "@/utils/format-loan-currency";

const DealerFinalOffer = ({ id, loanDetails, loansById }: any) => {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("loan-form");
  const isAssumedUser = storage.getAssumedUser();
  const loanCurrency = loanDetails?.loanCurrency;

  const toUploadDocuments = () => {
    navigate(
      `/dealer/applications/upload-signed-documents?loan_id=${id}&offer_id=${
        loanDetails?.id
      }&loan_doc=${JSON.stringify(loanDetails?.financeDocuments)}`
    );
  };

  const isDocumentSigned = loanDetails?.financeDocuments?.some(
    (doc: any) => doc?.documentSigned
  );

  const REJECTION_STATUSES = ["REJECTED", "OFFER_DECLINED", "OFFER_REJECTED"];

  const getDealerFinalOffer = () => (
    <Row gutter={[18, 18]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={5}>
        <DealerChart loanDetails={loanDetails} loansById={loansById} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={19}>
        {isMobile ? (
          <CollapseContainer>
            <Collapse defaultActiveKey={["0"]} expandIconPosition="end">
              <Panel header={t("loan-breakdown")} key="1">
                <LoanInformation>
                  <ul>
                    <li>
                      <div className="name">{t("loan-amount")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.approvedAmount,
                          loanCurrency
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Collateral Amount")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.offerSummary?.dealerCollateralValue?.replaceAll(
                            ",",
                            ""
                          ),
                          loanCurrency
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("monthly-repayment")}</div>
                      <div className="value">
                        {loanDetails?.offerSummary?.installmentFrequency ===
                        "End of Term"
                          ? "Not applicable"
                          : formatLoanInt(
                              loanDetails?.offerSummary?.installmentAmount,
                              loanCurrency
                            )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Instalment Frequency")}</div>
                      <div className="value">
                        {loanDetails?.offerSummary?.installmentFrequency}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Loan to value offer")}</div>
                      <div className="value">
                        {loanDetails?.offerSummary?.loanToValue}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Total repayment amount")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.offerSummary?.totalRepaymentAmount?.replaceAll(
                            ",",
                            ""
                          ),
                          loanCurrency
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Disbursement Offer")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.offerSummary?.cashOfferToDealer,
                          loanCurrency
                        )}
                      </div>
                    </li>
                  </ul>
                </LoanInformation>
              </Panel>
              <Panel header={t("additional-fees")} key="2">
                <LoanInformation>
                  <ul>
                    {loanDetails?.additionalPayments?.map((el: any) => (
                      <li key={loanDetails?.additionalPayments?.indexOf(el)}>
                        <div className="name">{t(el?.name)}</div>
                        <div className="value">
                          {formatLoanInt(el?.value, loanCurrency)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </LoanInformation>
              </Panel>
            </Collapse>
          </CollapseContainer>
        ) : (
          <BreakdownContainer>
            <LoanBreakdownContent>
              <div className="header">{t("loan-breakdown")}</div>
              <div className="breakdown-container">
                <div className="content">
                  <div className="name">{t("loan-amount")}</div>
                  <div className="value">
                    {formatLoanInt(loanDetails?.approvedAmount, loanCurrency)}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Collateral Amount")}</div>
                  <div className="value">
                    {formatLoanInt(
                      loanDetails?.offerSummary?.dealerCollateralValue?.replaceAll(
                        ",",
                        ""
                      ),
                      loanCurrency
                    )}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Instalment Frequency")}</div>
                  <div className="value">
                    {loanDetails?.offerSummary?.installmentFrequency}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Loan to value offer")}</div>
                  <div className="value">
                    {loanDetails?.offerSummary?.loanToValue}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("monthly-repayment")}</div>
                  <div className="value">
                    {loanDetails?.offerSummary?.installmentFrequency ===
                    "End of Term"
                      ? "Not applicable"
                      : formatLoanInt(
                          loanDetails?.offerSummary?.installmentAmount,
                          loanCurrency
                        )}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Total repayment amount")}</div>
                  <div className="value">
                    {formatLoanInt(
                      loanDetails?.offerSummary?.totalRepaymentAmount?.replaceAll(
                        ",",
                        ""
                      ),
                      loanCurrency
                    )}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Disbursement Offer")}</div>
                  <div className="value">
                    {formatLoanInt(
                      loanDetails?.offerSummary?.cashOfferToDealer,
                      loanCurrency
                    )}
                  </div>
                </div>
              </div>
            </LoanBreakdownContent>
            <AdditionalFeeContent>
              <div className="header">{t("additional-payments")}</div>
              <div className="breakdown-container">
                {loanDetails?.additionalPayments?.map((el: any) => (
                  <div
                    className="content"
                    key={loanDetails?.additionalPayments?.indexOf(el)}
                  >
                    <div className="name">{t(el?.name)}</div>
                    <div className="value">
                      {formatLoanInt(el?.value, loanCurrency)}
                    </div>
                  </div>
                ))}
              </div>
            </AdditionalFeeContent>
          </BreakdownContainer>
        )}
        {isMobile && (
          <DownloadLoanButton>
            <Button
              type="link"
              href={loanDetails?.pdfUrl}
              target="_blank"
              disabled={!loanDetails?.pdfUrl || !!isAssumedUser}
              download
            >
              {t("download-loan-offer")}
            </Button>
          </DownloadLoanButton>
        )}
        {loanDetails && loanDetails?.loanDocuments?.length !== 0 && (
          <PreviewUploadedDoc>
            <div className="header">
              {loansById?.status === "SIGNED_LOAN_DOCUMENTS_SUBMITTED"
                ? t("preview-signed-loan-documents")
                : t("download-and-sign")}
            </div>
            <div className="list-container">
              {loanDetails?.financeDocuments?.map((el: any) => (
                <div key={loanDetails?.financeDocuments?.indexOf(el)}>
                  <a
                    href={el?.signedFileUrl || el?.fileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DescriptionIcon
                      sx={{ marginRight: "4px", color: "#7F83A8" }}
                    />
                    <span>{convertCamelCase(t1(el?.name)) || "---"}</span>
                  </a>
                </div>
              ))}
            </div>
          </PreviewUploadedDoc>
        )}
        <DealerFootNoteInfo>{t("once-you-have-signed")}</DealerFootNoteInfo>

        {loanDetails?.financeDocuments?.length > 0 && !isDocumentSigned && (
          <ActionButtons className="border-t-[0.809513px] border-solid border-[#e5e7eb]">
            <div className="grouping">
              <Button
                onClick={toUploadDocuments}
                className="upload"
                disabled={!!isAssumedUser}
              >
                {isMobile
                  ? t("upload-signed-docs")
                  : t("upload-signed-documents")}
              </Button>
            </div>
          </ActionButtons>
        )}
      </Col>
    </Row>
  );

  let renderFinalOffer;

  if (
    loanDetails?.status === "OFFER_REFERRED" ||
    loansById?.status === "REFERRED_FINAL_OFFER" ||
    loansById?.status === "FINAL_OFFER_REFERRED"
  ) {
    renderFinalOffer = <ReferredOfferLayout loanDetails={loanDetails} />;
  } else if (REJECTION_STATUSES.includes(loanDetails?.status as string)) {
    renderFinalOffer = (
      <DeclinationReasonLayout offers={loanDetails} loan={loansById} />
    );
  } else if (loanDetails === undefined || loansById?.offers?.length === 0) {
    renderFinalOffer = <NoLoanOffers />;
  } else {
    renderFinalOffer = (
      <div>
        {!isMobile && (
          <DownloadLoanButton>
            <Button
              type="link"
              href={loanDetails?.pdfUrl}
              target="_blank"
              disabled={!loanDetails?.pdfUrl || !!isAssumedUser}
              download
            >
              {t("download-loan-offer")}
            </Button>
          </DownloadLoanButton>
        )}

        {getDealerFinalOffer()}
      </div>
    );
  }

  return (
    <main>
      <div>{renderFinalOffer}</div>
    </main>
  );
};

export default DealerFinalOffer;
