import CircularCheckMarkIcon from "@/components/CircularCheckIcon";
import UploadCloudIcon from "@/components/UploadCloudIcon";
import { useUploadDocument } from "@/hooks/use-fetchers";
import { Loader } from "@/pages/auth/styles/loader";
import {
  CreateCarFormValues,
  FilePayload,
} from "@/validations/create-car.schema";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import React, { useRef } from "react";
import { FormInputProps } from "./types";
import { useTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          width: "100%",
          backgroundColor: "#F8FAFC",
          color: "#30345E",
          display: "flex",
          justifyContent: "start",
          height: "56px",
          "&:hover": {
            backgroundColor: "#F8FAFC",
            boxShadow: "none",
          },
          borderRadius: "10px",
          padding: "8px 16px",
          fontSize: "16px",
          fontWeight: "600",
          boxShadow: "none",
          fontFamily: "OutfitRegular",
          textTransform: "capitalize",
        },
      },
    },
  },
});

const FormInputDocument: React.FC<FormInputProps> = ({
  item,
  methods,
  group: _group,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { mutate: uploadDocument, isLoading: isUploading } =
    useUploadDocument();

  const { t } = useTranslation("car-form");

  const name = item.name as keyof CreateCarFormValues;

  let url: string | undefined;
  let fileName: string | undefined;

  const group = _group as keyof CreateCarFormValues;

  const getValues = (group: keyof CreateCarFormValues) => {
    return methods.getValues(group) as FilePayload[];
  };

  if (group) {
    const list = getValues(group);
    const target = list.find((e: any) => e.name === item.name);
    url = target?.url;
    fileName = target?.label;
  }

  if (!group) {
    url = methods.getValues(name) as string;
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    uploadDocument(file, {
      onSuccess: (fileUrl) => {
        if (group) {
          const list = getValues(group);
          const target = list.find((e: any) => e.name === item.name);
          if (target) {
            target.url = fileUrl;
            target.label = file.name;
            methods.setValue(group, list);
          }

          if (!target) {
            list.push({
              name: item.name,
              label: file.name,
              type: "image",
              url: fileUrl,
            });
            methods.setValue(group, list);
          }
        }

        if (!group) {
          methods.setValue(name, fileUrl);
        }
      },
    });

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveFile = () => {
    if (group) {
      const list = getValues(group);
      const targetIndex = list.findIndex((e: any) => e.name === item.name);
      if (targetIndex !== -1) {
        list.splice(targetIndex, 1);
        methods.setValue(group, list);
      }
    } else {
      methods.setValue(name, "");
    }
  };

  return (
    <div className="col-span-2 relative">
      <label htmlFor={`${item.name}-input`}>
        <ThemeProvider theme={theme}>
          <Button
            variant="contained"
            component="span"
            startIcon={url ? <CircularCheckMarkIcon /> : <UploadCloudIcon />}
            sx={{ mt: 2 }}
          >
            {isUploading ? (
              <Loader variant="secondary" />
            ) : (
              <>{`${t(item.label)}`}</>
            )}
          </Button>
        </ThemeProvider>
      </label>

      <input
        ref={fileInputRef}
        id={`${item.name}-input`}
        type="file"
        disabled={item.disabled}
        placeholder={item.placeholder}
        className="hidden"
        onChange={handleFileUpload}
        accept={item?.validation?.accept}
      />

      {url && (
        <button
          type="button"
          className="absolute top-2 -right-2 flex items-center justify-center text-sm bg-[#ff4d4f] text-white border border-white rounded-full w-6 h-6"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveFile();
          }}
        >
          &#x2715;
        </button>
      )}

      {url && (
        <div className="mt-1">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {fileName ?? "View Uploaded File"}
          </a>
        </div>
      )}
    </div>
  );
};

export default FormInputDocument;
