import { Button, Row, Col, Collapse } from "antd";
import { LoanInformation } from "./EmailCustomer";
import {
  AdditionalFeeContent,
  BreakdownContainer,
  CollapseContainer,
  DownloadLoanButton,
  DealerFootNoteInfo,
  LoanBreakdownContent,
} from "./styles";
import { isMobile } from "react-device-detect";
import ReferredOfferLayout from "./ReferredOffer";
import NoLoanOffers from "./NoLoanOffer";
import { useTranslation } from "react-i18next";
import DeclinationReasonLayout from "@/components/DeclinationReason";
import DealerChart from "@/components/DealerChart";
import storage from "@/lib/storage";
import formatLoanInt from "@/utils/format-loan-currency";

interface ILoanInfo {
  id: string;
  loanDetails: Record<string, any>;
  loansById: Record<string, any>;
}

const REJECTION_STATUSES = ["REJECTED", "OFFER_DECLINED", "OFFER_REJECTED"];

const DealerConditionalOffer = ({ id, loanDetails, loansById }: ILoanInfo) => {
  const { t } = useTranslation("application");
  const { Panel } = Collapse;
  const isAssumedUser = storage.getAssumedUser();
  const loanCurrency = loanDetails?.loanCurrency;

  const getDealerLoanSection = () => (
    <Row gutter={[18, 18]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={5}>
        <DealerChart loanDetails={loanDetails} loansById={loansById} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={19}>
        {isMobile ? (
          <CollapseContainer>
            <Collapse defaultActiveKey={["0"]} expandIconPosition="end">
              <Panel header={t("loan-breakdown")} key="1">
                <LoanInformation>
                  <ul>
                    <li>
                      <div className="name">{t("loan-amount")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.approvedAmount,
                          loanCurrency
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Collateral Amount")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.offerSummary?.dealerCollateralValue?.replaceAll(
                            ",",
                            ""
                          ),
                          loanCurrency
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("monthly-repayment")}</div>
                      <div className="value">
                        {loanDetails?.offerSummary?.installmentFrequency ===
                        "End of Term"
                          ? "Not applicable"
                          : formatLoanInt(
                              loanDetails?.offerSummary?.installmentAmount,
                              loanCurrency
                            )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Instalment Frequency")}</div>
                      <div className="value">
                        {loanDetails?.offerSummary?.installmentFrequency}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Loan to value offer")}</div>
                      <div className="value">
                        {loanDetails?.offerSummary?.loanToValue}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Total repayment amount")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.offerSummary?.totalRepaymentAmount?.replaceAll(
                            ",",
                            ""
                          ),
                          loanCurrency
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("Disbursement Offer")}</div>
                      <div className="value">
                        {formatLoanInt(
                          loanDetails?.offerSummary?.cashOfferToDealer,
                          loanCurrency
                        )}
                      </div>
                    </li>
                  </ul>
                </LoanInformation>
              </Panel>
              <Panel header={t("additional-fees")} key="2">
                <LoanInformation>
                  <ul>
                    {loanDetails?.additionalPayments?.map((el: any) => (
                      <li key={loanDetails?.additionalPayments?.indexOf(el)}>
                        <div className="name">{t(el?.name)}</div>
                        <div className="value">
                          {formatLoanInt(el?.value, loanCurrency)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </LoanInformation>
              </Panel>
            </Collapse>
          </CollapseContainer>
        ) : (
          <BreakdownContainer>
            <LoanBreakdownContent>
              <div className="header">{t("loan-breakdown")}</div>
              <div className="breakdown-container">
                <div className="content">
                  <div className="name">{t("loan-amount")}</div>
                  <div className="value">
                    {formatLoanInt(loanDetails?.approvedAmount, loanCurrency)}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Collateral Amount")}</div>
                  <div className="value">
                    {formatLoanInt(
                      loanDetails?.offerSummary?.dealerCollateralValue?.replaceAll(
                        ",",
                        ""
                      ),
                      loanCurrency
                    )}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Instalment Frequency")}</div>
                  <div className="value">
                    {loanDetails?.offerSummary?.installmentFrequency}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Loan to value offer")}</div>
                  <div className="value">
                    {loanDetails?.offerSummary?.loanToValue}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("monthly-repayment")}</div>
                  <div className="value">
                    {loanDetails?.offerSummary?.installmentFrequency ===
                    "End of Term"
                      ? "Not applicable"
                      : formatLoanInt(
                          loanDetails?.offerSummary?.installmentAmount,
                          loanCurrency
                        )}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Total repayment amount")}</div>
                  <div className="value">
                    {formatLoanInt(
                      loanDetails?.offerSummary?.totalRepaymentAmount?.replaceAll(
                        ",",
                        ""
                      ),
                      loanCurrency
                    )}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("Disbursement Offer")}</div>
                  <div className="value">
                    {formatLoanInt(
                      loanDetails?.offerSummary?.cashOfferToDealer,
                      loanCurrency
                    )}
                  </div>
                </div>
              </div>
            </LoanBreakdownContent>
            <AdditionalFeeContent>
              <div className="header">{t("additional-payments")}</div>
              <div className="breakdown-container">
                {loanDetails?.additionalPayments?.map((el: any) => (
                  <div
                    className="content"
                    key={loanDetails?.additionalPayments?.indexOf(el)}
                  >
                    <div className="name">{t(el?.name)}</div>
                    <div className="value">
                      {formatLoanInt(el?.value, loanCurrency)}
                    </div>
                  </div>
                ))}
              </div>
            </AdditionalFeeContent>
          </BreakdownContainer>
        )}

        {isMobile && (
          <DownloadLoanButton>
            <Button
              type="link"
              href={loanDetails?.pdfUrl}
              target="_blank"
              disabled={!loanDetails?.pdfUrl || !!isAssumedUser}
              download
            >
              {t("download-loan-offer")}
            </Button>
          </DownloadLoanButton>
        )}

        <DealerFootNoteInfo>{t("new-offer-note")}</DealerFootNoteInfo>
      </Col>
    </Row>
  );

  const renderDealerInitialOffer = () => {
    if (
      loanDetails?.status === "OFFER_REFERRED" ||
      loansById?.status === "REFERRED_CONDITIONAL_OFFER" ||
      loansById?.status === "CONDITIONAL_OFFER_REFERRED"
    ) {
      return <ReferredOfferLayout loanDetails={loanDetails} />;
    } else if (REJECTION_STATUSES.includes(loanDetails?.status as string)) {
      return <DeclinationReasonLayout offers={loanDetails} loan={loansById} />;
    } else if (loanDetails === undefined || loansById?.offers?.length === 0) {
      return <NoLoanOffers />;
    } else {
      return (
        <div>
          {!isMobile && (
            <DownloadLoanButton>
              <Button
                type="link"
                href={loanDetails?.pdfUrl}
                target="_blank"
                disabled={!loanDetails?.pdfUrl || !!isAssumedUser}
                download
              >
                {t("download-loan-offer")}
              </Button>
            </DownloadLoanButton>
          )}

          {getDealerLoanSection()}
        </div>
      );
    }
  };

  return (
    <main>
      <div>{renderDealerInitialOffer()}</div>
    </main>
  );
};

export default DealerConditionalOffer;
