import { InputLabelStyle } from "@/layouts/application-layouts/styles";
import { FormLabel } from "@mui/material";
import { Item } from "@crudmates/form-config";
import { useTranslation } from "react-i18next";

export default function FormInputLabel({ item }: { item: Item }) {
  const { t } = useTranslation("car-form");

  return (
    <FormLabel sx={InputLabelStyle}>
      {t(item.label)}{" "}
      {item.validation?.required && (
        <span className="text-sm text-red-600">*</span>
      )}
    </FormLabel>
  );
}
