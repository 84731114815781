import React from "react";
import styled from "styled-components";
import { Progress } from "antd";
import { LevelModalCloseIcon } from "@/assets/svgs";
import { IDealerType } from "../DealerLevelCard";
import { useTranslation } from "react-i18next";

const ModalOverlay = styled.div`
  z-index: 501;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 0 0.69rem;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const LEVELTEXT = {
  bronze: "BRONZE (LEVEL 1) DEALER",
  silver: "SILVER (TIER 2) DEALER",
  gold: "GOLD (TIER 3) DEALER",
  platinum: "PLATINUM (TIER 4) DEALER",
  "new-dealer": "NEW DEALER",
  "non originating": "NEW DEALER",
};
export const dealerLevel = {
  bronze: {
    text: "BRONZE (LEVEL 1) DEALER",
    perc: 25,
    medal:
      "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png",
  },
  silver: {
    text: "SILVER (TIER 2) DEALER",
    perc: 50,
    medal:
      "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals.png",
  },
  gold: {
    text: "GOLD (TIER 3) DEALER",
    perc: 75,
    medal:
      "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-1.png",
  },
  platinum: {
    text: "PLATINUM (TIER 4) DEALER",
    perc: 100,
    medal:
      "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-2.png",
  },
  "new-dealer": {
    text: "NEW DEALER",
    perc: 0,
    medal: "",
  },
  "non originating": {
    text: "NEW DEALER",
    perc: 0,
    medal: "",
  },
};

const DealerLevelModal = ({
  level,
  closeModal,
}: {
  level: string;
  closeModal: () => void;
}) => {
  const { t } = useTranslation("dealer-gamification");

  const getBackgroundImageUrl = () => {
    switch (level) {
      case "bronze":
        return `url("https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-levels/BronzeBG.png")`;
      case "silver":
        return `url("https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-levels/SilverBG.png")`;
      case "gold":
        return `url("https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-levels/GoldBG.png")`;
      case "platinum":
        return `url("https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-levels/PlatinumBG.png")`;
      default:
        return "";
    }
  };

  const getMedalUrl = () => {
    switch (level) {
      case "bronze":
        return "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png";
      case "silver":
        return "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals.png";
      case "gold":
        return "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-1.png";
      case "platinum":
        return "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-2.png";
      default:
        return "";
    }
  };

  const bg = `bg-[${getBackgroundImageUrl()}]`;

  return (
    <ModalOverlay>
      <div className="absolute top-0 bottom-0 right-0">
        <img
          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/dealer-confetti-2.gif"
          alt="dealer-level-hurray"
        />
      </div>
      <div className="absolute top-0 bottom-0 left-0">
        <img
          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/dealer-level-confetti.gif"
          alt="dealer-level-hurray"
        />
      </div>
      <div
        className={`z-[502] py-9 px-5 rounded-[2.52219rem] w-full md:w-[25rem] lg:w-[32rem] h-[28rem] md:h-fit ${bg} bg-cover bg-no-repeat mb-12 md:mb-0`}
      >
        {/* close */}
        <div className="flex w-full justify-end">
          <button onClick={closeModal}>
            <LevelModalCloseIcon />
          </button>
        </div>
        {/* body */}
        <div className="flex flex-col justify-between h-fit">
          <div className="flex flex-col justify-center items-center pt-3">
            <p className="text-base font-bold leading-6 text-white">
              {t("congratulations-you-are-now-a")}
            </p>
            <div className="pt-4">
              <img src={getMedalUrl()} alt={level} className=" w-20 h-20 " />
            </div>
            <div className="flex flex-col items-center pt-3">
              <p className="text-2xl text-white font-bold leading-8 tracking-[-0.01125rem] pb-4">
                {t(dealerLevel[level as IDealerType].text)}
              </p>
              <ButtonOutline text="view benefits" />
            </div>
          </div>
          <div className="mt-8">
            <DealerProgressLevel level={level} />
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

const ButtonOutline = ({ text, ...rest }: { text: string }) => {
  return (
    <button
      {...rest}
      className="w-fit py-2 px-4 flex justify-center items-center rounded-[2.75rem] border border-[#EEEFFF]"
    >
      <span className="text-sm font-bold tracking-[0.01563rem] capitalize text-[#EEEFFF] leading-4">
        {text}
      </span>
    </button>
  );
};

const DealerProgressLevel = ({ level }: { level: string }) => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <img
            src="https://media.autochek.africa/file/publicAssets/Frame-37100.png"
            width="40px"
            height="40px"
            alt="medal"
          />
        </div>
        <div>
          <img
            src="https://media.autochek.africa/file/publicAssets/Medals-6.png"
            width="40px"
            height="40px"
            alt="medal"
          />
        </div>
        <div>
          <img
            src="https://media.autochek.africa/file/publicAssets/Medals-7.png"
            width="40px"
            height="40px"
            alt="medal"
          />
        </div>
        <div>
          <img
            src="https://media.autochek.africa/file/publicAssets/Medals-8.png"
            width="40px"
            height="40px"
            alt="medal"
          />
        </div>
      </div>
      <Progress
        percent={dealerLevel[level as IDealerType].perc}
        size="small"
        trailColor="#EEEFFF"
        strokeColor="#FFB619"
        showInfo={false}
      />
      <div className="grid grid-cols-3 gap-1 divide-x">
        <div className="text-center text-[10px] text-white font-semibold">
          {t("sell-3")} <br /> {t("silver-get-200")}
        </div>
        <div className="text-center text-[10px] text-white font-semibold">
          {t("sell-6")} <br /> {t("gold-get-1200")}
        </div>
        <div className="text-center text-[10px] text-white font-semibold">
          {t("sell-10")}
          <br /> {t("platinum-get-2000")}
        </div>
      </div>
    </div>
  );
};
export default DealerLevelModal;
