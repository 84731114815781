export const convertCamelCase = (text: string) => {
  const result = text?.replace(/([A-Z])/g, " $1");
  const finalResult = result?.charAt(0)?.toUpperCase() + result?.slice(1);
  return finalResult;
};

export const convertCamelCaseString = (camelCaseWord: string) => {
  let convertedWord = camelCaseWord?.replace(/([a-z])([A-Z])/g, "$1 $2");
  return convertedWord?.charAt(0).toUpperCase() + convertedWord?.slice(1);
};
