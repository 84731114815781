import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { Skeleton } from "@mui/material";
import { Progress } from "antd";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { dealerLevel } from "./refix/DealerLevelModal";

export type IDealerType =
  | "new-dealer"
  | "bronze"
  | "silver"
  | "gold"
  | "platinum"
  | "non originating";

interface IDealerCard {
  type: IDealerType;
  position: string;
  total: number;
  potentialPoints: number;
  lostPoints: number;
  totalPoints: number;
  level: string;
  viewHistory: () => void;
  loading: boolean;
  useDefault: boolean;
}

const getDealerCardBackground = (props: Partial<any>) => {
  const cardType = props.type;

  switch (cardType) {
    case "bronze":
      return `bg-[url('https://media.autochek.africa/file/publicAssets/Dealer-cards-bronze.webp')]`;
    case "silver":
      return `bg-[url('https://media.autochek.africa/file/publicAssets/Dealer-cards-silver.webp')]`;
    case "gold":
      return `bg-[url('https://media.autochek.africa/file/publicAssets/Dealer-cards-gold.webp')]`;
    case "platinum":
      return `bg-[url('https://media.autochek.africa/file/publicAssets/Dealer-cards-platinum.webp')]`;
    case "new-dealer":
      return `bg-[url('https://media.autochek.africa/file/publicAssets/Dealer-cards.webp')]`;
    default:
      if (props.useDefault) {
        return `bg-[url('https://media.autochek.africa/file/publicAssets/Dealer-cards.webp')]`;
      }
      return undefined;
  }
};

const getMedalStyles = (props: Partial<any>) => {
  const type = props.type;

  switch (type) {
    case "bronze":
      return "bg-[#bf690d] text-[#ffb619]";
    case "silver":
      return "bg-[#7C7A78] text-[#ffffff]";
    case "gold":
      return "bg-[#752900] text-[#FFFFFF]";
    case "platinum":
      return "bg-[#4E4E4E] text-[#FFFFFF]";
    default:
      return "";
  }
};

const getHistoryButtonStyles = (props: Partial<any>) => {
  const type = props.type;

  switch (type) {
    case "bronze":
      return "bg-[#ffb619] text-[#30345E]";
    case "silver":
      return "bg-[#ffb619] text-[#30345E]";
    case "gold":
      return "bg-[#ffb619] text-[#FFFFFF]";
    case "platinum":
      return "bg-[#ffb619] text-[#30345E]";
    case "new-dealer":
      return "bg-[#ffb619] text-[#30345E]";
    default:
      return "";
  }
};

const Container = tw.div`
  ${(props: Partial<any>) => getDealerCardBackground(props)}
  w-full min-h-auto md:min-h-[250px] h-full bg-[#C7C7C7] bg-no-repeat bg-center bg-cover py-3.5 px-2 rounded-lg
`;

const MedalText = tw.div`
  text-xs text-white font-semibold w-fit p-1 rounded-[4px]
  ${(props: Partial<any>) => getMedalStyles(props)}
`;

const LevelText = tw.div`text-white text-xs font-semibold w-fit cursor-pointer`;

const HistoryButton = tw.button`
  text-sm font-bold px-3 py-2 rounded-full
  ${(props: Partial<any>) => getHistoryButtonStyles(props)}
`;

const currentMedal = (medal: string) => {
  switch (medal) {
    case "bronze":
      return "https://media.autochek.africa/file/publicAssets/Frame-37100.png";
    case "silver":
      return "https://media.autochek.africa/file/publicAssets/Medals-6.png";
    case "gold":
      return "https://media.autochek.africa/file/publicAssets/Medals-7.png";
    case "platinum":
      return "https://media.autochek.africa/file/publicAssets/Medals-8.png";
    case "new-dealer":
      return "https://media.autochek.africa/file/publicAssets/Medals-1-l.png";
    default:
      return "https://media.autochek.africa/file/publicAssets/Frame-37100.png";
  }
};

const DealerCardLoading = () => (
  <div className="flex flex-col gap-5 justify-between h-[220px]">
    <div className="flex justify-between gap-4">
      <div className="w-1/2 ">
        <div className="flex gap-x-4  rounded-lg">
          <Skeleton
            height={80}
            width={80}
            sx={{
              background: "#e3e3e3",
            }}
          />
          <div className="w-full my-auto">
            {[0, 1, 2].map((i) => (
              <Skeleton
                height={10}
                width={"70%"}
                sx={{
                  background: "#e3e3e3",
                }}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-1/3 my-auto">
        {[0, 1, 2].map((i) => (
          <Skeleton
            height={10}
            width={"100%"}
            sx={{
              background: "#e3e3e3",
            }}
            key={i}
          />
        ))}
      </div>
    </div>
    <div className="flex flex-col gap-4">
      <div>
        {[0, 1, 2].map((i) => (
          <Skeleton
            height={10}
            width={"100%"}
            sx={{
              background: "#e3e3e3",
            }}
            key={i}
          />
        ))}
      </div>
      <div>
        {[0, 1, 2].map((i) => (
          <Skeleton
            height={10}
            width={"100%"}
            sx={{
              background: "#e3e3e3",
            }}
            key={i}
          />
        ))}
      </div>
    </div>
  </div>
);

export default function DealerLevelCard({
  type,
  position,
  total,
  potentialPoints,
  lostPoints,
  totalPoints,
  level,
  viewHistory,
  loading,
  useDefault,
}: Readonly<IDealerCard>) {
  const navigate = useNavigate();
  const { t } = useTranslation("dealer-gamification");

  return (
    <Container type={type} useDefault={useDefault}>
      {loading ? (
        <DealerCardLoading />
      ) : (
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <div className="flex">
                <div>
                  <img
                    src={currentMedal(type)}
                    alt="medal"
                    width="80px"
                    height="68.27px"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="text-white text-2xl font-bold">
                    {capitalFirstLetter(
                      type !== "new-dealer" ? type : "New Dealer"
                    )}
                  </div>
                  {type === "new-dealer" ? (
                    <div className="text-white text-[10px] font-semibold">
                      {t("you-have-not-sold-any-car-yet")}
                    </div>
                  ) : (
                    <>
                      <MedalText type={type}>
                        {t("you-are")} {position} {t("of")} {total} {level}{" "}
                        <br className="block sm:hidden" /> {t("dealers")}
                      </MedalText>
                      <div className="text-white text-[10px] font-semibold">
                        {t("no-sales-in-90-days")}{" "}
                        <br className="block sm:hidden" /> {t("1-level-back")}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <LevelText onClick={() => navigate("/dealer/benefits")}>
                {t("view-all-levels")}
              </LevelText>
            </div>
            <div className="flex flex-col gap-2.5">
              <div>
                <div className="text-white text-2xl font-semibold">
                  {totalPoints}
                  <span className="text-white text-sm font-semibold">pts</span>
                </div>
                <div className="text-white text-[10px] font-semibold">
                  {t("potential-points")}: {potentialPoints}
                </div>
                <div className="text-white text-[10px] font-semibold">
                  {t("lost-points")}: {lostPoints}
                </div>
              </div>
              {!useDefault && (
                <HistoryButton onClick={viewHistory} type={type}>
                  {t("view-history")}
                </HistoryButton>
              )}
            </div>
          </div>

          <div>
            {type === "new-dealer" || useDefault ? (
              <div className="flex justify-between">
                <div>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Medals-1-l.png"
                    width="40px"
                    height="40px"
                    alt="medal"
                  />
                </div>
                <div className="opacity-40">
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Frame-37100.png"
                    width="40px"
                    height="40px"
                    alt="medal"
                  />
                </div>
              </div>
            ) : (
              <div className="flex justify-between">
                <div>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Frame-37100.png"
                    width="40px"
                    height="40px"
                    alt="medal"
                  />
                </div>
                <div>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Medals-6.png"
                    width="40px"
                    height="40px"
                    alt="medal"
                  />
                </div>
                <div>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Medals-7.png"
                    width="40px"
                    height="40px"
                    alt="medal"
                  />
                </div>
                <div>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Medals-8.png"
                    width="40px"
                    height="40px"
                    alt="medal"
                  />
                </div>
              </div>
            )}

            {type === "new-dealer" ? (
              <Progress
                percent={0}
                size="small"
                trailColor="#EEEFFF"
                strokeColor="#FFB619"
                showInfo={false}
              />
            ) : (
              <Progress
                percent={type ? dealerLevel[type].perc : 0}
                size="small"
                trailColor="#EEEFFF"
                strokeColor="#FFB619"
                showInfo={false}
              />
            )}

            {type === "new-dealer" ? (
              <div className="text-center text-[10px] text-white font-semibold">
                {t("sell-at-least-one")}
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-1 divide-x">
                <div className="text-center text-[10px] text-white font-semibold">
                  {t("sell-3")} <br className="block sm:hidden" /> {t("silver")}{" "}
                  <br className="hidden sm:block" />
                  {t("get-200")}
                </div>
                <div className="text-center text-[10px] text-white font-semibold">
                  {t("sell-6")} <br className="block sm:hidden" /> {t("gold")}{" "}
                  <br className="hidden sm:block" />
                  {t("get-1200")}
                </div>
                <div className="text-center text-[10px] text-white font-semibold">
                  {t("sell-10")}
                  <br className="block sm:hidden" /> {t("platinum")}{" "}
                  <br className="hidden sm:block" /> {t("get-2000")}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  );
}
