import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import { ILimitById } from "@/interface/dealer";
import tw from "tailwind-styled-components";
import { convertCamelCaseString } from "@/utils/convertCamelCase";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

  .top-text {
    font-size: 16px;
    color: #30345e;
    font-weight: bolder;
    margin-left: 0.5rem;
  }
`;

const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;

interface ILimitDoc {
  code: string;
  documentSigned: boolean;
  fileUrl: string;
  name: string;
}

export default function CreditLimitActivation() {
  const navigate = useNavigate();
  const { t: t1 } = useTranslation("inventory");
  const role = storage.getRole();
  const [limitById, setLimitById] = useState<ILimitDoc[] | any>([]);
  const [searchParams] = useSearchParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const limitId = queryParameters.get("limit_id");
  const queryClient = useQueryClient();
  const dealerId = searchParams.get("dealer_id");

  useEffect(() => {
    document.title = `Dealer Plus | Credit Limit Activation [${role}]`;
  }, [role]);

  const { isLoading: limitByIdLoading } = useQuery({
    queryKey: ["fetch_limitById"],
    queryFn: () => {
      return client.get(`/v1/dealer/loanlimit/${limitId}`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: ILimitById = JSON.parse(res?.data);
      const limitDocument = parsedResponseData?.loanLimits?.find(
        (item) => item?.limitDocuments?.length > 0,
      );
      setLimitById(limitDocument?.limitDocuments);
      queryClient.invalidateQueries(["fetch_dealer_limit"]);
    },
    enabled: limitId ? true : false,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <div>
      {limitByIdLoading ? (
        <CustomLoading>
          <Loader variant={"secondary"} />
        </CustomLoading>
      ) : (
        <main className="w-full lg:w-[40%] lg:bg-white my-0 mx-auto lg:rounded-lg">
          <TextContainer
            className="lg:px-6 lg:pt-6 lg:pb-4 lg:border-b lg:border-solid lg:border-[#E5E7EB]"
            onClick={() => navigate(-1)}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
              alt="left-arrow"
            />
            <div className="top-text">{capitalFirstLetter(t1("back"))}</div>
          </TextContainer>

          <div className="flex flex-col gap-8 lg:px-6 lg:pb-8">
            <div>
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/credit-limit-web.png?updatedAt=1705319350045"
                alt="Credit Limit"
                className="w-full h-[174px] lg:h-[214px] object-cover"
              />
            </div>
            <div>
              <div className="text-xl text-[#30345E] custom-font-bold leading-relaxed">
                Why do I need to activate my limit?
              </div>
              <div className="text-sm text-[#30345E] font-normal">
                This ensures that you have agreed to the terms and conditions as
                regards to financing
              </div>
            </div>

            <div className="custom-download-bg rounded-lg px-4 py-4">
              <div className="text-[#30345E] text-base font-bold mb-1">
                Download and sign these loan documents
              </div>
              {limitById?.map((limit: Record<string, any>, index: number) => (
                <a
                  key={limitById.indexOf(limit)}
                  className="flex items-center gap-2 text-[#4B5563] text-sm"
                  href={limit?.fileUrl}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/download.png?updatedAt=1705316521070"
                    alt="Download"
                    width="24px"
                    height="24px"
                  />{" "}
                  {convertCamelCaseString(limit?.name)}
                </a>
              ))}
            </div>

            <Button
              disabled={limitById?.length === 0 ? true : false}
              onClick={() =>
                navigate(`/dealer/upload-master-agreement?limit_id=${limitId}&dealer_id=${dealerId}`)
              }
              className="bg-[#FFB619] w-full h-[48px] rounded-full hover:border-none focus:border-none text-sm font-bold"
            >
              <span className="text-[#30345E] hover:text-[#30345E] focus:text-[#30345E]">
                Upload signed document
              </span>
            </Button>
          </div>
        </main>
      )}
    </div>
  );
}
