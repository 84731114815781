import french from "antd/es/date-picker/locale/fr_FR";
import arabic from "antd/es/date-picker/locale/ar_EG";
import english from "antd/es/date-picker/locale/en_US";

export const getLocalizedText = (locale: any) => {
  if (locale === "fr") {
    return french;
  } else if (locale === "ar") {
    return arabic;
  } else {
    return english;
  }
};
