import { CreateCarFormValues } from "@/validations/create-car.schema";
import { Option } from "@crudmates/form-config";
import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { FormInputProps } from "./types";
import FormInputLabel from "./FormInputLabel";
import FormInputComment from "./FormInputComment";
import { FormDropdownStyle } from "@/layouts/application-layouts/styles";
import { Loader } from "@/pages/auth/styles/loader";
import { useTranslation } from "react-i18next";

export const FormInputDropdown: React.FC<FormInputProps> = ({
  field,
  item,
  options,
  methods,
  data,
  disabled,
}) => {
  const name = item.name as keyof CreateCarFormValues;
  const errors = { ...methods.formState.errors } as Record<string, any>;
  const error = errors[name]?.message;
  const { t } = useTranslation("car-form");

  const handleSelect = (_: any, _value: any) => {
    const selectedOption: any = options?.find(
      (option) => option.value === _value.value
    );
    const value = selectedOption?.value ?? "";
    const id = selectedOption?.id ?? "";
    const decoded = selectedOption?.decoded ?? "";

    switch (name) {
      case "make":
        methods.setValue("make", value);
        methods.setValue("makeId", id);
        break;
      case "model":
        methods.setValue("model", value);
        methods.setValue("modelId", id);
        methods.setValue("isModelDecoded", decoded);
        break;
      case "state":
        methods.setValue("state", value);
        methods.setValue("stateId", id);
        break;
      case "city":
        methods.setValue("city", value);
        methods.setValue("cityId", id);
        break;
      case "franchise":
        methods.setValue("franchise", value);
        methods.setValue("franchiseId", id);
        break;
      case "bodyType":
        methods.setValue("bodyType", value);
        methods.setValue("bodyTypeId", id);
        break;
      case "trim":
        methods.setValue("trim", value);
        methods.setValue("trimId", id);
        break;
      default:
        methods.setValue(name, value);
        break;
    }
  };

  const handleInputChange = (event: any) => {
    if (name === "franchise" && event?.target?.value) {
      const value = event?.target?.value;
      if (value === "") return;
      methods.setValue("searchFranchise", value);
      methods.setValue("franchise", value);
      methods.setValue("franchiseId", "");
    }
  };

  return (
    <div className="space-y-2">
      <FormInputLabel item={item} />
      <Autocomplete
        disablePortal
        options={options ?? []}
        getOptionLabel={(option: Option) => option.label || ""}
        onChange={handleSelect}
        onInputChange={(event) => handleInputChange(event)}
        isOptionEqualToValue={(option: Option, value: Option) =>
          option.value === value.value
        }
        fullWidth
        disabled={disabled || item.disabled}
        value={options?.find((option) => option.value === field.value) || null}
        sx={FormDropdownStyle}
        renderInput={(params) => (
          <TextField
            {...params}
            value={field.value}
            placeholder={t(item.placeholder as string)}
            variant="outlined"
            fullWidth
            error={!!error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {data?.isSearchingFranchise && item.name === "franchise" ? (
                    <Loader variant="secondary" />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "16px",
                color: "#30345E",
                fontFamily: "OutfitRegular",
                height: "15px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px",
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index });
            return <Chip key={key} label={option.label} {...rest} />;
          })
        }
      />
      <FormInputComment item={item} />
      {error && <span className="text-red-600 text-sm">{error}</span>}
    </div>
  );
};
