import { InputFieldStyle } from "@/layouts/application-layouts/styles";
import { Loader } from "@/pages/auth/styles/loader";
import { TextField } from "@mui/material";
import { FormInputProps } from "./types";
import FormInputLabel from "./FormInputLabel";
import { useTranslation } from "react-i18next";

export const FormInputSearch: React.FC<FormInputProps> = ({
  item,
  field,
  methods,
  data,
}: FormInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    methods.setValue("searchFeatures", e.target.value);
  };

  const { t } = useTranslation("car-form");

  return (
    <div className="space-y-2 col-span-2">
      <FormInputLabel item={item} />
      <TextField
        {...field}
        fullWidth
        error={!!field.error}
        type={item.type}
        disabled={item.disabled}
        hidden={item.hidden}
        placeholder={t(item.placeholder as string)}
        onChange={handleChange}
        sx={InputFieldStyle}
      />
      <div className="flex justify-center">
        {data?.isSearchingFeatures && <Loader variant="secondary" />}
      </div>
    </div>
  );
};
