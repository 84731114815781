import { useState } from "react";
import Stack from "@mui/material/Stack";
import { Typography, Box, IconButton, styled } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CircularProgress from "@mui/material/CircularProgress";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UploadCloudIcon from "../UploadCloudIcon";
import { createLoanDocument } from "@/hooks/use-loan";
import { useTranslation } from "react-i18next";

const Input = styled("input")({
  display: "none",
});

export type UploadButtonProps = {
  value: {
    name: string;
    data: string | ArrayBuffer;
  };
  onChange: (payload: any) => void;
  label: string;
  fileType?: string;
  fieldName?: string;
  removeable?: boolean;
  onRemoveField?: () => void;
  name?: any;
  channel?: string;
};

const NewUploadButton = ({
  value,
  onChange,
  label,
  fileType,
  fieldName,
  removeable = false,
  onRemoveField = () => {},
  name,
}: UploadButtonProps) => {
  const { t } = useTranslation("loan-form");
  const [loading, setLoading] = useState(false);

  const handleCapture = async ({ target }: any) => {
    try {
      setLoading(true);
      const { data } = await createLoanDocument({
        file: target.files[0],
        public: "true",
      });
      setLoading(false);
      let url = JSON.parse(data);
      onChange({
        name: target.files[0].name,
        data: url?.file?.url,
        label: label,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = () => {
    onChange(undefined);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Box
        sx={{
          fontWeight: 600,
          backgroundColor: "#fff",
          border: "1px solid #e5e7eb",
          boxShadow:
            "rgb(250, 250, 255) 0px 1px 3px, rgba(0, 0, 0, 0.06) 0px 1px 2px",
          width: { md: "100%", xs: "100%" },
          paddingY: "15px",
          paddingX: "15px",
          marginY: "9px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        component="label"
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UploadCloudIcon sx={{ marginRight: "15px" }} />
          <Input
            accept={fileType ?? "/*"}
            onChange={handleCapture}
            multiple={false}
            type="file"
          />
          <Box>
            <Typography
              sx={{ fontColor: "#30345E", fontWeight: "600", fontSize: "16px" }}
            >
              {t(label)}{" "}
              {value?.name && (
                <CheckCircleRoundedIcon style={{ color: "#00E676" }} />
              )}
            </Typography>
            {value?.name ? (
              <Typography fontSize="14px">{value.name}</Typography>
            ) : null}
          </Box>
        </Box>
        {loading ? <CircularProgress size={20} /> : null}
        {value?.name ? (
          <IconButton sx={{ color: "red" }} onClick={onDelete}>
            <DeleteOutlinedIcon />
          </IconButton>
        ) : null}

        {!value?.name && removeable && (
          <HighlightOffIcon
            sx={{
              backgroundColor: "#F8FAFC",
            }}
            onClick={(e) => {
              e.preventDefault();
              onRemoveField();
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default NewUploadButton;
