import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import storage from "@l/storage";
import DealerBenefitDetail from "@/components/DealerBenefitDetail";
import { dealerLevelsData } from "@/data/benefitListData";

interface IBenefitList {
  type: string;
  title: string;
  description: string;
  medalIcon: string;
}

const BackButton = tw.div`flex items-center gap-2 text-[#30345E] text-base md:text-lg font-bold mb-5 cursor-pointer`;
const Title = tw.div`text-2xl text-[#30345E] text-center font-bold`;
const MedalText = tw.div`text-[#30345E] text-base font-bold uppercase`;
const Text = tw.div`text-[#6B7280] text-sm font-normal`;
const StatusText = tw.div`absolute bottom-0 text-white font-bold w-fit text-[10px] bg-[#30345E] rounded-t-lg px-2 py-1`;
const Container = tw.div`relative w-full flex items-center justify-between bg-white border px-3 md:px-4 py-8 border-solid border-[#e5e7eb] cursor-pointer rounded-lg`;

export default function DealerBenefits() {
  const navigate = useNavigate();
  const role = storage.getRole();
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<boolean>(true);
  const [benefitList, setBenefitList] = useState({});

  useEffect(() => {
    document.title = `Dealer Plus | Dealer Benefits [${role}]`;
  }, [role]);

  const openBenefitsList = (data: IBenefitList) => {
    setCurrentView(false);
    setBenefitList(data);
  };

  const checkMobileView = () => {
    const breakpoint: number = 768;
    setIsMobileView(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <main className="w-full md:w-1/2 my-0 mx-auto">
      {currentView === true ? (
        <div>
          <BackButton onClick={() => navigate("/dealer/dashboard")}>
            {isMobileView ? (
              <img
                src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-l.svg"
                alt="back arrow"
              />
            ) : (
              <img
                src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
                alt="back arrow"
              />
            )}{" "}
            Back
          </BackButton>

          <div className="flex flex-col gap-4">
            <Title>Dealership Levels</Title>
            {dealerLevelsData.map((level, index) => (
              <Container key={index} onClick={() => openBenefitsList(level)}>
                <div className="flex items-center gap-1">
                  <div>
                    <img
                      src={level.medalIcon}
                      width="46px"
                      height="46px"
                      alt="medal"
                    />
                  </div>
                  <div>
                    <MedalText>{level.title}</MedalText>
                    <Text>{level.description}</Text>
                  </div>
                </div>
                <div>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-1-1.svg"
                    alt="arrow right"
                  />
                </div>
                {level.type === "bronze" && (
                  <StatusText>☝🏼 You are here</StatusText>
                )}
              </Container>
            ))}
          </div>
        </div>
      ) : (
        <DealerBenefitDetail
          benefitList={benefitList}
          setCurrentView={setCurrentView}
        />
      )}
    </main>
  );
}
