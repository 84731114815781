import { NavLink } from "react-router-dom";

import styled from "styled-components";
import { device } from "@/utils/device";

const Container = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  .active-label {
    border-bottom: 3px solid #ffb619;
  }
`;

const Label = styled.div`
  padding: 20px 14px;
  color: #30345e;
  font-size: 14px;
  font-weight: bold;
  @media ${device.mobileL} {
    padding: 16px 12px;
  }
`;

const Item = ({ $to, children }: IAsideItem) => {
  let content;

  content = (
    <>
      <Container>
        <NavLink
          className={({ isActive }) =>
            isActive ? "active-label" : "text-scene"
          }
          to={$to}
        >
          <Label>{children}</Label>
        </NavLink>
      </Container>
    </>
  );

  return content;
};

export default Item;
