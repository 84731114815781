import { useMutation } from "@tanstack/react-query";
import client from "@/lib/axios-client";

const signIn = (data: ISignIn) => {
  return client.post("/v1/auth/login", data);
};

const resetLink = (data: IResetLink) => {
  return client.post("/v1/auth/login/otp", data);
};

const verifyCode = (data: IVerifyCode) => {
  return client.post("/v1/auth/login/otp/verify", data);
};

export const useSignIn = () => {
  return useMutation(signIn);
};

export const useResetLink = () => {
  return useMutation(resetLink);
};

export const useVerifyCode = () => {
  return useMutation(verifyCode);
};
