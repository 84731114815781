import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";

export default function Sider() {
  const role = storage.getRole();
  const isFranchise = storage.utilities.isFranchise();
  const { t } = useTranslation("navigation");

  const getStoredLang: any = window.localStorage.getItem("storedLang");

  let langLeftStyle;

  switch (getStoredLang) {
    case "en":
      langLeftStyle = `left-[-72px] md:left-[-100px]`;
      break;
    case "fr":
      langLeftStyle = `left-[-127px] md:left-[-171px]`;
      break;
    case "ar":
      langLeftStyle = `left-[-53px] md:left-[-75px]`;
      break;
    default:
      langLeftStyle = `left-[-72px] md:left-[-100px]`; // Default (optional)
  }

  return (
    <>
      {isFranchise && (
        <div className="hidden md:block w-full">
          <div
            className={`fixed ${langLeftStyle} top-0 bottom-0 px-3 md:px-6 w-fit h-[24px] md:h-[32px] flex flex-col justify-center bg-[#30345E] transform rotate-90 my-auto mx-0 rounded-t-lg`}
          >
            <div className="uppercase text-white font-bold md:font-semibold text-sm md:text-lg tracking-widest">
              {t(role?.replace("_", " ")?.toUpperCase())}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
