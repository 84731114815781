import { List, message, Tabs } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import type { PaginationProps } from "antd";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { NotificationContainer, NotifyTab } from "./styles";
import styled from "styled-components";
import NotificationCard from "@/components/NotificationCard";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

type props = {
  notificationsResponse: INotificationsResponse;
  loadNotification: boolean;
  activePage: IActivePage;
  setActivePage: (a: IActivePage) => void;
  inAppNotification: INotificationsResponse;
  inAppLoadNotification: boolean;
  inAppActivePage: IActivePage;
  setInAppActivePage: (a: IActivePage) => void;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
};

const Message = tw.div`text-sm`;
const CButton = tw.button`w-auto h-fit border border-solid border-[#30345e] rounded-[44px] bg-white text-[#30345e] font-semibold text-[13px] px-2 py-0.5`;
const Title = tw.div`font-semibold text-base px-4 py-3 bg-[#eeefff] text-[#30345e]`;

const NotificationList = styled.div`
  .ant-list .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0;
    color: rgba(0, 0, 0, 0.88);
  }

  @media (min-width: 768px) {
    .group:hover .group-hover\:opacity-100 {
      opacity: 1;
    }
    .md\:opacity-0 {
      opacity: 0;
    }
    .md\:group-hover\:opacity-100 {
      opacity: 0;
    }
  }
`;

const NotificationView = ({
  notificationsResponse,
  loadNotification,
  activePage,
  setActivePage,
  inAppNotification,
  inAppLoadNotification,
  inAppActivePage,
  setInAppActivePage,
  setShowNotification,
}: props) => {
  const user = storage.getUser();
  const queryClient = useQueryClient();
  const { t } = useTranslation("navigation");
  const { t: t1 } = useTranslation("dashboard");
  const isAccountManager = storage.utilities.isAccountManager();

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <button className="bg-[#ffb619] font-semibold rounded px-2">
          {t1("previous")}
        </button>
      );
    }
    if (type === "next") {
      return (
        <button className="bg-[#ffb619] font-semibold rounded px-2">
          {t1("next")}
        </button>
      );
    }
    return originalElement;
  };

  const handleMarkAsRead = async (notificationId: string) => {
    const payload = {
      id: notificationId,
      dealer_id: user?.company?.id,
      is_read: true,
    };
    try {
      const res = await client.put(
        `/v1/dealer/${user?.company?.id}/notifications`,
        payload
      );
      if (res) {
        message.success("Message has being read");
        queryClient.invalidateQueries(["fetch_notifications"]);
      }
    } catch (error: any) {
      message.error(
        JSON.parse(error?.response?.data)?.error || "Error reading messages"
      );
    }
  };

  const renderCustomerLoanNotification = () => {
    return (
      <NotificationList>
        <List
          dataSource={inAppNotification?.notifications}
          loading={inAppLoadNotification}
          pagination={{
            current: inAppActivePage?.currentPage,
            pageSize: inAppActivePage?.pageSize,
            total: inAppNotification?.pagination?.total,
            onChange(page, pageSize) {
              setInAppActivePage({
                ...inAppActivePage,
                currentPage: page,
              });
              paramsObjectToQueryString({
                current_page: page,
                page_size: pageSize,
              });
            },
            itemRender: itemRender,
            position: "bottom",
            align: "center",
            simple: true,
          }}
          renderItem={(item) => (
            <List.Item>
              <NotificationCard
                item={item}
                setShowNotification={setShowNotification}
              />
            </List.Item>
          )}
        />
      </NotificationList>
    );
  };

  const renderDealerLoanNotification = () => {
    return (
      <List
        dataSource={notificationsResponse?.notifications}
        loading={loadNotification}
        pagination={{
          current: activePage?.currentPage,
          pageSize: activePage?.pageSize,
          total: notificationsResponse?.pagination?.total,
          onChange(page, pageSize) {
            setActivePage({
              ...activePage,
              currentPage: page,
            });
            paramsObjectToQueryString({
              current_page: page,
              page_size: pageSize,
            });
          },
          itemRender: itemRender,
          position: "bottom",
          align: "center",
          simple: true,
        }}
        renderItem={(item) => (
          <List.Item>
            <div className="flex gap-1.5 w-full px-3 md:px-4">
              <div className="flex-1">
                <Message
                  className={`${
                    item?.isRead
                      ? "font-medium text-[#8f8f8f]"
                      : "font-semibold text-[#30345E]"
                  }`}
                >
                  {item?.message}
                </Message>
              </div>
              {item?.isRead === false && (
                <div>
                  <CButton
                    onClick={() => {
                      handleMarkAsRead(item?.id);
                    }}
                  >
                    Mark as read
                  </CButton>
                </div>
              )}
            </div>
          </List.Item>
        )}
      />
    );
  };

  return (
    <NotificationContainer>
      <div className="notify-container">
        <Title>{t("notifications")}</Title>

        {isAccountManager ? (
          renderCustomerLoanNotification()
        ) : (
          <NotifyTab>
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: `Customer Loans`,
                  key: "1",
                  children: renderCustomerLoanNotification(),
                },
                {
                  label: `Dealer Loans`,
                  key: "2",
                  children: renderDealerLoanNotification(),
                },
              ]}
            />
          </NotifyTab>
        )}
      </div>
    </NotificationContainer>
  );
};

export default NotificationView;
