import { useEffect, useState } from "react";
import {
  Container,
  Content,
  Logo,
  FormContainer,
  CustomSelect,
} from "./styles/sign-in";
import { Button, Form, Input, message, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import tw from "tailwind-styled-components";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import { useTranslation } from "react-i18next";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const ResetPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { i18n } = useTranslation("navigation");
  const { t: t1 } = useTranslation("auth");
  const [lang, setLang] = useState("");
  const queryParam = new URLSearchParams(window.location.search);
  const locale: string | any = queryParam.get("lang");

  useEffect(() => {
    document.title = "Dealer Plus | Reset Password";
  }, []);

  const user = storage.getUser();
  const location = useLocation() as IUseLocation | any;
  const redirectPath = location.state?.path || "/";

  const handleLanguageChange = (value: string) => {
    const params = new URLSearchParams({ lang: value });
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true },
    );
    i18n.changeLanguage(value);
    setLang(value);
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
    setLang(i18n.language);
  }, [i18n, i18n.language, locale]);

  const { mutate, isLoading, isError, isSuccess, error } = useMutation({
    mutationFn: (payload) => client.put(`/v1/user/${user?.id}`, payload),
  });

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      message.error(JSON.parse(error.response?.data).error);
    isError && error instanceof AxiosError && form.resetFields();
  }, [isError, form, error]);

  useEffect(() => {
    if (isSuccess) {
      message.success("Password reset successful");
      navigate(redirectPath, { replace: true });
    }
  }, [isSuccess, navigate, redirectPath]);

  const handleVerifyCode = async (values: IResetPassword) => {
    if (values.password !== values.confirm) {
      message.error("Passwords does not match");
      return;
    }
    const payload: any = {
      password: values.password,
      confirm: values.confirm,
    };
    mutate({ ...payload });
  };

  return (
    <Container>
      <Content>
        <Logo>
          <img
            alt="Autochek's Logo"
            src="https://media.autochek.africa/file/publicAssets/logo-horizontal-1.svg"
          />
          <div
            className="chevron-back"
            onClick={() => navigate("/auth/verify-link")}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
              alt="chevron-back"
            />
          </div>
        </Logo>
        <FormContainer>
          <Form
            name="verify-link"
            layout="vertical"
            requiredMark={false}
            onFinish={handleVerifyCode}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label={t1("new-password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t1("please-enter-your-new-password"),
                },
              ]}
            >
              <Input.Password placeholder={t1("enter-new-password")} />
            </Form.Item>
            <Form.Item
              label={t1("confirm-password")}
              name="confirm"
              rules={[
                {
                  required: true,
                  message: t1("please-enter-your-confirm-password"),
                },
              ]}
            >
              <Input.Password placeholder={t1("enter-confirm-password")} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit">
                {isLoading ? (
                  <Loader variant={"secondary"} />
                ) : (
                  t1("save-password")
                )}
              </Button>
            </Form.Item>
          </Form>
        </FormContainer>
      </Content>
      <CustomSelect>
        <div className="language-img">
          <img
            src="https://media.autochek.africa/file/publicAssets/icons8-language.svg"
            alt="language-img"
          />
        </div>
        <Select
          onChange={handleLanguageChange}
          value={lang}
          options={[
            { value: "en", label: "English" },
            { value: "fr", label: "Français" },
            { value: "ar", label: "عربي" },
          ]}
        />
      </CustomSelect>
    </Container>
  );
};

export default ResetPassword;
