import tw from "tailwind-styled-components";

const getBorderStyle = (props: Partial<IButtonLoader>): string | undefined => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  }
  return undefined;
};

export const Loader = tw.div`
${(props: Partial<IButtonLoader>) =>
  getBorderStyle(
    props
  )} inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;
