import { useEffect } from "react";
import { Input, Table, List, Button } from "antd";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { isMobile } from "react-device-detect";
import {
  FilterContainer,
  PendingDocMobileCard,
  TableContainer,
} from "@/layouts/application-layouts/styles";
import { useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

interface DealerDataType {
  key: string;
  name: string;
  phonenumber: number;
  displayAddress: string;
  id: string;
}

interface IDigDealer {
  activePage: any;
  setActivePage: React.Dispatch<React.SetStateAction<any>>;
  query: any;
  setValue: any;
  fetchDealers: any;
  fetchDealersLoading: boolean;
}

const DigDealersLayout = ({
  activePage,
  setActivePage,
  query,
  setValue,
  fetchDealers,
  fetchDealersLoading,
}: IDigDealer) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("dashboard");

  useEffect(() => {
    if (query.current_page && typeof query.current_page == "string") {
      setActivePage({
        ...activePage,
        currentPage: parseInt(query.current_page),
      });
    }
  }, [activePage, query.current_page, setActivePage]);

  const handlePageChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page.current,
    });
    navigate(
      `/applications${paramsObjectToQueryString({
        ...query,
        current_page: page.current,
        page_size: page.pageSize,
      })}`
    );
  };

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    navigate(
      `/applications${paramsObjectToQueryString({
        ...query,
        search: value,
      })}`
    );
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <a href="#link">{t("previous")}</a>;
    }
    if (type === "next") {
      return <a href="#link">{t("next")}</a>;
    }
    return originalElement;
  };

  const itemTableRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 6px",
          }}
        >
          {i18n.language === "ar" ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 6px",
          }}
        >
          {i18n.language === "ar" ? <LeftOutlined /> : <RightOutlined />}
        </Button>
      );
    }
    return originalElement;
  };

  const columns: ColumnsType<DealerDataType> = [
    {
      title: t("dealer-name"),
      dataIndex: "dealerName",
      key: "dealerName",
      render: (_, el) => <span>{el?.name || "---"}</span>,
    },
    {
      title: t("phone-no"),
      dataIndex: "phoneNo",
      key: "phoneNo",
      render: (_, el) => <span>{el?.phonenumber || "---"}</span>,
    },
    {
      title: t("address"),
      key: "address",
      dataIndex: "address",
      render: (_, el) => <span>{el?.displayAddress || "---"}</span>,
    },
  ];

  return (
    <div style={{ padding: "0px" }}>
      <FilterContainer>
        <div className="filter-field">
          <div className="input-field">
            <div className="label mobile-input-field">{t("quick-search")}</div>
            <Input
              placeholder={t("search-customer")}
              onChange={(val) => handleFilterSearch(val)}
              prefix={
                <img
                  src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                  alt="search-icon"
                />
              }
              allowClear
            />
          </div>
        </div>
      </FilterContainer>

      <TableContainer>
        {isMobile ? (
          <div>
            <List
              bordered={false}
              loading={fetchDealersLoading}
              dataSource={fetchDealers?.result}
              pagination={{
                current: activePage?.currentPage,
                pageSize: activePage?.pageSize,
                total: fetchDealers?.pagination?.total,
                simple: true,
                onChange(page, pageSize) {
                  setActivePage({
                    ...activePage,
                    currentPage: page,
                  });
                  navigate(
                    `/applications${paramsObjectToQueryString({
                      ...query,
                      current_page: page,
                      page_size: pageSize,
                    })}`
                  );
                },
                itemRender: itemRender,
              }}
              renderItem={(item: Record<string, any>) => (
                <List.Item style={{ padding: "8px" }}>
                  <PendingDocMobileCard style={{ width: "100%" }}>
                    <button
                      className="car-info"
                      onClick={() => {
                        setValue("BY ME");
                        navigate(
                          `/applications?role=dealer&dealerId=${item?.id}&dealerName=${item?.name}`
                        );
                      }}
                    >
                      <div>
                        <div className="name">{item?.name || "---"}</div>
                        <div className="phone">
                          {item?.phonenumber || "---"}
                        </div>
                        <div className="car">
                          {item?.displayAddress || "---"}
                        </div>
                      </div>
                    </button>
                    <div className="arrow-detail">
                      <div>
                        <img
                          src="https://media.autochek.africa/file/publicAssets/arrow-narrow-right.svg"
                          alt="arrow-right"
                        />
                      </div>
                    </div>
                  </PendingDocMobileCard>
                </List.Item>
              )}
            />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={fetchDealers?.result}
            onChange={handlePageChange}
            loading={fetchDealersLoading}
            pagination={{
              current: activePage?.currentPage,
              pageSize: activePage?.pageSize,
              total: fetchDealers?.pagination?.total,
              itemRender: itemTableRender,
              showSizeChanger: false,
            }}
            rowKey="id"
            onRow={(data) => {
              return {
                onClick: () => {
                  setValue("BY ME");
                  navigate(
                    `/applications?role=dealer&dealerId=${data?.id}&dealerName=${data?.name}`
                  );
                },
              };
            }}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default DigDealersLayout;
