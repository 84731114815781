import { CustomFormField } from "@/layouts/application-layouts/styles";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, InputNumber, Radio, RadioChangeEvent } from "antd";
import tw from "tailwind-styled-components";
import { TelInputContainer } from "@/pages/auth/styles/sign-in";
import storage from "@/lib/storage";
import { device } from "@/utils/device";
import { useNavigate } from "react-router-dom";
import { getCountryFlag } from "@/utils/getCountryFlag";
import { getCountryTelCode } from "@/utils/getCountryTelCode";
import formatCurrency from "@u/formatCurrency";

const PrequalificationHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.18px;
    padding-left: 15rem;
    @media ${device.mobileL} {
      padding-left: 1rem;
      margin-top: 1rem;
    }
  }

  .back {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    cursor: pointer;
  }
`;
const PrequalificationFormContainer = styled.div`
  background: #fff;
  margin-top: 48px;
  max-width: 50%;
  margin-inline: auto;
  padding: 24px 24px 56px 24px;
  @media ${device.mobileL} {
    max-width: 100%;
    padding: 1rem;
  }
  .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 1rem;
    border-radius: 0px 0px 16px 16px;

    @media ${device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

function PreQualificationForm({ submit, loading, initialData }: any) {
  const { t } = useTranslation("inventory");
  const navigate = useNavigate();
  const isAssumedUser = storage.getAssumedUser();

  const values = [
    { label: t("6-months"), value: "6" },
    { label: t("12-months"), value: "12" },
    { label: t("24-months"), value: "24" },
    { label: t("36-months"), value: "36" },
    { label: t("48-months"), value: "48" },
  ];

  const applicantTypes = [
    { label: t("salary-earner"), value: "Salary Earner" },
    { label: t("business-owner"), value: "Business Owner" },
  ];

  const [selectedValue, setSelectedValue] = useState(values[0]);
  const country = storage.getCountry();
  const [phoneNumber, setPhoneNumber] = useState(initialData?.telephone || "");
  const [monthlyIncome, setMonthlyIncome] = useState(
    initialData?.net_monthly_income || ""
  );
  const [applicantType, setApplicantType] = useState(
    initialData?.applicant_type || applicantTypes[0]
  );
  const [upfrontPayment, setUpfrontPayment] = useState("");

  const [inputValue, setInputValue] = useState({
    country: country,
    applicant_type: initialData?.applicant_type || "",
    first_name: initialData?.first_name || "",
    last_name: initialData?.last_name || "",
    email: initialData?.email || "",
    telephone: initialData?.telephone || "",
    net_monthly_income: initialData?.net_monthly_income || "",
    term: initialData?.term || "",
    pre_qual_residual_value_percentage: "0.1",
  });

  const handleTenure = (e: any, newValue: any) => {
    setSelectedValue(newValue);
  };

  const handleInputValue =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        [name]: e.target.value,
      });
    };

  const handlePhoneNumber = (value: any) => {
    setPhoneNumber(value);
  };
  const handleIncome = (value: any) => {
    setMonthlyIncome(value);
  };
  const handleUpfrontPayment = (value: any) => {
    setUpfrontPayment(value);
  };

  const onChangeApplicantType = (e: RadioChangeEvent) => {
    setApplicantType(e.target.value);
  };

  const handleSubmit = async () => {
    const data = { ...inputValue };
    data.telephone = phoneNumber
      ? `${getCountryTelCode(country)}${phoneNumber}`
      : "";
    data.net_monthly_income = monthlyIncome;
    data.term = selectedValue.value;
    data.applicant_type = applicantType;
    submit(data);
  };

  useEffect(() => {
    setPhoneNumber(
      initialData?.telephone
        ? initialData?.telephone?.substring(
            getCountryTelCode(country)?.length ?? 3
          )
        : ""
    );
    setApplicantType(initialData?.applicant_type);
    setMonthlyIncome(initialData?.net_monthly_income);
    setSelectedValue(initialData?.term);
  }, [country, initialData]);

  return (
    <>
      <PrequalificationHeaderContainer>
        <button onClick={() => navigate(-1)} className="back">
          <img
            src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
            alt="chevron-back"
            style={{ marginTop: "3px", marginRight: "10px" }}
          />
          {t("back")}
        </button>
        <div className="title">
          <div>{t("pre-qualification-title")}</div>
        </div>
        <div className="form">
          <div className="container"></div>
        </div>
        <div className="next"></div>
      </PrequalificationHeaderContainer>
      <PrequalificationFormContainer>
        <div className="form">
          <CustomFormField>
            <div className="label">{t("first-name")}</div>
            <TextField
              id="outlined-controlled"
              fullWidth
              placeholder={t("first-name")}
              onChange={handleInputValue("first_name")}
              value={inputValue?.first_name}
            />
          </CustomFormField>
          <CustomFormField>
            <div className="label">{t("last-name")} </div>
            <TextField
              id="outlined-controlled"
              fullWidth
              placeholder={t("last-name")}
              onChange={handleInputValue("last_name")}
              value={inputValue?.last_name}
            />
          </CustomFormField>
          <CustomFormField>
            <div className="label">{t("phone-number")} </div>

            <TelInputContainer>
              <InputNumber
                addonBefore={<img src={getCountryFlag(country)} alt="flag" />}
                prefix={getCountryTelCode(country)}
                style={{ width: "100%" }}
                onChange={handlePhoneNumber}
                keyboard={false}
                placeholder={t("enter-phone-number")}
                value={phoneNumber}
                min={0}
              />
            </TelInputContainer>
          </CustomFormField>
          <CustomFormField>
            <div className="label">{t("email-address")} </div>
            <TextField
              id="outlined-controlled"
              fullWidth
              placeholder={t("enter-email-address")}
              inputProps={{ pattern: "/^[0-9a-zA-Z]{15,17}$/" }}
              onChange={handleInputValue("email")}
              value={inputValue?.email}
            />
          </CustomFormField>
          <CustomFormField>
            <div className="label">{t("next-monthly-income")} </div>
            <TelInputContainer>
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => {
                  return formatCurrency(value);
                }}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={handleIncome}
                value={monthlyIncome}
                keyboard={false}
                placeholder={t("enter-next-monthly-income")}
              />
            </TelInputContainer>
          </CustomFormField>
          <CustomFormField>
            <div className="label">{t("upfront-payment")} </div>
            <TelInputContainer>
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value: any) => {
                  return formatCurrency(value);
                }}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={handleUpfrontPayment}
                value={upfrontPayment}
                keyboard={false}
                placeholder={t("enter-upfront-payment")}
              />
            </TelInputContainer>
          </CustomFormField>
          <CustomFormField>
            <div className="label">{t("loan-term")}</div>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                id="bodyType"
                disablePortal
                options={values}
                value={selectedValue}
                // loading={loadBodyType}
                onChange={handleTenure}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t("select-your-desired-loan-term")}
                  />
                )}
              />
            </FormControl>
          </CustomFormField>
          <CustomFormField>
            <div className="label">{t("applicant-type")}</div>
            <FormControl
              style={{ justifyContent: "center", marginTop: "1rem" }}
              fullWidth
            >
              <Radio.Group
                onChange={onChangeApplicantType}
                value={applicantType}
              >
                {applicantTypes.map((item: any) => (
                  <Radio key={item.label} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </FormControl>
          </CustomFormField>
        </div>
        <CustomFormField>
          <div className="loading-btn-container">
            <Button
              className="loading-btn"
              type="primary"
              onClick={handleSubmit}
              disabled={!!isAssumedUser}
            >
              {loading ? <Loader variant={"secondary"} /> : t("submit")}
            </Button>
          </div>
        </CustomFormField>
      </PrequalificationFormContainer>
    </>
  );
}

export default PreQualificationForm;
