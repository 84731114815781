import { useState, useEffect } from "react";
import {
  ChangeCarButton,
  CustomButton,
  TextContainer,
  ViewButton,
  TableContainer,
} from "@/layouts/application-layouts/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@l/storage";
import moment from "moment";
import { handleFormatDateToDays } from "@/utils/formatDateToDays";
import { Loader } from "../auth/styles/loader";

export default function RequestInspection() {
  const navigate = useNavigate();
  const location = useLocation();
  const country = storage.getCountry();
  const queryClient = useQueryClient();
  const role = storage.getRole();
  const car_id = (location.state as { carId: string })?.carId;
  const loan_id = (location.state as { loanId: string })?.loanId;
  const [inspectorList, setInspectorList] = useState<any>([]);
  const [workshopInspectorList, setWorkshopInspectorList] = useState<any>([]);
  const [inspectorId, setInspectorId] = useState<any>("");
  const [inspectionReport, setInspectionReport] = useState<any>({});
  const [loansById, setLoansById] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const isAssumedUser = storage.getAssumedUser();

  useEffect(() => {
    document.title = `Dealer Plus | Request Inspection [${role}]`;
  }, [role]);

  const { isLoading: loansByIdLoading } = useQuery({
    queryKey: ["fetch_loans_on_inspection", country, loan_id],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${loan_id}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      setLoansById(parsedLoanDetails);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: inspectorLoading } = useQuery({
    queryKey: ["fetch_inspectors", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        role_name: "SALES_OFFICER",
        page_size: "1000",
      });
      return client.get(`/v1/user`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedResponse: any = JSON.parse(res?.data);
      const filteredList = parsedResponse?.users?.map((item: any) => {
        return {
          label: `${item?.firstname} ${item?.lastname}`,
          value: item?.id,
        };
      });
      setInspectorList(filteredList);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["fetch_warranty_inspectors", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        inspection_type: "warranty",
        page_size: "1000",
      });
      return client.get(`/v1/franchise/inspection_workshop`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedResponse: any = JSON.parse(res?.data);
      const filteredWarrantyList = parsedResponse?.staff?.map((item: any) => {
        return {
          label: `${item?.firstname} ${item?.lastname}`,
          value: item?.id,
        };
      });
      setWorkshopInspectorList(filteredWarrantyList);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const { isLoading: inspectionReportLoading } = useQuery({
    queryKey: ["fetch_inspector_report", car_id],
    queryFn: () => {
      const params = new URLSearchParams({
        id: car_id,
        type: "warranty",
      });
      return client.get(`/v1/inventory/car/${car_id}/inspection/marketplace`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedResponse: any = JSON.parse(res?.data);
      setInspectionReport(parsedResponse);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const handleSelectInspector = (e: any, newValue: any) => {
    setInspectorId(newValue?.value);

    const currentValue = [...inspectorList, ...workshopInspectorList].find(
      (item: any) => item.value === newValue?.value
    );
    setSelectedOption(currentValue);
  };

  const handleRequestInspection = async () => {
    setLoading(true);

    const payload = {
      year: loansById?.car?.year,
      vin: loansById?.car?.vin,
      country: loansById?.car?.country,
      model_id: loansById?.car?.model.id,
      inspection_type: "warranty",
      car_id: car_id,
      state: loansById?.car?.state,
      city: loansById?.car?.city,
      franchise_id: loansById?.car?.carManagerId,
      assignee_id: inspectorId,
      note: "PPI",
      loan_id: loan_id,
    };

    try {
      const response = await client.post(
        `v1/inventory/inspection_request`,
        payload
      );
      if (response) {
        setLoading(false);
        setSelectedOption("");
        queryClient.invalidateQueries(["fetch_inspector_report"]);
        queryClient.invalidateQueries(["loans_by_id"]);
        message.success("PPI requested successfully");
        navigate(`/applications/loans/${loan_id}`);
      }
    } catch (error: any) {
      message.error(
        JSON.parse(error.response?.data)?.error || "Something went wrong"
      );
      setLoading(false);
      setSelectedOption("");
    }
  };

  const disabledButton = () => {
    return (
      !inspectorId ||
      (Object.keys(inspectionReport)?.length > 0 &&
        handleFormatDateToDays(inspectionReport?.createdAt) <= 21 &&
        inspectionReport?.warrantyResult !== "fail") ||
      !!isAssumedUser
    );
  };

  if (inspectionReportLoading || loansByIdLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <main className="flex flex-col gap-5">
      <div className="relative mb-5">
        <div className="text-2xl font-bold text-center text-[#30345e]">
          Book Inspection
        </div>
        <button
          onClick={() => navigate(-1)}
          className="absolute left-0 top-[4px] cursor-pointer"
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
        </button>
      </div>

      <div className="w-full md:w-[75%] xl:w-[45%] h-auto py-6 bg-white my-0 mx-auto rounded-lg">
        <div className="px-4 lg:px-6 py-1">
          <div className="mb-10">
            <div className="mb-2 text-[#30345E] text-base font-semibold">
              Inspector
            </div>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                id="inspector_select"
                disablePortal
                loading={inspectorLoading}
                options={[...inspectorList, ...workshopInspectorList]}
                onChange={handleSelectInspector}
                value={selectedOption}
                renderInput={(params) => (
                  <TextField {...params} placeholder={"Select inspector"} />
                )}
              />
            </FormControl>
          </div>

          <CustomButton className="w-[50%] my-0 mx-auto">
            <Button
              onClick={handleRequestInspection}
              disabled={disabledButton()}
            >
              {loading ? <Loader variant="secondary" /> : "Request PPI"}
            </Button>
          </CustomButton>
        </div>
      </div>

      <div className="w-full md:w-[75%] xl:w-[45%] min-h-[300px] bg-white my-0 mx-auto ounded-lg">
        <TextContainer className="px-4 lg:px-6 pt-6 pb-4 border-b border-solid border-[#E5E7EB]">
          <div className="top-text">Available PPI report</div>
        </TextContainer>

        {Object.keys(inspectionReport)?.length > 0 ? (
          <div className="flex flex-col gap-4 px-4 lg:px-6 pt-0 pb-6">
            <div className="flex flex-col divide-y border rounded-lg">
              <div className="flex justify-between items-center p-3">
                <div className="flex gap-x-2 items-center">
                  <img
                    src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Vector.svg"
                    alt="document"
                  />
                  <div>
                    <p className="text-base font-bold text-[#30345E] mb-1">
                      PPI report
                    </p>
                    <p className="-mt-1 text-xs text-[#30345E]">
                      {inspectionReport?.createdAt
                        ? moment(inspectionReport?.createdAt).format("LLLL")
                        : "---"}
                    </p>
                  </div>
                </div>
                <a
                  href={inspectionReport?.pdfReport}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ViewButton className="px-5">View</ViewButton>
                </a>
              </div>
              <div className="flex justify-between items-center p-3">
                <p className="text-base font-bold text-[#30345E]">PPI status</p>
                <div>
                  {inspectionReport?.warrantyResult === "fail" ? (
                    <div className="flex items-center gap-x-4">
                      <div className="flex gap-x-2">
                        <img
                          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Icon.svg"
                          alt="check"
                        />
                        <span className="text-[#F87171] font-bold capitalize">
                          fail
                        </span>
                      </div>
                      <ChangeCarButton
                        onClick={() =>
                          navigate(`/applications/car-list?loan_id=${loan_id}`)
                        }
                        disabled={!!isAssumedUser}
                      >
                        Change car
                      </ChangeCarButton>
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-1">
                      {inspectionReport?.warrantyResult ? (
                        <>
                          <img
                            src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/check-circle.svg"
                            alt="check"
                          />
                          <span className="text-[#22C55E] font-bold capitalize">
                            {inspectionReport?.warrantyResult}
                          </span>
                        </>
                      ) : (
                        <span className="text-[orange] font-bold capitalize">
                          pending
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center p-3">
                <p className="text-base font-bold text-[#30345E]">
                  Validity{" "}
                  <span className="text-[13px] font-medium">
                    {handleFormatDateToDays(inspectionReport?.createdAt) >= 21
                      ? `(the report has expired, kindly make a new ppi request)`
                      : `(the report will expires 21 days after inspection)`}
                  </span>
                </p>
                {handleFormatDateToDays(inspectionReport?.createdAt) >= 21 ? (
                  <div className="text-[#F87171] font-bold capitalize">
                    Expired
                  </div>
                ) : (
                  <div className="text-[#22C55E] font-bold capitalize">
                    Valid
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full my-0 mx-auto py-3">
            <div className="flex flex-col items-center w-full">
              <div>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                  alt="caution"
                />
              </div>
              <div className="text-sm lg:text-lg text-[#6b7280]">
                No PPI report available.
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
