import React from "react";
import { GoBackIcon, DealerFinancingStepper } from "@/assets/svgs";
import styled from "styled-components";
import Button from "@/components/button";
import { useNavigate } from "react-router-dom";

const why_loan_limit = [
  {
    id: 1,
    heading: "Fill out the loan application ",
    text: "Your application will be reviewed and and a conditional offer will be provided. ",
  },
  {
    id: 2,
    heading: "Upload required documents",
    text: "Once you have received your conditional offer, kindly upload the required documents then a final offer will be provided.",
  },
  {
    id: 3,
    heading: "Sign loan contract ",
    text: "After your final offer, you can proceed to sign the loan contract and take your car.",
  },
];

const ListWrap = styled.div`
  ul li:nth-child(2) {
    border-bottom: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;
  }
`;

const PreRepoFinancingForm = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const carId = queryParameters.get("car_id");

  const toRepoFinancing = () => {
    navigate(`/dealer/financing/repo-financing?car_id=${carId}&form_type=new`);
  };

  return (
    <div className="py-2 md:py-10">
      <div className="bg-white max-w-xl mx-auto">
        {/* 1 */}
        <div onClick={() => navigate(-1)} className="px-4 py-4">
          <p className="flex items-center font-bold capitalize text-lg text-[#30345E] space-x-2 cursor-pointer">
            <GoBackIcon />
            <span>back</span>
          </p>
        </div>
        {/* 2 */}
        <div className="border-t border-[#E5E7EB]">
          <div className="p-4">
            <div className="flex justify-center items-center space-x-2">
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png"
                alt="medal"
                className="w-10 h-10"
              />
              <p className="text-xl font-bold text-[#30345E]">Repo Financing</p>
            </div>
            <div className="pt-3">
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/autochek_repofi_what_to_expect.png?updatedAt=1702297016770"
                alt="what-to-expect"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
        {/* 3 */}
        <div className="px-4 pb-8 ">
          <p className="text-xl font-bold text-[#30345E]">
            Here is what to expect
          </p>
          <div className="pt-3">
            <ListWrap>
              <ul className="flex flex-col justify-between w-full">
                {why_loan_limit.map((item) => {
                  return (
                    <li key={item.id} className="py-3">
                      <div className="flex flex-row space-x-2">
                        <div>
                          <DealerFinancingStepper />
                        </div>
                        <div className="max-w-md pt-1">
                          <p className="leading-5 font-bold text-[#30345E] tracking-[0.01rem]">
                            {item.heading}
                          </p>
                          <span className="leading-5 text-sm text-[#30345E]">
                            {item.text}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </ListWrap>
          </div>
          <div className="mt-4 mx-auto flex justify-center w-full md:w-3/5 ">
            <Button
              onClick={() => toRepoFinancing()}
              isLoading={false}
              width=""
              variant="secondary"
              style={{ borderRadius: "3.75rem", width: "100%" }}
            >
              <span className="text-sm font-bold text-[#30345E]">Proceed</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRepoFinancingForm;
