const getInitial = (fullname: string): string => {
  const names = fullname.trim().split(" ");
  if (names.length === 1) return `${names[0].charAt(0).toUpperCase()}`;
  else
    return `${names[0].charAt(0).toUpperCase()}${names[names.length - 1]
      .charAt(0)
      .toUpperCase()}`;
};

export default getInitial;
