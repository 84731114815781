import { useState, useEffect } from "react";
import { List, Card, Table, Button } from "antd";
import styled from "styled-components";
import type { PaginationProps } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import tw from "tailwind-styled-components";
import type { ColumnsType } from "antd/es/table";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import OutlineButton from "./OutlineButton";
import formatInt from "@/utils/format-int";
import formatLoanInt from "@/utils/format-loan-currency";
import TableFieldFilters from "./TableFieldFilters";
import InventoryFilterModal from "@/layouts/application-layouts/InventoryFilterModal";

const CustomList = styled.div`
  .status-button {
    background: #ffed8e;
    border-radius: 4px;
    color: #30345e !important;
    letter-spacing: -0.25px;
    border: 0;
  }
  .ant-list-split .ant-list-item {
    border-block-end: none;
  }
  .ant-list-split.ant-list-something-after-last-item
    .ant-spin-container
    > .ant-list-items
    > .ant-list-item:last-child {
    border-block-end: none;
  }
  .ant-list .ant-list-item {
    padding: 0px 0;
    margin-bottom: 16px;
  }
  .ant-card-small > .ant-card-body {
    padding: 12px 14px;
  }
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    background: #ffb619;
    border-radius: 44px;
    border: 0;
    padding: 0px 16px;
    font-weight: 600;
  }
  .ant-progress.ant-progress-circle .ant-progress-text {
    color: #30345e;
    font-size: 16px;
    font-weight: 600;
  }
`;

const HeaderText = tw.div`text-[#6B7280] text-sm uppercase leading-tight`;
const Text = tw.div`text-[#6B7280] text-sm`;
const BlueText = tw.div`text-[##30345E] text-xs font-bold uppercase`;
const StatusText = tw.div`text-[##30345E] bg-[#FFED8E] text-xs font-bold uppercase p-0.5 rounded-sm`;
const AmountText = tw.div`text-[##30345E] text-[24px] font-bold`;

export default function DealerFinancingList({
  query,
  fetchDealerLoans,
  dealerFinancingLoading,
  activePage,
  setActivePage,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation("dashboard");
  const { t: t2 } = useTranslation("application");
  const { t: t3 } = useTranslation("loan-status");
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [openInventoryFilter, setOpenInventoryFilter] = useState(false);

  useEffect(() => {
    if (query.current_page && typeof query.current_page == "string") {
      setActivePage({
        ...activePage,
        currentPage: parseInt(query.current_page),
      });
    }
  }, [activePage, query.current_page, setActivePage]);

  const checkMobileView = () => {
    const breakpoint: number = 1024;
    setIsMobileView(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const handlePageChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page.current,
    });
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        current_page: page.current,
        page_size: page.pageSize,
      })}`
    );
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <a href="#link">{t("previous")}</a>;
    }
    if (type === "next") {
      return <a href="#link">{t("next")}</a>;
    }
    return originalElement;
  };

  const itemTableRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
          }}
        >
          {i18n.language === "ar" ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
          }}
        >
          {i18n.language === "ar" ? <LeftOutlined /> : <RightOutlined />}
        </Button>
      );
    }
    return originalElement;
  };

  const columns: ColumnsType<DealerDataType> = [
    {
      title: t2("date"),
      dataIndex: "date",
      key: "date",
      render: (_, el) => (
        <span>
          {el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "---"}
        </span>
      ),
      width: 120,
    },
    {
      title: t2("type"),
      dataIndex: "type",
      key: "type",
      render: (_, el) => (
        <span className="text-sm text-[#000] font-normal capitalize">
          {el?.product?.name}
        </span>
      ),
    },
    {
      title: t2("ID"),
      dataIndex: "id",
      key: "id",
      render: (_, el) => (
        <div className="text-sm text-[#000] font-normal">{el?.id}</div>
      ),
    },
    {
      title: t2("loan-amount"),
      dataIndex: "loanValue",
      key: "loanValue",
      render: (_, el) => (
        <span className="text-sm text-[#000] font-normal">
          {el?.loanValue
            ? formatLoanInt(Number(el?.loanValue), el?.loanCurrency)
            : "---"}
        </span>
      ),
    },
    {
      title: t2("disbursed-amount"),
      dataIndex: "disbursedAmount",
      key: "loanValue",
      render: (_, el) => (
        <span className="text-sm text-[#000] font-normal">
          {formatLoanInt(el?.disbursedAmount, el?.loanCurrency)}
        </span>
      ),
    },
    {
      title: t2("disbursed-date"),
      dataIndex: "disbursedDate",
      key: "loanValue",
      render: (_, el) => (
        <span className="text-sm text-[#000] font-normal">
          {el?.disbursedDate
            ? moment(el?.disbursedDate).format("DD-MM-YYYY")
            : "---"}
        </span>
      ),
    },
    {
      title: t2("loan-status"),
      dataIndex: "loanStatus",
      key: "loanStatus",
      render: (_, el) => (
        <Button className={`status-button`}>
          {el?.productStatus?.name ? t3(el?.productStatus?.name) : "---"}
        </Button>
      ),
    },
    {
      title: t2("action"),
      dataIndex: "action",
      key: "action",
      render: (_, el) => (
        <button
          onClick={() => navigate(`/dealer/applications/loans/${el?.id}`)}
          className="text-[#30345E] text-sm border border-solid border-[#30345E] font-semibold rounded-full h-[32px] flex items-center px-3"
        >
          {t2("view-details")}
        </button>
      ),
    },
  ];

  return (
    <main className="pt-0 md:pt-3">
      <TableFieldFilters
        variant="dealer-financing"
        inventoryLoading={dealerFinancingLoading}
        query={query}
        setOpenInventoryFilter={setOpenInventoryFilter}
      />

      <CustomList>
        {isMobileView ? (
          <List
            bordered={false}
            loading={dealerFinancingLoading}
            dataSource={fetchDealerLoans?.data}
            pagination={{
              current: activePage?.currentPage,
              pageSize: activePage?.pageSize,
              total: fetchDealerLoans?.pagination?.total,
              simple: true,
              onChange(page, pageSize) {
                setActivePage({
                  ...activePage,
                  currentPage: page,
                });
                navigate(
                  `${location.pathname}${paramsObjectToQueryString({
                    ...query,
                    current_page: page,
                    page_size: pageSize,
                  })}`
                );
              },
              itemRender: itemRender,
            }}
            renderItem={(item: Record<string, any>) => (
              <List.Item className="">
                <Card bordered={true} size="small" className="w-full">
                  <div className="grid grid-cols-1 divide-y">
                    <div className="flex justify-between pb-2">
                      <div>
                        <HeaderText>{item?.product?.name}</HeaderText>
                        <AmountText>
                          {formatInt(Number(item?.loanValue), true)}
                        </AmountText>
                      </div>
                      <HeaderText>ID: {item?.id}</HeaderText>
                    </div>
                    <div className="flex justify-between items-center md:justify-start md:gap-8 pt-3">
                      <div className="flex flex-col gap-1.5">
                        <div>
                          <Text>Disbursed Amount:</Text>
                          <BlueText>
                            {formatInt(item?.disbursedAmount, true)}
                          </BlueText>
                        </div>
                        <div>
                          <Text>Disbursed Date:</Text>
                          <BlueText>
                            {item?.disbursedDate
                              ? moment(item?.disbursedDate).format("DD-MM-YYYY")
                              : "---"}
                          </BlueText>
                        </div>
                        <div>
                          <Text>Loan status</Text>
                          <StatusText>
                            {item?.productStatus?.name
                              ? t(item?.productStatus?.name, {
                                  ns: "loan-status",
                                })
                              : "---"}
                          </StatusText>
                        </div>
                      </div>

                      <OutlineButton
                        path={`/dealer/applications/loans/${item?.id}`}
                      >
                        View Details
                      </OutlineButton>
                    </div>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={fetchDealerLoans?.data}
            onChange={handlePageChange}
            loading={dealerFinancingLoading}
            pagination={{
              current: activePage?.currentPage,
              pageSize: activePage?.pageSize,
              total: fetchDealerLoans?.pagination?.total,
              showSizeChanger: false,
              itemRender: itemTableRender,
            }}
            rowKey="id"
          />
        )}
      </CustomList>

      <InventoryFilterModal
        isModalOpen={openInventoryFilter}
        handleOk={() => setOpenInventoryFilter(false)}
        handleCancel={() => setOpenInventoryFilter(false)}
        query={query}
        loading={dealerFinancingLoading}
        setOpenFilter={setOpenInventoryFilter}
        variant="dealer-financing"
      />
    </main>
  );
}
