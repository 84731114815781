import { Component } from "react";
import ErrorOccurred from "@p/errors/error-occurred";

class ErrorBoundary extends Component<IErrorBoundary, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): IErrorBoundaryState {
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorOccurred />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
