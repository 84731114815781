import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Segmented } from "antd";
import type { TabsProps } from "antd";
import storage from "@l/storage";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import {
  AppTabContainer,
  CustomDealerLayout,
  Header,
  ParentTabHeader,
} from "@/layouts/application-layouts/styles";
import CustomerFinancingTable from "@/components/CustomerFinancingTable";
import DealerFinancingList from "@/components/DealerFinancingList";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import {
  fetchDealerOriginatedLoans,
  fetchOriginationLoans,
} from "@/hooks/use-api";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

export default function DealerApplication() {
  const { t } = useTranslation("dashboard");
  const role = storage.getRole();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const dealerQuery: any = queryString.parse(search);
  const country = storage.getCountry();
  const user = storage.getUser();

  const [value, setValue] = useState<string | number>("CUSTOMERS");
  const [dealerTabValue, setDealerTabValue] = useState("1");
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const [fetchFilteredLoans, setFetchFilteredLoans]: any = useState([]);
  const [fetchCarForCashLoans, setFetchCarForCashLoans]: any = useState([]);
  const [fetchLoanTransferLoans, setFetchLoanTransferLoans]: any = useState([]);
  const [fetchDealerLoans, setFetchDealerLoans]: any = useState([]);
  const [parentTabValue, setParentTabValue] = useState("1");
  const [childTabValue, setChildTabValue] = useState("1");

  useEffect(() => {
    document.title = `Dealer Plus | Dealer Applications [${role}]`;
  }, [role]);

  const handleDealerTabChange = (key: string) => {
    setDealerTabValue(key);
  };

  const handleParentTabChange = (key: string) => {
    setParentTabValue(key);
  };

  const handleChildTabChange = (key: string) => {
    setChildTabValue(key);
  };

  let childTabStatus = "";
  switch (childTabValue) {
    case "1":
      childTabStatus = "";
      break;
    case "2":
      childTabStatus = "PENDING_DOCS";
      break;
    case "3":
      childTabStatus = "IN_PROGRESS";
      break;
    case "4":
      childTabStatus = "DISBURSED";
      break;
    default:
      childTabStatus =
        "REJECTED_FINAL_OFFER,REJECTED_CONDITIONAL_OFFER,DECLINED_FINAL_OFFER,DECLINED_CONDITIONAL_OFFER,FINAL_OFFER_REJECTED,CONDITIONAL_OFFER_REJECTED";
      break;
  }

  let dealerTabStatus = "";
  switch (dealerTabValue) {
    case "1":
      dealerTabStatus = "";
      break;
    case "2":
      dealerTabStatus = "DOCUMENT_PENDING,DOCUMENTS_REJECTED";
      break;
    case "3":
      dealerTabStatus =
        "APPLICATION_COMPLETED,APPLICATION_NOT_COMPLETED,CONDITIONAL_OFFER_RECEIVED,FINAL_OFFER_RECEIVED,REFERRED_CONDITIONAL_OFFER,REFERRED_FINAL_OFFER,DOCUMENT_SUBMITTED,INSPECTION_PENDING,INSPECTION_COMPLETED,INSPECTION_IN_PROGRESS,SIGNED_LOAN_DOCUMENTS_SUBMITTED";
      break;
    case "4":
      dealerTabStatus = "DISBURSED";
      break;
    default:
      dealerTabStatus =
        "REJECTED_FINAL_OFFER,REJECTED_CONDITIONAL_OFFER,DECLINED_FINAL_OFFER,DECLINED_CONDITIONAL_OFFER,FINAL_OFFER_REJECTED,CONDITIONAL_OFFER_REJECTED";
      break;
  }

  const productNameByLoanType = () => {
    if (parentTabValue === String(1)) {
      return "Decision Engine Loan";
    } else if (parentTabValue === String(2)) {
      return "Logbook Finance";
    } else {
      return "Dealer WhiteLabel";
    }
  };

  const productResultByLoanType = (data: any) => {
    if (parentTabValue === String(1)) {
      setFetchFilteredLoans(data);
    } else if (parentTabValue === String(2)) {
      setFetchCarForCashLoans(data);
    } else {
      setFetchLoanTransferLoans(data);
    }
  };

  const { isLoading: filteredLoanLoading } = useQuery({
    queryKey: [
      "application_financing_loans",
      country,
      query,
      activePage,
      user,
      parentTabValue,
      childTabValue,
      childTabStatus,
      productNameByLoanType(),
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        user_id: user?.id,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        hasCommission: "all",
        order: "DESC",
        sort_by: "createdAt",
        productName: productNameByLoanType(),
        ...(parentTabValue === String(1)
          ? {
              status: childTabStatus,
            }
          : ""),
        ...query,
      });
      return fetchOriginationLoans(params);
    },
    staleTime: 1000,
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      productResultByLoanType(parsedLoans);
    },
    enabled: value === "CUSTOMERS",
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: dealerFinancingLoading } = useQuery({
    queryKey: [
      "fetch_dealer_financing_table_1",
      dealerQuery,
      user?.company?.id,
      dealerTabValue,
      dealerTabStatus,
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        current_page: activePage.currentPage.toString(),
        page_size: activePage.pageSize.toString(),
        status: dealerTabStatus,
        ...dealerQuery,
      });
      return fetchDealerOriginatedLoans(user?.company?.id, params);
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setFetchDealerLoans(parsedLoans);
    },
    enabled: value === "FOR ME",
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const childTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: t(`all`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "2",
      label: t(`pending-docs`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "3",
      label: t(`in-progress`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "4",
      label: t(`disbursed`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "5",
      label: t(`rejected`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
  ];

  const parentTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: <ParentTabHeader>{t("car-loan")}</ParentTabHeader>,
      children: (
        <Tabs
          defaultActiveKey={childTabValue}
          items={childTabItems}
          onChange={handleChildTabChange}
        />
      ),
    },
    {
      key: "2",
      label: <ParentTabHeader>{t("cash-loan")}</ParentTabHeader>,
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchCarForCashLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    ...(["NG", "KE"].includes(country)
      ? [
          {
            key: "3",
            label: <ParentTabHeader>{t("Dealer Whitelabel")}</ParentTabHeader>,
            children: (
              <CustomerFinancingTable
                activePage={activePage}
                setActivePage={setActivePage}
                query={query}
                fetchFilteredLoans={fetchLoanTransferLoans}
                filteredLoanLoading={filteredLoanLoading}
                parentTabValue={parentTabValue}
              />
            ),
          },
        ]
      : []),
  ];

  const dealerItems: TabsProps["items"] = [
    {
      key: "1",
      label: t(`all`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "2",
      label: t(`pending-docs`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "3",
      label: t(`in-progress`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "4",
      label: t(`disbursed`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "5",
      label: "Rejected",
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
  ];

  return (
    <main>
      <CustomDealerLayout>
        <Header>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center gap-3 cursor-pointer"
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
              alt="Back Arrow"
              className="mt-[3px]"
            />{" "}
            <span className="text-base text-[#30345E] font-semibold">
              {t("applications")}
            </span>
          </button>
          <AppTabContainer style={{ padding: "0px" }}>
            <Segmented
              options={[
                { label: t("customers"), value: "CUSTOMERS" },
                { label: t("for-me"), value: "FOR ME" },
              ]}
              value={value}
              onChange={setValue}
            />
          </AppTabContainer>
        </Header>

        {value === "CUSTOMERS" ? (
          <AppTabContainer>
            <Tabs
              defaultActiveKey={parentTabValue}
              items={parentTabItems}
              onChange={handleParentTabChange}
            />
          </AppTabContainer>
        ) : (
          <AppTabContainer>
            <Tabs
              defaultActiveKey={dealerTabValue}
              items={dealerItems}
              onChange={handleDealerTabChange}
            />
          </AppTabContainer>
        )}
      </CustomDealerLayout>

      <NewLoansBtn />
    </main>
  );
}
