import { ImageLoader } from "./ImageLoader";

export const generateCompressedUrl = async (url: string) => {
  try {
    const img = new Image();
    img.src = url;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    const compressedUrl = ImageLoader({
      src: url,
      width: img.naturalWidth > 1200 ? 1200 : img.naturalWidth,
      quality: 100,
    });

    return compressedUrl;
  } catch (error) {
    return "";
  }
};
