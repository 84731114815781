import { createSvgIcon } from '@mui/material';

const CircularCheckMarkIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12' r='10' stroke='green' strokeWidth='2' fill='none' />
    <path
      d='M16 10L10.5 15.5L8 13'
      stroke='green'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'CircularCheckMark',
);

export default CircularCheckMarkIcon;
