import styled from "styled-components";
import { Button } from "antd";
import { device } from "@/utils/device";

const ButtonContainer: any = styled.div`
  width: 48%;
  margin: 1rem 0;
  .ant-btn {
    width: 100%;
    color: ${(props: any) => props.color};
    border: ${(props: any) => props.border};
    background: ${(props: any) => props.background};
    font-weight: bolder;
    font-size: 14px;
    @media ${device.mobileL} {
      font-size: 10px;
    }
    &:hover {
      background: ${(props: any) => props.hoverBackground};
      color: ${(props: any) => props.hoverColor};
    }
    &:disabled {
      background: #d1d5db;
      color: #9ca3af;
    }
  }
`;

const FormButton = ({
  text,
  color,
  disabled,
  type,
  shape,
  border,
  background,
  hoverBackground,
  hoverColor,
  onClick,
}: any) => {
  return (
    <ButtonContainer
      color={color}
      border={border}
      background={background}
      hoverBackground={hoverBackground}
      hoverColor={hoverColor}
    >
      <Button
        className="button"
        disabled={disabled}
        type={type}
        shape={shape}
        onClick={onClick}
        size="large"
      >
        {text}
      </Button>
    </ButtonContainer>
  );
};

export default FormButton;
