import { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    document.title = "Dealer Plus | Page Not found";
  }, []);

  return <div style={{ marginTop: "36px" }}>NotFound</div>;
};

export default NotFound;
