import { Modal } from "antd";
import LoanCalculator from "./LoanCalculator";

interface IListModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  car: any;
  loading: boolean;
  carId: string;
}
export const LoanCalculatorModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  loading,
  carId,
  car,
}: IListModal) => {
  return (
    <div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        footer={null}
        rootClassName="loan-calculator-modal"
        closeIcon={
          <img
            src="https://media.autochek.africa/file/publicAssets/x-close-u.svg"
            alt="close-icon"
            style={{ marginTop: 8 }}
          />
        }
      >
        <LoanCalculator car={car} loading={loading} carId={carId} />
      </Modal>
    </div>
  );
};
