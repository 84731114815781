import UploadCustomsFile from "./UploadCustomsFile";
import React, { useState } from "react";
import { Form, message } from "antd";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { editCarDocument } from "@/hooks/use-api";
import { useQueryClient } from "@tanstack/react-query";
import { Loader } from "@/pages/auth/styles/loader";
import { ICarDocumentList } from "@/types";
import { convertCamelCaseString } from "@/utils/convertCamelCase";

interface IDocumentForm {
  documentData?: ICarDocumentList;
  setOpenDocumentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditWhitelabelForm({
  documentData,
  setOpenDocumentModal,
}: Readonly<IDocumentForm>) {
  const [form] = Form.useForm();
  const { t } = useTranslation("loan-form");
  const queryParam = new URLSearchParams(window.location.search);
  const carId = queryParam.get("carId") as string;
  const [loading, setLoading] = useState(false);
  const isAssumedUser = storage.getAssumedUser();
  const queryClient = useQueryClient();

  const handleEditDocuments = async (values: any) => {
    setLoading(true);

    const payload = {
      car_id: carId,
      payload: [
        {
          id: documentData?.id,
          name: documentData?.name,
          url: values.editDocumentFile[0].response.file.url,
          car_id: carId,
          status: documentData?.status,
        },
      ],
    };

    try {
      const response = await editCarDocument(payload, carId);
      if (response) {
        message.success("Documents submitted successfully");
        form.resetFields();
        queryClient.invalidateQueries(["fetch_car_documents"]);
        setOpenDocumentModal(false);
        setLoading(false);
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  return (
    <main className="px-4">
      <Form
        name="edit_whitelabel_car_docs"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleEditDocuments}
        autoComplete="off"
      >
        <div className="pb-2 pt-3">
          <UploadCustomsFile
            name="editDocumentFile"
            label={t(
              `Upload ${convertCamelCaseString(
                documentData?.name as string
              )} document`
            )}
            messageText={t(`Please upload ${documentData?.name} document`)}
            rule={true}
          />

          <Form.Item>
            <div className="button-container w-[40%] my-0 mx-auto">
              <button
                className="text-xs lg:text-base mt-4 w-full h-[45px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                disabled={!!isAssumedUser}
              >
                {loading ? <Loader variant={"secondary"} /> : t("Submit")}
              </button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </main>
  );
}
