export const determineRole = (roles: string[]): string | undefined => {
  if (roles.includes("ADMIN")) return "ADMIN";
  if (roles.includes("ACCOUNT_MANAGER")) return "ACCOUNT_MANAGER";
  if (roles.includes("FRANCHISE ADMIN") && !roles.includes("ACCOUNT_MANAGER"))
    return "FRANCHISE ADMIN";
  if (
    roles.includes("DSA_AGENT") &&
    !roles.includes("ACCOUNT_MANAGER") &&
    !roles.includes("FRANCHISE ADMIN")
  )
    return "DSA_AGENT";
  if (
    roles.includes("DEALER_DSA") &&
    !roles.includes("ACCOUNT_MANAGER") &&
    !roles.includes("FRANCHISE ADMIN") &&
    !roles.includes("DSA_AGENT")
  )
    return "DEALER_DSA";
  if (
    roles.includes("DEALER_FINANCE_MANAGER") &&
    !roles.includes("ACCOUNT_MANAGER") &&
    !roles.includes("FRANCHISE ADMIN") &&
    !roles.includes("DSA_AGENT")
  )
    return "DEALER_FINANCE_MANAGER";
  return undefined;
};
