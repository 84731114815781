export const getCountryTelCodeLength = (code: string) => {
  let telNumberLength;
  switch (code) {
    case "NG":
      telNumberLength = 10;
      break;
    case "GH":
      telNumberLength = 9;
      break;
    case "KE":
      telNumberLength = 9;
      break;
    case "CI":
      telNumberLength = 9;
      break;
    case "UG":
      telNumberLength = 9;
      break;
    case "SN":
      telNumberLength = 9;
      break;
    default:
      break;
  }

  return telNumberLength;
};

export const getCountryCustomerLine = (code: string) => {
  let telNumberLength;
  switch (code) {
    case "NG":
      telNumberLength = "7002886248";
      break;
    case "GH":
      telNumberLength = "242436898";
      break;
    case "KE":
      telNumberLength = "111034300";
      break;
    case "CI":
      telNumberLength = "701777283";
      break;
    case "UG":
      telNumberLength = "761401171";
      break;
    case "SN":
      telNumberLength = "";
      break;
    default:
      break;
  }

  return telNumberLength;
};
