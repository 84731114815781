import { TextAreaFieldStyle } from "@/layouts/application-layouts/styles";
import { TextField } from "@mui/material";
import { FormInputProps } from "./types";
import FormInputLabel from "./FormInputLabel";
import { useTranslation } from "react-i18next";

export const FormInputTextArea: React.FC<FormInputProps> = ({
  item,
  field,
}: FormInputProps) => {
  const { t } = useTranslation("car-form");

  return (
    <div className="space-y-2 col-span-2">
      <FormInputLabel item={item} />
      <TextField
        {...field}
        fullWidth
        error={!!field.error}
        type={item.type}
        multiline
        rows={7}
        disabled={item.disabled}
        hidden={item.hidden}
        placeholder={t(item.placeholder as string)}
        onChange={field.onChange}
        sx={TextAreaFieldStyle}
      />
    </div>
  );
};
