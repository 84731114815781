import { useState } from "react";
import { Modal, Form, Input, Button, Upload, message } from "antd";
import storage from "@/lib/storage";
import { UploadDocumentModal } from "./styles";
import { createDocuments } from "@/hooks/use-loan";
import { useQueryClient } from "@tanstack/react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { convertCamelCaseString } from "@/utils/convertCamelCase";
import { useTranslation } from "react-i18next";
import { Loader } from "@/pages/auth/styles/loader";

interface IUploadDocs {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  id: string;
  customerDocs: any;
}

export default function AdditionalDocModal({
  isModalOpen,
  handleOk,
  handleCancel,
  id,
  customerDocs,
}: Readonly<IUploadDocs>) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const queryClient = useQueryClient();
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const [loading, setLoading] = useState(false);
  const [docName, setDocName] = useState("");
  const { t } = useTranslation("loan-form");

  const seenDocs: any = {};
  const listedDocs: any = [];

  customerDocs.forEach((option: any) => {
    if (!seenDocs[option.group]) {
      if (option.type === "file") {
        listedDocs.push({ label: option.name, value: option.name });
        seenDocs[option.group] = true;
      } else if (option.type === "fileSelection") {
        option.options.forEach((label: any, i: number) => {
          listedDocs.push({ label, value: `${option.name}` });
          seenDocs[option.group] = true;
        });
      }
    }
  });

  const handleListedDoc = (e: any, newValue: any) => {
    setDocName(newValue?.value);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .pdf",
    action: `${API_URL}document/upload`,
    headers: {
      authorization: `bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {},
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    const documents = [
      {
        name: docName,
        file_url: values.document_upload[0].response.file.url,
        description: values.document_description,
        password: values.password ? values.password : "",
      },
    ];

    try {
      const response = await createDocuments({
        loan_id: id,
        documents,
        data: [],
      });
      if (response) {
        message.success("Document uploaded successfully");
        queryClient.invalidateQueries(["fetch_uploaded_documents"]);
        setLoading(false);
        form.resetFields();
        handleCancel();
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  const handleResetForm = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <div>
      <Modal
        title={t("Upload Additional Document")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        width={400}
        wrapClassName="upload-document-modal-container"
        zIndex={1007}
      >
        <UploadDocumentModal>
          <FormControl fullWidth style={{ marginBottom: 24 }}>
            <label className="label">{t("document-name")}</label>
            <Autocomplete
              fullWidth
              id="document_name"
              disablePortal
              options={listedDocs?.map((list: any) => {
                return {
                  label: t(convertCamelCaseString(list?.label)),
                  value: list?.value,
                };
              })}
              onChange={handleListedDoc}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>

          <Form
            name="document-upload-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmitForm}
            autoComplete="off"
          >
            <Form.Item
              label={
                <div>
                  {t("document-description")}{" "}
                  <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                    ({t("optional")})
                  </span>
                </div>
              }
              name="document_description"
            >
              <TextArea rows={3} />
            </Form.Item>

            <Form.Item label={t("document-upload")}>
              <Form.Item
                name="document_upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
                rules={[
                  {
                    required: true,
                    message: t("please-upload-a-file"),
                  },
                ]}
              >
                <Upload.Dragger {...props} name="file" maxCount={1}>
                  <div className="ant-upload-text">{t("click-or-drag")}</div>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item
              label={
                <div>
                  {t("Password")}{" "}
                  <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                    ({t("optional")})
                  </span>
                </div>
              }
              name="password"
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <div className="button-container">
                <Button
                  className="cancel-button"
                  type="primary"
                  onClick={handleResetForm}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  className="submit-button"
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? <Loader variant={"secondary"} /> : t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </UploadDocumentModal>
      </Modal>
    </div>
  );
}
