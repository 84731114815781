import tw from "tailwind-styled-components";

const getBackgroundColor = (props: Partial<IButton>) => {
  if (props.variant === "primary") {
    return "bg-button-variants-primary-scene";
  } else if (props.variant === "secondary") {
    return "bg-button-variants-secondary-scene";
  } else if (props.variant === "tertiary") {
    return "bg-white";
  } else {
    return undefined;
  }
};

const getHoverBackgroundColor = (props: Partial<IButton>) => {
  if (props.variant === "primary") {
    return "hover:bg-button-variants-primary-scene/90";
  } else if (props.variant === "secondary") {
    return "hover:bg-button-variants-secondary-scene/80";
  } else if (props.variant === "tertiary") {
    return "hover:bg-white/60";
  } else {
    return undefined;
  }
};

const getTextColor = (props: Partial<IButton>) => {
  if (props.variant === "primary") {
    return "text-white";
  } else if (props.variant === "secondary") {
    return "text-button-variants-secondary-text";
  } else if (props.variant === "tertiary") {
    return "text-button-variants-tertiary-text";
  } else {
    return undefined;
  }
};

const getDisabledBackgroundColor = (props: Partial<IButton>) => {
  if (props.variant === "primary") {
    return "disabled:bg-button-variants-primary-scene/40";
  } else if (props.variant === "secondary") {
    return "disabled:bg-button-variants-secondary-scene/50";
  } else if (props.variant === "tertiary") {
    return "disabled:bg-white/10";
  } else {
    return undefined;
  }
};

const Container = tw.button`
  ${(props: Partial<IButton>) => getBackgroundColor(props)}
  ${(props: Partial<IButton>) => getHoverBackgroundColor(props)}
  ${(props: Partial<IButton>) => getTextColor(props)}
  ${(props: Partial<IButton>) => getDisabledBackgroundColor(props)}
  
  ${(props: Partial<IButton>) => props.width || "w-[unset]"}

  flex flex-row h-12 gap-x-2 justify-center items-center px-6 py-3 text-base font-bold rounded-md disabled:cursor-not-allowed tracking-wide`;

const getBorderStyle = (props: Partial<IButtonLoader>): string | undefined => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  }
  return undefined;
};

const Loader = tw.div`
${(props: Partial<IButtonLoader>) =>
  getBorderStyle(
    props,
  )} inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const Button = ({
  isLoading,
  icon,
  variant,
  width,
  onClick,
  children,
  style,
}: IButton) => {
  return (
    <Container
      disabled={isLoading}
      width={width}
      variant={variant}
      onClick={onClick}
      style={style}
      {...(!onClick && { type: "submit" })}
    >
      {isLoading ? (
        <Loader variant={variant} />
      ) : (
        <>
          {icon}
          {children}
        </>
      )}
    </Container>
  );
};

export default Button;
