import { useState, useEffect } from "react";
import { Accordion, Alert, Box, Snackbar } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getSimulationConfig } from "@/hooks/use-loan";
import { Input } from "@/components/formElements/input-group";
import { useForm } from "react-hook-form";
import { TableContainer } from "@/layouts/application-layouts/styles";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { Button, message } from "antd";
import SimulatedCarInfo from "@/components/SimulatedCarInfo";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import storage from "@/lib/storage";
import { convertStringToBoolean } from "@/utils/stringToBoolean";
import Grid from "@mui/material/Grid";
import { Loader } from "../auth/styles/loader";

const CarContainer = styled.div`
  .MuiAccordion-root {
    margin-bottom: 1.5rem;
  }
  .MuiAccordionDetails-root {
    border-top: 1px solid rgb(229, 231, 235);
  }
  .formLabel {
    font-weight: 600;
    font-size: 14px;
    color: #30345e;
  }
`;

const OfferSimulator = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("inventory");
  const { t: t2 } = useTranslation("loan-form");
  const queryParam = new URLSearchParams(window.location.search);
  const loanId: string = queryParam.get("loan_id") ?? "";
  const partnerId: string = queryParam.get("partner_id") ?? "";
  const offerId: string = queryParam.get("offer_id") ?? "";
  const carId = queryParam.get("car_id");
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [loanFieldConfig, setLoanFieldConfig] = useState<any>([]);
  const [configData, setConfigData] = useState<any>([]);
  const [loanFields, setLoanFields]: any = useState({});
  const [loansById, setLoansById] = useState<any>({});
  const [carData, setCarData] = useState<any>({});
  const [alert, setAlert] = useState<any>("");
  const [offersById, setOffersById] = useState<any>({});
  const role = storage.getRole();

  const methods = useForm();
  const { handleSubmit } = methods;

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    document.title = `Dealer Plus | Offer Simulation [${role}]`;
  }, [role]);

  useEffect(() => {
    setLoading(true);
    const simulationConfig = getSimulationConfig(loanId);
    simulationConfig.then((result) => {
      const parsedData = JSON.parse(result.data);
      const loanPreferenceField =
        parsedData?.loanFields?.find(
          (item: { name: string }) => item.name === "loanPreferences"
        )?.fields || [];

      const consentField =
        parsedData?.loanFields?.find(
          (item: { name: string }) => item.name === "termsAndConditions"
        )?.fields || [];

      const newLoanFields = [...loanPreferenceField, ...consentField];

      setLoanFieldConfig(newLoanFields);
      setConfigData(parsedData);
      setLoading(false);
    });
  }, [loanId]);

  useEffect(() => {
    const creditCheckConsent = {
      name: "creditCheckConsent",
      value: "Yes",
      type: "radio",
      valid: true,
    };

    const financeAndVehicleConsent = {
      name: "financeAndVehicleConsent",
      value: "Yes",
      type: "radio",
      valid: true,
    };

    const platformPolicy = {
      name: "platformPolicy",
      value: "Yes",
      type: "radio",
      valid: true,
    };

    let data: any = {
      creditCheckConsent,
      financeAndVehicleConsent,
      platformPolicy,
    };

    setLoanFields((state: any) => ({
      ...state,
      ...data,
    }));
  }, []);

  useQuery({
    queryKey: ["offer_simulator_loan_id", loanId],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${loanId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      const filteredOffer = parsedLoanDetails?.offers?.filter(
        (item: any) => item?.partner?.id === partnerId
      );
      setOffersById(filteredOffer[0]);
      setLoansById(parsedLoanDetails);
    },
    retry: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["admin_cars_on_simulator", carId],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inventory/admin/car/${carId}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarData(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const loanPreferencesFields: any = [];

    configData?.loanFields?.forEach((item: any, index: any) => {
      if (item.name === "loanPreferences") {
        loanPreferencesFields.push(item.fields);
      }
    });

    const fieldWithDefaultVal = loanPreferencesFields
      ?.flat()
      .filter((field: any) => field.defaultValue);

    const initialDefaultValField = fieldWithDefaultVal?.map((field: any) => ({
      name: field.name,
      value: field.defaultValue,
      type: field.type,
      valid: true,
    }));

    initialDefaultValField.forEach((field: any) => {
      setLoanFields((state: any) => ({
        ...state,
        [field.name]: field,
      }));
    });
  }, [configData, loanId]);

  const handleLoanFieldChange = async (e: any) => {
    const { name, value, type, label } = e.target;
    const valueObject = {
      name,
      value,
      type,
      label: label || e.label,
      valid: true,
    };

    setLoanFields((state: any) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  const submit = async () => {
    setButtonLoader(true);
    setAlert(null);

    Object.keys(loanFields).forEach((item) => {
      if (loanFields[item]?.type === "amount") {
        loanFields[item].value = loanFields[item]?.value?.replaceAll(",", "");
      }
    });

    const mappedLoanFields = Object.values(loanFields).map((field: any) => ({
      ...field,
      value:
        field.type === "checkbox"
          ? convertStringToBoolean(field.value)
          : field.value,
    }));

    const payload = {
      loan_id: loanId,
      car_id: carId,
      partner_id: partnerId,
      customer_preferences: Object.values(mappedLoanFields),
    };

    try {
      const response: any = await client.post(
        `v2/origination/loans/offer-simulations`,
        payload
      );
      if (response) {
        message.success("Offer simulation created successfully");
        setButtonLoader(false);
        navigate(
          `/applications/offer-simulation-history?loan_id=${loanId}&offer_id=${offerId}&partner_id=${partnerId}`
        );
      }
    } catch (error: any) {
      setButtonLoader(false);
      setAlert(JSON.parse(error?.response?.data)?.error);
    }
  };

  if (loading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <div className="w-[100%] md:w-[51%] h-[auto] my-[0] mx-[auto] flex flex-col gap-4">
      <div className="relative flex items-center md:justify-center gap-2 bg-white md:bg-transparent px-[12px] py-[16px] md:p-[16px] rounded-lg border-b-[1px] md:border-b-transparent border-[#e5e7eb]">
        <button
          className="relative md:absolute left-0 flex items-center gap-1"
          onClick={() => navigate(`/applications/loans/${loanId}`)}
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
            alt="arrow-back"
            className="w-[24px] h-[24px]"
          />{" "}
          <div className="hidden md:block font-bold text-[16px] text-[#30345e]">
            {capitalFirstLetter(t1("back"))}
          </div>
        </button>
        <div className="font-bold font-[OutfitBold] text-[#30345e] text-[20px] md:text-[30px]">
          {t("offer-simulator")}
        </div>
      </div>

      <CarContainer>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="font-bold text-[#30345e] text-[16px] md:text-[20px]">
              {t("car-info")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <SimulatedCarInfo
              carData={carData}
              loanId={loanId}
              partnerId={partnerId}
              offersById={offersById}
              loansById={loansById}
            />
          </AccordionDetails>
        </Accordion>

        {loanFieldConfig && (
          <>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={!!alert}
              autoHideDuration={5000}
              onClose={() => setAlert("")}
            >
              <Alert severity="error" variant="filled">
                {alert}
              </Alert>
            </Snackbar>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleAccordionChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="font-bold text-[#30345e] text-[16px] md:text-[20px]">
                  {t("customer-loan-preference")}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Grid container spacing={2}>
                    {loanFieldConfig?.map((item: any, index: number) => {
                      const shouldRender =
                        !item?.channel ||
                        (item?.channel === "dealerplus" &&
                          !(
                            item?.name === "creditCheckConsent" ||
                            item?.name === "financeAndVehicleConsent"
                          ));

                      return shouldRender ? (
                        <Input
                          formData={loanFields}
                          key={loanFieldConfig.indexOf(item)}
                          required={item.required}
                          type={item.type}
                          label={item.label}
                          placeholder={item.label}
                          name={item.name}
                          when={item.when}
                          options={item.options?.length ? item.options : []}
                          onChange={handleLoanFieldChange}
                          value={
                            item.value ||
                            loanFields[item.name]?.value ||
                            item.defaultValue
                          }
                          selectOptions={
                            item.options?.length ? item.options : []
                          }
                          visibility={true}
                          innerVisibility={true}
                          fullWidth={item.fullWidth}
                          autoComplete={false}
                          subsection={
                            item.subsection ===
                            loanFieldConfig[index - 1]?.subsection
                              ? null
                              : item.subsection
                          }
                          hideSubsection={false}
                          addDirector={undefined}
                          directorCount={undefined}
                        />
                      ) : (
                        <></>
                      );
                    })}
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        )}

        <div className="flex flex-col gap-4 bg-white mt-7 py-5 px-4 rounded-lg">
          <div className="flex gap-4 items-center justify-center">
            <Button
              onClick={handleSubmit(submit)}
              className="bg-[#ffb619] text-[#30345e] text-[16px] font-bold py-2 px-4 rounded-full w-1/2 h-[48px]"
            >
              {buttonLoader ? <Loader variant="secondary" /> : t2("Submit")}
            </Button>
          </div>
          <button
            className="text-[#30345e] text-[15px] mt-2 text-center font-bold"
            onClick={() =>
              navigate(
                `/applications/offer-simulation-history?loan_id=${loanId}&partner_id=${partnerId}`
              )
            }
          >
            {t("view-simulation-history")}
          </button>
        </div>
      </CarContainer>
    </div>
  );
};

export default OfferSimulator;
