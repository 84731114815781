import { useState } from "react";
import Chart from "@/components/chart";
import { Button, Row, Col, Collapse, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import EmailCustomer, { LoanInformation } from "./EmailCustomer";
import {
  ActionButtons,
  AdditionalFeeContent,
  BreakdownContainer,
  CollapseContainer,
  DownloadLoanButton,
  FootNoteInfo,
  LoanBreakdownContent,
} from "./styles";
import { isMobile } from "react-device-detect";
import storage from "@l/storage";
import axios from "axios";
import ReferredOfferLayout from "./ReferredOffer";
import NoLoanOffers from "./NoLoanOffer";
import { useTranslation } from "react-i18next";
import DeclinationReasonLayout from "@/components/DeclinationReason";
import formatLoanInt from "@/utils/format-loan-currency";

interface ILoanInfo {
  id: string;
  loanDetails: Record<string, any>;
  loansById: Record<string, any>;
}

const LoanInfo = ({ id, loanDetails, loansById }: ILoanInfo) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("application");
  const { Panel } = Collapse;
  const [emailModal, setEmailModal] = useState(false);
  const isAccountManager = storage.utilities.isAccountManager();
  const isFranchise = storage.utilities.isFranchise();
  const isDsaAgent = storage.utilities.isDsaAgent();
  const isDealerDsa = storage.utilities.isDealerDsa();
  const isLoanDetailsPath = location.pathname === `/applications/loans/${id}`;
  const isAssumedUser = storage.getAssumedUser();

  const showEmailModal = () => {
    setEmailModal(true);
  };

  const handleOpenEmail = () => {
    setEmailModal(false);
  };

  const handleCloseEmail = () => {
    setEmailModal(false);
  };

  const toUploadDocuments = () => {
    navigate(`/applications/upload-documents?loan_id=${id}`);
  };

  const handleShortenUrl = () => {
    const url = `https://api-ssl.bitly.com/v4/shorten`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
    };

    const payload = {
      group_guid: "Bm610av6Tt3",
      domain: "bit.ly",
      long_url: loanDetails?.pdfUrl,
    };

    axios
      .post(url, payload, {
        headers: headers,
      })
      .then((res: any) => {
        if (res?.data?.link) {
          window.open(
            `https://wa.me/${loansById?.phone}?text=Hi, there is now a conditional offer for you. Click ${res?.data?.link} to download`,
            "_blank",
            "noreferrer"
          );
        }
      })
      .catch((err: any) => {
        message.error("Error generating link");
      });
  };

  const REJECTION_STATUSES = ["REJECTED", "OFFER_DECLINED", "OFFER_REJECTED"];

  const uploadButtonText = isMobile ? t("upload-docs") : t("upload-documents");

  const getCommissionPerAccountType = () => {
    if (!loanDetails) return "---";

    if (isFranchise) {
      return formatLoanInt(loanDetails?.dealerCommission, loanCurrency);
    } else if (isAccountManager) {
      return formatLoanInt(loanDetails?.accountManagerCommission, loanCurrency);
    } else if (isDsaAgent) {
      return formatLoanInt(loanDetails?.dsaCommission, loanCurrency);
    } else if (isDealerDsa) {
      return formatLoanInt(loanDetails?.dealerDsaCommission, loanCurrency);
    }
  };
  const loanCurrency = loanDetails?.loanCurrency || loansById?.currency;
  const getConditionalLoanDetailsSection = () => (
    <Row gutter={[18, 18]}>
      {isLoanDetailsPath && (
        <Col xs={24} sm={24} md={24} lg={24} xl={5}>
          <Chart loanDetails={loanDetails} loansById={loansById} />
        </Col>
      )}
      <Col xs={24} sm={24} md={24} lg={24} xl={isLoanDetailsPath ? 19 : 24}>
        {isMobile ? (
          <CollapseContainer>
            <Collapse defaultActiveKey={["0"]} expandIconPosition="end">
              <Panel header={t("loan-breakdown")} key="1">
                <LoanInformation>
                  <ul>
                    <li>
                      <div className="name">{t("car-price")}</div>
                      <div className="value">
                        {formatLoanInt(loansById?.car?.marketplacePrice, loanCurrency)}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("loan-amount")}</div>
                      <div className="value">
                        {formatLoanInt(loanDetails?.approvedAmount, loanCurrency)}
                      </div>
                    </li>
                    <li>
                      {loansById?.productType === "Logbook Finance" ? (
                        <>
                          <div className="name">{t("Disbursed Amount")}</div>
                          <div className="value">
                            {formatLoanInt(loansById?.disburseAmount, loanCurrency)}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="name">{t("Equity")}</div>
                          <div className="value">
                            {formatLoanInt(loanDetails?.equity, loanCurrency)}
                          </div>
                        </>
                      )}
                    </li>
                    <li>
                      <div className="name">{t("monthly-repayment")}</div>
                      <div className="value">
                        {formatLoanInt(loanDetails?.monthlyRepayment, loanCurrency)}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("tenure")}</div>
                      <div className="value">
                        {loanDetails?.tenure || "---"} {t("months")}
                      </div>
                    </li>
                    {!isLoanDetailsPath && (
                      <li>
                        <div className="name">{t("commission")}</div>
                        <div className="value">
                          {getCommissionPerAccountType()}
                        </div>
                      </li>
                    )}

                    <li>
                      <div className="name">{t("interest")}</div>
                      <div className="value">{t("available-on-request")}</div>
                    </li>
                  </ul>
                </LoanInformation>
              </Panel>
              <Panel header={t("additional-fees")} key="2">
                <LoanInformation>
                  <ul>
                    {loanDetails?.additionalPayments?.map((el: any) => (
                      <li key={loanDetails?.additionalPayments?.indexOf(el)}>
                        <div className="name">{t(el?.name)}</div>
                        <div className="value">
                          {formatLoanInt(el?.value, loanCurrency)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </LoanInformation>
              </Panel>
            </Collapse>
          </CollapseContainer>
        ) : (
          <BreakdownContainer>
            <LoanBreakdownContent>
              <div className="header">{t("loan-breakdown")}</div>
              <div className="breakdown-container">
                <div className="content">
                  <div className="name">{t("loan-amount")}</div>
                  <div className="value">
                    {formatLoanInt(loanDetails?.approvedAmount, loanCurrency)}
                  </div>
                </div>
                <div className="content">
                  {loansById?.productType === "Logbook Finance" ? (
                    <>
                      <div className="name">{t("Disbursed Amount")}</div>
                      <div className="value">
                        {formatLoanInt(loansById?.disburseAmount, loanCurrency)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="name">{t("Equity")}</div>
                      <div className="value">
                        {formatLoanInt(loanDetails?.equity, loanCurrency)}
                      </div>
                    </>
                  )}
                </div>
                <div className="content">
                  <div className="name">{t("tenure")}</div>
                  <div className="value">
                    {loanDetails?.tenure || "---"} {t("months")}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("monthly-repayment")}</div>
                  <div className="value">
                    {formatLoanInt(loanDetails?.monthlyRepayment, loanCurrency)}
                  </div>
                </div>
                {!isLoanDetailsPath && (
                  <div className="content">
                    <div className="name">{t("commission")}</div>
                    <div className="value">{getCommissionPerAccountType()}</div>
                  </div>
                )}

                <div className="content">
                  <div className="name">{t("interest-rate")}</div>
                  <div className="value">{t("available-on-request")}</div>
                </div>
              </div>
            </LoanBreakdownContent>
            <AdditionalFeeContent>
              <div className="header">{t("additional-payments")}</div>
              <div className="breakdown-container">
                {loanDetails?.additionalPayments?.map((el: any) => (
                  <div
                    className="content"
                    key={loanDetails?.additionalPayments?.indexOf(el)}
                  >
                    <div className="name">{t(el?.name)}</div>
                    <div className="value">{formatLoanInt(el?.value, loanCurrency)}</div>
                  </div>
                ))}
              </div>
            </AdditionalFeeContent>
          </BreakdownContainer>
        )}

        {isLoanDetailsPath && (
          <>
            {isMobile && (
              <DownloadLoanButton>
                <Button
                  type="link"
                  href={loanDetails?.pdfUrl}
                  target="_blank"
                  disabled={
                    !loanDetails?.pdfUrl ||
                    !!loansById?.salesOppId?.trim() ||
                    !!isAssumedUser
                  }
                  download
                >
                  {t("download-loan-offer")}
                </Button>
              </DownloadLoanButton>
            )}
          </>
        )}

        {isLoanDetailsPath && <FootNoteInfo>{t("offer-note")}</FootNoteInfo>}

        {isLoanDetailsPath && (
          <ActionButtons>
            <div className="grouping">
              <Button
                type="link"
                onClick={handleShortenUrl}
                disabled={!!loansById?.salesOppId?.trim() || !!isAssumedUser}
                className="px-0"
              >
                <img
                  src="https://media.autochek.africa/file/publicAssets/logos_whatsapp-1.svg"
                  alt="Chat on WhatsApp"
                />
              </Button>

              <Button
                className="email"
                onClick={showEmailModal}
                disabled={!!loansById?.salesOppId?.trim() || !!isAssumedUser}
              >
                {t("email-customer")}
              </Button>

              {loansById?.status === "CONDITIONAL_OFFER_RECEIVED" && (
                <button
                  onClick={toUploadDocuments}
                  className="hidden w-fit px-4 py-1.5 bg-[#ffb619] text-[#30345e] hover:text-[#30345e] text-sm font-bold rounded-[60px] border-none disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                  disabled={!!loansById?.salesOppId?.trim() || !!isAssumedUser}
                >
                  {uploadButtonText}
                </button>
              )}
            </div>
          </ActionButtons>
        )}
      </Col>
    </Row>
  );

  const getDownloadButton = () => {
    return (
      !isMobile && (
        <DownloadLoanButton>
          <Button
            type="link"
            href={loanDetails?.pdfUrl}
            target="_blank"
            disabled={
              !loanDetails?.pdfUrl ||
              !!loansById?.salesOppId?.trim() ||
              !!isAssumedUser
            }
            download
          >
            {t("download-loan-offer")}
          </Button>
        </DownloadLoanButton>
      )
    );
  };

  const renderConditionalOffer = () => {
    if (
      loanDetails?.status === "OFFER_REFERRED" ||
      loansById?.status === "REFERRED_CONDITIONAL_OFFER" ||
      loansById?.status === "CONDITIONAL_OFFER_REFERRED"
    ) {
      return <ReferredOfferLayout loanDetails={loanDetails} />;
    } else if (REJECTION_STATUSES.includes(loanDetails?.status as string)) {
      return <DeclinationReasonLayout offers={loanDetails} loan={loansById} />;
    } else if (loanDetails === undefined || loansById?.offers?.length === 0) {
      return <NoLoanOffers />;
    } else {
      return (
        <div>
          {isLoanDetailsPath && getDownloadButton()}
          {getConditionalLoanDetailsSection()}
        </div>
      );
    }
  };

  return (
    <div>
      {renderConditionalOffer()}

      <EmailCustomer
        emailModal={emailModal}
        handleOpenEmail={handleOpenEmail}
        handleCloseEmail={handleCloseEmail}
        loansById={loansById}
        loanDetails={loanDetails}
        id={id}
      />
    </div>
  );
};

export default LoanInfo;
