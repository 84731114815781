import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { device } from "@/utils/device";
import styled from "styled-components";
import { CustomFormField } from "@/layouts/application-layouts/styles";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import client from "@/lib/axios-client";
import { Button } from "antd";
import storage from "@l/storage";
import { useQuery } from "@tanstack/react-query";

const SelectDealerHeaderDontainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.18px;
    padding-left: 15rem;
    @media ${device.mobileL} {
      padding-left: 1rem;
      margin-top: 1rem;
    }
  }
  .back {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    cursor: pointer;
  }
`;

const SelectDealerFormContainer = styled.div`
  background: #fff;
  margin-top: 48px;
  max-width: 50%;
  margin-inline: auto;
  padding: 24px 24px 56px 24px;
  @media ${device.mobileL} {
    max-width: 100%;
    padding: 1rem;
  }
  .form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    gap: 1rem;
    border-radius: 0px 0px 16px 16px;

    @media ${device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const SelectDealer = () => {
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const { t } = useTranslation("inventory");
  const navigate = useNavigate();
  const [getDealers, setGetDealers] = useState<any>([]);
  const [fetchDealers, setFetchDealers] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const formType = searchParams.get("form_type");
  const productType = searchParams.get("product_type");
  const user = storage.getUser();

  const handleDealerChange = (e: any, newValue: any) => {
    setSelectedValue(newValue);
  };

  const handleSubmit = () => {
    navigate(
      `/dealer/financing/loan?form_type=${formType}&product_type=${productType}&dealer_id=${selectedValue.value}`
    );
  };

  const country = storage.getCountry();

  const { isLoading: fLoanLoading } = useQuery({
    queryKey: ["pending_loan_statuses_1", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        page_size: "10000",
        account_manager_id: user.id
      });
      return client.get(`v1/franchise`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoanStatus = JSON.parse(res?.data);
      setGetDealers(parsedLoanStatus?.result);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const filterDealers = getDealers?.map((dealer: IDealerUser) => {
      return {
        label: `${dealer.name}`,
        value: dealer?.id,
      };
    });
    setFetchDealers(filterDealers);
  }, [getDealers]);

  return (
    <main>
      <SelectDealerHeaderDontainer>
        <button onClick={() => navigate(-1)} className="back">
          <img
            src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
            alt="chevron-back"
            style={{ marginTop: "3px", marginRight: "10px" }}
          />
          {t("back")}
        </button>
        <div className="title">
          <div>{t("select-dealer")}</div>
        </div>
        <div className="form">
          <div className="container"></div>
        </div>
        <div className="next"></div>
      </SelectDealerHeaderDontainer>
      <SelectDealerFormContainer>
        <div className="form">
          <CustomFormField>
            <div className="label">{t("list-of-dealers")}</div>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                id="filter_loans"
                disablePortal
                loading={fLoanLoading}
                options={fetchDealers}
                onChange={handleDealerChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("select-dealer")} />
                )}
              />
            </FormControl>
          </CustomFormField>
          <CustomFormField>
            <div className="loading-btn-container">
              <Button
                className="loading-btn"
                type="primary"
                onClick={handleSubmit}
                disabled={selectedValue.length === 0}
              >
                {t("proceed-to-loan-application")}
              </Button>
            </div>
          </CustomFormField>
        </div>
      </SelectDealerFormContainer>
    </main>
  );
};

export default SelectDealer;
