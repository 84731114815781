import { Item } from "@crudmates/form-config";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function FormInputComment({ item }: { item: Item }) {
  const { t } = useTranslation("car-form");

  return (
    <Fragment>
      {item?.comment && (
        <p className="text-xs text-gray-400 leading-tight">
          {t(item?.comment)}
        </p>
      )}
    </Fragment>
  );
}
